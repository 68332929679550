import { Component, OnInit } from '@angular/core';
import { PaymentplanService } from '../shared/services/paymentplan.service';

@Component({
 selector: 'app-payment-offers',
 templateUrl: './payment-offers.component.html',
 styleUrls: ['./payment-offers.component.scss']
})
export class PaymentOffersComponent implements OnInit {
  
    stripe = require('stripe')('sk_test_51J9P4XHE9rvMqwXXSJNChK2WoKcmFGVDLCJomoIiiEgWMvvtgOwcIStMagGuwLCTkZExPhERrjfLx2GsCa8ejpEx00sN6oUumJ'); 
    private key: string = 'pk_test_51J9P4XHE9rvMqwXXBhqF4VUO5xUb9HfFMXKiTkMmdNOns7cEtQC6XNoNzfLVKSYMckH0eJXBuA2h6KlXB6ULG9PE00zfAK8giN';

    handler:any = null;
    selectedIndex = 0;  

    selectedDiv: number = 0;

    constructor(private paymentplanService: PaymentplanService) { 

    }
  
    ngOnInit() {
        this.invokeStripe(); 
    }

    
    selectDiv(divNumber: number) {
        this.selectedDiv = divNumber;
    }

    async openPay() {
        if (this.selectedIndex === 0) {
            await this.makePayment2('price_1MdmDMHE9rvMqwXXjXJ9PyeL', 100);
        } else if (this.selectedIndex === 1) {
            await this.makePayment2('price_1Mful6HE9rvMqwXXeZe5G6Em', 200);
        } else {
            
        }
    }

    async makePayment2(priceId: string, amount: number) {
        const customerEmail = await this.paymentplanService.getCurrentUserEmail();
        const company = await this.paymentplanService.getClickedCompany();
        var handler = (<any>window).StripeCheckout.configure({
        key: this.key,
        locale: 'auto',
        token: async (token: any) => {
            token.metadata = {
            customerEmail: customerEmail,
            companyKey: company.$key
            };
            token.priceId = priceId;
            try {
            await this.paymentplanService.sendStripeToken(token, company, amount);
            } catch (err) {
            console.error(err);
            }
        }
        });

        handler.open({
        name: 'eBottli',
        description: 'Monthly Subscription for ' + company.companyName,
        amount: amount * 100,
        });
    }

    async makePayment(priceId: string, amount: number) {
        if(localStorage.getItem('company') !== null) { 
            const company = JSON.parse(localStorage.getItem('company')!);
            if(company.subscriptionPrice === amount) {
                alert('You are already subscribed to this plan!');
                return;
            } else if(company.subscriptionPrice !== amount && company.subscriptionPrice !== undefined){ // Updating subscription
                var handler = (<any>window).StripeCheckout.configure({
                key: this.key,
                locale: 'auto',
                token: async (token: any) => {
                    token.priceId = priceId;
                    token.subscriptionId = company.subscriptionId;
                    token.amount = amount;
                    try {
                    await this.paymentplanService.changeSubscription(token);
                    } catch (err) {
                    console.error(err);
                    }
                }
                });

                handler.open({
                name: 'eBottli',
                description: 'Change Subscription for ' + company.companyName,
                amount: amount * 100,
                });
            } else {
                const customerEmail = await this.paymentplanService.getCurrentUserEmail();
                const company = await this.paymentplanService.getClickedCompany();
                var handler = (<any>window).StripeCheckout.configure({
                key: this.key,
                locale: 'auto',
                token: async (token: any) => {
                    token.metadata = {
                    customerEmail: customerEmail,
                    companyKey: company.$key
                    };
                    token.priceId = priceId;
                    try {
                    await this.paymentplanService.sendStripeToken(token, company, amount);
                    } catch (err) {
                    console.error(err);
                    }
                }
                });

                handler.open({
                name: 'eBottli',
                description: 'Monthly Subscription for ' + company.companyName,
                amount: amount * 100,
                });
            }
        }
    
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }

  nextButton(): void {
    localStorage.setItem('selectedOffer', this.selectedDiv+'');
  }
}


