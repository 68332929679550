import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { HarvestCrudService } from 'src/app/shared/services/harvest-crud.service';
import { Harvest } from 'src/app/shared/interfaces/harvest';
import { BoatCrudService } from '../shared/services/boat-crud.service';
import { Boat } from '../shared/interfaces/boat';


@Component({
  selector: 'app-harvests',
  templateUrl: './harvests.component.html',
  styleUrls: ['./harvests.component.scss']
})
export class HarvestsComponent implements OnInit {

  harvests: Harvest[];
  harvest: Harvest;
  selectedHarvests: Harvest[] = [];

  boats: Harvest[];
  boatsMap : Map<string,Boat>;

  harvestDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private harvestService : HarvestCrudService,
    private boatService : BoatCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Harvests'}
    ];

    this.harvests = [];
    this.harvest = { } as Harvest ;

    this.boats = [];
    this.boatsMap = new Map<string,Boat>();

  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let s = this.harvestService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.harvests = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Harvest = jsonItem as Harvest;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.harvests.push(l);
        }
      })
    });

    let b = this.boatService.getList(); 
    b.snapshotChanges().subscribe(data => {
      this.boats = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Boat = jsonItem as Boat;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          if ( l.isEnabled ) { 
            this.boats.push(l);
          }
          this.boatsMap.set( l.$key , l);
        }
      });
      this.boats = this.boats.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);
    });

    // column management
    this.cols = [
      { field: 'qrcode', header: 'QRCode' },
      { field: 'name', header: 'Name' },
      
      { field: 'instant', header: 'Date time' },
      { field: 'boat', header: 'Name' },

      { field: 'captain', header: 'Captain' },
      { field: 'crews', header: 'Crews' },
      { field: 'location', header: 'Area' },
      { field: 'latitude', header: 'Latitude' }, 
      { field: 'longitude', header: 'Longitude' },
      
      { field: 'description', header: 'Description' },
      { field: 'tags', header: 'Tags' }, 
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // instant?: string;
    // boat?: string;
    

    // location?: string;
    // latitude?: string;
    // longitude?: string;

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'qrcode');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'name');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'captain');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'crews');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'tags');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.harvestService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.harvests);
            doc.save('harvests.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.harvests);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "harvests");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedHarvests);
      // console.log('SAVEC CSV LOCATIONS ', this.harvests);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedHarvests, 'harvests', exportCols);
   // this.exportService.exportToCsv(this.harvests, 'harvests', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.harvest = {} as Harvest;
    this.submitted = false;
    this.harvestDialog = true;
  }

  deleteSelectedHarvests() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected harvests?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.harvests = this.harvests.filter(val => !this.selectedHarvests.includes(val));
            
            this.selectedHarvests.forEach( harvest => this.harvestService.delete(harvest.$key));
            this.selectedHarvests = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Harvestss Deleted', life: 3000});
        }
    });
  }

  editHarvest(harvest: Harvest) {
    this.harvest = {...harvest};
    this.harvestDialog = true;
  }

  deleteHarvest(harvest: Harvest) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + harvest.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.harvests = this.harvests.filter(val => val.$key !== harvest.$key);
            this.harvest = {} as Harvest;
            this.harvestService.delete(harvest.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Harvest Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.harvestDialog = false;
    this.submitted = false;
  }

  saveHarvest() {
    this.submitted = true;

    this.harvest.name = this.computeName(this.harvest);

    if ( this.harvest.name.trim() ) {
      if (this.harvest.$key) {
          this.harvests[this.findIndexById(this.harvest.$key)] = this.harvest;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Harvest Updated', life: 3000});
          
          
          
          this.harvestService.update(this.harvest);
        } else {
          this.harvest.position = this.harvests.length;
          this.harvestService.add(this.harvest);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Harvest Created', life: 3000});
      this.harvests = [...this.harvests];
    }

    this.harvestDialog = false;
    this.harvest = { } as Harvest;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.harvests.length; i++) {
        if (this.harvests[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeHarvest(harvest : Harvest) {
    // let name : string = '';
    // if (harvest.instant) {
    //   name  = harvest.instant;
    //   if ( typeof name === 'string') {
    //     name = name.substring(0,10);
    //     if (harvest.boat) {
    //       name += ' ';
    //     }
    //   }
    //   // console.log(typeof name);
    //   // console.log(name);
    //   // name = name.substring(0,10);
      
    // } 
    // if (harvest.boat) {
    //   name += harvest.boat;
    // } 
    harvest.name = this.computeName(harvest);
    this.harvestService.update(harvest);
  }

  computeName(harvest: Harvest) {
    let name : string = '';
    if (harvest.instant) {
      name  = harvest.instant;
      if ( typeof name === 'string') {
        name = name.substring(0,10);
        if (harvest.boatName) {
          name += ' ';
        }
      }
      // console.log(typeof name);
      // console.log(name);
      // name = name.substring(0,10);
      
    } 
    if (harvest.boat) {
      name += harvest.boat;
    } 
    return name;
  }

  completePosition(harvest : Harvest) {
    if ( this.newPosition > -1 ) {
        harvest.position = this.newPosition;
        harvest = this.checkPositionLimits(harvest);
        this.reorderElements(harvest);
        //this.updateDB(harvest);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.harvests);
    // const myClonedArray = [];
    // this.harvests.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.harvests.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.harvests.length - 1 - event.dropIndex;
    const harvestToMove = this.harvests[startIndex];

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.harvests[index] = this.harvests[index+1];
        this.harvests[index].position = index;
        this.harvestService.update(this.harvests[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.harvests[index] = this.harvests[index-1];
        this.harvests[index].position = index;
        this.harvestService.update(this.harvests[index]);
      }
    } 
    this.harvests[endIndex] = harvestToMove;
    this.harvests[endIndex].position = endIndex;
    this.harvestService.update(this.harvests[endIndex]);
  }

  // onFieldEdit(harvest: Spray, fieldName: string): void {
  //   //console.log(this.harvests);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + harvest[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       harvest.position = this.newPosition;
  //       harvest = this.checkPositionLimits(harvest);
  //       this.reorderElements(harvest);
  //       //this.updateDB(harvest);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(harvest);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.harvests.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, harvest: Harvest) {
    // console.log("event" +event);
    if (harvest.position != null && harvest.position > -1) {
      this.previousPosition = harvest.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(harvest: Harvest) {
    console.log('check ',harvest.position + ' ' + this.harvests.length);
    if (!harvest.position) {
     // console.log('check 1');
      return this.harvest;
    }
    if (harvest.position < 0) {
      harvest.position = 0;
      // console.log('check 2');
      return harvest;
    }
    if (harvest.position > this.harvests.length) {
      harvest.position = this.harvests.length - 1;
    //  console.log('check 3', harvest.position);
      return harvest;
    }
   // console.log('check 4');
    return harvest;
  }

  async reorderElements(harvest: Harvest) {
    this.newPosition = this.newPosition  > this.harvests.length ? this.harvests.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === harvest.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const harvestToMove = this.harvests[startIndex];
   // console.log('Item to move: ', this.harvests[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        // console.log('LOC', this.harvests);
        // console.log('Item to +1: ', this.harvests[index+1]);

        this.harvests[index] = this.harvests[index+1];
        this.harvests[index].position = index;
        // console.log('Move: ', this.harvests[index+1]);
        // console.log('Move pos: ', this.harvests[index].position);
        //await this.updateDB(this.harvests[index]);
        this.harvestService.update(this.harvests[index]);
     //   console.log('Update DB: ', this.harvests[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.harvests[index] = this.harvests[index-1];
        this.harvests[index].position = index;
        this.harvestService.update(this.harvests[index]);
      }
    } 
    this.harvests[endIndex] = harvestToMove;
    this.harvests[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.harvestService.update(this.harvests[endIndex]);
    
    // const myClonedArray = [];
    // this.harvests.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.harvests = myClonedArray;
   // this.getAllRecords();
  }

  onSelect (event: any, harvest: Harvest) {
    //this.completeBarrel(barrel);
  }

  onBlur(event: any, harvest: Harvest) {
    this.completeHarvest(harvest);
  }

  onClose(event: any, harvest: Harvest) {
    this.completeHarvest(harvest);
  }

  onChangeBoat(harvest: Harvest, harvestBoatKey: string, event: any): boolean {

    const newKey = event.value;
    const previousKey = harvestBoatKey;


    // Si il n'y en avait pas avant et ni maintenant, riemn a faire
    if (!previousKey && !newKey) {
      return true;
    }

    // Si le barril est le meme rien a faire
    if (previousKey && newKey && previousKey == newKey) {
      return true;
    }


    // Sil il n'y avait pas de barril avant et maintenant oui
    if (!previousKey && newKey) {

      const newBoat = this.boatsMap.get(newKey) || {} as Boat;
      const newBoatName = newBoat.name;
        
      harvest.boatKey = newKey;
      harvest.boatName = newBoatName;
      this.harvestService.update(harvest);
      return true;

    }

    if (previousKey && !newKey) {
      harvest.boatKey = '';
      harvest.boatName = '';
      this.harvestService.update(harvest);
      return true;
    }

    const newBoat = this.boatsMap.get(newKey) || {} as Boat;
    const newBoatName = newBoat.name;
      
    harvest.boatKey = newKey;
    harvest.boatName = newBoatName;
    this.harvestService.update(harvest);
    return true;
  }

  displayBoatName(key : string): string {
    if (this.boatsMap.has(key)) {
      return this.boatsMap.get(key)?.name || '';
    }
    return '';
  }
}