import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { PalletCrudService } from 'src/app/shared/services/pallet-crud.service';
import { Pallet } from 'src/app/shared/interfaces/pallet';
//import { BatchCrudService } from 'src/app/shared/services/batch-crud.service';

import { Batch } from 'src/app/shared/interfaces/batch';
import { PalletLocation as Location } from 'src/app/shared/interfaces/pallet-location';
import { WineCrudService } from 'src/app/shared/services/wine-crud.service';
import { Wine } from 'src/app/shared/interfaces/wine';
import { PalletLocationCrudService } from 'src/app/shared/services/pallet-location-crud.service';
import { Client } from 'src/app/shared/interfaces/client';
import { ClientCrudService } from 'src/app/shared/services/client-crud.service';
import { PalletArchiveCrudService } from 'src/app/shared/services/pallet-archive-crud.service';


@Component({
  selector: 'app-pallet-current',
  templateUrl: './pallet-current.component.html',
  styleUrls: ['./pallet-current.component.scss']
})
export class PalletCurrentComponent implements OnInit {

  pallets: Pallet[];
  pallet: Pallet;
  selectedPallets: Pallet[] = [];


  clients: Client[];
  clientsMap : Map<string,Client>;


  locations: Location[];
  locationsMap : Map<string,Location>;

  wines: Wine[];
  winesMap : Map<string,Wine>;

  palletDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private palletService : PalletCrudService,
    private palletArchiveService : PalletArchiveCrudService,
    private clientService: ClientCrudService,
    private locationService: PalletLocationCrudService,
    private wineService: WineCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Pallets'}
    ];

    this.pallets = [];
    this.pallet = { } as Pallet ;

    this.clients = [];
    this.clientsMap = new Map<string,Client>();

    this.locations = [];
    this.locationsMap = new Map<string,Location>();

    this.wines = [];
    this.winesMap = new Map<string,Wine>();
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let s = this.palletService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.pallets = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Pallet = jsonItem as Pallet;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }

          this.pallets.push(l);
        }
      });
      this.pallets = this.pallets.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);
    });


  

    let c = this.clientService.getClientsList(); 
    c.snapshotChanges().subscribe(data => {
      this.clients = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Client = jsonItem as Client;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          if ( l.isEnabled ) { 
            this.clients.push(l);
          }
          this.clientsMap.set( l.$key , l);
        }
      });
      this.clients = this.clients.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);
    });


    let loc = this.locationService.getLocationsList(); 
    loc.snapshotChanges().subscribe(data => {
      this.locations = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Location = jsonItem as Location;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          if ( l.isEnabled ) { 
            this.locations.push(l);
            
          }
          this.locationsMap.set( l.$key , l);
         
        }
      });
      this.locations = this.locations.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);
    });

  
    let wi = this.wineService.getList(); 
    wi.snapshotChanges().subscribe(data => {
      this.wines = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Wine = jsonItem as Wine;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          if ( l.isEnabled ) {
            this.wines.push(l);
          };
          this.winesMap.set( l.$key , l);
        }
      });
      this.wines = this.wines.sort((a, b) => ( (a.name || 0) < (b.name || 1) ) ? -1 : 1);
     
    });

    // column management
    this.cols = [
    //  { field: 'id', header: 'Id' },
      { field: 'name', header: 'Name' },
      { field: 'qrcode', header: 'QRCode' },
      { field: 'description', header: 'Description' },
     // { field: 'category', header: 'Category' },
      //{ field: 'year', header: 'Year' },
     // { field: 'volume', header: 'Volume' },
     // { field: 'units', header: 'Units' },
     
      { field: 'client', header: 'Client' },
     
      { field: 'tags', header: 'Tags' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'location', header: 'Location' },
      
      { field: 'wine', header: 'Wine' },
      { field: 'quantity', header: 'Quantity' },

      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
     this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'tags');
   // this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.palletService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.pallets);
            doc.save('pallets.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.pallets);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "pallets");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedPallets);
      // console.log('SAVEC CSV LOCATIONS ', this.pallets);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedPallets, 'pallets', exportCols);
   // this.exportService.exportToCsv(this.pallets, 'pallets', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.pallet = {} as Pallet;
    this.submitted = false;
    this.palletDialog = true;
  }

  deleteSelectedPallets() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected pallets?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.pallets = this.pallets.filter(val => !this.selectedPallets.includes(val));
            this.selectedPallets.forEach( pallet => this.palletService.delete(pallet.$key));
            this.selectedPallets = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Palletss Deleted', life: 3000});
        }
    });
  }

  editPallet(pallet: Pallet) {
    this.pallet = {...pallet};
    this.palletDialog = true;
  }

  deletePallet(pallet: Pallet) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + pallet.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.pallets = this.pallets.filter(val => val.$key !== pallet.$key);
            this.pallet = {} as Pallet;
            this.palletService.delete(pallet.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Pallet Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.palletDialog = false;
    this.submitted = false;
  }

  savePallet() {
    this.submitted = true;

    if ( this.pallet.name.trim() ) {
      this.pallet.isEnabled = true;
      
      if (this.pallet.$key) {
          this.pallets[this.findIndexById(this.pallet.$key)] = this.pallet;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Pallet Updated', life: 3000});
          this.palletService.update(this.pallet);
        } else {
          this.pallet.position = this.pallets.length;
          this.palletService.add(this.pallet);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Pallet Created', life: 3000});
      this.pallets = [...this.pallets];
    }

    this.palletDialog = false;
    this.pallet = { } as Pallet;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.pallets.length; i++) {
        if (this.pallets[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completePallet(pallet : Pallet) {
    console.log("SAVE", pallet);
    this.palletService.update(pallet);
  }

  completePosition(pallet : Pallet) {
   // console.log("SAVE", pallet);
  //  this.palletService.updatePallet(pallet);


    if ( this.newPosition > -1 ) {
        pallet.position = this.newPosition;
        pallet = this.checkPositionLimits(pallet);
        this.reorderElements(pallet);
        //this.updateDB(pallet);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.pallets);
    // const myClonedArray = [];
    // this.pallets.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.pallets.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.pallets.length - 1 - event.dropIndex;
    const palletToMove = this.pallets[startIndex];
    
    
    // console.log('StartIndex', startIndex);
    // console.log('EndIndex', endIndex);

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.pallets[index] = this.pallets[index+1];
        this.pallets[index].position = index;
        this.palletService.update(this.pallets[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.pallets[index] = this.pallets[index-1];
        this.pallets[index].position = index;
        this.palletService.update(this.pallets[index]);
      }
    } 
    this.pallets[endIndex] = palletToMove;
    this.pallets[endIndex].position = endIndex;
    this.palletService.update(this.pallets[endIndex]);
  }

  // onFieldEdit(pallet: Spray, fieldName: string): void {
  //   //console.log(this.pallets);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + pallet[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       pallet.position = this.newPosition;
  //       pallet = this.checkPositionLimits(pallet);
  //       this.reorderElements(pallet);
  //       //this.updateDB(pallet);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(pallet);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.pallets.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, pallet: Pallet) {
    // console.log("event" +event);
    if (pallet.position != null && pallet.position > -1) {
      this.previousPosition = pallet.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(pallet: Pallet) {
    console.log('check ',pallet.position + ' ' + this.pallets.length);
    if (!pallet.position) {
     // console.log('check 1');
      return this.pallet;
    }
    if (pallet.position < 0) {
      pallet.position = 0;
      // console.log('check 2');
      return pallet;
    }
    if (pallet.position > this.pallets.length) {
      pallet.position = this.pallets.length - 1;
    //  console.log('check 3', pallet.position);
      return pallet;
    }
   // console.log('check 4');
    return pallet;
  }

  async reorderElements(pallet: Pallet) {
    this.newPosition = this.newPosition  > this.pallets.length ? this.pallets.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === pallet.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const palletToMove = this.pallets[startIndex];
   // console.log('Item to move: ', this.pallets[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.pallets);
        console.log('Item to +1: ', this.pallets[index+1]);

        this.pallets[index] = this.pallets[index+1];
        this.pallets[index].position = index;
        // console.log('Move: ', this.pallets[index+1]);
        // console.log('Move pos: ', this.pallets[index].position);
        //await this.updateDB(this.pallets[index]);
        this.palletService.update(this.pallets[index]);
     //   console.log('Update DB: ', this.pallets[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.pallets[index] = this.pallets[index-1];
        this.pallets[index].position = index;
        this.palletService.update(this.pallets[index]);
      }
    } 
    this.pallets[endIndex] = palletToMove;
    this.pallets[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.palletService.update(this.pallets[endIndex]);
    
    // const myClonedArray = [];
    // this.pallets.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.pallets = myClonedArray;
   // this.getAllRecords();
  }

  displayRackName(key: string) {
    // if (this.racksMap.has(key) ) {
    //   const rack = this.racksMap.get(key);
    //   const name = rack?.name;
    //   const bay = rack?.bayNumber;
    //   const col = rack?.deepNumber;
    //   const lev = rack?.highNumber;
    //   return name + 
    //   ' (bay: ' + bay + 
    //   ' / col: ' + col +
    //   ' / lev: ' + lev +
    //   ')'
    //   ;
    // }
    // return 'not found'
  }

  onSelect(event: any, pallet: Pallet) {
    //this.completePallet(pallet);
  }

  onBlur(event: any, pallet: Pallet) {
    this.completePallet(pallet);
  }

  onClose(event: any, pallet: Pallet) {
    this.completePallet(pallet);
  }

  onChangeClient(pallet: Pallet, palletClientKey: string, event: any): boolean {

    // console.log('barrel event', event);
    // console.log('be ', event);

    const newKey = event.value;
    const previousKey = palletClientKey;
    // console.log('new key wanted',  newBarrelKey);
    // console.log('before key ', previousBarrelKey);

    // Si il n'y en avait pas avant et ni maintenant, riemn a faire
    if (!previousKey && !newKey) {
      return true;
    }

    // Si le barril est le meme rien a faire
    if (previousKey && newKey && previousKey == newKey) {
      return true;
    }


    // Sil il n'y avait pas de barril avant et maintenant oui
    if (!previousKey && newKey) {

      const newClient = this.clientsMap.get(newKey) || {} as Client;
      const newClientName = newClient.companyName;
        
      pallet.clientKey = newKey;
      pallet.clientName = newClientName;
      this.palletService.update(pallet);
      return true;

    }

    if (previousKey && !newKey) {
      pallet.clientKey = '';
      pallet.clientName = '';
      this.palletService.update(pallet);
      return true;
    }

    const newClient = this.clientsMap.get(newKey) || {} as Client;
    const newClientName = newClient.companyName;
        
    pallet.clientKey = newKey;
    pallet.clientName = newClientName;
    this.palletService.update(pallet);
    return true;
  }

  onChangeLocation(pallet: Pallet, palletLocationKey: string, event: any): boolean {

    // console.log('barrel event', event);
    // console.log('be ', event);

    const newKey = event.value;
    const previousKey = palletLocationKey;
    // console.log('new key wanted',  newBarrelKey);
    // console.log('before key ', previousBarrelKey);

    // Si il n'y en avait pas avant et ni maintenant, riemn a faire
    if (!previousKey && !newKey) {
      return true;
    }

    // Si le barril est le meme rien a faire
    if (previousKey && newKey && previousKey == newKey) {
      return true;
    }


    // Sil il n'y avait pas de barril avant et maintenant oui
    if (!previousKey && newKey) {

      const newLocation = this.locationsMap.get(newKey) || {} as Location;
      const newLocationName = newLocation.name;
        
      pallet.locationKey = newKey;
      pallet.locationName = newLocationName;
      this.palletService.update(pallet);
      return true;

    }

    if (previousKey && !newKey) {
      pallet.locationKey = '';
      pallet.locationName = '';
      this.palletService.update(pallet);
      return true;
    }

    const newLocation = this.locationsMap.get(newKey) || {} as Location;
    const newLocationName = newLocation.name;
        
    pallet.locationKey = newKey;
    pallet.locationName = newLocationName;
    this.palletService.update(pallet);
    return true;
  }

  onChangeWine(pallet: Pallet, palletWineKey: string, event: any): boolean {

    // console.log('barrel event', event);
    // console.log('be ', event);

    const newKey = event.value;
    const previousKey = palletWineKey;
    // console.log('new key wanted',  newBarrelKey);
    // console.log('before key ', previousBarrelKey);

    // Si il n'y en avait pas avant et ni maintenant, riemn a faire
    if (!previousKey && !newKey) {
      return true;
    }

    // Si le barril est le meme rien a faire
    if (previousKey && newKey && previousKey == newKey) {
      return true;
    }


    // Sil il n'y avait pas de barril avant et maintenant oui
    if (!previousKey && newKey) {

      const newWine = this.winesMap.get(newKey) || {} as Wine;
      const newWineName = newWine.name;
        
      pallet.wineKey = newKey;
      pallet.wineName = newWineName;
      this.palletService.update(pallet);
      return true;

    }

    if (previousKey && !newKey) {
      pallet.wineKey = '';
      pallet.wineName = '';
      this.palletService.update(pallet);
      return true;
    }

    const newWine = this.winesMap.get(newKey) || {} as Wine;
    const newWineName = newWine.name;
        
    pallet.wineKey = newKey;
    pallet.wineName = newWineName;
    this.palletService.update(pallet);
    return true;
  }



  // ARCHIVE
  archiveSelectedPallets() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to archive the selected pallets?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.pallets = this.pallets.filter(val => !this.selectedPallets.includes(val));
            
            this.selectedPallets.forEach( pallet => {
              try {
                let p : Partial<Pallet>= {
                  ...pallet
                }
                if (p.$key) {
                  delete p.$key;
                }
                this.palletArchiveService.add(p as Pallet);
                this.palletService.delete(pallet.$key);
              } catch (e) {
                console.error(pallet);
                console.error(e);
              }
              
            } );
            
            this.selectedPallets = [];

            //this.pallets = this.pallets.filter(val => val.$key !== pallet.$key);
  
            
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Pallets Archived', life: 3000});
        }
    });
  }

  archivePallet(pallet: Pallet) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to archive ' + pallet.id + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.pallets = this.pallets.filter(val => val.$key !== pallet.$key);
            
            try {
              let p : Partial<Pallet>= {
                ...pallet
              }
              if (p.$key) {
                delete p.$key;
              }
              this.palletArchiveService.add(p as Pallet);
              //this.palletService.DeletePallet(pallet.$key);
            } catch (e) {
              console.error(pallet);
              console.error(e);
            }
            this.pallet = {} as Pallet;
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Pallet Archived', life: 3000});
        }
    });
  }

  computeQuantity() : number{
    let total : number = Number(0);
    this.selectedPallets.forEach( pallet => {
      if (pallet.quantity) {
        let i : number = pallet.quantity as number;
        total += Number(i);
      }
    });
    return total;
  }

  computeClients() : string[] {
    let results : string[] = [];
    this.selectedPallets.forEach( pallet => {
      if (pallet.clientName) {
        if (!results.includes(pallet.clientName)) {
          results.push(pallet.clientName);
        }
      }
    });
    return results;
  }

  computeLocations() : string[] {
    let results : string[] = [];
    this.selectedPallets.forEach( pallet => {
      if (pallet.locationName) {
        if (!results.includes(pallet.locationName)) {
          results.push(pallet.locationName);
        }
      }
    });
    return results;
  }
}
