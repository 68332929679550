import { Component, OnInit } from '@angular/core';
import { PaymentplanService } from '../shared/services/paymentplan.service';
import { OffersCrudService } from '../shared/services/offers-crud.service';
import { Offer } from '../shared/interfaces/offer';
import { Service } from '../shared/interfaces/service';
import { MyOrganisationCrudService } from '../shared/services/my-organisation-crud.service';
import { Organisation } from '../shared/interfaces/organisation';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

  stripe = require('stripe')('sk_test_51J9P4XHE9rvMqwXXSJNChK2WoKcmFGVDLCJomoIiiEgWMvvtgOwcIStMagGuwLCTkZExPhERrjfLx2GsCa8ejpEx00sN6oUumJ');  

  handler:any = null;

  private key: string = 'pk_test_51J9P4XHE9rvMqwXXBhqF4VUO5xUb9HfFMXKiTkMmdNOns7cEtQC6XNoNzfLVKSYMckH0eJXBuA2h6KlXB6ULG9PE00zfAK8giN';


  offer: Offer;
  offers: Offer[];
  offersMap : Map<string,Offer>;

  services: string[];

  selectedIndex = 0;  
  selectedDiv: Offer = {} as Offer;

  selectDiv(divNumber: Offer) {
    this.selectedDiv = divNumber;
}

  constructor(
    private paymentplanService: PaymentplanService,
    private myOrganisationService: MyOrganisationCrudService,
    private offersService: OffersCrudService,
    public router: Router,
    private messageService: MessageService,
  ) { 
      this.offer = {} as Offer ;
      this.offers = [];
      this.offersMap = new Map<string,Offer>();
      this.services = [];
  }

  ngOnInit(): void {
    this.invokeStripe();  

    // console.log("INIT");

    let o = this.offersService.getList(); 
    o.snapshotChanges().subscribe(data => {
      this.offers = [];
      this.offersMap.clear();
      // console.log("data"+ data);
      data.forEach(item => {
        // console.log(item);
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Offer = jsonItem as Offer;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          // console.log(l);
          this.offers.push(l);
          this.offersMap.set( l.$key, l);

          if(l.services) {
            const s : Service[] = Object.assign([], l.services);
           
            for( let service of s) {
              if( !this.services.includes(service.name) ) {
                this.services.push(service.name);
              }
            }
          }
        }
      })
    });
  }

  async openPayment() {

    console.log('Open payment');

    const priceId = this.selectedDiv.stripePriceId;
    const price = parseFloat(this.selectedDiv.price);
    

    console.log('Price', price);
    console.log('Price Id', priceId);

    // Here I must be the tenant

    const organisationLocalStorage = localStorage.getItem('organisation');

    if(!organisationLocalStorage) {
      console.error('No organisation - local storage');
      return;
    }

    const organisation = JSON.parse(localStorage.getItem('organisation')!) as Organisation ;
    if (!organisation) {
      console.error('No organisation - JSON');
      return;
    }

    const user = JSON.parse(localStorage.getItem('user')!);

    // If I have already subscibed to this offers
    if (organisation.offerKey === this.selectedDiv.$key) {
      console.log('Same plan');
      alert('You have already subscribed to this plan!');
      return;
    }

    // If I chose another one 
    if(organisation.offerKey && organisation.offerKey !== this.selectedDiv.$key){ // Updating subscription
      console.log('Other plan');
      const handler = (<any>window).StripeCheckout.configure({
        key: this.key,
        locale: 'auto',
        token: async (token: any) => {
          token.metadata = {
            customerEmail: user.email ,
            companyKey: organisation.$key
          };
          token.priceId = priceId;
          token.subscriptionId = organisation.subscriptionId;
          token.amount = price;
          try {
            const succeed = await this.paymentplanService.changeSubscriptionForOganisation(token, organisation, this.selectedDiv.$key, user.uid );
            
            if(succeed) {
              this.routeTo(organisation);
            }
            // organisation.offerKey = this.selectedDiv.$key;
            // this.myOrganisationService.update(user.uid, organisation);
          } catch (err) {
            console.error(err);
          }
        }
      });

      handler.open({
        name: 'eBottli',
        description: 'Change Subscription for ' + organisation.name,
        amount: price * 100,
      });
      return;
    }

    console.log('New plan');
    const customerEmail = await this.paymentplanService.getCurrentUserEmail();
//    const company = await this.paymentplanService.getClickedCompany();
    const handler2 = (<any>window).StripeCheckout.configure({
      key: this.key,
      locale: 'auto',
      token: async (token: any) => {
        token.metadata = {
          customerEmail: customerEmail,
          companyKey: organisation.$key
        };
        token.priceId = priceId;
        try {
          const succeed = await this.paymentplanService.sendStripeTokenForOrganisation(token, organisation, price, this.selectedDiv.$key, user.uid);
          // organisation.offerKey = this.selectedDiv.$key;
          // this.myOrganisationService.update(user.uid, organisation);
          if(succeed) {
            this.messageService.add({key: 'bc', severity:'success', summary: 'Info', detail: 'Subscription confirmed'});
    
            this.routeTo(organisation);
          }
        } catch (err) {
          console.error(err);
        }
      }
    });

    handler2.open({
      name: 'eBottli',
      // customerEmail: user.email,
      email: user.email,
      description: 'Monthly Subscription for ' + organisation.name,
      amount: price * 100,
    });

    // If we have a company in the local storage, it means taht we have already selected it
    // if(localStorage.getItem('company') !== null) { 
    //   const company = JSON.parse(localStorage.getItem('company')!);
    //   //const company = JSON.parse(localStorage.getItem('organisation')!);
    //   const user = JSON.parse(localStorage.getItem('user')!);
    //   if(company.subscriptionPrice === price) {
    //     alert('You have already subscribed to this plan!');
    //     return;
    //   } else if(company.subscriptionPrice !== price && company.subscriptionPrice !== undefined){ // Updating subscription
    //     var handler = (<any>window).StripeCheckout.configure({
    //       key: this.key,
    //       locale: 'auto',
    //       token: async (token: any) => {
    //         token.metadata = {
    //           customerEmail: user.email ,
    //           companyKey: company.$key
    //         };
    //         token.priceId = priceId;
    //         token.subscriptionId = company.subscriptionId;
    //         token.amount = price;
    //         try {
    //           await this.paymentplanService.changeSubscription(token);
    //           this.myOrganisationService.update(user.uid, company);
    //         } catch (err) {
    //           console.error(err);
    //         }
    //       }
    //     });

    //     handler.open({
    //       name: 'eBottli',
    //       description: 'Change Subscription for ' + company.name,
    //       amount: price * 100,
    //     });
    //   } else {
    //     const customerEmail = await this.paymentplanService.getCurrentUserEmail();
    //     const company = await this.paymentplanService.getClickedCompany();
    //     var handler = (<any>window).StripeCheckout.configure({
    //       key: this.key,
    //       locale: 'auto',
    //       token: async (token: any) => {
    //         token.metadata = {
    //           customerEmail: customerEmail,
    //           companyKey: company.$key
    //         };
    //         token.priceId = priceId;
    //         try {
    //           await this.paymentplanService.sendStripeToken(token, company, price);
    //         } catch (err) {
    //           console.error(err);
    //         }
    //       }
    //     });

    //     handler.open({
    //       name: 'eBottli',
    //      // customerEmail: user.email,
    //       email: user.email,
    //       description: 'Monthly Subscription for ' + company.name,
    //       amount: price * 100,
    //     });
    //   }
    // }
    
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }

  hasService(offer : Offer, service: string) : boolean {
    if(!offer.services) {
      return false;
    }
    const offerServices : Service[] = Object.assign([], offer.services);
    for (let s of offerServices) {
      if (service === s.name) {
        return true;
      }
    }
    return false;
  }


  async routeTo(organisation: Organisation) {
    if (!organisation.offerKey) {
      console.error('Offer key is missing for the routing');
      return;
    }

    const offer = this.offersMap.get(organisation.offerKey);
    const homePage = offer?.homePage;

    if (!homePage) {
      return;
    }

    localStorage.setItem('organisation', JSON.stringify(organisation));
    this.router.navigate([ homePage ]);
    return;
  }

}
