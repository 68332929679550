import { Component, OnInit, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { LocationCrudService } from 'src/app/shared/services/location-crud.service';
import { Location } from 'src/app/shared/interfaces/location';
import { Product } from 'src/app/shared/interfaces/product';
import { ProductCrudService } from 'src/app/shared/services/product-crud.service';
import { WoProductCrudService } from 'src/app/shared/services/wo-product-crud.service';
import { WoProduct } from 'src/app/shared/interfaces/wo-product';
import { WoProductType } from 'src/app/shared/interfaces/wo-product-type';
import { WoProductTypeCrudService } from 'src/app/shared/services/wo-product-type-crud.service';
import { Transformation } from 'src/app/shared/interfaces/transformation';
import { WoEvent } from 'src/app/shared/interfaces/wo-event';
import { WoProductTypeComponent } from '../wo-product-type/wo-product-type.component';
import { TransformCrudService } from 'src/app/shared/services/transform-crud.service';
import { Router } from '@angular/router';
import { Dropdown } from 'primeng/dropdown';
import { AuthService } from 'src/app/shared/services/auth.service';
import { WoEventCrudService } from 'src/app/shared/services/event-crud.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-transforms',
  templateUrl: './transforms.component.html',
  styleUrls: ['./transforms.component.scss']
})
export class TransformsComponent implements OnInit {

  @ViewChild('dropdown') dropdown: Dropdown;

   // Config
   public modules = environment.modules;

   // ui beadcrumb
   breadcrumbs: MenuItem[];
   homeBreadcrumb: MenuItem = {} as MenuItem;
 
   list1: any[];
   originalList1: any[];
 
   list2: any[];
   list2Map = new Map<string, boolean>();
 
   locations: Location[] = [];
   location: Location = {} as Location;
   locationKey : string = "";
   previousLocationKey : string = "";
 
   locationMap = new Map<string, Location>();

   locationTransformation : string = '';

   productTypeMap = new Map<string, WoProductType>();

   // Transformation 
   transformedProductTypes: WoProductType[] = [];
   transformedProductType: WoProductType = {} as WoProductType;
   transformedProductTypeKey : string = "";
   transformedProductTypeOriginals : WoProductType[] = [];
   transformedMap = new Map<string, WoProductType>();
   previousTransformedTypeKey : string = "";

   code = '';
   lastCode = 0;
   viewCode = '';


   // Constructor
   constructor(
    public  authService: AuthService,
    private productService: WoProductCrudService,
    private productTypeService: WoProductTypeCrudService,
    private transformationService: TransformCrudService,
    private eventService: WoEventCrudService,
    private confirmationService: ConfirmationService,
    private locationService: LocationCrudService,
    private messageService: MessageService,
    private router : Router
     ) {
     // ui breadcrumbs
     this.breadcrumbs = [
       {label: 'Actions'},
       {label: 'Transform'}
     ];
 
     this.list1 = [];
     this.originalList1 = Object.assign([], this.list1);
     this.list2 = [];
     this.locations = [];
     this.location = {} as Location;
     this.dropdown = {} as Dropdown;
    }
 
   ngOnInit(): void {
 
     let s = this.locationService.getLocationsList(); 
     s.snapshotChanges().subscribe(data => {
       this.locations = [];
       this.locationMap = new Map<string, Location>();
       data.forEach(item => {
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }
           const loc = jsonItem as Location;
           this.locationMap.set( loc.$key , loc );
           this.locations.push(loc);
         }
       })
     });
     this.locations = this.locations.sort((a, b) => (a.name < b.name) ? -1 : 1);

     let pt = this.productTypeService.getWoProductTypesList(); 
     pt.snapshotChanges().subscribe(data => {
      
       this.transformedProductTypes = [];
       this.transformedProductTypeOriginals = [];
       data.forEach(item => {
        
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }
           const productType = jsonItem as WoProductType;
           
           
           if( productType.transformed ) {
            this.transformedMap.set( productType.$key , productType );
            this.transformedProductTypes.push(jsonItem as WoProductType);
            
            if (productType.name == 'Potato and Leek Soup')
              console.log('soldable PT',productType);
           }
           if (productType.transformable || productType.transformed) {
            //console.log('transformable', productType.name);
            this.productTypeMap.set( productType.$key , jsonItem as WoProductType);
          }

          
         }
         
       })
       this.transformedProductTypes = Object.assign([],this.transformedProductTypes);
       this.transformedProductTypeOriginals = Object.assign([], this.transformedProductTypes);
       this.transformedProductTypes = this.transformedProductTypes.sort((a, b) => (a.name < b.name) ? -1 : 1);
       this.loadProducts();
      });
   }

   loadProducts() {
    let p = this.productService.getWoProductsList(); 
     p.snapshotChanges().subscribe(data => {
       this.list1 = [];
       this.originalList1 = [];
       data.forEach(item => {
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }

           const product = jsonItem as WoProduct;
          const productTypeKey : string = product.productTypeKey || '';
          const productType = this.productTypeMap.get(productTypeKey);
          
          // console.log('PRODUCT', product);
          // console.log('PRODUCT TYPE KEY', productTypeKey);
          // console.log('PRODUCT TYPE', productType);
          if (productType && productType.transformable) {
            this.list1.push(jsonItem as WoProduct);
            this.originalList1.push(jsonItem as WoProduct); 
          }

          //  const product = jsonItem as WoProduct;
          //  if( product.productType?.transformable ) {
          //   //this.transformedMap.set( productType.$key , productType );
          //   this.list1.push(jsonItem as WoProduct);
          //   this.originalList1.push(jsonItem as WoProduct);
        
          //  }
          
         }
       })
       this.originalList1 = this.originalList1.sort((a, b) => (a.name < b.name) ? -1 : 1);
       
       this.list1 = Object.assign([],this.originalList1);
       //this.originalList1 = Object.assign([],this.list1);
       //this.transformedProductTypeOriginals = Object.assign([], this.transformedProductTypes);
     });
     this.list1 = Object.assign([],this.originalList1);
   }
 
   // EXPLICATIONS 
   // https://cdiese.fr/angular-customize-change-detection/

   confirmIsOpen : boolean = false;
 
   moveToTarget(event: any) {
    //console.log('Moved All Items: ', event.items);
    //this.confirmIsOpen = true;
    this.confirm({} as Event, event.items);
    this.list2 = [...new Set(this.list2)];
   }
 
   confirm(event: Event, items : any[]) {

    try {
      this.confirmationService.confirm({
        target: event.target || undefined,
        message: 'Are you consuming all the entire product?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            //confirm action
            items.forEach(
              item => {
                this.list2Map.set( item.name + item.batch, true);
              }
            );
           // this.confirmIsOpen = false;
        },
        reject: () => {
            //reject action
            if (items) {
              items.forEach( item => {

                this.list2Map.set( item.name + item.batch, false);
                
                const found = this.list1.find((litem) => {
                  return litem.name === item.name && litem.batch === item.batch;
                });
                
                //console.log(found); // 👉️ {id: 1, name: 'Alfred'}
                
                if (found !== undefined) {
                  // 👇️ this runs
                  console.log('✅ the object is contained in the array');
                } else {
                  console.log('⛔️ the object is NOT contained in the array');
                  this.list1.push(item);
                  //  this.changeDetectorRef.markForCheck();
                  this.list1 = Object.assign([], this.list1);
                  //console.log(this.list1);
                }
 
 
              }); 
             // this.confirmIsOpen = false;
            }
        }
    });
    //console.log(this.list1);
    
    //this.changeDetectorRef.detectChanges();
  } catch (e) {

  }
}
 
  
 
  sourceReorder(event: any) {
    //console.log('Reorder source: ', event.items);
  }
 
  moveToSource(event: any)  {
    //console.log('Moved items to source: ', event.items);
 
 
    const items = event.items;
    items.forEach( (item : any) => {
      const found = this.list1.find((litem) => {
        return litem.name === item.name && litem.batch === item.batch;
      });
      //console.log(found); 
      if (found !== undefined) {
        console.log('✅ the object is contained in the array');
      } else {
        console.log('⛔️ the object is NOT contained in the array');
        this.list1.push(item);
        //  this.changeDetectorRef.markForCheck();
        
        //console.log(this.list1);
      }
    }); 
   // this.list1 = Object.assign([], this.list1);
 
   this.list1 = [...new Set(this.list1)];
 
 
   
 
   items.forEach( (item : any) => {
    this.list2 = this.list2.filter(litem => {
      return litem.name !== item.name || litem.batch !== item.batch;
    });
    }); 
 
   this.list2 = [...new Set(this.list2)];
 
    //console.log(this.list1);
    //this.changeDetectorRef.detectChanges();
 
   }

  //  @ViewChild('confirmPopup', { static: true }) confirmPopup: ConfirmPopup = {} as ConfirmPopup;

  //  ngAfterViewInit() {
  //      // this.confirmPopup.style.position = 'fixed';
  //      // this.confirmPopup.style.top = '50%';
  //      // this.confirmPopup.style.left = '50%';
  //      //this.confirmPopup.style.transform = 'translate(-50%, -50%)';
  //  }


   submit() {
  
   }
  
    disabled() : boolean {
      return this.list2.length < 1 
    }
  
    disabledPickList() : boolean {
      return this.transformedProductTypeKey === '' || this.transformedProductTypeKey === undefined;
    }
  
    filterList1(event : any) {

      //console.log(event);
      const productKey = event.value;
  
      //console.log('Product key', productKey);

      if ( !productKey) {
        //this.list1 = [...new Set(this.list1)];
        //this.transformedProductOriginals = [...new Set(this.transformedProductOriginals)];
        this.list1 = [...new Set(this.originalList1)];
        this.dropdown.disabled;
        this.code = '';
        this.lastCode = 0;
        this.viewCode = '';
        return;
      }
  
      //console.log('Product transformed key', this.transformedProductTypeKey);
      if (this.previousTransformedTypeKey === productKey ) {
        return;
      } 
      
      //console.log(' ... ');

      // We put again all the element before filtering
      this.list1 = [...new Set(this.originalList1)];
      
      //
      if (!this.transformedMap.has(productKey) ) {
        console.log('Transformed map does not contain ', productKey); 
        return;
      }

      
      const transformedProduct = this.transformedMap.get(productKey);
    //  console.log('transformed product ', transformedProduct);

      const transormedPT = this.productTypeMap.get(this.transformedProductTypeKey);
      if (transormedPT?.soldable) {        
        this.code = transormedPT.code || '' ;

        const lc : number = transormedPT.lastCode || 1;

        this.lastCode = lc;

        console.log( 'soldable number', lc);
        
        let strNum: string = lc.toString().padStart(5, '0');


        this.viewCode = this.code+'/' + strNum;
      }

      const inputs = transformedProduct?.inputs;
     // console.log('inputs ', inputs);


      const inputsArr : WoProductType[] = Object.assign([], inputs);
        
      const newList1 : WoProductType[] = [];

       // console.log('before compare');

        this.originalList1.forEach(
          (item: WoProduct) => {
            for(let input of inputsArr) {
              
              // console.log('compare');
              // console.log( input.name );
              // console.log( item.productType?.name );
              if (input.name === item.productType?.name) {
                newList1.push( item );
                break;
              }
            }
          }
        );

         this.list1 = Object.assign( [], newList1);

        // this.transformedProducts = this.transformedProducts.filter(item => {
        //    console.log('loc name', item.location);
        //    console.log('loca name', transformedName);
        //    return item.location !== transformedName;
        //  });
        //  this.transformedProductOriginals = [...new Set(this.transformedProducts)];
      
      
        
    }



   onSubmit(){
      
    if(this.list2.length > 0){
      
      const transformation : Transformation = {} as Transformation;
      transformation.when = new Date().toISOString();
      transformation.who = this.authService.userData.email || ' ';
      transformation.fromWhat = [];
      transformation.toWhat = {} as WoProduct;

      if (!this.transformedProductTypeKey) {
        console.error('Missing product type to create - error 1');
        return;
      }
      if (!this.transformedMap.has(this.transformedProductTypeKey)) {
        console.error('Missing product type to create - error 2');
        return;
      }
      const selectedProductType = this.transformedMap.get(this.transformedProductTypeKey);
      //console.log('DRY r' , selectedProductType);

      // Name of the new product to create
      const selectedProductTypeName = selectedProductType?.name;

      // Batch of the new product
      const now = new Date();
      let batch = now.toISOString().replace(/[-T:Z\.]/g, "");

      if ( this.modules.wo ) {
        const lc : number = this.lastCode || 1;

        
        let strNum: string = lc.toString().padStart(5, '0');

        batch = this.code + '/' + strNum ;
      }

      const newProduct : Partial<WoProduct> = {} as WoProduct;
      newProduct.name          = selectedProductTypeName;
      newProduct.batch         = batch;
      newProduct.dryable       = selectedProductType?.dryable       || false;
      newProduct.dried         = selectedProductType?.dried         || false;
      newProduct.receivable    = selectedProductType?.receivable    || false;
      newProduct.transformable = selectedProductType?.transformable || false;
      newProduct.transformed   = selectedProductType?.transformed   || false;
      newProduct.soldable      = selectedProductType?.soldable      || false;
      newProduct.instant       = new Date().toISOString();
      newProduct.productTypeKey= selectedProductType?.$key;
      let productTypeCopy : Partial<WoProductType> = Object.assign({},selectedProductType);
      delete productTypeCopy.$key;
      newProduct.productType   =  productTypeCopy as WoProductType;
      
      if (!this.locationTransformation) {
        this.locationTransformation = 'Transformation area';
      }
      newProduct.location = this.locationTransformation;
      if(!newProduct.history) {
        newProduct.history = [];
      }

      // For the history, need to see the from products
      let histories : WoEvent[] = [];
      this.list2.forEach( (product : WoProduct)  => { 

        // For dry event
        const fromProduct : Partial<WoProduct> = Object.assign({}, product);
        delete fromProduct.$key;
        transformation.fromWhat.push(fromProduct as WoProduct);
        
        // For history
        let currentFromProductHistory = Object.assign([],product.history);
        currentFromProductHistory.forEach( (element: WoEvent)  => {
          let event : Partial<WoEvent> = Object.assign({},element);
          delete event?.$key;
          histories.push(element);
        });

        
        // histories.push(woEvent);

        // For consumption
        const k =  product.name+product.batch;
        if (this.list2Map.has(k) ) {
          const hasBeenConsumedEntirely = this.list2Map.get(k);
          if (hasBeenConsumedEntirely) {
            console.log('entirely', product.$key);
            this.productService.deleteWoProduct(product.$key);
          }
        }

      });
      
      // Dry event
      let newPorductWithoutHistory = Object.assign({},newProduct);
      delete newPorductWithoutHistory.history;
      transformation.toWhat = newPorductWithoutHistory as WoProduct;

     // 3.B) for the event
     const woEvent : WoEvent = {
      which: 'TRANSFORMATION',
      what: transformation,
      when: new Date().toISOString(),
      who: this.authService.userData.email,
      why: 'HUMAN-CLICK',
      where: 'WEBSERVER'
     } as WoEvent;
     this.eventService.addWoEvent( woEvent );
     histories.push(woEvent);

     // console.log('histories', histories);
      newProduct.history = histories;
      
      

      // Save the new product
   //   console.log("New product", newProduct);
      this.productService.addWoProduct(newProduct as WoProduct);


      const selectedProductType2 = Object.assign({}, selectedProductType);
      selectedProductType2.lastCode = this.lastCode + 1;
      console.log('soldable save0', this.lastCode  );
      console.log('soldable save1', this.lastCode + 1 );
      console.log('soldable save1', selectedProductType2.lastCode );
      console.log('soldable save',selectedProductType2);
      this.productTypeService.updateWoProductType( selectedProductType2 );

      this.transformationService.addTransformation(transformation);
      this.router.navigate(['/transformation/transformation-table']);
      this.dropdown.resetFilter();
    }
  }

}
