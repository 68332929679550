import { Injectable } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader"

@Injectable({
  providedIn: 'root'
})
export class EblockService {
  

  constructor() { }


  // loadMap() {
  //   const loader = new Loader({
  //     apiKey: "AIzaSyDc0yVyQE7UpZxYOsS9-5gVPQ8s6beH2no",
  //     version: "weekly",

  //   });
  //   loader.load().then(async () => {
  //     const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
  //     map = new Map(document.getElementById("map") as HTMLElement, {
  //       center: { lat: -34.397, lng: 150.644 },
  //       zoom: 8,
  //     });
  //   });
  // }

}
