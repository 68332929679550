import { Injectable } from '@angular/core';
import { Type } from '../interfaces/type';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
//import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = '/users/' ;
  }

  // Create Type
  add(email: string, uid: string, isVerified : boolean) {
    this.refs = this.db.list( this.path );
    const item = {
      email,
      uid,
      isVerified
    }
    this.refs?.set( uid , { 'profile' : item } );
  }
  // Fetch Single Type Object
  get(uid: string) {
    this.ref = this.db.object( this.path + uid + '/profile');
    return this.ref;
  }
  // Fetch Types List
  getList() {
    this.refs = this.db.list(this.path );
    return this.refs;
  }
  // Update Type Object
  update(email: string, uid: string, isVerified : boolean) {
    this.ref = this.db.object( this.path + uid + '/profile');
    const item = {
      email,
      uid,
      isVerified
    }
    this.ref?.update(item);
  }
  // Delete Type Object
  delete(uid: string) {
    this.ref = this.db.object( this.path + uid);
    this.ref.remove();
  }
}
