
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedBlocks()" [disabled]="!selectedBlocks || !selectedBlocks.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="blocks"                                                                                                      
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['name','grapeVariety','growthStage','description','isActive']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedBlocks" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name">
                        Block
                    </th>
                    <th id="grapeVariety" name="grapeVariety" pResizableColumn pReorderableColumn pSortableColumn="grapeVariety">
                        Grape Variety 
                    </th>
                    <th id="growthStage" name="growthStage" pResizableColumn pReorderableColumn pSortableColumn="growthStage">
                        Growth Stage 
                        <p-sortIcon field="growthStage"></p-sortIcon> 
                        <p-columnFilter type="text" field="growthStage" display="menu"></p-columnFilter>
                    </th>
                    <th id="description" name="description" pResizableColumn pReorderableColumn pSortableColumn="description">
                        Description
                        <p-sortIcon field="description"></p-sortIcon> 
                        <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                    </th>
                    <th id="isActive" name="isActive" pResizableColumn pReorderableColumn pSortableColumn="isActive" style="width: 10rem">
                        Enabled
                        <p-sortIcon field="isActive"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isActive" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions 
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                </tr>
            </ng-template>

            <ng-template 
                pTemplate="body" 
                let-block 
                let-columns="columns" 
                let-index="rowIndex">
                
                <tr [pReorderableRow]="index" [pEditableRow]="block">
                    <td>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" style="margin-right: 1rem;"></span>
                        {{block.position}} 
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                [(ngModel)]="block.name" 
                                (blur)="completeBlock(block)"
                                 (keydown.tab)="completeBlock(block)"
                                 (keydown.enter)="completeBlock(block)"
                                required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{block.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">

                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="block.grapeVariety" 
                                    [options]="grapeVarieties" 
                                    optionLabel="name" 
                                    optionValue="value"
                                    
                                    (blur)="completeBlock(block)"
                                 (keydown.tab)="completeBlock(block)"
                                 (keydown.enter)="completeBlock(block)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{block.grapeVariety}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-dropdown 
                                appendTo="body" 
                                [filter]="true"
                                [showClear]="false" 
                                [(ngModel)]="block.growthStage" 
                                [options]="growthStages" 
                                optionLabel="name" 
                                optionValue="value" 
                                placeholder="Any">
                            </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{block.growthStage}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="block.description" 
                                (blur)="completeBlock(block)"
                                 (keydown.tab)="completeBlock(block)"
                                 (keydown.enter)="completeBlock(block)"
                                required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{block.description}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td>
                        <div style="display: flex;align-items: center;flex-direction: column;justify-content: center;">
                            <p-inputSwitch [(ngModel)]="block.isActive" ></p-inputSwitch>
                        </div>
                    </td>

                    <td class="row-actions">
                        <button pButton pRipple style="margin-right: 5px;" icon="pi pi-pencil" class="p-button-success p-mr-2" (click)="editBlock(block)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-warning p-mr-2" style="margin-right: 5px; color: white" (click)="deleteBlock(block)"></button>
                        <p-tableCheckbox class="checkbox" [value]="block" ></p-tableCheckbox>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{blocks ? blocks.length : 0 }} block{{blocks.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV - Save the selected lines and columns" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS - Save all the lines and columns" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF - Save all the lines and columns" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>
    </div>

    <div class="page-footer">
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>        
    </div>

    <!-- Dialog : confirm -->
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    

    <!-- Dialog : Popup -->
    <p-dialog [(visible)]="blockDialog" [style]="{width: '450px', height: '385px'}" header="Block Details" [modal]="true" styleClass="p-fluid" [baseZIndex]="1000">
        <div class="p-field">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" [(ngModel)]="block.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !block.name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="grapeVariety">Grape Variety</label>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="false" 
                [(ngModel)]="block.grapeVariety" 
                [options]="grapeVarieties" 
                optionLabel="name" 
                optionValue="value" 
                placeholder="Any">
            </p-dropdown>
        </div>
        <div class="p-field">
            <label for="growthStage">Growth Stage</label>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="false" 
                [(ngModel)]="block.growthStage" 
                [options]="growthStages" 
                optionLabel="name" 
                optionValue="value" 
                placeholder="Any">
            </p-dropdown>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="block.description" rows="3" cols="20"></textarea>
        </div>
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-warning" style="color: white; width: 140px;" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" style="color: white; width: 140px;" (click)="saveBlock()" ></button>
        </div>
    </p-dialog>
</div>







