import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MyAccount } from 'src/app/shared/interfaces/my-account';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MyAccountCrudService } from 'src/app/shared/services/my-account-crud.service';
import { MyProfileService } from 'src/app/shared/services/my-profile.service';
import { LocalStorageBService } from 'src/app/shared/services/local-storage-b.service';
import { PaymentplanService } from 'src/app/shared/services/paymentplan.service';
import { Observable, of } from 'rxjs';
import { Organisation } from 'src/app/shared/interfaces/organisation';


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})


export class MyProfileComponent implements OnInit { 
  
  priceID100 = 'price_1MdmDMHE9rvMqwXXjXJ9PyeL';
  priceID200 = 'price_1Mful6HE9rvMqwXXeZe5G6Em';
  priceId!: number;    
  companyName!: string;
  companyId!: string;
  companyDescription!: string;

  myAccounts: MyAccount[];
  myAccount: MyAccount = {} as MyAccount;

  email!: string;

    // ui beadcrumb
    breadcrumbs: MenuItem[];
    homeBreadcrumb: MenuItem = {} as MenuItem;

  public organisation$ : Observable<Organisation> = this.localStorageService.organisation$;

   // myForm: FormGroup | undefined;

  constructor(
    private authService: AuthService,
    private myAccountCrudService : MyAccountCrudService,
   // private formBuilder,: FormBuilder,
    //private _route: ActivatedRoute,
    private Router: Router, 
    private paymentPlanService: PaymentplanService,
    public localStorageService: LocalStorageBService
    ) {
     
      // ui breadcrumbs
      this.breadcrumbs = [
        {label: 'My Account'}
      ];

      this.myAccounts =[];

      this.localStorageService.loadOrganisation();

      this.localStorageService.organisation$
      
  }

  ngOnInit() {
      const company = JSON.parse(localStorage.getItem('company')!);
      const user = JSON.parse(localStorage.getItem('user')!);
      this.priceId = company.subscriptionPrice;
      this.companyName = company.name;
      this.companyId = company.$key;
      this.companyDescription = company.companyDescription;
      this.email = user.email;


      this.accountKey = user.uid;
      // this.myForm = this.formBuilder.group({
      //   myField: ['', Validators.required] // Replace 'myField' with the name of your form field
      // });

      let o = this.myAccountCrudService.getList(); 
      o.snapshotChanges().subscribe(data => {
        this.myAccounts = [];
        data.forEach(item => {
          let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
          if (jsonItem) {
            if (jsonItem) {
              jsonItem['$key'] = item.key;
            }
            let l : MyAccount = jsonItem as MyAccount;
            
            this.myAccounts.push(l);
            //this.organisationsMap.set( l.$key , l.isPublic || false);
            this.myAccount = l;
           // this.myAccount.$key;
           console.log('reload', this.myAccount);
            this.myAccount.key = user.uid;
            this.myAccount.email = user.email;
            this.myAccount.organisationKey = this.companyId;
            this.myAccount.organisationName = this.companyName;
          }
        })
      });

  }


  onSubmit(_click: any) {

  }

  accessChangePlan() {
    this.Router.navigate(['/paymentplans']);
  }

  cancelPlan() {
    this.paymentPlanService.cancelSubscription();
  }


  value: string = '';
  originalValue: string = '';


  accountKey : string = '';
  firstName: string = '';
  originalFirstName: string = '';
  lastName: string = '';
  originalLastName: string = '';
  phone: string = '';
  originalPhone: string = '';


  organisationKey: string = '';
  organisationName: string = '';

  // saveValue() {
  //   // Save the edited value
  //   //this.originalValue = this.value;
  //   console.log(this.myAccount);
  //   this.myAccountCrudService.update(this.myAccount);
  // }


  saveValue() {
    console.log(this.myAccount);
    if (this.myAccount.$key) { // already exist 
      this.myAccountCrudService.update( this.myAccount );
      //  this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyOrganisation Updated', life: 3000});
      //this.myOrganisationService.update( this.userId, this.myOrganisation);
    } else {
      const ref = this.myAccountCrudService.add(this.myAccount);
     // this.myAccount = this.myAccountCrudService.get(ref.$key);
      //this.myAccount.
      //this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyOrganisation Created', life: 3000});
      
    }  
  }

   

  cancelEdit() {
    // Cancel the edit and revert to the original value
    this.value = this.originalValue;
  }
}
  