import { Injectable } from '@angular/core';
import { Colour } from '../interfaces/colour';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ColourCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/colours/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // newPath() {
  //   const user = JSON.parse(localStorage.getItem('user')!)
  //   const company = JSON.parse(localStorage.getItem('company')!);
  //   this.path = 'users' + '/' + user.uid + '/' + 'companies' + '/' + company.$key + '/' + 'wines/colours/';
  // }

  // Create Colour
  add(record: Colour) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single Colour Object
  get(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
    return this.ref;
  }
  // Fetch Colours List
  getList() {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    return this.refs;
  }
  // Update Colour Object
  update(record: Colour) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object('organisations/' + org + '/' + this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<Colour>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Colour Object
  delete(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
    this.ref.remove();
  }
}
