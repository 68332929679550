import { Injectable } from '@angular/core';
import { MyJoinedRequest } from '../interfaces/my-joined-request';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyJoinedRequestCrudService {

  
  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/myJoinedRequests/' ;
  }

  // Create MyJoinedRequest
  add(uid : string, record: MyJoinedRequest) {
    this.path = environment.name + '/' + uid + '/settings/myJoinedRequests/' ;
 
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single MyJoinedRequest Object
  get(uid : string, id: string) {
    this.path = environment.name + '/' + uid + '/settings/myJoinedRequests/' ;
 
    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch MyJoinedRequests List
  getList(uid : string) {
    this.path = environment.name + '/' + uid + '/settings/myJoinedRequests/' ;
 
    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update MyJoinedRequest Object
  update(uid : string, record: MyJoinedRequest) {
    this.path = environment.name + '/' + uid + '/settings/myJoinedRequests/' ;
 
    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<MyJoinedRequest>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete MyJoinedRequest Object
  delete(uid : string, id: string) {
    this.path = environment.name + '/' + uid + '/settings/myJoinedRequests/' ;
 
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}
