import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { BlockCrudService } from 'src/app/shared/services/block-crud.service';
import { Block } from 'src/app/shared/interfaces/block';



@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss']
})
export class BlocksComponent implements OnInit {
  blocks: Block[];
  block: Block;
  selectedBlocks: Block[] = [];

  blockDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  // ui selection
  grapeVarieties: any[] = [];
  growthStages: any[] = [];

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private blockService : BlockCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Blocks'}
    ];

    this.blocks = [];
    this.block = { } as Block ;
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let s = this.blockService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.blocks = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Block = jsonItem as Block;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.blocks.push(l);
        }
      })
    })

    // column management
    this.cols = [
      { field: 'position', header: 'Order' },
      { field: 'name', header: 'Name' },
      { field: 'grapeVariety', header: 'Grape variety' },
      { field: 'growthStage', header: 'Growth stage' },
      { field: 'isActive', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.blockService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.blocks);
            doc.save('blocks.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.blocks);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "blocks");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedBlocks);
      // console.log('SAVEC CSV LOCATIONS ', this.blocks);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedBlocks, 'blocks', exportCols);
   // this.exportService.exportToCsv(this.blocks, 'blocks', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.block = {} as Block;
    this.submitted = false;
    this.blockDialog = true;
  }

  deleteSelectedBlocks() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected blocks?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.blocks = this.blocks.filter(val => !this.selectedBlocks.includes(val));
            
            this.selectedBlocks.forEach( block => this.blockService.delete(block.$key));
            this.selectedBlocks = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Blockss Deleted', life: 3000});
        }
    });
  }

  editBlock(block: Block) {
    this.block = {...block};
    this.blockDialog = true;
  }

  deleteBlock(block: Block) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + block.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.blocks = this.blocks.filter(val => val.$key !== block.$key);
            this.block = {} as Block;
            this.blockService.delete(block.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Block Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.blockDialog = false;
    this.submitted = false;
  }

  saveBlock() {
    this.submitted = true;

    if ( this.block.name.trim() ) {
      this.block.isEnabled = true;

      if (this.block.$key) {
          this.blocks[this.findIndexById(this.block.$key)] = this.block;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Block Updated', life: 3000});
          this.blockService.update(this.block);
        } else {
          this.block.position = this.blocks.length;
          this.blockService.add(this.block);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Block Created', life: 3000});
      this.blocks = [...this.blocks];
    }

    this.blockDialog = false;
    this.block = { } as Block;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.blocks.length; i++) {
        if (this.blocks[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeBlock(block : Block) {
    //console.log("SAVE", block);
    this.blockService.update(block);
  }

  completePosition(block : Block) {
   // console.log("SAVE", block);
  //  this.blockService.updateBlock(block);


    if ( this.newPosition > -1 ) {
        block.position = this.newPosition;
        block = this.checkPositionLimits(block);
        this.reorderElements(block);
        //this.updateDB(block);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.blocks);
    // const myClonedArray = [];
    // this.blocks.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.blocks.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.blocks.length - 1 - event.dropIndex;
    const blockToMove = this.blocks[startIndex];
    
    
    // console.log('StartIndex', startIndex);
    // console.log('EndIndex', endIndex);

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.blocks[index] = this.blocks[index+1];
        this.blocks[index].position = index;
        this.blockService.update(this.blocks[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.blocks[index] = this.blocks[index-1];
        this.blocks[index].position = index;
        this.blockService.update(this.blocks[index]);
      }
    } 
    this.blocks[endIndex] = blockToMove;
    this.blocks[endIndex].position = endIndex;
    this.blockService.update(this.blocks[endIndex]);
  }

  // onFieldEdit(block: Spray, fieldName: string): void {
  //   //console.log(this.blocks);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + block[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       block.position = this.newPosition;
  //       block = this.checkPositionLimits(block);
  //       this.reorderElements(block);
  //       //this.updateDB(block);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(block);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.blocks.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, block: Block) {
    // console.log("event" +event);
    if (block.position != null && block.position > -1) {
      this.previousPosition = block.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(block: Block) {
    console.log('check ',block.position + ' ' + this.blocks.length);
    if (!block.position) {
     // console.log('check 1');
      return this.block;
    }
    if (block.position < 0) {
      block.position = 0;
      // console.log('check 2');
      return block;
    }
    if (block.position > this.blocks.length) {
      block.position = this.blocks.length - 1;
    //  console.log('check 3', block.position);
      return block;
    }
   // console.log('check 4');
    return block;
  }

  async reorderElements(block: Block) {
    this.newPosition = this.newPosition  > this.blocks.length ? this.blocks.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === block.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const blockToMove = this.blocks[startIndex];
   // console.log('Item to move: ', this.blocks[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.blocks);
        console.log('Item to +1: ', this.blocks[index+1]);

        this.blocks[index] = this.blocks[index+1];
        this.blocks[index].position = index;
        // console.log('Move: ', this.blocks[index+1]);
        // console.log('Move pos: ', this.blocks[index].position);
        //await this.updateDB(this.blocks[index]);
        this.blockService.update(this.blocks[index]);
     //   console.log('Update DB: ', this.blocks[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.blocks[index] = this.blocks[index-1];
        this.blocks[index].position = index;
        this.blockService.update(this.blocks[index]);
      }
    } 
    this.blocks[endIndex] = blockToMove;
    this.blocks[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.blockService.update(this.blocks[endIndex]);
    
    // const myClonedArray = [];
    // this.blocks.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.blocks = myClonedArray;
   // this.getAllRecords();
  }
}
