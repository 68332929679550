import { Directive } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appSpreadingDateValidator]'
})
export class SpreadingDateValidatorDirective {

  constructor() { }

}

export function dateStartEndValidator(startDateString: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const endDate: Date = new Date(control.value);

    //const startDate: Date = new Date(startDateString);
    const startDate: Date = new Date();
    
    if (startDate.getDate() > endDate.getDate() ) {
      return {dateError: {value: control.value}} ;
    }
    return null;
  };
}