import { Inject, Injectable } from '@angular/core';
import { MyOrganisation } from '../interfaces/my-organisation';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
// import { AuthService } from './auth.service';
// import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyOrganisationCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(
   // @Inject('uid') private uid: string,
   // private userId$: BehaviorSubject<string>,
    private db: AngularFireDatabase
    ) 
  {

    this.path = environment.name + '/myOrganisations/' ;
   
  
  }

//
//https://stackoverflow.com/questions/62099759/how-to-wait-for-firebase-auth-before-starting-angular-app

  // Create MyOrganisation
  add(orgKey: string, uid : string, record: MyOrganisation) {
   // this.path = environment.name + '/' + this.userId$.getValue() + '/settings/myOrganisations/' ;
    this.path = '/users/' + uid + '/environments/' + environment.name  + '/myOrganisations/' ;
   
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    const key = this.refs?.set( orgKey, item );
    return key;
  }
  // Fetch Single MyOrganisation Object
  get(uid: string, id: string) {
   // this.path = environment.name + '/' + this.userId$.getValue() + '/settings/myOrganisations/' ;
   this.path = '/users/' + uid + '/environments/' + environment.name  + '/myOrganisations/' ;
   

    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch MyOrganisations List
  getList(uid: string) {
   // this.path = environment.name + '/' + this.userId$.getValue() + '/settings/myOrganisations/' ;
   this.path = '/users/' + uid + '/environments/' + environment.name  + '/myOrganisations/' ;
   

    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update MyOrganisation Object
  update(uid : string, record: MyOrganisation) {
   // this.path = environment.name + '/' + this.userId$.getValue()+ '/settings/myOrganisations/' ;
   this.path = '/users/' + uid + '/environments/' + environment.name  + '/myOrganisations/' ;
   

    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<MyOrganisation>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete MyOrganisation Object
  delete(uid : string, id: string) {
   //this.path = environment.name + '/' + this.userId$.getValue() + '/settings/myOrganisations/' ;
   this.path = '/users/' + uid + '/environments/' + environment.name  + '/myOrganisations/' ;
   
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}