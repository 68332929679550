
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button> -->
            <button pButton pRipple label="Unarchive" icon="pi pi-upload" class="button-action p-button-warning" (click)="unarchiveSelectedBins()" [disabled]="!selectedBins || !selectedBins.length "></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedBins()" [disabled]="!selectedBins || !selectedBins.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="bins"                                                                                                      
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['position','qrcode','name','description','tags','status','grapeVarietyName','quantity','nbOfUses','brandName']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedBins" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="qrcode" style="width: 8rem" name="qrcode" pResizableColumn pReorderableColumn pSortableColumn="qrcode" [class]="includesCol('qrcode') ? 'd' : 'h'">QRCode 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> 
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description
                        <p-sortIcon field="description"></p-sortIcon> 
                        <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                    </th>
                    <th id="location" name="location" pResizableColumn pReorderableColumn [class]="includesCol('location') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/location.png" width="24px" height="24px" [routerLink]="['/bin-management/bin-locations']"/> 
                        Location
                        <p-sortIcon field="locationName"></p-sortIcon> 
                        <p-columnFilter type="text" field="locationName" display="menu"></p-columnFilter>
                    </th>
                    <th id="grapeVariety" name="grapeVariety" pResizableColumn pReorderableColumn [class]="includesCol('grapeVariety') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_grape.png" width="24px" height="24px" [routerLink]="['/wine-management/grape-varieties']"/> 
                        Grape variety
                        <p-sortIcon field="grapeVarietyName"></p-sortIcon> 
                        <p-columnFilter type="text" field="grapeVarietyName" display="menu"></p-columnFilter>
                    </th>
                    <th id="quantity" name="quantity" pResizableColumn pReorderableColumn [class]="includesCol('quantity') ? 'd' : 'h'">
                        Quantity
                        <p-sortIcon field="quantity"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="quantity" display="menu"></p-columnFilter>
                    </th>
                    <th id="nbOfUses" name="nbOfUses" pResizableColumn pReorderableColumn [class]="includesCol('nbOfUses') ? 'd' : 'h'">
                        Cycles
                        <p-sortIcon field="nbOfUses"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="nbOfUses" display="menu"></p-columnFilter>
                    </th>
                    <th id="brand" name="brand" pResizableColumn pReorderableColumn [class]="includesCol('brand') ? 'd' : 'h'">
                        <!-- <img style="vertical-align: middle;" src="assets/images-black/ic_grape.png" width="24px" height="24px" [routerLink]="['/wine-management/grape-varieties']"/>  -->
                        Brand
                        <p-sortIcon field="brandName"></p-sortIcon> 
                        <p-columnFilter type="text" field="brandName" display="menu"></p-columnFilter>
                    </th>
                    <th id="tags" name="tags" pResizableColumn pReorderableColumn [class]="includesCol('tags') ? 'd' : 'h'">Tags
                        <p-sortIcon field="tags"></p-sortIcon> 
                        <p-columnFilter type="text" field="tags" display="menu"></p-columnFilter>
                    </th>
                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('isEnabled') ? 'show' : 'hide'">
                        Enabled
                        <p-sortIcon field="isEnabled"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions 
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-bin let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" [pReorderableRowHandle]="index" style="margin-right: 1rem;"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                (ngModelChange)="modelChangeFn($event, bin)"
                                [ngModel]="bin.position" 
                                (blur)="completePosition(bin)" 
                                (keydown.tab)="completePosition(bin)" 
                                (keydown.enter)="completePosition(bin)"  
                                required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{bin.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('qrcode') ? 'd' : 'h'">
                        <qrcode [qrdata]="bin.name" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="bin.name" 
                                (blur)="completeBin(bin)" 
                                (keydown.tab)="completeBin(bin)" 
                                (keydown.enter)="completeBin(bin)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{bin.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="bin.description"  (blur)="completeBin(bin)" (keydown.tab)="completeBin(bin)" (keydown.enter)="completeBin(bin)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{bin.description}}</span>
                            
                         
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('location') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                               <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="bin.locationName" 
                                    [options]="locations" 
                                    optionLabel="name" 
                                    optionValue="$key"  

                                    (onChange)="onChangeLocation(bin, bin.locationKey, $event)"
                                    (blur)="onChangeLocation(bin, bin.locationKey, $event)" 
                                    (keydown.tab)="onChangeLocation(bin, bin.locationKey, $event)" 
                                    (keydown.enter)="onChangeLocation(bin, bin.locationKey, $event)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{bin.locationName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('grapeVariety') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="bin.grapeVarietyName" 
                                    [options]="grapeVarieties" 
                                    optionLabel="name" 
                                    optionValue="$key"  
                                    (onChange)="onChangeGrapeVariety(bin, bin.grapeVarietyKey, $event)"
                                    (blur)="onChangeGrapeVariety(bin, bin.grapeVarietyKey, $event)" 
                                    (keydown.tab)="onChangeGrapeVariety(bin, bin.grapeVarietyKey, $event)" 
                                    (keydown.enter)="onChangeGrapeVariety(bin, bin.grapeVarietyKey, $event)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{bin.grapeVarietyName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('quantity') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="number" [(ngModel)]="bin.quantity" 
                                (blur)="completeBin(bin)" 
                                (keydown.tab)="completeBin(bin)" 
                                (keydown.enter)="completeBin(bin)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{bin.quantity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td pEditableColumn [class]="includesCol('nbOfUses') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="number" [(ngModel)]="bin.nbOfUses" 
                                (blur)="completeBin(bin)" 
                                (keydown.tab)="completeBin(bin)" 
                                (keydown.enter)="completeBin(bin)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{bin.nbOfUses}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('brand') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="bin.brandName" 
                                    [options]="brands" 
                                    optionLabel="name" 
                                    optionValue="$key"  
                                    (onChange)="onChangeBrand(bin, bin.brandKey, $event)"
                                    (blur)="onChangeBrand(bin, bin.brandKey, $event)" 
                                    (keydown.tab)="onChangeBrand(bin, bin.brandKey, $event)" 
                                    (keydown.enter)="onChangeBrand(bin, bin.brandKey, $event)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{bin.brandName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('tags') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-chips  id="tags" [(ngModel)]="bin.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3" (onBlur)="completeBin(bin)" (onAdd)="completeBin(bin)" (onRemove)="completeBin(bin)"></p-chips>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="margin-right: 5px;" *ngFor="let tag of bin.tags">
                                    <p-tag >{{tag}}</p-tag>
                                </span>
                               
                       
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="bin.isEnabled" (onChange)="completeBin(bin)"></p-inputSwitch>
                    </td>

                    <td class="row-actions">
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editBin(bin)"></button>
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteBin(bin)"></button>
                        <p-tableCheckbox class="checkbox" [value]="bin"></p-tableCheckbox>
                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{bins ? bins.length : 0 }} bin{{bins.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV - Save the selected lines and columns" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS - Save all the lines and columns" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF - Save all the lines and columns" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>        
    </div>

    <!-- Dialog : confirm -->
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    

    <!-- Dialog : Popup -->
    <p-dialog [(visible)]="binDialog" [style]="{width: '450px', height: '450px'}" header="Bin" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="bin.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !bin.name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="bin.description" rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="tags" >Tags</label>
            <div style="height: 10px;">&nbsp;</div>
            <p-chips  id="tags" [(ngModel)]="bin.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
        </div>   
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveBin()"></button>
        </div>
    </p-dialog>
    
    

</div>