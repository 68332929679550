import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Company } from '../interfaces/company';
import { getLocaleMonthNames } from '@angular/common';
import { Organisation } from '../interfaces/organisation';
import { MyOrganisationCrudService } from './my-organisation-crud.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class PaymentplanService {

  constructor(
    private messageService: MessageService,
    private http: HttpClient, 
    private afAuth: AngularFireAuth, 
    private myOrganisationService: MyOrganisationCrudService,
    public router: Router) { 
  }

  stripe = require('stripe')('sk_test_51J9P4XHE9rvMqwXXSJNChK2WoKcmFGVDLCJomoIiiEgWMvvtgOwcIStMagGuwLCTkZExPhERrjfLx2GsCa8ejpEx00sN6oUumJ');

  async getCurrentUserUid(): Promise<string | null> {
    return new Promise((resolve) => {
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          resolve(user.uid);
        } else {
          resolve(null);
        }
      });
    });
  }

  async getCurrentUserEmail(): Promise<string | null> {
    return new Promise((resolve) => {
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          resolve(user.email);
        } else {
          resolve(null);
        }
      });
    });
  }

  getClickedCompany() {
    const company = JSON.parse(localStorage.getItem('company')!);
    return company;
  }

  async toDashboard(id: string) {
    console.log('SUBSCRIPTION ID', id);
    
    this.router.navigate(['dashboard']);
  }

  async sendStripeToken(token: any, company: Company, amount: number) {
    await this.makePayment(token, token.priceId, token.metadata.companyKey, token.metadata.customerEmail, token.card.id, company, amount);
  }

  async sendStripeTokenForOrganisation(token: any, organisation: Organisation, amount: number, offerKey: string, userUid: string) : Promise<boolean> {
    const result = await this.makePaymentForOrganisation(token, token.priceId, token.metadata.companyKey, token.metadata.customerEmail, token.card.id, organisation, amount, offerKey, userUid);
    return result;
  }

  async changeSubscription(token: any) {
    try {
      const subscription = await this.stripe.subscriptions.retrieve(token.subscriptionId);
      const currentItemId = subscription.items.data[0].id;
      const updatedSubscription = await this.stripe.subscriptions.update(token.subscriptionId, {
        items: [{
          id: currentItemId,
          price: token.priceId,
        }],
      });
      //alert('Subscription updated!');
      this.messageService.add({key: 'bc', severity:'info', summary: 'Info', detail: 'Subscription updated'});
      
      this.toDashboard(currentItemId);
    } catch (err) {
      alert('Error changing subscription');
      console.error(err);
    }
  }

  async cancelSubscription() {
    if(confirm('This action cannot be revert. Are you sure you want to cancel your subscription?')) {
      try {
        const subscription = await this.stripe.subscriptions.retrieve(this.getClickedCompany().subscriptionId);
        await this.stripe.subscriptions.del(subscription.id);
        alert('Subscription canceled!');

        this.messageService.add({key: 'bc', severity:'info', summary: 'Info', detail: 'Subscription canceled'});
      

        localStorage.removeItem('company');
        this.router.navigate(['connect-company']);
      } catch (err) {
        alert('Error canceling subscription');
        console.error(err);
      }
    }
  }

  async makePayment(token: any, priceId:string, companyKey: string, customerEmail: string, cardID: string, company: Company, amount: number) {
    // Get customer details
    try {
      var customerId = '';
      var onProcessCustomer: any;
      const existCustomer = await this.stripe.customers.list({
        email: customerEmail,
      });
      
      if(existCustomer.data.length <= 0) {
        const customer = await this.stripe.customers.create({
          name: customerEmail,
          source: token.id,
          email: customerEmail,
        });
        customerId = customer.id;
        onProcessCustomer = customer;
      } else {
        customerId = existCustomer.data[0].id;
        onProcessCustomer = existCustomer.data[0];
      }

      const subscription = await this.stripe.subscriptions.create({
        customer: customerId,
        items: [{ price: priceId }],
        metadata: {
          companyKey: companyKey,
          customerEmail: customerEmail,
          companyName: company.companyName,
        }
      });
  
      //alert('Subscription created!');
      this.messageService.add({key: 'bc', severity:'success', summary: 'Info', detail: 'Subscription confirmed'});
      


      company.subscriptionPrice = amount;
      company.subscriptionId = subscription.id;
      localStorage.setItem('company', JSON.stringify(company));
      this.toDashboard(subscription.id);
    } catch (err) {
      //alert('Error creating subscription');

      this.messageService.add({key: 'bc', severity:'error', summary: 'Error', detail: ''+err});
      

      console.error(err);
    }
  }

  async changeSubscriptionForOganisation(token: any, organisation: Organisation, offerKey: string, userUid: string) : Promise<boolean> {
    try {
      const subscription = await this.stripe.subscriptions.retrieve(token.subscriptionId);
      const currentItemId = subscription.items.data[0].id;
      const updatedSubscription = await this.stripe.subscriptions.update(token.subscriptionId, {
        items: [{
          id: currentItemId,
          price: token.priceId,
        }],
      });
     // alert('Subscription updated!');
      this.messageService.add({key: 'bc', severity:'success', summary: 'Info', detail: 'Subscription updated'});
      
      
      //organisation.subscriptionId = subscription.id;
      organisation.offerKey = offerKey;
      localStorage.setItem('organisation', JSON.stringify(organisation));
      this.myOrganisationService.update(userUid, organisation);

      return true;
      //this.toDashboard(currentItemId);
    } catch (err) {
      //alert('Error changing subscription');
      this.messageService.add({key: 'bc', severity:'error', summary: 'Error', detail: ''+err});
      
      console.error(err);
    }
    return false;
  }


  async makePaymentForOrganisation(token: any, priceId:string, companyKey: string, customerEmail: string, cardID: string, organisation: Organisation, amount: number, offerKey: string, userUid: string) : Promise<boolean> {
    // Get customer details
    try {
      var customerId = '';
      var onProcessCustomer: any;
      const existCustomer = await this.stripe.customers.list({
        email: customerEmail,
      });
      
      if(existCustomer.data.length <= 0) {
        const customer = await this.stripe.customers.create({
          name: customerEmail,
          source: token.id,
          email: customerEmail,
        });
        customerId = customer.id;
        onProcessCustomer = customer;
      } else {
        customerId = existCustomer.data[0].id;
        onProcessCustomer = existCustomer.data[0];
      }

      const subscription = await this.stripe.subscriptions.create({
        customer: customerId,
        items: [{ price: priceId }],
        metadata: {
          companyKey: companyKey,
          customerEmail: customerEmail,
          companyName: organisation.name,
        }
      });
  
     // alert('Subscription created!');
      //this.messageService.add({key: 'bc', severity:'success', summary: 'Info', detail: 'Subscription confirmed'});
     // organisation.offer
     // o.subscriptionPrice = amount;
      organisation.subscriptionId = subscription.id;
      organisation.offerKey = offerKey;

      localStorage.setItem('organisation', JSON.stringify(organisation));
      this.myOrganisationService.update(userUid, organisation);
     // this.toDashboard(subscription.id);
     return true;
    } catch (err) {
     // alert('Error creating subscription');
      this.messageService.add({key: 'bc', severity:'error', summary: 'Error', detail: ''+err});
      
      console.error(err);
    }
    return false;
}


}
