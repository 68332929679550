import { Component, OnInit, ViewChild } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Output as OutputType } from 'src/app/shared/interfaces/output';
import { TankCrudService } from 'src/app/shared/services/tank-crud.service';
import { ClientCrudService } from 'src/app/shared/services/client-crud.service';
import { Client } from 'src/app/shared/interfaces/client';
import { Barrel } from 'src/app/shared/interfaces/barrel';
import { Tank } from 'src/app/shared/interfaces/tank';
import { BarrelLocationCrudService } from 'src/app/shared/services/barrel-location-crud.service';
import { BarrelLocation } from 'src/app/shared/interfaces/barrel-location';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { BarrelCrudService } from 'src/app/shared/services/barrel-crud.service';
import { Filling } from 'src/app/shared/interfaces/filling';
import { WoEvent } from 'src/app/shared/interfaces/wo-event';
import { WoEventCrudService } from 'src/app/shared/services/event-crud.service';

@Component({
  selector: 'app-filling-tank',
  templateUrl: './filling-tank.component.html',
  styleUrls: ['./filling-tank.component.scss']
})
export class FillingTankComponent implements OnInit {

  @ViewChild('dropdown') dropdown: Dropdown;

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  list1: any[];
  originalList1: any[];

  list2: any[];
  list2Map = new Map<string, boolean>();

  locations: BarrelLocation[] = [];
  location: BarrelLocation = {} as BarrelLocation;
  locationKey : string = "";
  previousLocationKey : string = "";

  locationMap = new Map<string, BarrelLocation>();

  locationFilling : string = '';

  withdrawTypeMap = new Map<string, OutputType>();


  // Transformation 
  outputTypes: OutputType[] = [];
  outputType: OutputType = {} as OutputType;
  outputTypeKey : string = "";
  clients: Client[];
  client: Client;
  clientKey : string = "";

    // Transformation 
    barrel: Barrel[] = [];
    // withdrawalProductType: WoProductType = {} as WoProductType;
    // withdrawalProductTypeKey : string = "";
    barrelOriginal : Barrel[] = [];
    fillingMap = new Map<string, Barrel>();
    previousFillingTypeKey : string = "";

    quantity: string = '';


  // Constructor
  constructor(
      public  authService: AuthService,
      private tankCrudService : TankCrudService,
      private clientCrudService : ClientCrudService,
      private confirmationService: ConfirmationService,
      private BarrelLocationCrudService: BarrelLocationCrudService,
      private BarrelCrudService: BarrelCrudService,
      private messageService: MessageService,
      private eventService: WoEventCrudService,
      private router : Router
    ) {
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Actions'},
      {label: 'Filling'}
    ];

    this.clients = [];
    this.client = {} as Client;

    this.list1 = [];
     this.originalList1 = Object.assign([], this.list1);
     this.list2 = [];
     this.locations = [];
     this.location = {} as BarrelLocation;
     this.dropdown = {} as Dropdown;
   }

  ngOnInit(): void {
    let s = this.BarrelLocationCrudService.getLocationsList(); 
     s.snapshotChanges().subscribe(data => {
       this.locations = [];
       this.locationMap = new Map<string, BarrelLocation>();
       data.forEach(item => {
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }
           const loc = jsonItem as BarrelLocation;
           this.locationMap.set( loc.$key , loc );
           this.locations.push(loc);
         }
       })
       console.log(this.locations)
     });
     this.locations = this.locations.sort((a, b) => (a.name < b.name) ? -1 : 1);

    let ot = this.tankCrudService.getList(); 
    ot.snapshotChanges().subscribe(data => {
      this.outputTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.outputTypes.push(jsonItem as OutputType);
        }
      })
    })
    this.outputTypes = this.outputTypes.sort((a, b) => (a.name < b.name) ? -1 : 1);

    let c = this.clientCrudService.getClientsList(); 
    c.snapshotChanges().subscribe(data => {
      this.clients = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.clients.push(jsonItem as Client);
        }
      })
    });
    this.clients = this.clients.sort((a, b) => (a.companyName < b.companyName) ? -1 : 1);

    let pt = this.BarrelCrudService.getList(); 
     pt.snapshotChanges().subscribe(data => {
       this.barrel = [];
       this.barrelOriginal = [];
       data.forEach(item => {
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }
          const productType = jsonItem as Barrel;
          this.fillingMap.set( productType.$key , productType );
          this.barrel.push(jsonItem as Barrel);
         }
       })
       this.barrel = Object.assign([],this.barrel);
       this.barrel = this.barrel.sort((a, b) => (a.name < b.name) ? -1 : 1);

       this.barrelOriginal = Object.assign([], this.barrel);
       this.loadProducts();
      });
  }

  loadProducts() {
    let p = this.BarrelCrudService.getList(); 
     p.snapshotChanges().subscribe(data => {
       this.list1 = [];
       this.originalList1 = [];
       data.forEach(item => {
         let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
         if (jsonItem) {
           if (jsonItem) {
             jsonItem['$key'] = item.key;
           }

          const product = jsonItem as Barrel;
          const productTypeKey : string = product.$key || '';
          const productType = this.fillingMap.get(productTypeKey);
          if (productType) {
            this.list1.push(jsonItem as Barrel);
            this.originalList1.push(jsonItem as Barrel); 
            // console.log('LIST1', product);
          }
          
         }
       })
       this.originalList1 = this.originalList1.sort((a, b) => (a.name < b.name) ? -1 : 1);
       this.list1 = Object.assign([],this.originalList1);

     });
     this.list1 = Object.assign([],this.originalList1);
   }

   confirmIsOpen : boolean = false;

  moveToTarget(event: any) {
      this.confirm({} as Event, event.items);
      this.list2 = [...new Set(this.list2)];
  }

  confirm(event: Event, items : any[]) {
      try {
        this.confirmationService.confirm({
          target: event.target || undefined,
          message: 'Are you consuming all the entire barrel?',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              //confirm action
              items.forEach(
                item => {
                  this.list2Map.set( item.name, true);
                }
              );
            // this.confirmIsOpen = false;
          },
          reject: () => {
              //reject action
              if (items) {
                items.forEach( item => {

                  this.list2Map.set( item.name, false);
                  
                  const found = this.list1.find((litem) => {
                    return litem.name === item.name;
                  });
                  
                  if (found !== undefined) {
                    // 👇️ this runs
                  // console.log('✅ the object is contained in the array');
                  } else {
                  // console.log('⛔️ the object is NOT contained in the array');
                    this.list1.push(item);
                    this.list1 = Object.assign([], this.list1);
                  }
                }); 
              }
          }
      });
    } catch (e) {

    }
  }

  sourceReorder(event: any) {
 
  }

  moveToSource(event: any)  {
    const items = event.items;
    items.forEach( (item : any) => {
      const found = this.list1.find((litem) => {
        return litem.name === item.name;
      });
      if (found !== undefined) {
       // console.log('✅ the object is contained in the array');
      } else {
       // console.log('⛔️ the object is NOT contained in the array');
        this.list1.push(item);
      }
    }); 
   this.list1 = [...new Set(this.list1)];
 
   items.forEach( (item : any) => {
    this.list2 = this.list2.filter(litem => {
      return litem.name !== item.name;
    });
    }); 
 
   this.list2 = [...new Set(this.list2)];

 
  }

  disabled() : boolean {
    return this.list2.length < 1 
  }

  disabledPickList() : boolean {
    return this.list1.length < 1 && this.list2.length < 1 ;
   // return this.withdrawalProductTypeKey === '' || this.withdrawalProductTypeKey === undefined;
    // return !this.driedProductTypeKey;
    //  return this.driedProductTypeKey === '' || this.driedProductTypeKey === undefined ; 
  
  }

  // filterList1(event : any) {

  //   //console.log(event);
  //   const productKey = event.value;

  //   //console.log('Product key', productKey);

  //   if ( !productKey) {
  //     //this.list1 = [...new Set(this.list1)];
  //     //this.transformedProductOriginals = [...new Set(this.transformedProductOriginals)];
  //     this.list1 = [...new Set(this.originalList1)];
  //     this.dropdown.disabled;
  //     return;
  //   }

  //   //console.log('Product transformed key', this.transformedProductTypeKey);
  //   if (this.previousFillingTypeKey === productKey ) {
  //     return;
  //   } 
    
  //   //console.log(' ... ');

  //   // We put again all the element before filtering
  //   this.list1 = [...new Set(this.originalList1)];
    
  //   //
  //   if (!this.fillingMap.has(productKey) ) {
  //     //.log('Transformed map does not contain ', productKey); 
  //     return;
  //   }

    
  //   const transformedProduct = this.fillingMap.get(productKey);
  // //  console.log('transformed product ', transformedProduct);

  //   const inputs = transformedProduct?.inputs;
  //  // console.log('inputs ', inputs);


  //   const inputsArr : Barrel[] = Object.assign([], inputs);
      
  //   const newList1 : Barrel[] = [];

  //    // console.log('before compare');

  //     this.originalList1.forEach(
  //       (item: Barrel) => {
  //         for(let input of inputsArr) {
            
  //           // console.log('compare');
  //           // console.log( input.name );
  //           // console.log( item.productType?.name );
  //           console.log('input.name', input.name)
  //           console.log( 'item?.name', item?.name)
  //           if (input.name === item?.name) {
  //             newList1.push( item );
  //             break;
  //           }
  //         }
  //       }
  //     );

  //      this.list1 = Object.assign( [], newList1);

  //     // this.transformedProducts = this.transformedProducts.filter(item => {
  //     //    console.log('loc name', item.location);
  //     //    console.log('loca name', transformedName);
  //     //    return item.location !== transformedName;
  //     //  });
  //     //  this.transformedProductOriginals = [...new Set(this.transformedProducts)];
    
    
      
  // }

  onSubmit(){
    
    if(this.list2.length > 0){
      
      const filling : Filling = {} as Filling;
      filling.when = new Date().toISOString();
      filling.who = this.authService.userData.email || ' ';
      filling.fromWhat = [];
      filling.toWhat = {} as Tank;
      filling.toWho = '';
      filling.why = '';
      filling.howMuch = this.quantity;
  
  
      filling.toWho = this.clientKey;
      filling.why = this.outputTypeKey;
  
      // if (!this.withdrawalProductTypeKey) {
      //   console.error('Missing product type to create - error 1');
      //   return;
      // }
      // if (!this.withdrawalMap.has(this.withdrawalProductTypeKey)) {
      //   console.error('Missing product type to create - error 2');
      //   return;
      // }
      // const selectedProductType = this.withdrawalMap.get(this.withdrawalProductTypeKey);
  
  
      // // Name of the new product to create
      // const selectedProductTypeName = selectedProductType?.name;
  
      // Batch of the new product
      const now = new Date();
      const batch = now.toISOString().replace(/[-T:Z\.]/g, "");
  
     
  
      // For the history, need to see the from products
      let histories : WoEvent[] = [];
      this.list2.forEach( (product : Barrel)  => { 
  
        const newProduct : Partial<Barrel> = {} as Barrel;
        newProduct.name          = product.name;
        newProduct.batchKey         = batch;
        newProduct.quantity      = product.quantity;
        newProduct.description          = product.description;
        newProduct.year   = product.year;
        newProduct.ownerKey = product.ownerKey;
        newProduct.ownerName = product.ownerName;
        newProduct.typeKey = product.typeKey;
        newProduct.typeName = product.typeName;
        newProduct.cooperKey = product.cooperKey;
        newProduct.cooperName = product.cooperName;
        newProduct.volumeKey = product.volumeKey;
        newProduct.volumeName = product.volumeName;
        newProduct.woodKey = product.woodKey;
        newProduct.woodName = product.woodName;
        newProduct.locationKey = product.locationKey;
        newProduct.locationName = product.locationName;
        newProduct.wineKey = product.wineKey;
        newProduct.wineName = product.wineName;
        newProduct.filledDatetime = product.filledDatetime;
        newProduct.toppedDatetime = product.toppedDatetime;
        
        if (!this.locationFilling) {
          this.locationFilling = 'Filling area';
        }
        newProduct.locationName = this.locationFilling;
        if(!newProduct.history) {
          newProduct.history = [];
        }
  
        console.log('NEW PRODUCT', newProduct);
  
        // For dry event
        const fromProduct : Partial<Barrel> = Object.assign({}, product);
        delete fromProduct.$key;
        filling.fromWhat.push(fromProduct as Barrel);
        
        // For history
        let currentFromProductHistory = Object.assign([],product.history);
        currentFromProductHistory.forEach( (element: WoEvent)  => {
          let event : Partial<WoEvent> = Object.assign({},element);
          delete event?.$key;
          histories.push(element);
        });
  
        
        // histories.push(woEvent);
  
        // For consumption
        const k =  product.name+product.batchKey;
        if (this.list2Map.has(k) ) {
          const hasBeenConsumedEntirely = this.list2Map.get(k);
          if (hasBeenConsumedEntirely) {
           // console.log('entirely', product.$key);
            this.BarrelCrudService.delete(product.$key);
          }
        }
  
        // Dry event
      let newPorductWithoutHistory = Object.assign({},newProduct);
      delete newPorductWithoutHistory.history;
      filling.toWhat = newPorductWithoutHistory as Barrel;
  
      // 3.B) for the event
     const woEvent : WoEvent = {
      which: 'FILLING',
      what: filling,
      who: this.authService.userData.email,
      when: new Date().toISOString(),
      why: 'HUMAN-CLICK',
      where: 'WEBSERVER'
     } as WoEvent;
     this.eventService.addWoEvent( woEvent );
     histories.push(woEvent);
  
     // console.log('histories', histories);
      newProduct.history = histories;
      
      
  
      // Save the new product
   //   console.log("New product", newProduct);
      this.BarrelCrudService.add(newProduct as Barrel);
  
      });
      
      
      // A REFAIRE transformation.toWho = newPorductWithoutHistory as WoProduct;
  
     
  
  
      
      // this.withdrawService.addWithdrawal(withdrawal);
      
      // this.router.navigate(['/withdrawal/withdrawal-table']);
      // this.dropdown.resetFilter();
      // this.quantity = '';
  
    }
  }

  isSales() : boolean {
    //console.log(this.outputTypeKey);
    if (!this.outputTypeKey) {
      return false;
    }
    if (this.outputTypeKey == 'Sales' ) {
      return true;
    }
    return false;
  }
}
