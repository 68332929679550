import { Inject, Injectable } from '@angular/core';
import { RankedWine } from '../interfaces/ranked-wine';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyRankedWineCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(
   // @Inject('uid') private uid: string,
   // private userId$: BehaviorSubject<string>,
    private db: AngularFireDatabase
    ) 
  {

    this.path = environment.name + '/wines/myRanks/' ;
   
  
  }

//
//https://stackoverflow.com/questions/62099759/how-to-wait-for-firebase-auth-before-starting-angular-app

  // Create RankedWine
  add(uid : string, record: RankedWine) {
   // this.path = environment.name + '/' + this.userId$.getValue() + '/settings/myOrganisations/' ;
    this.path = environment.name + '/' + uid + '/wines/myRanks/'  ;
   

    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    const key = this.refs?.push( item );
    return key;
  }
  // Fetch Single RankedWine Object
  get(uid: string, id: string) {
   // this.path = environment.name + '/' + this.userId$.getValue() + '/settings/myOrganisations/' ;
    this.path = environment.name + '/' + uid + '/wines/myRanks/'  ;


    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch RankedWines List
  getList(uid: string) {
   // this.path = environment.name + '/' + this.userId$.getValue() + '/settings/myOrganisations/' ;
    this.path = environment.name + '/' + uid + '/wines/myRanks/'  ;

    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update RankedWine Object
  update(uid : string, record: RankedWine) {
   // this.path = environment.name + '/' + this.userId$.getValue()+ '/settings/myOrganisations/' ;
   this.path = environment.name + '/' + uid + '/wines/myRanks/' ;

    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<RankedWine>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete RankedWine Object
  delete(uid : string, id: string) {
   //this.path = environment.name + '/' + this.userId$.getValue() + '/settings/myOrganisations/' ;
   this.path = environment.name + '/' + uid + '/wines/myRanks/'  ;

    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}
