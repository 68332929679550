import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';

import { Harvest } from '../shared/interfaces/harvest';
import { HarvestCrudService } from '../shared/services/harvest-crud.service';
import { CageCrudService } from 'src/app/shared/services/cage-crud.service';
import { Cage } from 'src/app/shared/interfaces/cage';
import { TunaProcessCrudService } from '../shared/services/tuna-process-crud.service';
import { TunaProcess } from 'src/app/shared/interfaces/tuna-process';
import { Fridge } from '../shared/interfaces/fridge';
import { FridgeCrudService } from '../shared/services/fridge-crud.service';
import { Client } from '../shared/interfaces/client';
import { ClientCrudService } from '../shared/services/client-crud.service';

@Component({
  selector: 'app-tuna-process',
  templateUrl: './tuna-process.component.html',
  styleUrls: ['./tuna-process.component.scss']
})
export class TunaProcessComponent implements OnInit {

  quantity : number = 0;
  fridgeKey : string = '';

  harvests: Partial<Harvest>[];
  harvestNames: string[];
  harvestsMap : Map<string,Harvest>;

  cages: Cage[];
  cagesNames: string[];
  cagesMap : Map<string,Cage>;

  tunaProcesses: TunaProcess[];
  tunaProcess: TunaProcess;
  selectedTunaProcesses: TunaProcess[] = [];
  //tunaProcessMap : Map<string,TunaProcess>;

  fridges: Partial<Fridge>[];
  fridgesMap : Map<string,Harvest>;

  clients: Partial<Client>[];
  clientsMap : Map<string,Client>;

  status: string[];
  markets: string[];

  tunaProcessDialog: boolean = false;
  moveDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private harvestService : HarvestCrudService,
    private cageService : CageCrudService,
    private tunaProcessService : TunaProcessCrudService,
    private fridgeService : FridgeCrudService,
    private clientService : ClientCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Full tuna'},
      {label: 'Processes'}
    ];

    this.harvests = [];
    this.harvestNames = [];
    this.harvestsMap = new Map<string,Harvest>();

    this.cages = [];
    this.cagesNames = [];
    this.cagesMap = new Map<string,Cage>();

    this.tunaProcesses = [];
    this.tunaProcess = { } as TunaProcess ;

    this.fridges = [];
    this.fridgesMap = new Map<string,Fridge>();

    this.clients = [];
    this.clientsMap = new Map<string,Client>();

    this.status = [];
    this.status.push('Available');
    this.status.push('Disposal');
    this.status.push('Reserved');
    this.status.push('Sold');

    this.markets = [];
    this.markets.push('Local');
    this.markets.push('Overseas');
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let tp = this.tunaProcessService.getList(); 
    tp.snapshotChanges().subscribe(data => {
      this.tunaProcesses = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : TunaProcess = jsonItem as TunaProcess;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.tunaProcesses.push(l);
        }
      })
    });

    let s = this.cageService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.cages = [];
      this.cagesNames = [];
      this.cagesMap.clear();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Cage = jsonItem as Cage;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          let ll : Partial<Cage> = l;
            this.cages.push(l);
            this.cagesNames.push(l.name);
          this.cagesMap.set(ll.$key || 'na' , l);
        }
      })
    });

    let h = this.harvestService.getList(); 
    h.snapshotChanges().subscribe(data => {
      this.harvests = [];
      this.harvestNames = [];
      this.harvestsMap.clear();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Harvest = jsonItem as Harvest;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          //l.code = l.name;
          let ll : Partial<Harvest> = l;
          //delete ll.$key;
         // if (l.isEnabled ) {
            this.harvests.push(ll);
            this.harvestNames.push(l.name);
         // }
          this.harvestsMap.set(ll.$key || 'na' , l);
        }
      });
     // console.log(this.grapeVarieties);
     this.harvests = this.harvests.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);
    });

    let f = this.fridgeService.getList(); 
    f.snapshotChanges().subscribe(data => {
      this.fridges = [];
      this.fridgesMap.clear();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Harvest = jsonItem as Harvest;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          //l.code = l.name;
          let ll : Partial<Harvest> = l;
          //delete ll.$key;
         // if (l.isEnabled ) {
            this.fridges.push(ll);
         // }
          this.fridgesMap.set(ll.$key || 'na' , l);
        }
      });
     // console.log(this.grapeVarieties);
     this.fridges = this.fridges.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);

     if (this.fridges && this.fridges.length > 0) {
      this.fridgeKey = this.fridges[0].$key || '';
     }

    });


    let c = this.clientService.getClientsList(); 
    c.snapshotChanges().subscribe(data => {
      this.clients = [];
      this.clientsMap.clear();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Client = jsonItem as Client;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          //l.code = l.name;
          let ll : Partial<Client> = l;
          //delete ll.$key;
         // if (l.isEnabled ) {
            this.clients.push(ll);
         // }
          this.clientsMap.set(ll.$key || 'na' , l);
        }
      });
     // console.log(this.grapeVarieties);
     this.clients = this.clients.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);

    //  if (this.clients && this.clients.length > 0) {
    //   this.clientKey = this.fridges[0].$key || '';
    //  }

    });

    // column management
    this.cols = [
      { field: 'qrcode', header: 'QRCode' },
      { field: 'name', header: 'Process Id' },
      { field: 'harvestName', header: 'Harvest' },
      { field: 'cageName', header: 'Cage' },

      { field: 'fishWeight', header: 'Fish Total Weight' },
      { field: 'fishAverageWeight', header: 'Fish Weight (avg)' },
      { field: 'fishNumber', header: 'Fish (nb)' },
      { field: 'fishMortality', header: 'Fish Mortality (nb / %)' },

      { field: 'fishLocation', header: 'Fish Location' },
      { field: 'fishStatus', header: 'Fish status' },

      { field: 'customerMarket', header: 'Market' },
      { field: 'customerName', header: 'Customer' },
      { field: 'customerUnitPrice', header: 'Unit price' },
      { field: 'customerIsInvoiced', header: 'IsInvoiced' },

      { field: 'description', header: 'Notes' },
      { field: 'tags', header: 'Tags' },

      
      
      // { field: 'filledAt', header: 'Filled at' },
      // { field: 'emptiedAt', header: 'Emptied at' },

      // { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'qrcode');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'tags');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.cageService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.cages);
            doc.save('tuna-processes.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.tunaProcesses);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "tuna-processes");
    });
  }

  exportDTCSV() {
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field); });
      exportCols.push('position');
      this.exportService.exportToCsv(this.selectedTunaProcesses, 'tuna-processes', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.tunaProcess = {} as TunaProcess;
    this.submitted = false;
    this.tunaProcessDialog = true;
  }

  openMove(tunaProcess: TunaProcess) {
    this.tunaProcess = tunaProcess;
    this.submitted = false;
    this.moveDialog = true;
  }

  deleteSelectedTunaProcesses() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected tuna record?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.tunaProcesses = this.tunaProcesses.filter(val => !this.selectedTunaProcesses.includes(val));
            
            this.selectedTunaProcesses.forEach( tunaProcess => this.tunaProcessService.delete(this.tunaProcess.$key));
            this.selectedTunaProcesses = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'TunaProcessess Deleted', life: 3000});
        }
    });
  }

  editTunaProcess(tunaProcess: TunaProcess) {
    this.tunaProcess = {...tunaProcess};
    this.tunaProcessDialog = true;
  }

  deleteTunaProcess(tunaProcess: TunaProcess) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + tunaProcess.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.tunaProcesses = this.tunaProcesses.filter(val => val.$key !== tunaProcess.$key);
            this.tunaProcess = {} as TunaProcess;
            this.tunaProcessService.delete(tunaProcess.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'TunaProcess Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.tunaProcessDialog = false;
    this.submitted = false;
  }

  hideMoveDialog() {
    this.moveDialog = false;
    this.submitted = false;
  }

  saveTunaProcess() {
    this.submitted = true;

    if ( this.tunaProcess.name.trim() ) {
      if (this.tunaProcess.$key) {
          this.tunaProcesses[this.findIndexById(this.tunaProcess.$key)] = this.tunaProcess;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'TunaProcess Updated', life: 3000});
          this.tunaProcessService.update(this.tunaProcess);
        } else {
          this.tunaProcess.position = this.tunaProcesses.length;
          this.tunaProcessService.add(this.tunaProcess);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'TunaProcess Created', life: 3000});
      this.tunaProcesses = [...this.tunaProcesses];
    }

    this.tunaProcessDialog = false;
    this.tunaProcess = { } as TunaProcess;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.tunaProcesses.length; i++) {
        if (this.tunaProcesses[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeTunaProcess(tunaProcess : TunaProcess) {
    console.log(tunaProcess);
    console.log(this.harvests);
    this.tunaProcessService.update(tunaProcess);
  }

  completePosition(tunaProcess : TunaProcess) {
    if ( this.newPosition > -1 ) {
        tunaProcess.position = this.newPosition;
        tunaProcess = this.checkPositionLimits(tunaProcess);
        this.reorderElements(tunaProcess);
        //this.updateDB(tunaProcess);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.tunaProcesses);
    // const myClonedArray = [];
    // this.tunaProcesses.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.tunaProcesses.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.tunaProcesses.length - 1 - event.dropIndex;
    const tunaProcessToMove = this.tunaProcesses[startIndex];

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.tunaProcesses[index] = this.tunaProcesses[index+1];
        this.tunaProcesses[index].position = index;
        this.tunaProcessService.update(this.tunaProcesses[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.tunaProcesses[index] = this.tunaProcesses[index-1];
        this.tunaProcesses[index].position = index;
        this.tunaProcessService.update(this.tunaProcesses[index]);
      }
    } 
    this.tunaProcesses[endIndex] = tunaProcessToMove;
    this.tunaProcesses[endIndex].position = endIndex;
    this.tunaProcessService.update(this.tunaProcesses[endIndex]);
  }

  // onFieldEdit(tunaProcess: Spray, fieldName: string): void {
  //   //console.log(this.tunaProcesses);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + tunaProcess[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       tunaProcess.position = this.newPosition;
  //       tunaProcess = this.checkPositionLimits(tunaProcess);
  //       this.reorderElements(tunaProcess);
  //       //this.updateDB(tunaProcess);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(tunaProcess);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.tunaProcesses.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, tunaProcess: TunaProcess) {
    // console.log("event" +event);
    if (tunaProcess.position != null && tunaProcess.position > -1) {
      this.previousPosition = tunaProcess.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(tunaProcess: TunaProcess) {
    console.log('check ',tunaProcess.position + ' ' + this.tunaProcesses.length);
    if (!tunaProcess.position) {
     // console.log('check 1');
      return this.tunaProcess;
    }
    if (tunaProcess.position < 0) {
      tunaProcess.position = 0;
      // console.log('check 2');
      return tunaProcess;
    }
    if (tunaProcess.position > this.tunaProcesses.length) {
      tunaProcess.position = this.tunaProcesses.length - 1;
    //  console.log('check 3', tunaProcess.position);
      return tunaProcess;
    }
   // console.log('check 4');
    return tunaProcess;
  }

  async reorderElements(tunaProcess: TunaProcess) {
    this.newPosition = this.newPosition  > this.tunaProcesses.length ? this.tunaProcesses.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === tunaProcess.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const tunaProcessToMove = this.tunaProcesses[startIndex];
   // console.log('Item to move: ', this.tunaProcesses[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.tunaProcesses);
        console.log('Item to +1: ', this.tunaProcesses[index+1]);

        this.tunaProcesses[index] = this.tunaProcesses[index+1];
        this.tunaProcesses[index].position = index;
        // console.log('Move: ', this.tunaProcesses[index+1]);
        // console.log('Move pos: ', this.tunaProcesses[index].position);
        //await this.updateDB(this.tunaProcesses[index]);
        this.tunaProcessService.update(this.tunaProcesses[index]);
     //   console.log('Update DB: ', this.tunaProcesses[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.tunaProcesses[index] = this.tunaProcesses[index-1];
        this.tunaProcesses[index].position = index;
        this.tunaProcessService.update(this.tunaProcesses[index]);
      }
    } 
    this.tunaProcesses[endIndex] = tunaProcessToMove;
    this.tunaProcesses[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.tunaProcessService.update(this.tunaProcesses[endIndex]);
    
    // const myClonedArray = [];
    // this.tunaProcesses.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.tunaProcesses = myClonedArray;
   // this.getAllRecords();
  }


  onSelect (event: any, tunaProcess: TunaProcess) {
    //this.completeBarrel(barrel);
  }

  onBlur(event: any, tunaProcess: TunaProcess) {
    this.completeTunaProcess(tunaProcess);
  }

  onClose(event: any, tunaProcess: TunaProcess) {
    this.completeTunaProcess(tunaProcess);
  }


  onChangeHarvest(tunaProcess: TunaProcess, tunaProcessHarvestKey: string, event: any): boolean {

    // console.log('barrel event', event);
    // console.log('be ', event);

    const newKey = event.value;
    const previousKey = tunaProcessHarvestKey;
    console.log('new key wanted',  newKey);
    console.log('before key ', previousKey);

    // Si il n'y en avait pas avant et ni maintenant, riemn a faire
    if (!previousKey && !newKey) {
      console.log('save1 ');
      return true;
    }

    // Si le barril est le meme rien a faire
    if (previousKey && newKey && previousKey == newKey) {
      console.log('save2 ');
      return true;
    }


    // Sil il n'y avait pas de barril avant et maintenant oui
    if (!previousKey && newKey) {

      const newHarvest = this.harvestsMap.get(newKey) || {} as Harvest;
      const newHarvestName = newHarvest.name;
        
      tunaProcess.harvestKey = newKey;
      tunaProcess.harvestName = newHarvestName;
      console.log('save3 ');
      this.tunaProcessService.update(tunaProcess);
      return true;

    }

    if (previousKey && !newKey) {
      tunaProcess.harvestKey = '';
      tunaProcess.harvestName = '';
      console.log('save4 ');
      this.tunaProcessService.update(tunaProcess);
      return true;
    }

    const newRegion = this.harvestsMap.get(newKey) || {} as Harvest;
    const newHarvestName = newRegion.name;
        
    tunaProcess.harvestKey = newKey;
    tunaProcess.harvestName = newHarvestName;
    this.tunaProcessService.update(tunaProcess);
    return true;
  }

  displayHarvestName(key : string): string {
    if (this.harvestsMap.has(key)) {
      return this.harvestsMap.get(key)?.name || '';
    }
    return '';
  }

  onChangeCage(tunaProcess: TunaProcess, tunaProcessCageKey: string, event: any): boolean {

    // console.log('barrel event', event);
    // console.log('be ', event);

    const newKey = event.value;
    const previousKey = tunaProcessCageKey;
    console.log('new key wanted',  newKey);
    console.log('before key ', previousKey);

    // Si il n'y en avait pas avant et ni maintenant, riemn a faire
    if (!previousKey && !newKey) {
      console.log('save1 ');
      return true;
    }

    // Si le barril est le meme rien a faire
    if (previousKey && newKey && previousKey == newKey) {
      console.log('save2 ');
      return true;
    }


    // Sil il n'y avait pas de barril avant et maintenant oui
    if (!previousKey && newKey) {

      const newCage = this.cagesMap.get(newKey) || {} as Cage;
      const newCageName = newCage.name;
        
      tunaProcess.cageKey = newKey;
      tunaProcess.cageName = newCageName;
      console.log('save3 ');
      this.tunaProcessService.update(tunaProcess);
      return true;

    }

    if (previousKey && !newKey) {
      tunaProcess.cageKey = '';
      tunaProcess.cageName = '';
      console.log('save4 ');
      this.tunaProcessService.update(tunaProcess);
      return true;
    }

    const newCage = this.cagesMap.get(newKey) || {} as Cage;
    const newCageName = newCage.name;
        
    tunaProcess.cageKey = newKey;
    tunaProcess.cageName = newCageName;
    this.tunaProcessService.update(tunaProcess);
    return true;
  }

  displayCageName(key : string): string {
    if (this.cagesMap.has(key)) {
      return this.cagesMap.get(key)?.name || '';
    }
    return '';
  }

  onChangeFridge(tunaProcess: TunaProcess, tunaProcessFridgeKey: string, event: any): boolean {

    // console.log('barrel event', event);
    // console.log('be ', event);

    const newKey = event.value;
    const previousKey = tunaProcessFridgeKey;
    console.log('new key wanted',  newKey);
    console.log('before key ', previousKey);

    // Si il n'y en avait pas avant et ni maintenant, riemn a faire
    if (!previousKey && !newKey) {
      console.log('save1 ');
      return true;
    }

    // Si le barril est le meme rien a faire
    if (previousKey && newKey && previousKey == newKey) {
      console.log('save2 ');
      return true;
    }


    // Sil il n'y avait pas de barril avant et maintenant oui
    if (!previousKey && newKey) {

      const newFridge = this.fridgesMap.get(newKey) || {} as Fridge;
      const newFridgeName = newFridge.name;
        
      tunaProcess.fridgeKey = newKey;
      tunaProcess.fridgeName = newFridgeName;
      console.log('save3 ');
      this.tunaProcessService.update(tunaProcess);
      return true;

    }

    if (previousKey && !newKey) {
      tunaProcess.fridgeKey = '';
      tunaProcess.fridgeName = '';
      console.log('save4 ');
      this.tunaProcessService.update(tunaProcess);
      return true;
    }

    const newFridge = this.fridgesMap.get(newKey) || {} as Fridge;
    const newFridgeName = newFridge.name;
        
    tunaProcess.fridgeKey = newKey;
    tunaProcess.fridgeName = newFridgeName;
    this.tunaProcessService.update(tunaProcess);
    return true;
  }

  onChangeCustomer(tunaProcess: TunaProcess, tunaProcessCustomerKey: string, event: any): boolean {

    // console.log('barrel event', event);
    // console.log('be ', event);

    const newKey = event.value;
    const previousKey = tunaProcessCustomerKey;
    console.log('new key wanted',  newKey);
    console.log('before key ', previousKey);

    // Si il n'y en avait pas avant et ni maintenant, riemn a faire
    if (!previousKey && !newKey) {
      console.log('save1 ');
      return true;
    }

    // Si le barril est le meme rien a faire
    if (previousKey && newKey && previousKey == newKey) {
      console.log('save2 ');
      return true;
    }


    // Sil il n'y avait pas de barril avant et maintenant oui
    if (!previousKey && newKey) {

      const newCustomer = this.clientsMap.get(newKey) || {} as Client;
      const newCustomerName = newCustomer.companyName;
        
      tunaProcess.customerKey = newKey;
      tunaProcess.customerName = newCustomerName;
      console.log('save3 ');
      this.tunaProcessService.update(tunaProcess);
      return true;

    }

    if (previousKey && !newKey) {
      tunaProcess.customerKey = '';
      tunaProcess.customerName = '';
      console.log('save4 ');
      this.tunaProcessService.update(tunaProcess);
      return true;
    }

    const newCustomer = this.clientsMap.get(newKey) || {} as Client;
      const newCustomerName = newCustomer.companyName;
        
      tunaProcess.customerKey = newKey;
      tunaProcess.customerName = newCustomerName;
    this.tunaProcessService.update(tunaProcess);
    return true;
  }

  moveToFridge(tunaProcess: TunaProcess) {
    // this.tunaProcess = {...tunaProcess};
    // this.tunaProcessDialog = true;
    this.openMove(tunaProcess)
    this.moveDialog = true;
    //this.confirm({} as Event, []);

  }
  
  
  confirm(event: Event, items : any[]) {
    this.confirmationService.confirm({
        target: event.target || {} as EventTarget,
        header: 'Confirmation',
        // message: 'Are you moving the entire product?',
        // icon: 'pi pi-exclamation-triangle',
        accept: () => {
            //confirm action
        },
        reject: () => {
            //reject action
        }
    });
  }

  getInvoicedAmount() {
    let total = 0;
    this.tunaProcesses.forEach( p => {
      if (p.customerIsInvoiced && p.customerUnitPrice && p.fishNumber  ) {
        total += p.customerUnitPrice * ( p.fishNumber - (p.fishMortality ||0 ) );
      }
    } );
    return total;
  }

  getNotInvoicedAmount() {
    let total = 0;
    this.tunaProcesses.forEach( p => {
      if (!p.customerIsInvoiced && p.customerUnitPrice && p.fishNumber  ) {
        total += p.customerUnitPrice * ( p.fishNumber - (p.fishMortality ||0 ) );
      }
    } );
    return total;
  }

  getCountByStatus() {
    let total = 0;
    this.tunaProcesses.forEach( p => {
      if (p.customerIsInvoiced && p.customerUnitPrice && p.fishNumber  ) {
        total += p.customerUnitPrice * ( p.fishNumber - (p.fishMortality ||0 ) );
      }
    } );
    return total;
  }

}