import { Component, OnInit } from '@angular/core';
import { PaymentplanService } from '../shared/services/paymentplan.service';
import { AuthService } from '../shared/services/auth.service';
@Component({
  selector: 'app-paymentplans',
  templateUrl: './paymentplans.component.html',
  styleUrls: ['./paymentplans.component.scss']
})
export class PaymentplansComponent implements OnInit {
  stripe = require('stripe')('sk_test_51J9P4XHE9rvMqwXXSJNChK2WoKcmFGVDLCJomoIiiEgWMvvtgOwcIStMagGuwLCTkZExPhERrjfLx2GsCa8ejpEx00sN6oUumJ');  
 
  constructor(
    private paymentplanService: PaymentplanService
  ) { }

  handler:any = null;
  private key: string = 'pk_test_51J9P4XHE9rvMqwXXBhqF4VUO5xUb9HfFMXKiTkMmdNOns7cEtQC6XNoNzfLVKSYMckH0eJXBuA2h6KlXB6ULG9PE00zfAK8giN';
  ngOnInit() {
    this.invokeStripe();  }

  async makePayment(priceId: string, amount: number) {
    if(localStorage.getItem('company') !== null) { 
      const company = JSON.parse(localStorage.getItem('company')!);
      const user = JSON.parse(localStorage.getItem('user')!);
      if(company.subscriptionPrice === amount) {
        alert('You are already subscribed to this plan!');
        return;
      } else if(company.subscriptionPrice !== amount && company.subscriptionPrice !== undefined){ // Updating subscription
        var handler = (<any>window).StripeCheckout.configure({
          key: this.key,
          locale: 'auto',
          token: async (token: any) => {
            token.metadata = {
              customerEmail: user.email ,
              companyKey: company.$key
            };
            token.priceId = priceId;
            token.subscriptionId = company.subscriptionId;
            token.amount = amount;
            try {
              await this.paymentplanService.changeSubscription(token);
            } catch (err) {
              console.error(err);
            }
          }
        });

        handler.open({
          name: 'eBottli',
          description: 'Change Subscription for ' + company.name,
          amount: amount * 100,
        });
      } else {
        const customerEmail = await this.paymentplanService.getCurrentUserEmail();
        const company = await this.paymentplanService.getClickedCompany();
        var handler = (<any>window).StripeCheckout.configure({
          key: this.key,
          locale: 'auto',
          token: async (token: any) => {
            token.metadata = {
              customerEmail: customerEmail,
              companyKey: company.$key
            };
            token.priceId = priceId;
            try {
              await this.paymentplanService.sendStripeToken(token, company, amount);
            } catch (err) {
              console.error(err);
            }
          }
        });

        handler.open({
          name: 'eBottli',
         // customerEmail: user.email,
          email: user.email,
          description: 'Monthly Subscription for ' + company.name,
          amount: amount * 100,
        });
      }
    }
    
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }
}


