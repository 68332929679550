
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedClients()" [disabled]="!selectedClients || !selectedClients.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="clients" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['companyName','companyId','phone','email','address','companyDescription','status']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedClients" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>


            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="companyName" name="companyName" pResizableColumn pReorderableColumn pSortableColumn="companyName" [class]="includesCol('companyName') ? 'd' : 'h'">Client Name 
                        <p-sortIcon field="companyName"></p-sortIcon> 
                        <p-columnFilter type="text" field="companyName" display="menu"></p-columnFilter>
                    </th>
                    <th id="companyId" name="companyId" pResizableColumn pReorderableColumn [class]="includesCol('companyId') ? 'd' : 'h'">Company Id
                        <p-sortIcon field="companyId"></p-sortIcon> 
                        <p-columnFilter type="text" field="companyId" display="menu"></p-columnFilter>
                    </th>
                    <th id="phone" name="phone" pResizableColumn pReorderableColumn [class]="includesCol('phone') ? 'd' : 'h'">Phone
                        <p-sortIcon field="phone"></p-sortIcon> 
                        <p-columnFilter type="text" field="phone" display="menu"></p-columnFilter>
                    </th>
                    <th id="email" name="email" pResizableColumn pReorderableColumn [class]="includesCol('email') ? 'd' : 'h'">E-Mail
                        <p-sortIcon field="email"></p-sortIcon> 
                        <p-columnFilter type="text" field="email" display="menu"></p-columnFilter>
                    </th>
                    <th id="address" name="address" pResizableColumn pReorderableColumn [class]="includesCol('address') ? 'd' : 'h'">Address
                        <p-sortIcon field="address"></p-sortIcon> 
                        <p-columnFilter type="text" field="address" display="menu"></p-columnFilter>
                    </th>
                     <th id="companyDescription" name="companyDescription" pResizableColumn pReorderableColumn [class]="includesCol('companyDescription') ? 'd' : 'h'">Description
                        <p-sortIcon field="companyDescription"></p-sortIcon> 
                        <p-columnFilter type="text" field="companyDescription" display="menu"></p-columnFilter>
                    </th>
                    <th id="tags" name="tags" pResizableColumn pReorderableColumn [class]="includesCol('tags') ? 'd' : 'h'">Tags
                        <p-sortIcon field="tags"></p-sortIcon> 
                        <p-columnFilter type="text" field="tags" display="menu"></p-columnFilter>
                    </th>
                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('isEnabled') ? 'show' : 'hide'">
                        Enabled
                        <p-sortIcon field="isEnabled"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions &nbsp;
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-client let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="client.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{client.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                        <!-- <p-tableCheckbox [value]="client"></p-tableCheckbox> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('companyName') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="client.companyName" 
                                (blur)="completeClient(client)" 
                                (keydown.tab)="completeClient(client)" 
                                (keydown.enter)="completeClient(client)" 
                                required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{client.companyName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
<!-- onEditInit= onEditComplete onEditCancel
                    (blur)="save({ data: item })"
                                 (keydown.tab)="save({ data: item })"
                                 (keydown.enter)="save({ data: item })" -->
                    <td pEditableColumn [class]="includesCol('companyId') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="client.companyId"  
                                (blur)="completeClient(client)" (keydown.tab)="completeClient(client)" (keydown.enter)="completeClient(client)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{client.companyId}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td pEditableColumn [class]="includesCol('phone') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="client.phone"  
                                (blur)="completeClient(client)" (keydown.tab)="completeClient(client)" (keydown.enter)="completeClient(client)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{client.phone}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td pEditableColumn [class]="includesCol('email') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="client.email"  
                                (blur)="completeClient(client)" (keydown.tab)="completeClient(client)" (keydown.enter)="completeClient(client)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{client.email}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td pEditableColumn [class]="includesCol('address') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="client.address"  
                                (blur)="completeClient(client)" (keydown.tab)="completeClient(client)" (keydown.enter)="completeClient(client)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{client.address}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('companyDescription') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="client.companyDescription" 
                                (blur)="completeClient(client)" 
                                (keydown.tab)="completeClient(client)" 
                                (keydown.enter)="completeClient(client)" 
                                required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{client.companyDescription}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('tags') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-chips  id="tags" [(ngModel)]="client.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3" (onBlur)="completeClient(client)" (onAdd)="completeClient(client)" (onRemove)="completeClient(client)"></p-chips>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="margin-right: 5px;" *ngFor="let tag of client.tags">
                                    <p-tag >{{tag}}</p-tag>
                                </span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                      <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="client.isEnabled" (onChange)="completeClient(client)"></p-inputSwitch>
                    </td>

                    
                    <td class="row-actions">
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editClient(client)"></button>
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteClient(client)"></button>
                        <p-tableCheckbox class="checkbox" [value]="client"></p-tableCheckbox>
                        
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="client"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{clients ? clients.length : 0 }} client{{clients.length > 1? 's': ''}}
                    </div>
       
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="clientDialog" [style]="{width: '450px', height: '600px'}" header="Client - Add or Update" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field" style="margin-top: 10px;">
            <label for="companyName">Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="companyName" [(ngModel)]="client.companyName" required autofocus />
            <small class="p-error" *ngIf="submitted && !client.companyName">Name is required.</small>
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="phone">Phone</label>
            <input style="margin-top: 10px;" type="text" pInputText id="phone" [(ngModel)]="client.phone"  autofocus />
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="email">E-mail</label>
            <input style="margin-top: 10px;" type="email" pInputText id="email" [(ngModel)]="client.email"  autofocus />
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="address">Address</label>
        
            <textarea style="margin-top: 10px;" id="address" pInputTextarea [(ngModel)]="client.address" rows="3" cols="20"></textarea>
        </div>
 
        <!-- <div class="p-field">
            <label for="description">Notes</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="client.companyId" rows="3" cols="20"></textarea>
        </div> -->
 
             <div class="p-field">
                 <label for="tags" >Tags</label>
                 <div style="height: 10px;">&nbsp;</div>
                 <p-chips  id="tags" [(ngModel)]="client.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
             </div>   
    
         <div class="in-out" style="margin-top: 20px;">
             <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
             <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveClient()"></button>
         </div>
     </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>




