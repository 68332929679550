import { Injectable } from '@angular/core';
import { Grower } from '../interfaces/grower';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GrowerCrudService {

  
  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/growers/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // Create Grower
  add(record: Grower) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single Grower Object
  get(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
    return this.ref;
  }
  // Fetch Growers List
  getList() {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    return this.refs;
  }
  // Update Grower Object
  update(record: Grower) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + record.$key);
    let item : Partial<Grower>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Grower Object
  delete(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
    this.ref.remove();
  }
}
