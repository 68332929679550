import { Injectable, NgZone } from '@angular/core';
import { User } from '../services/user';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  authState,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  UserInfo,
} from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { concatMap, from, Observable, of, switchMap } from 'rxjs';
import { UserCrudService } from './user-crud.service';
import { MessageService } from 'primeng/api';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: any; // Save logged in user data
  currentUser$ = this.afAuth.authState;
  stripe = require('stripe')('sk_test_51J9P4XHE9rvMqwXXSJNChK2WoKcmFGVDLCJomoIiiEgWMvvtgOwcIStMagGuwLCTkZExPhERrjfLx2GsCa8ejpEx00sN6oUumJ');

  user$ : any;

  constructor(
    private userCrudService : UserCrudService,
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private messageService: MessageService,
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
        if(!('subscription' in localStorage)) {
          localStorage.setItem('subscription', JSON.stringify([]));
        }
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
    this.user$ = this.afAuth.user;
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(result.user);
        this.afAuth.authState.subscribe((user) => {
          if (user) {
            if(user.emailVerified) {
              if (result.user?.email && result.user?.uid) {
                this.userCrudService.update( result.user?.email , result.user?.uid , true);
              }
              this.SetUserData(user);
              this.router.navigate(['connect-company']);
            } else {
              //window.alert('Please verify your email before logging in.');
              this.messageService.add({key: 'bc', severity:'info', summary: 'Info', detail: 'Please verify your email before logging in.'});
    
              this.router.navigate(['verify-email-address']); 
            }
          }
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === 'auth/user-not-found' || errorCode === 'auth/wrong-password') {
         // window.alert('Invalid email or password.');
        
          this.messageService.add({key: 'bc', severity:'warn', summary: 'Warning', detail: 'Invalid email or password.'});
    
        } else {
          //window.alert(error.message);
          this.messageService.add({key: 'bc', severity:'error', summary: 'Error', detail: ''+error.message});
      
        }
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificationMail() function when new user signs up and returns promise */
        
        if (result.user?.email && result.user?.uid) {
          this.userCrudService.add( result.user?.email , result.user?.uid , false);
        }
        this.SendVerificationMail();
        this.SetUserData(result.user);

        
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === 'auth/email-already-in-use') {
          this.messageService.add({key: 'bc', severity:'warn', summary: 'Warning', detail: 'Email is already in use. Please use a different email.'});
        } else {
          //window.alert(error.message);
          this.messageService.add({key: 'bc', severity:'error', summary: 'Error', detail: ''+error.message});
       
        }
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
        
      })
      .catch((error) => {
        window.alert(error);
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false;
  }

  // get isUserVerified(): boolean {
  //   const user = JSON.parse(localStorage.getItem('user')!);
  //   return user !== null && user.emailVerified !== false;
  // }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      this.router.navigate([ environment.homePath ]);
    });
  }
  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.router.navigate([ environment.homePath ]);
        this.SetUserData(result.user);
      })
      .catch((error) => {
        //window.alert(error);
        this.messageService.add({key: 'bc', severity:'error', summary: 'Error', detail: ''+error.message});
      
      });
  }
  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }
  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('subscription');
      localStorage.removeItem('company');
      localStorage.removeItem('organisation');
      localStorage.removeItem('bin');
      localStorage.removeItem('block');
      this.router.navigate(['sign-in']);
    });
  }

  updateProfileData(profileData: Partial<auth.UserProfile>): Observable<any> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return from(user.updateProfile(profileData));
        } else {
          throw new Error('Not Authenticated');
        }
      })
    );
  }

  getTenantId(): string {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user?.tenantId; // Modify this based on your actual user data structure
  }




  SignUpAnEmployee(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        //this.SendVerificationMail();
        this.SetUserData(result.user);
        console.log('RESULT', result);
        return result.user?.uid;
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === 'auth/email-already-in-use') {
         // window.alert('Email is already in use. Please use a different email.');
          this.messageService.add({key: 'bc', severity:'warn', summary: 'Warning', detail: 'Email is already in use. Please use a different email.'});
        } else {
          //window.alert(error.message);
          this.messageService.add({key: 'bc', severity:'error', summary: 'Error', detail: ''+error.message});
        }
      });
  }

  // DeleteEmployee(email: string) {
  //   this.afAuth.
  // }
}

