<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
    </div>


    <div>
        <h1> New Movement to</h1>
    
        <div class="my-dropdown">
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="locationKey" 
                [options]="locations" 
                optionLabel="name" 
                optionValue="$key"
                
                (onChange)="filterList1($event)"
                placeholder="Any">
            </p-dropdown>  
        </div>
    </div>
    
   


    <p-pickList #pl 
        [responsive]="true" 
        [source]="list1" 
        [target]="list2" 
        [dragdrop]="true"
        sourceHeader="Available" 
        targetHeader="Selected" 
        [responsive]="true" 
        [sourceStyle]="{'width':'350px','height':'300px'}" 
        [targetStyle]="{'width':'350px','height':'300px'}" 
        filterBy="name,batch,location"
        (onMoveToTarget)="moveToTarget($event)"
        (onMoveAllToTarget)="moveToTarget($event)"
        (onMoveToSource)="moveToSource($event)"
        (onMoveAllToSource)="moveToSource($event)"
        (onSourceReorder)="sourceReorder($event)"
        sourceFilterPlaceholder="Search" 
        targetFilterPlaceholder="Search"
        [disabled]="disabledPickList()"
        >
        
        <ng-template let-receipt pTemplate="item">
            <div>
                <!-- <img src="assets/showcase/images/demo/car/{{car.brand}}.png" style="display:inline-block;margin:2px 0 2px 2px" width="48"> -->
                <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{receipt.name}} - {{receipt.batch}} ( {{receipt.location}} )</div>
            </div>
        </ng-template>
       
    </p-pickList>


    <!-- <h1>To Location</h1> -->
    

    <!-- [style]="{
        width: '450px', 
        fontFamily: 'fangsong', 
        fontSize: '20px', 
        border: '2px solid black', 
        borderRadius: '9px', 
        backgroundColor: 'green', 
        color: 'white'
    }" 

[style]="{
        position: 'bottom',
        width: '450px', 
       
        border: '1px solid black', 
        borderRadius: '5px', 
        backgroundColor: 'grey'
    }"
-->


    <p-confirmPopup  #confirmPopup id="confirmPopup">


    </p-confirmPopup>


    <!-- <div class="section">
        <div class="sectionTitle">3) Submit</div>
    </div> -->

    <!-- https://www.samanthaming.com/tidbits/94-how-to-check-if-object-is-empty/ -->
    <div style="margin-top: 20px">
         <button type="button" pButton pRipple label="Submit" [disabled]="disabled()" icon="pi pi-plus" class="button-action p-button-success p-mr-2"(click)="pl.resetFilter(); onSubmit()" ></button>
    </div>



</div>