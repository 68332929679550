import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  signinForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('',[ Validators.minLength(2), Validators.required ]),
    password: new UntypedFormControl('', [ Validators.required, Validators.min(6) ])
  });
  currentGroup : any  ;
  hide = true;

  get emailInput() { return this.signinForm.get('email'); }
  get passwordInput() { return this.signinForm.get('password'); }


  constructor(
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('user');
    try {
      this.authService.SignOut()
    } catch (error) {
      console.log('[ERROR] logout', error);
    }
  }

  getEmailInputError() {
    if (this.emailInput?.hasError('email')) {
      return 'Invalid username';
    }
    if (this.emailInput?.hasError('required')) {
      return 'Username is required';
    }
    return undefined;
  }

  getPasswordInputError() {
    if (this.passwordInput?.hasError('required')) {
      return 'Password is required';
    }
    return undefined;
  }

  signIn() { 
    this.authService.SignIn( this.emailInput?.value , this.passwordInput?.value)
  }


}
