import { Injectable } from '@angular/core';
import { Barrel } from '../interfaces/barrel';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BarrelCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/barrels/current/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // newPath() {
  //   const user = JSON.parse(localStorage.getItem('user')!)
  //   const company = JSON.parse(localStorage.getItem('company')!);
  //   this.path = 'users' + '/' + user.uid + '/' + 'companies' + '/' + company.$key + '/' + 'barrels/currentBarrels/';
  // }

  // Create Barrel
  add(record: Barrel) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    const item = {
      ...record
    }
    const newItem = this.refs?.push( item );
    const key = newItem.key;
    item.key = key || 'na';
    item.$key = key || 'na';
    this.update(item);
  }
  // Fetch Single Barrel Object
  get(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
    return this.ref;
  }
  // Fetch Barrels List
  getList() {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    return this.refs;
  }
  // Update Barrel Object
  update(record: Barrel) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + record.$key);
    let item : Partial<Barrel>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Barrel Object
  delete(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
    this.ref.remove();
  }
}
