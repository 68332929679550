import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { Employee } from 'src/app/shared/interfaces/employee';
import { EmployeeCrudService } from 'src/app/shared/services/employee-crud.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MyOrganisationCrudService } from 'src/app/shared/services/my-organisation-crud.service';
import { MyOrganisation } from 'src/app/shared/interfaces/my-organisation';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {
  employees: Employee[];
  employee: Employee;
  selectedEmployees: Employee[] = [];

  employeeDialog: boolean = false;
  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  randomPassword: string = '';

  generateRandomPassword(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }
    this.randomPassword = password;
  }

  constructor(
   // private api: APIService,
    private authService : AuthService,
    private employeeService : EmployeeCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService,
    private myEmployeeOrganisationService: MyOrganisationCrudService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Organisation'},
      {label: 'Employees'}
    ];

    this.employees = [];
    this.employee = {} as Employee;
    //this.getAllRecords();
  }

  ngOnInit(): void {
    this.dataState();
    let s = this.employeeService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.employees = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.employees?.push(jsonItem as Employee);
        }
      })
    })

    // this.getAllRecords();

    // column management
    this.cols = [
      //{ field: 'employeeId', header: 'Employee ID' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'jobFunction', header: 'Job Function' },
      { field: 'email', header: 'Email' },
      { field: 'phoneNumber', header: 'Phone Number' }, 
      { field: 'randomPassword', header: 'Random password' }, 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.employeeService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.employees);
            doc.save('employees.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.employees);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "employees");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    this.exportService.exportToCsv(this.employees, 'employees', ['employeeId', 'employeeName', '$key', 'employeeEmail', 'employeeNumber']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.employee = {} as Employee;
    this.submitted = false;
    this.employeeDialog = true;
  }

  deleteSelectedEmployees() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Employees?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            // this.companys = this.companys.filter(val => !this.selectedCompanys.includes(val));
            // this.selectedCompanys = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Employees Removed', life: 3000});
        }
    });
  }

  editEmployee(employee: Employee) {
    this.employee = {...employee};
    this.employeeDialog = true;
  }

  deleteEmployee(employee: Employee) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + employee.firstName + ' ' + employee.lastName + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.employees = this.employees.filter(val => val.$key !== employee.$key);
            this.employee = {} as Employee;
            this.employeeService.delete(employee.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Employee Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.employeeDialog = false;
    this.submitted = false;
  }

  saveEmployee() {
    this.submitted = true;

    if (this.employee.firstName && this.employee.firstName.trim()) {
        if (this.employee.$key) {
            this.employees[this.findIndexById(this.employee.$key)] = this.employee;                
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Company Updated', life: 3000});
            this.employeeService.update(this.employee);
        }
        else {
            this.employee.position = this.employees.length;
            this.employeeService.add(this.employee);

            

            this.generateRandomPassword(8);
            this.employee.randomPassword  = this.randomPassword;
            this.authService.SignUpAnEmployee( this.employee.email, this.randomPassword ).then(
              uid => {  
                console.log('UID ', uid)  ;

                const company = JSON.parse(localStorage.getItem('company')!) ;

                const currentOrganisation : MyOrganisation = {} as MyOrganisation;
                currentOrganisation.$key     = company.$key;
                currentOrganisation.name     = company.name;
                currentOrganisation.ownerKey = company.ownerKey;
                
                
                // TO REDO
                // if (uid) {
                //   this.myEmployeeOrganisationService.add( uid , currentOrganisation );
                // }

              }

            );
        }

        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Company Created', life: 3000});
        this.employees = [...this.employees];
    }        

    

    this.employeeDialog = false;
    this.employee = { } as Employee;

    
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.employees.length; i++) {
        if (this.employees[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  // createId(): string {
  //   let id = '';
  //   var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   for ( var i = 0; i < 5; i++ ) {
  //       id += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return id;
  // }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeEmployee(employee : Employee) {
    console.log("SAVE", employee);
  }
}
