import { Injectable } from '@angular/core';
import { Column } from '../interfaces/column';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
@Injectable({
  providedIn: 'root'
})
export class ColumnCrudService {

  columnsRef: AngularFireList<any> | undefined;
  columnRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // Create Column
  AddColumn(column: Column) {
    const item = {
      ...column
    }
    this.columnsRef?.push( item );
  }
  // Fetch Single Column Object
  GetColumn(id: string) {
    this.columnRef = this.db.object('columns-list/' + id);
    return this.columnRef;
  }
  // Fetch Columns List
  GetColumnsList() {
    this.columnsRef = this.db.list('columns-list');
    return this.columnsRef;
  }
  // Update Column Object
  UpdateColumn(column: Column) {
    this.columnRef = this.db.object('columns-list/' + column.$key);
   // console.log("P inside", column);
    let item : Partial<Column>  = {
      ...column
    }
    delete item?.$key;
    this.columnRef?.update(item);
  }
  // Delete Column Object
  DeleteColumn(id: string) {
    this.columnRef = this.db.object('columns-list/' + id);
    this.columnRef.remove();
  }
}
