import { Injectable } from '@angular/core';
import { BinLocation as Location } from '../interfaces/bin-location';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BinLocationCrudService {


  locationsRef: AngularFireList<any> | undefined;
  locationRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/binLocations/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // Create Location
  addLocation(location: Location) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.locationsRef = this.db.list( 'organisations/' + org + '/' + this.path );
    const item = {
      ...location
    }
    console.log(this.locationsRef);
    this.locationsRef?.push( item );
  }
  // Fetch Single Location Object
  getLocation(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.locationRef = this.db.object( 'organisations/' + org + '/' + this.path + id);
    return this.locationRef;
  }
  // Fetch Locations List
  getLocationsList() {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.locationsRef = this.db.list( 'organisations/' + org + '/' + this.path );
    return this.locationsRef;
  }
  // Update Location Object
  updateLocation(location: Location) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.locationRef = this.db.object( 'organisations/' + org + '/' + this.path + location.$key);
    let item : Partial<Location>  = {
      ...location
    }
    delete item?.$key;
    this.locationRef?.update(item);
  }
  // Delete Location Object
  deleteLocation(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.locationRef = this.db.object( 'organisations/' + org + '/' + this.path + id);
    this.locationRef.remove();
  }
}
