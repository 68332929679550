import { Injectable } from '@angular/core';
import { Scale } from '../interfaces/scale';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScaleCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/scales/' ;
  }

  // Create Scale
  add(record: Scale) {
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single Scale Object
  get(id: string) {
    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch Scales List
  getList() {
    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update Scale Object
  update(record: Scale) {
    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<Scale>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Scale Object
  delete(id: string) {
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}
