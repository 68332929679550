<div class="content" style="flex: 1">   

  <div class="container column mat-elevation-z4">
    <form class="full column2" [formGroup]="signinForm">
      <div class="title">
        <span class="greytext title-size">Already an account</span>
      </div>
  
      <div style="flex-grow: 2;" class="input-fields">
        <div class="input-column" >
          <mat-form-field style="width: 200px;">
            <input class="input-lf" matInput placeholder="Email" type="text" formControlName="email" autocomplete="email" required>
            <mat-error *ngIf="!emailInput?.valid">
                {{getEmailInputError()}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="input-column">
          <mat-form-field>
            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" required>
            <mat-icon matSuffix style="color: #58585e;"  (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="!passwordInput?.valid">
                {{getPasswordInputError()}}
            </mat-error>
        </mat-form-field>
        </div>
      </div>
  
      <div class="button">
        <button mat-raised-button style="background-color: #e1e1e1; color: #58585e;" (click)="signIn()" [disabled]="!signinForm.valid">Sign-In</button>
        <!-- <button mat-raised-button (click)="authService.GoogleAuth()">Log in with Google</button> -->
      </div>
   
    </form>

    <div class="input-column" [routerLink]="['/forgot-password']"><span class="forgot">Forgotten password - Reset</span></div>
    <div class="input-column" routerLink="/register-user"><span class="forgot">Don't have an account? Sign Up</span></div>

  </div>

  <p-toast position="center" style="background-color: white;" key="bc"></p-toast>

</div>