import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageRefService } from './local-storage-ref.service';
import { Organisation } from '../interfaces/organisation';

// https://blog.briebug.com/blog/managing-local-storage-in-angular

@Injectable({
  providedIn: 'root'
})
export class LocalStorageBService {

  private _localStorage: Storage;

  private _organisation$ = new BehaviorSubject<Organisation>({} as Organisation);
  public organisation$ : Observable<Organisation> = this._organisation$.asObservable();

  private _offer$ = new BehaviorSubject<string>('');
  public offer$ : Observable<string> = this._offer$.asObservable();

  constructor(private _localStorageRefService: LocalStorageRefService) {
    this._localStorage = _localStorageRefService.localStorage
   }

//   setData(data: string) {
//     const jsonData = JSON.stringify(data)
//     localStorage.setItem('myData', jsonData)
//  }
 
//  getData() {
//     return localStorage.getItem('myData')
//  }
 
//  removeData(key: string) {
//     localStorage.removeItem(key)
//  }



 setOrganisation(data: Organisation) {
  const jsonData = JSON.stringify(data)
  this._localStorage.setItem('organisation', jsonData)
  this._organisation$.next(data)
  this._offer$.next(data.offerKey || '');
}

loadOrganisation() {
  const data = JSON.parse(this._localStorage.getItem('organisation') || '{}')
  this._organisation$.next(data)
  this._offer$.next(data.offerKey || '');
}

clearOrganisation() {
  this._localStorage.removeItem('organisation')
  this._organisation$.next({} as Organisation);
  this._offer$.next('');
}

clearAllLocalStorage() {
  this._localStorage.clear()
  this._organisation$.next({} as Organisation)
  this._offer$.next('');
}
}
