import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { PaymentplanService } from '../shared/services/paymentplan.service';
@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
})

export class CompanyProfileComponent implements OnInit { 
    
    priceID100 = 'price_1MdmDMHE9rvMqwXXjXJ9PyeL';
    priceID200 = 'price_1Mful6HE9rvMqwXXeZe5G6Em';
    priceId!: number;    
    companyName!: string;
    companyId!: string;
    companyDescription!: string;
    email!: string;
    constructor(private _route: ActivatedRoute, private Router: Router, private paymentPlanService: PaymentplanService) {}

    ngOnInit() {
      const company = JSON.parse(localStorage.getItem('company')!);
      const user = JSON.parse(localStorage.getItem('user')!);
      this.priceId = company.subscriptionPrice;
      this.companyName = company.companyName;
      this.companyId = company.companyId;
      this.companyDescription = company.companyDescription;
      this.email = user.email;
    }

  
    
    onSubmit(_click: any) {
      // send data to the back-end
    }

    accessChangePlan() {
      this.Router.navigate(['/paymentplans']);
    }

    cancelPlan() {
      this.paymentPlanService.cancelSubscription();
    }
}
