<div class="sidebar" >
  <br/>

  <div style="display: flex; flex-direction: row; width:100%; align-items: flex-end;justify-content: flex-end;">
    <button mat-button (click)="sidenav.toggle()" style="border-width: 1px;border-style: solid">Hide</button>
  </div>
  <br/>
  
  <!-- Bin management module -->
  <ng-container *ngIf="saas == 'saas1' && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel *ngIf="offer == '0' || offer == '3'"> 
      <mat-expansion-panel-header>Bin Management</mat-expansion-panel-header>
      <div>
        <img src="/assets/images-black/ic_bin.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/bin-management/bins']">Bins</button>
      </div>
      <div>
        <img src="/assets/images-black/cabinet.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/bin-management/bin-archive']">Bins (Archive)</button>
      </div>
      <div>
        <img src="/assets/images/location.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/bin-management/bin-locations']">Bin locations</button>
      </div>
    </mat-expansion-panel>
    <br *ngIf="offer == '0' || offer == '3'"/>
  </ng-container>
  
  <!-- Box management module -->
  <ng-container *ngIf="saas == 'saas5' && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel *ngIf="offer == '0'"> 
      <mat-expansion-panel-header>Box Management</mat-expansion-panel-header>
      <div>
        <img src="/assets/images-black/ic_box.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/box-management/boxes']">Boxes</button>
      </div>
      <div>
        <img src="/assets/images/location.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/box-management/box-locations']">Box locations</button>
      </div>
    </mat-expansion-panel>
    <br *ngIf="offer == '0'"/>
  </ng-container>
  
  <!--Barrel management module -->
  <ng-container *ngIf="saas == 'saas2' && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel *ngIf="offer == '0' || offer == '2'"> 
      <mat-expansion-panel-header>Barrel Management</mat-expansion-panel-header>
      <div>
        <img src="/assets/images-black/ic_world.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/barrel-management/types']">Types</button>
      </div>
      <div>
        <img src="/assets/images-black/ic_carpenter.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/barrel-management/coopers']">Coopers</button>
      </div>
      <div>
        <img src="/assets/images-black/content.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/barrel-management/volumes']">Volumes</button>
      </div>
      <div>
        <img src="/assets/images-black/ic_wood.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/barrel-management/woods']">Wood</button>
      </div>
      <div>
        <img src="/assets/images-black/client.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/barrel-management/owners']">Owners</button>
      </div>
      <div>
        <img src="/assets/images-black/ic_barrel.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/barrel-management/barrels']">Barrels</button>
      </div>
      <div>
        <img src="/assets/images/location.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/barrel-management/barrel-locations']">Barrel locations</button>
      </div>
    </mat-expansion-panel>
    <br *ngIf="offer == '0' || offer == '2'"/>

    <mat-expansion-panel *ngIf="offer == '0' || offer == '2'"> 
      <mat-expansion-panel-header>Rack Management</mat-expansion-panel-header>
      <div>
        <img src="/assets/images-black/ic_stack.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/rack-management/racks']">Racks</button>
      </div>
      <div>
        <img src="/assets/images/location.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/rack-management/rack-locations']">Rack locations</button>
      </div>
    </mat-expansion-panel>
    <br *ngIf="offer == '0' || offer == '2'"/>
  </ng-container>
  
 
  <!-- Pallet management module -->
  <ng-container *ngIf="saas == 'saas2' && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel *ngIf="offer == '1' || offer == '2'"> 
      <mat-expansion-panel-header>Pallet Management</mat-expansion-panel-header>
      <div>
        <img src="/assets/images-black/pallet.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/pallet-management/pallets']">Pallets</button>
      </div>
      <div>
        <img src="/assets/images-black/cabinet.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/pallet-management/pallet-archive']">Pallets (Archive)</button>
      </div>
      <div>
        <img src="/assets/images/location.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/pallet-management/pallet-locations']">Pallet locations</button>
      </div>
    </mat-expansion-panel>
    <br *ngIf="offer == '1' || offer == '2'"/>
  </ng-container>
 
  


  <!-- Tank management module -->
  <ng-container *ngIf="saas == 'saas2' && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel *ngIf="offer == '2'"> 
      <mat-expansion-panel-header>Tank Management</mat-expansion-panel-header>
      <div>
        <img src="/assets/images-black/ic_tank.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/tank-management/tanks']">Tanks</button>
      </div>
      <!-- <div>
        <img src="/assets/images/location.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/tank-management/tank-locations']">Tank locations</button>
      </div> -->
    </mat-expansion-panel>
    <br *ngIf=" offer == '2'"/>
  </ng-container>
  
  

  <!-- Wine management module -->
  <ng-container *ngIf="(saas == 'saas2') && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel> 
      <mat-expansion-panel-header>Wine Management</mat-expansion-panel-header>
      <div >
        <img src="/assets/images-black/ic_grape.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/wine-management/grape-varieties']">Grape varieties</button>
      </div>
      <div *ngIf="saas == 'saas2'&& offer == '0'">
        <img src="/assets/images-black/ic_colour.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/wine-management/colours']">Colours</button>
      </div>
      <div *ngIf="saas == 'saas2'&& offer == '0'">
        <img src="/assets/images-black/ic_region.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/wine-management/regions']">Regions</button>
      </div>
      <div *ngIf="saas == 'saas2'&& offer == '0'">
        <img src="/assets/images-black/ic_grower.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/wine-management/growers']">Growers</button>
      </div>
      <div *ngIf="saas == 'saas2'&& offer == '0'">
        <img src="/assets/images-black/ic_wine.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/wine-management/wines']">Wines</button>
      </div>
      <!-- <div>
        <img src="/assets/images-black/ic_tags.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/wines/batches']">Batches</button>
      </div> -->
    </mat-expansion-panel>
    <br/>
  </ng-container>
   
  

  <!-- WO Product module -->
  <ng-container *ngIf="saas == 'saas3' && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel *ngIf="offer == '0'"> 
      <mat-expansion-panel-header>Product Management</mat-expansion-panel-header>
      <div>
        <img src="/assets/images-black/products.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/wo-product/manage/product-types']">Product types</button>
      </div>
      <!-- <div>
        <img src="/assets/images/unit.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/product/manage/units']">Units</button>
      </div>
      <div>
        <img src="/assets/images/content.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/product/manage/content-types']">Content types</button>
      </div>
      <div>
        <img src="/assets/images/packaging3.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/product/manage/packaging-types']">Packaging types</button>
      </div>
      <div>
        <img src="/assets/images-black/products.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/product/manage/product-types']">Product codes</button>
      </div>
      <div>
        <img src="/assets/images/container.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/product/manage/container-types']">Container types</button>
      </div> -->
    </mat-expansion-panel>
    <br />
  </ng-container>
  
  
   <!-- Equipemnt fish module -->
  <ng-container *ngIf="saas == 'saas4' && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel *ngIf="offer == '0'"> 
      
        <mat-expansion-panel-header>Boat management</mat-expansion-panel-header>
        <div>
          <img src="/assets/images-black/ic_cargo.png" style="width: 24px; vertical-align: middle;" />
          <button mat-flat-button [routerLink]="['/fish-management/boats']">Boats / Trawlers</button>
        </div>
        <div>
          <img src="/assets/images-black/ic_captain.png" style="width: 24px; vertical-align: middle;" />
          <button mat-flat-button [routerLink]="['/fish-management/captains']">Captains</button>
        </div>
        <div>
          <img src="/assets/images-black/ic_sailor_cap.png" style="width: 24px; vertical-align: middle;" />
          <button mat-flat-button [routerLink]="['/fish-management/crews']">Crew</button>
        </div>
      </mat-expansion-panel>
      <br />

      <!-- Equipemnt fish module -->
      <mat-expansion-panel *ngIf="offer == '0'"> 
        <mat-expansion-panel-header>Equipment Management</mat-expansion-panel-header>
        <div>
          <img src="/assets/images-black/ic_cage.png" style="width: 24px; vertical-align: middle;" />
          <button mat-flat-button [routerLink]="['/fish-management/cages']">Cages</button>
        </div>
        <div>
          <img src="/assets/images-black/ic_fridge.png" style="width: 24px; vertical-align: middle;" />
          <button mat-flat-button [routerLink]="['/fish-management/fridges']">Fridges</button>
        </div>
        <div>
          <img src="/assets/images-black/ic_scale.png" style="width: 24px; vertical-align: middle;" />
          <button mat-flat-button [routerLink]="['/fish-management/scales']">Scales</button>
        </div>
      </mat-expansion-panel>
      <br />

      <!-- Fish fish module -->
      <mat-expansion-panel *ngIf="offer == '0'"> 
        <mat-expansion-panel-header>Fish Management</mat-expansion-panel-header>
        <div>
          <img src="/assets/images-black/ic_fish_piece.png" style="width: 24px; vertical-align: middle;" />
          <button mat-flat-button [routerLink]="['/fish-management/tuna-pieces']">Tuna pieces</button>
        </div>
      </mat-expansion-panel>
      <br/>
  </ng-container>
    
  
  <!-- Grower module -->
  <ng-container *ngIf="saas == 'saas1' && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel> 
      <mat-expansion-panel-header>Parcel Management</mat-expansion-panel-header>
      <div>
        <img *ngIf="offer == '1' || offer == '0' || offer == '3'" src="/assets/images-black/ic_grape.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/parcel-management/grape-varieties']">Grape varieties</button>
      </div>
      <div *ngIf="offer == '1' || offer == '3'">
        <img src="/assets/images-black/ic_growth.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/parcel-management/growth-stages']">Growth stages</button>
      </div>
      <div *ngIf="offer == '1' || offer == '3'">
        <img src="/assets/images-black/ic_block.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/parcel-management/eblocks']">Blocks</button>
      </div>
      <div *ngIf="offer == '1' || offer == '3'">
        <img  src="/assets/images-black/ic_parcel.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/parcel-management/parcels']">Parcels</button>
      </div>
      <!-- <div *ngIf="offer == '1' || offer == '3'">
        <img  src="/assets/menu/block.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/parcel-management/eblocks']">Eblocks</button>
      </div> -->
      <!-- <div *ngIf="offer == '1' || offer == '3'">
        <img  src="/assets/images/updown.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/parcel-management/eblocks-history']">His</button>
      </div> -->
      <!-- <div>
        <img src="/assets/images-black/ic_bin.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/growers/bins']">Bins</button>
      </div> -->
    </mat-expansion-panel>
    <br/>  
  </ng-container>
   
  

  <!-- Spreadings -->
  <ng-container *ngIf="saas == 'saas1' && (localStorageService.offer$ | async) as offer">
    <mat-expansion-panel *ngIf="offer == '2' || offer == '3'"> 
      <mat-expansion-panel-header>Spray Management</mat-expansion-panel-header>
      <div>
        <img src="/assets/images-black/ic_spray.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/spray-management/sprays']">Sprays</button>
      </div>
      <div>
        <img src="/assets/images-black/ic_tractor.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/spray-management/spreadings']">Spreadings</button>
      </div>
      <div>
        <img src="/assets/images-black/ic_target.png" style="width: 24px; vertical-align: middle;" />
        <button mat-flat-button [routerLink]="['/spray-management/targets']">Targets</button>
      </div>
      <div>
      <img src="/assets/menu/equipment.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/spray-management/equipments']">Equipments</button>
    </div>
    </mat-expansion-panel>
    <br *ngIf="offer == '2'"/> 
  </ng-container>

   
  
  

  <!-- Product module -->
  <!-- <mat-expansion-panel> 
    <mat-expansion-panel-header>Product Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/unit.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/units']">Units</button>
    </div>
    <div>
      <img src="/assets/images/content.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/content-types']">Content types</button>
    </div>
    <div>
      <img src="/assets/images/packaging3.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/packaging-types']">Packaging types</button>
    </div>
    <div>
      <img src="/assets/images-black/products.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/product-types']">Product types</button>
    </div>
    <div>
      <img src="/assets/images/container.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/product/manage/container-types']">Container types</button>
    </div>
  </mat-expansion-panel>
  <br/> -->

  <!-- Reception module -->
  <!-- <mat-expansion-panel> 
    <mat-expansion-panel-header>Purchase Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/providers.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/reception/manage/providers']">Providers</button>
    </div>
  </mat-expansion-panel>
  <br/>   -->

  <!-- Reception module -->
  <mat-expansion-panel *ngIf="modules.receive"> 
    <mat-expansion-panel-header>Reception Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/deliveryMen.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/reception/manage/delivery-men']">Delivery men</button>
    </div>
    <div>
      <img src="/assets/images/providers.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/reception/manage/providers']">Providers</button>
    </div>
  </mat-expansion-panel>
  <br *ngIf="modules.receive"/>  
    
  <!-- Movement module -->
  <mat-expansion-panel *ngIf="modules.move"> 
    <mat-expansion-panel-header>Movement Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/location.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/movement/manage/locations']">Locations</button>
    </div>
  </mat-expansion-panel>
  <br *ngIf="modules.move"/>  

  <!-- Transformation module -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>Transformation Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/recipe.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/transformation/manage/recipes']">Recipes</button>
    </div>
  </mat-expansion-panel>
  <br/>   -->

  <!-- WO Transformation module -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>Transformation Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/recipe.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/transformation/manage/wo-recipes']">Product creations</button>
    </div>
  </mat-expansion-panel>
  <br/>   -->

  <!-- Output module -->
  <mat-expansion-panel *ngIf="modules.output">
    <mat-expansion-panel-header>Outputs Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images-black/outputs.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/clients/manage/outputs']">Output types</button>
    </div>
    <!-- <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/client-management/clients']">Clients</button>
    </div> -->
    <!-- <div>
      <img src="/assets/images/companies.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/clients/manage/companies']">Companies</button>
    </div> -->
  </mat-expansion-panel>
  <br *ngIf="modules.output"/>

  <!-- Client module -->
  <!-- <mat-expansion-panel *ngIf="modules.clients">
    <mat-expansion-panel-header>Client Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/client-management/clients']">Clients</button>
    </div>
  </mat-expansion-panel>
  <br *ngIf="modules.clients"/> -->

  <!-- Team module -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>HR Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Organisation / Structure</button>
    </div>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Team members</button>
    </div>
  </mat-expansion-panel>
  <br/> -->

  <!-- Safety module -->
  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>Safety Management</mat-expansion-panel-header>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Emergencies</button>
    </div>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Safety meetings</button>
    </div>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Safety reports</button>
    </div>
    <div>
      <img src="/assets/images/client.png" style="width: 24px; vertical-align: middle;" />
      <button mat-flat-button [routerLink]="['/manage/clients']">Safety audits</button>
    </div>
  </mat-expansion-panel>
  <br/> -->
    
  <br/>
</div>