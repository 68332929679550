import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Invoice } from 'src/app/shared/interfaces/invoice';

@Component({
  selector: 'app-invoice-download',
  templateUrl: './invoice-download.component.html',
  styleUrls: ['./invoice-download.component.scss']
})
export class InvoiceDownloadComponent {

  invoices: Invoice[] = [];
  constructor(private http: HttpClient) {}
  stripe = require('stripe')('sk_test_51J9P4XHE9rvMqwXXSJNChK2WoKcmFGVDLCJomoIiiEgWMvvtgOwcIStMagGuwLCTkZExPhERrjfLx2GsCa8ejpEx00sN6oUumJ');
  ngOnInit() {
    this.fetchInvoices();
  }

  async fetchInvoices() {
    const user = JSON.parse(localStorage.getItem('user')!);
    const customer = await this.stripe.customers.list({
      email: user.email
    });
    const invoicesResponse = await this.stripe.invoices.list({
      customer: customer.data[0].id,
    });
    this.invoices = invoicesResponse.data;
  }
  
  downloadInvoice(invoiceId: string) {
    console.log('Downloading invoice', invoiceId);
    const url = `https://api.stripe.com/v1/invoices/${invoiceId}.pdf`;
    const headers = new HttpHeaders({
      Authorization:'Bearer sk_test_51J9P4XHE9rvMqwXXSJNChK2WoKcmFGVDLCJomoIiiEgWMvvtgOwcIStMagGuwLCTkZExPhERrjfLx2GsCa8ejpEx00sN6oUumJ'
    });

    this.http.get(url, { headers, responseType: 'arraybuffer' }).subscribe(response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const filename = `${invoiceId}.pdf`;
        saveAs(blob, filename);
    });
  }
}
    
  
  
