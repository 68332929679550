// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'test',
  saas: 'saas1',
  stripekey: "pk_test_51N1MOsKuDTX4FRRckbH37g7xrAIxMfe0Td03eAk7kNyINU65goIm6jboDcge8SQC35WbLQ3wTvLJI15elAfiZ6vp00We88GSpa",
  firebaseConfig : {
    // IBI
    // apiKey: "AIzaSyDBkM12nf987TcfeYbMDzxeDQzKlQHEWJw",
    // authDomain: "ibisa-75eab.firebaseapp.com",
    // databaseURL: "https://ibisa-75eab-default-rtdb.firebaseio.com",
    // projectId: "ibisa-75eab",
    // storageBucket: "ibisa-75eab.appspot.com",
    // messagingSenderId: "374440258825",
    // appId: "1:374440258825:web:39f9eb359ef9dd13b937d8",
    // measurementId: "G-KWECPHX19P"

    // Wine Bottling Solutions
    // apiKey: "AIzaSyD4YHqej3PeW9gmJBIzZLXTMxEN-rW2xDw",
    // authDomain: "winebottlingsolutions.firebaseapp.com",
    // databaseURL: "https://winebottlingsolutions-default-rtdb.firebaseio.com",
    // projectId: "winebottlingsolutions",
    // storageBucket: "winebottlingsolutions.appspot.com",
    // messagingSenderId: "227924042197",
    // appId: "1:227924042197:web:6ce7c0cd37afaaba751d5c",
    // measurementId: "G-CPWJDRMLR8"

    // Dinko
    // apiKey: "AIzaSyA2_fvKoW2Ww-4Uc8Kugfs9tnZvtTLrarI",
    // authDomain: "dinko-7861c.firebaseapp.com",
    // databaseURL: "https://dinko-7861c-default-rtdb.firebaseio.com",
    // projectId: "dinko-7861c",
    // storageBucket: "dinko-7861c.appspot.com",
    // messagingSenderId: "1032026427499",
    // appId: "1:1032026427499:web:58361a8e2dd6224327612e",
    // measurementId: "G-3SH49EW79Z",
  
    // Growers
    apiKey: "AIzaSyCHfIsHoSGH4_32l0KmqfD_lgtJY_5Ddss",
    authDomain: "growers-df34d.firebaseapp.com",
    projectId: "growers-df34d",
    storageBucket: "growers-df34d.appspot.com",
    messagingSenderId: "199126400075",
    appId: "1:199126400075:web:0e455060db25798b1921ce",
    measurementId: "G-RVZZVGCTG4"

    // Qwine
    // apiKey: "AIzaSyDXvaHp6C8xJ3YQpWOI5zysOWXh8YlV5LI",
    // authDomain: "q-wine-66f00.firebaseapp.com",
    // databaseURL: "https://q-wine-66f00-default-rtdb.firebaseio.com",
    // projectId: "q-wine-66f00",
    // storageBucket: "q-wine-66f00.appspot.com",
    // messagingSenderId: "373347692836",
    // appId: "1:373347692836:web:f148416bed976a6c0f83b1",
    // measurementId: "G-PM80V4ZTTG"


    // Sorby Adams Wine
    // apiKey: "AIzaSyDRtzhO97gcyLzUEIm9pHDDyuSr1kNh-og",
    // authDomain: "sorby-adams-wines.firebaseapp.com",
    // projectId: "sorby-adams-wines",
    // storageBucket: "sorby-adams-wines.appspot.com",
    // messagingSenderId: "416367543090",
    // appId: "1:416367543090:web:6629b5df4c2960970ecd2b",
    // measurementId: "G-FY8BWSPH97"

    // Peter's firebase key
    // apiKey: "AIzaSyAXzHsZ4O5oPizWcCXPDmmT2a43ek5rnVA",
    // authDomain: "ebottli.firebaseapp.com",
    // databaseURL: "https://ebottli-default-rtdb.firebaseio.com",
    // projectId: "ebottli",
    // storageBucket: "ebottli.appspot.com",
    // messagingSenderId: "587509940675",
    // appId: "1:587509940675:web:1bcbefbfa466be2b4a7d76",
    // measurementId: "G-SC9RMHGSVP"


    // Wine club
    // apiKey: "AIzaSyBz2pAx3mWwyh7WvG3c34B31OZrL59kPGo",
    // authDomain: "nukon-fee0f.firebaseapp.com",
    // databaseURL: "https://nukon-fee0f-default-rtdb.firebaseio.com",
    // projectId: "nukon-fee0f",
    // storageBucket: "nukon-fee0f.appspot.com",
    // messagingSenderId: "453461116145",
    // appId: "1:453461116145:web:1c28000376b28b7f779550",
    // measurementId: "G-VRQV506RZ2"

    // Islander Estate Vineyards
    // apiKey: "AIzaSyAWmTb29GfYtgR8T40pY7jdaY4cRHvKDMI",
    // authDomain: "islander-estate-vineyards.firebaseapp.com",
    // databaseURL: "https://islander-estate-vineyards-default-rtdb.firebaseio.com",
    // projectId: "islander-estate-vineyards",
    // storageBucket: "islander-estate-vineyards.appspot.com",
    // messagingSenderId: "816849220652",
    // appId: "1:816849220652:web:e3e0619c3c5dfad0f0cd89",
    // measurementId: "G-J0R06LDQ4J"

    // Taronga Almonds
    // apiKey: "AIzaSyA09Mf0PNKyZ-IOc2RVG7KX2ZvE1i-UhGw",
    // authDomain: "taronga-almonds.firebaseapp.com",
    // databaseURL: "https://taronga-almonds-default-rtdb.firebaseio.com",
    // projectId: "taronga-almonds",
    // storageBucket: "taronga-almonds.appspot.com",
    // messagingSenderId: "452887588022",
    // appId: "1:452887588022:web:8e0dc77d3ed54479b6ca17",
    // measurementId: "G-TQ2LG83K3H"

    // chat database
    // apiKey: "AIzaSyC8eI6tRR2ULDiBR4a-AeH9fizhEhKcm_g",
    // authDomain: "chat-38ebe.firebaseapp.com",
    // projectId: "chat-38ebe",
    // storageBucket: "chat-38ebe.appspot.com",
    // messagingSenderId: "869737643268",
    // appId: "1:869737643268:web:1a94b543b9034fbfc46451",
    // measurementId: "G-RNKQ5ZMHLN"

    // Woodlane Orchad
    // apiKey: "AIzaSyAHDoOy13yuaSe3zAekzZqToNLZIcjHw_Y",
    // authDomain: "woodlane-orchad.firebaseapp.com",
    // databaseURL: "https://woodlane-orchad-default-rtdb.firebaseio.com",
    // projectId: "woodlane-orchad",
    // storageBucket: "woodlane-orchad.appspot.com",
    // messagingSenderId: "22889169115",
    // appId: "1:22889169115:web:daa567d6f2af8232054cfe",
    // measurementId: "G-WN18MGTJXW"

    // Ziptrak
    // apiKey: "AIzaSyDDYYz7Q7kv8vmog8ki4EPb6tUvdkNYrdQ",
    // authDomain: "ziptrak-19cc5.firebaseapp.com",
    // databaseURL: "https://ziptrak-19cc5-default-rtdb.firebaseio.com",
    // projectId: "ziptrak-19cc5",
    // storageBucket: "ziptrak-19cc5.appspot.com",
    // messagingSenderId: "474321451827",
    // appId: "1:474321451827:web:a80eaf89c7ec56bae61c46",
    // measurementId: "G-BJ8GY810RW"

    // ???
  // firebaseConfig : {
  //   apiKey: "AIzaSyAaamoXjL5jk19aUBROf6-7pQAhw5o84uc",
  //   authDomain: "ebottli-project.firebaseapp.com",
  //   databaseURL: "https://ebottli-project-default-rtdb.firebaseio.com",
  //   projectId: "ebottli-project",
  //   storageBucket: "ebottli-project.appspot.com",
  //   messagingSenderId: "94366406246",
  //   appId: "1:94366406246:web:dfe725a0272aaef0a93fa7",
  //   measurementId: "G-EN7K0C2Z0B"
  // },

  //applicationLogo: 'assets/images/logo_ziptrak_white_trans.png',
  //applicationLogo: 'assets/logo_woodlane_trans.png',
  //applicationLogo: 'assets/logo_sorbyadamswines.png',

  
  //
  //applicationLogo: 'assets/logo_ta.jpeg',
  //#005a87
  },

  companyLogo: 'assets/images/logo.png',
  //companyLogo: 'assets/images/logo.png',
  facebook: 'https://www.facebook.com/ebottli',
  linkedin: 'https://www.linkedin.com/company/e-bottli/',
  copyright: 'Copyright eBottli Pty Ltd 2023',

  //Dinko
  // homePath: 'fish-management/harvests',
  // applicationLogo: 'assets/logo_dinko.jpg',
  // modules : {

  //   wineclub: false,

  //   fish: true,

  //   barrels: false,
  //   racks: false,
  //   bins: false,
  //   boxes: false,
  //   pallets: false,
  //   tanks: false,
  //   wines: false,
  //   parcels: false,
  //   sprays: false,

  //   wo: false,

  //   clients: true,

  //   warehouse: false,

  //   actions: false,
  //   tables: false,

  //   purchase: false,
  //   receive: false,
  //   dry: true,
  //   move: false,
  //   transform: false,
  //   filling: true,
  //   output: false,
  //   update: false,
  //   event: false
  // }


  // SAAS
  applicationLogo: 'assets/images/logo.png',
  homePath: 'barrel-management/barrels',
  modules : {
    wineclub: false,
    fish: false,
    barrels: true,
    racks: true,
    bins: true,
    boxes: true,
    pallets: true,
    tanks: true,
    wines: true,
    parcels: true,
    sprays: true,

    wo: false,

    clients: true,

    warehouse: false,

    actions: false,
    tables: false,

    purchase: false,
    receive: false,
    dry: false,
    move: false,
    transform: false,
    output: false,
    update: false,
    event: false
  }



  // Sorby Adams
 // applicationLogo: 'assets/logo_sorbyadamswines.png',
 // homePath: 'barrel-management/barrels',

  // modules : {


  //   wineclub: false,
  //   fish: false,

  //   barrels: true,
  //   racks: true,
  //   bins: false,
  //   boxes: false,
  //   pallets: true,
  //   tanks: true,
  //   wines: true,
  //   parcels: false,
  //   sprays: false,

  //   wo: true,

  //   clients: true,

  //   warehouse: false,

  //   actions: true,
  //   tables: true,

  //   purchase: true,
  //   receive: true,
  //   dry: true,
  //   move: true,
  //   transform: true,
  //   output: true,
  //   update: true,
  //   event: true
  // }

  // Wine Club
  // homePath: 'wine-club/ranked-wines',
  // applicationLogo: 'assets/logo_wc.png',
  // copyright: 'Copyright The Wine Club (NF) Pty Ltd 2023',
  // modules : {

  //   wineclub: true,

  //   fish: false,

  //   barrels: false,
  //   racks: false,
  //   bins: false,
  //   boxes: false,
  //   pallets: false,
  //   tanks: false,
  //   wines: true,
  //   parcels: false,
  //   sprays: false,

  //   wo: false,

  //   clients: false,

  //   warehouse: false,

  //   actions: false,
  //   tables: false,

  //   purchase: false,
  //   receive: false,
  //   dry: false,
  //   move: false,
  //   transform: false,
  //   output: false,
  //   update: false,
  //   event: false
  // }

  // // Islander
  // applicationLogo: 'assets/logo_iev2.png',
  // homePath: 'barrel-management/barrels',
  // modules : {

  //   fish: false,

  //   barrels: true,
  //   racks: true,
  //   bins: false,
  //   boxes: false,
  //   pallets: true,
  //   tanks: true,
  //   wines: true,
  //   parcels: false,
  //   sprays: false,

  //   wo: false,

  //   clients: true,

  //   warehouse: false,

  //   actions: false,
  //   tables: false,

  //   purchase: false,
  //   receive: false,
  //   dry: false,
  //   move: false,
  //   transform: false,
  //   output: false,
  //   update: false,
  //   event: false
  // }

  // Taronga
  //  modules : {
  //   barrels: false,
  //   pallets: false,
  //   wines: false,
  //   warehouse: true,
  //   purchase: false,
  //   receive: true,
  //   dry: false,
  //   move: true,
  //   transform: true,
  //   output: true,
  //   update: false,
  //   event: false
  // }

  // // WO
  //  modules : {
  //   barrels: false,
  //   racks: false,
  //   bins: false,
  //   boxes: false,
  //   pallets: false,
  //   tanks: false,
  //   wines: false,
  //   parcels: false,
  //   sprays: false,
  //   clients: false,


  //   actions: true,
  //   tables: true,

  //   wo: true,

  //   warehouse: true,
  //   purchase: false,
  //   receive: true,
  //   dry: true,
  //   move: true,
  //   transform: true,
  //   output: true,
  //   update: false,
  //   event: false
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
