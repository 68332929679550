import { Component, OnInit, Input, ViewChild } from '@angular/core';

// View
import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { BatchTuna } from 'src/app/shared/interfaces/batch-tuna';

// Service
import { BatchTunaCrudService } from 'src/app/shared/services/batch-tuna-crud.service';



@Component({
  selector: 'app-tuna-batches',
  templateUrl: './tuna-batches.component.html',
  styleUrls: ['./tuna-batches.component.scss']
})
export class TunaBatchesComponent implements OnInit {

 harvestDate: Date = new Date();

 // Model
 tunas: BatchTuna[];
 tuna: BatchTuna;
 selectedTunas: BatchTuna[] = [];

 // Dialog for add and edit
 tunaDialog: boolean = false;

 // Diloags
 submitted: boolean = false;

 // column management
 cols: any[] = [];
 _selectedColumns: any[] = [];

 // export
 @ViewChild('dt', {static: true}) dt : any;
 exportColumns: any[] = [];

 // ui beadcrumb
 breadcrumbs: MenuItem[];
 homeBreadcrumb: MenuItem = {} as MenuItem;

 hideWhenNoPallet: boolean = false;
 noData: boolean = false;
 preLoader: boolean = true;

 separatorExp: string = "[,| ]";

 constructor(
   private tunaService : BatchTunaCrudService,
   private confirmationService: ConfirmationService,
   private messageService: MessageService,
   private exportService: ExportService
 ) { 
   // ui breadcrumbs
   this.breadcrumbs = [
     {label: 'Harvests'},
     {label: 'Tunas'}
   ];

   this.tunas = [];
   this.tuna = { } as BatchTuna ;
 }

 ngOnInit(): void {

   this.isFilter = false;
   
   this.dataState();
   let s = this.tunaService.getList(); 
   s.snapshotChanges().subscribe(data => {
     this.tunas = [];
     data.forEach(item => {
       let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
       if (jsonItem) {
         if (jsonItem) {
           jsonItem['$key'] = item.key;
         }
         const tuna = jsonItem as BatchTuna;      
         this.tunas.push(tuna);
       }
     })
   })

   // column management
   this.cols = [
     { field: 'title', header: 'Title' },
     { field: 'datetime', header: 'Date time' },
     { field: 'latitude', header: 'Latitude' },
     { field: 'longitude', header: 'Longitude' },
     { field: 'comments', header: 'Comments' },
     { field: 'enabled', header: 'Enabled' },
     { field: 'actions', header: 'Actions' } 
   ];



   this._selectedColumns = this.cols;
   this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
   this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

   // Exports
   this.exportColumns = this.cols.map(col => {
     if (col.field === 'position' || col.field === 'rank')  
       return {title: col.header, dataKey: 'position'};
     if (col.field !== 'actions')  
       return {title: col.header, dataKey: col.field};
     return {};
       
   } );
 }

 dataState() {     
   this.tunaService.getList().valueChanges().subscribe(data => {
     this.preLoader = false;
     if(data.length <= 0){
       this.hideWhenNoPallet = false;
       this.noData = true;
     } else {
       this.hideWhenNoPallet = true;
       this.noData = false;
     }
   })
 }

 includesCol(val: string) : boolean{
   let c = this._selectedColumns.find(c => c.field === val);
   return c;
 }

 // Column management
 @Input() get selectedColumns(): any[] {
   // console.log('selected columns', this._selectedColumns);
   return this._selectedColumns;
 }
 set selectedColumns(val: any[]) {
   //restore original order
   // console.log('before selected columns', this._selectedColumns);
   // console.log('before cols', this._selectedColumns);
   this._selectedColumns = this.cols.filter(col => val.includes(col));
   // console.log('after selected columns', this._selectedColumns);
 }

 // Export management
 exportPdf() {
   import("jspdf").then(jsPDF => {
       import("jspdf-autotable").then(x => {
           const doc = new jsPDF.default('l','pt');
           // @ts-ignore
           doc.autoTable(this.exportColumns, this.Tunas);
           doc.save('audit-processes.pdf');
       })
   })
 }

 exportExcel() {
   import("xlsx").then(xlsx => {
       const worksheet = xlsx.utils.json_to_sheet(this.tunas);
       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
       const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
       this.saveAsExcelFile(excelBuffer, "audit-processes");
   });
 }

 exportDTCSV() {
   console.log('export csv');
   this.dt.exportCSV();
 }

 exportToCsv(): void {
   let exportCols : string[] = [];
   this.selectedColumns.forEach( sc => exportCols.push(sc.field));
   this.exportService.exportToCsv(this.selectedTunas, 'audit-processes', exportCols);
 }

 saveAsExcelFile(buffer: any, fileName: string): void {
   let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
   let EXCEL_EXTENSION = '.xlsx';
   const data: Blob = new Blob([buffer], {
       type: EXCEL_TYPE
   });
   FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
 }

 // CRUD management
 openNew() {
   this.tuna = {} as BatchTuna;
   this.submitted = false;
   this.tunaDialog = true;
 }

 deleteSelectedTunas() {
   this.confirmationService.confirm({
       message: 'Are you sure you want to delete the selected audit',
       header: 'Confirm',
       icon: 'pi pi-exclamation-triangle',
       accept: () => {
         this.tunas = this.tunas.filter(val => !this.selectedTunas.includes(val));
         this.selectedTunas = [];
         this.tunas.forEach( pt => {
           this.tunaService.delete(pt.$key);
         } );
         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Content types deleted', life: 3000});
     }
 });
 }

 editTuna(tuna: BatchTuna) {
   this.tuna = {...tuna};
   this.tunaDialog = true;
 }

 deleteTuna(tuna: BatchTuna) {
   this.confirmationService.confirm({
       message: 'Are you sure you want to delete audit at ' + tuna?.name + '?',
       header: 'Confirm',
       icon: 'pi pi-exclamation-triangle',
       accept: () => {
           this.tunas = this.tunas.filter(val => val.$key !== tuna.$key);
           this.tuna = {} as BatchTuna;
           this.tunaService.delete(tuna.$key);
           this.messageService.add({severity:'success', summary: 'Successful', detail: 'Product Type Deleted', life: 3000});
       }
   });
 }

 hideDialog() {
   this.tunaDialog = false;
   this.submitted = false;
 }

 saveTuna() {
   this.submitted = true;

   if (this.tuna.$key) {
     this.tunas[this.findIndexById(this.tuna.$key)] = this.tuna;                
     this.tunaService.update(this.tuna);
     this.messageService.add({severity:'success', summary: 'Successful', detail: 'Audit Updated', life: 3000});
   } else {
     this.tuna.position = this.tunas.length;
     
     this.tunaService.add(this.tuna);
     this.messageService.add({severity:'success', summary: 'Successful', detail: 'Audit Created', life: 3000});
     this.tunas = [...this.tunas];
   }
   this.tunaDialog = false;
   this.tuna = { } as BatchTuna;
 }

 findIndexById(id: string): number {
   let index = -1;
   for (let i = 0; i < this.tunas.length; i++) {
       if (this.tunas[i].$key === id) {
           index = i;
           break;
       }
   } 
   return index;
 }

 filter( dt : Table, event : {target: any} ) {
   dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
 }

 completeTuna(tuna : BatchTuna) {
   const tunaTmp = {
     ...tuna
   };
   console.log("SAVE", tunaTmp);
   this.tunaService.update(  tunaTmp );
 }

  //
  completePosition(location : BatchTuna) {
   console.log("SAVE", location);
 //  this.locationService.updateLocation(location);


   if ( this.newPosition > -1 ) {
       location.position = this.newPosition;
       location = this.checkPositionLimits(location);
       this.reorderElements(location);
       //this.updateDB(spray);
       this.newPosition = -1;
       this.previousPosition = -1;
       return;
     }
 }


 @ViewChild('dt') tableElement: any; // already dt above
 isReordering : boolean = false;
 isAscReordering : boolean = false;
 isFilter : boolean = false;

 // Reorder
 reorder(event: any) {
   //console.log('Reorder event', event);
   // console.log('Before', this.sprays);
   // const myClonedArray = [];
   // this.sprays.forEach(val => myClonedArray.push(Object.assign({}, val)));
   // console.log('Before2', myClonedArray);
   if (event.dragIndex === event.dropIndex ) {
     return;
   }
   const startIndex = this.isAscReordering? event.dragIndex : this.tunas.length - 1 - event.dragIndex;
   const endIndex   = this.isAscReordering? event.dropIndex : this.tunas.length - 1 - event.dropIndex;
   const sprayToMove = this.tunas[startIndex];
   
   
   // console.log('StartIndex', startIndex);
   // console.log('EndIndex', endIndex);

   if (startIndex < endIndex) {
     for(let index = startIndex; index < endIndex; index++) {
       this.tunas[index] = this.tunas[index+1];
       this.tunas[index].position = index;
       this.tunaService.update(this.tunas[index]);
     }     
   } else {
     for(let index = startIndex; index > endIndex; index--) {
       this.tunas[index] = this.tunas[index-1];
       this.tunas[index].position = index;
       this.tunaService.update(this.tunas[index]);
     }
   } 
   this.tunas[endIndex] = sprayToMove;
   this.tunas[endIndex].position = endIndex;
   this.tunaService.update(this.tunas[endIndex]);
 }

 ngAfterViewInit() {
   this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
       if (data.field && data.field === "position") {
         this.isReordering = true;
         this.isAscReordering = (data.order == 1);
         // console.log("ASC", this.isAscReordering);
         // console.log("data.order", data.order);
       } else {
         this.isReordering = false;
       }
   });
   this.dt.onFilter.subscribe( (data: {
     filteredValue: {
       [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
     if (data && data.filteredValue) {
       this.isFilter = !(this.tunas.length === data.filteredValue['length']);
     } else {
       this.isFilter = false;
     }
 });
 
}


 previousPosition : number = -1;
 newPosition : number = -1;

 modelChangeFn(event: number, spray: BatchTuna) {
   console.log("event" +event);
   if (spray.position != null && spray.position > -1) {
     this.previousPosition = spray.position;
     this.newPosition = event;
   }
  
   console.log("previous" +this.previousPosition);
   console.log("new" +this.newPosition);
 }

 checkPositionLimits(spray: BatchTuna) {
   console.log('check ',spray.position + ' ' + this.tunas.length);
   if (!spray.position) {
   //  console.log('check 1');
     return this.tuna;
   }
   if (spray.position < 0) {
     spray.position = 0;
    // console.log('check 2');
     return spray;
   }
   if (spray.position > this.tunas.length) {
     spray.position = this.tunas.length - 1;
   //  console.log('check 3', spray.position);
     return spray;
   }
 //  console.log('check 4');
   return spray;
 }

 async reorderElements(spray: BatchTuna) {
   this.newPosition = this.newPosition  > this.tunas.length ? this.tunas.length - 1 : this.newPosition;
   this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
   if (this.previousPosition === spray.position ) {
     return;
   }
   // console.log('Previous position: '+ this.previousPosition);
   // console.log('New position: '+ this.newPosition);
   const startIndex = this.previousPosition;
   const endIndex   = this.newPosition;
   const sprayToMove = this.tunas[startIndex];
   console.log('Item to move: ', this.tunas[startIndex]);
   if (startIndex < endIndex) {
     for(let index = startIndex; index < endIndex; index++) {

       // console.log('LOC', this.tunas);
       // console.log('Item to +1: ', this.tunas[index+1]);

       this.tunas[index] = this.tunas[index+1];
       this.tunas[index].position = index;
       // console.log('Move: ', this.tunas[index+1]);
       // console.log('Move pos: ', this.tunas[index].position);
       //await this.updateDB(this.sprays[index]);
       this.tunaService.update(this.tunas[index]);
       //console.log('Update DB: ', this.tunas[index]);
     }     
   } else {
     for(let index = startIndex; index > endIndex; index--) {
       this.tunas[index] = this.tunas[index-1];
       this.tunas[index].position = index;
       this.tunaService.update(this.tunas[index]);
     }
   } 
   this.tunas[endIndex] = sprayToMove;
   this.tunas[endIndex].position = endIndex;
   this.previousPosition = -1;
   this.newPosition = -1;
   this.tunaService.update(this.tunas[endIndex]);
   
 }


 toSections(tuna : BatchTuna) {
   // if (!tuna.sections) {
      return [];
   // }
   // const sections : Section[] = Object.values(tuna.sections);
   // return sections;
 }

 toFields(section : any) {
   // if (!section.fields) {
      return [];
   // }
   // const fields : Field[] = Object.values(section.fields);
   // return fields;
 }

 isChecked(value: string | undefined) : boolean {
   if(!value) 
     return false;
   if(value == 'true')
     return true;
   return false;
 }




 onSelect(event: any) {
  //console.log('onSelect ', event);
}

onBlur(event: any) {
  //console.log('onBlur ', event);
}

onClose(event: any) {
  //console.log('onClose ', event);
}

dateSelected(event : Date) {
  this.harvestDate = event;
}

}
