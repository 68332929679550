import { Injectable } from '@angular/core';
import { Wood } from '../interfaces/wood';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WoodCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/woods/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  newPath() {
    const user = JSON.parse(localStorage.getItem('user')!)
    const company = JSON.parse(localStorage.getItem('company')!);
    this.path = 'users' + '/' + user.uid + '/' + 'companies' + '/' + company.$key + '/' + 'barrels/woods/' ;
  }

  // Create Wood
  add(record: Wood) {
     
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single Wood Object
  get(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.ref = this.db.object('organisations/' +  org + '/' + this.path + id);
    return this.ref;
  }
  // Fetch Woods List
  getList() {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    return this.refs;
  }
  // Update Wood Object
  update(record: Wood) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + record.$key);
    let item : Partial<Wood>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Wood Object
  delete(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
    this.ref.remove();
  }
}
