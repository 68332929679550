<div class="container" style="flex: 1">

  <div class="column mat-elevation-z4" style="width: 310px">

    <div class="title">
      <span class="greytext title-size">Thank You for Registering</span>
    </div>


    <div class="col" *ngIf="authService.userData as user">
      <div style="text-align: center;margin-top: 20px;">We have sent a confirmation email to <strong>{{user.email}}</strong>.</div>
      <div style="text-align: center;margin-top: 20px;margin-bottom: 20px;padding-left: 10px;padding-right: 10px;">Please check your email and click on the link to verfiy your email address.</div>
    </div>
        
      
 
    <div class="button" style="margin-bottom: 30px;">  
      <button mat-raised-button style="background-color: #e1e1e1; color: #58585e;" (click)="authService.SendVerificationMail()">Resend Verification Email</button>
      <!-- <p>Vous n'avez pas reçcu de code? <a href="javascript:void(null)" (click)="sendAgain()">Envoyer de nouveau</a></p> -->
    </div>


    <div class="input-column" routerLink="/sign-in"><span class="forgot">Going back to Sign-in</span></div>

  </div>
</div>