import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tasted-wines',
  templateUrl: './tasted-wines.component.html',
  styleUrls: ['./tasted-wines.component.scss']
})
export class TastedWinesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
