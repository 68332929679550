import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ItemListComponent } from './components/items/item-list/item-list.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { ZLongComponent } from './components/z-long/z-long.component';
import { ZShortComponent } from './components/z-short/z-short.component';
//import { PalletBwsListComponent } from './components/pallets-bws/pallet-bws-list/pallet-bws-list.component';
import { ClientListComponent } from './components/clients/client-list/client-list.component';
import { CompaniesComponent } from './components/clients/companies/companies.component';
import { ColumnListComponent } from './components/columns/column-list/column-list.component';
import { DeliveryMenComponent } from './components/delivery-men/delivery-men.component';
import { LevelListComponent } from './components/levels/level-list/level-list.component';
import { LocationListComponent } from './components/locations/location-list/location-list.component';
import { MoveLocationComponent } from './components/move/move-location/move-location.component';
import { MoveSelectionComponent } from './components/move/move-selection/move-selection.component';
import { MoveStepsComponent } from './components/move/move-steps/move-steps.component';
import { MovementTableComponent } from './components/move/movement-table/movement-table.component';
import { OutputsComponent } from './components/outputs/outputs.component';
import { ProductTypeComponent } from './components/product-type/product-type.component';
import { ProductsPerLocationComponent } from './components/products-per-location/products-per-location.component';
import { ProductsPerTypeComponent } from './components/products-per-type/products-per-type.component';
import { ProvidersComponent } from './components/providers/providers.component';
import { RowListComponent } from './components/rows/row-list/row-list.component';
import { StockComponent } from './components/stock/stock.component';
import { RecipesComponent } from './components/transform/recipes/recipes.component';
import { TransformConfirmationComponent } from './components/transform/transform-confirmation/transform-confirmation.component';
import { TransformSelectionComponent } from './components/transform/transform-selection/transform-selection.component';
import { TransformStepsComponent } from './components/transform/transform-steps/transform-steps.component';
import { TransformTableComponent } from './components/transform/transform-table/transform-table.component';
import { TransformToProductsComponent } from './components/transform/transform-to-products/transform-to-products.component';
import { ContainerTypesComponent } from './components/types/container-types/container-types.component';
import { ContentTypeComponent } from './components/types/content-type/content-type.component';
import { PackagingTypesComponent } from './components/types/packaging-types/packaging-types.component';
import { ProductTypesComponent } from './components/types/product-types/product-types.component';
import { UnitComponent } from './components/types/unit/unit.component';
//import { MovesComponent } from './movement/moves/moves.component';
//import { TransformsComponent } from './transformation/transforms/transforms.component';
import { WithdrawsComponent } from './withdrawal/withdraws/withdraws.component';
//import { ReceivesComponent } from './reception/receives/receives.component';
import { BarrelLocationsComponent } from './barrel-locations/barrel-locations.component';
import { BarrelArchiveComponent } from './barrels/barrel-archive/barrel-archive.component';
import { BarrelCurrentComponent } from './barrels/barrel-current/barrel-current.component';
import { CoopersComponent } from './barrels/coopers/coopers.component';
import { OwnersComponent } from './barrels/owners/owners.component';
import { RacksComponent } from './barrels/racks/racks.component';
import { TypesComponent } from './barrels/types/types.component';
import { VolumesComponent } from './barrels/volumes/volumes.component';
import { WoodsComponent } from './barrels/woods/woods.component';
import { BinLocationsComponent } from './bin-locations/bin-locations.component';
import { BinsComponent } from './bins/bins.component';
import { BinEventsComponent } from './bin-events/bin-events.component';
import { BlocksComponent } from './blocks/blocks.component';
import { BoatsComponent } from './boats/boats.component';
import { BoxLocationsComponent } from './box-locations/box-locations.component';
import { BoxesComponent } from './boxes/boxes.component';
import { CageToFreezerComponent } from './cage-to-freezer/cage-to-freezer.component';
import { CagesComponent } from './cages/cages.component';
import { CaptainsComponent } from './captains/captains.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { CrewsComponent } from './crews/crews.component';
import { DispatchesComponent } from './dispatches/dispatches.component';
import { Dispatches1Component } from './dispatches1/dispatches1.component';
import { FillingTankComponent } from './filling/filling-tank/filling-tank.component';
import { FridgesComponent } from './fridges/fridges.component';
import { GrowthStagesComponent } from './growth-stages/growth-stages.component';
import { HarvestsComponent } from './harvests/harvests.component';
import { PackingsComponent } from './packings/packings.component';
import { PalletLocationsComponent } from './pallet-locations/pallet-locations.component';
import { PalletArchiveComponent } from './pallets/pallet-archive/pallet-archive.component';
import { PalletCurrentComponent } from './pallets/pallet-current/pallet-current.component';
import { ParcelsComponent } from './parcels/parcels.component';
import { PaymentplansComponent } from './paymentplans/paymentplans.component';
import { PurchaseOrderComponent } from './purchases/purchase-order/purchase-order.component';
import { PurchaseOrdersComponent } from './purchases/purchase-orders/purchase-orders.component';
import { RackLocationsComponent } from './rack-locations/rack-locations.component';
import { SalesOrdersComponent } from './sales-orders/sales-orders.component';
import { ScalesComponent } from './scales/scales.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { SpraysComponent } from './sprays/sprays.component';
import { SpreadingsComponent } from './spreadings/spreadings.component';
import { TaProductsComponent } from './ta/ta-products/ta-products.component';
import { TargetsComponent } from './targets/targets.component';
import { DryTableComponent } from './transformation/dry-table/dry-table.component';
import { TunaBatchesComponent } from './tuna-batches/tuna-batches.component';
import { TunaPieceProcessComponent } from './tuna-piece-process/tuna-piece-process.component';
import { TunaPiecesComponent } from './tuna-pieces/tuna-pieces.component';
import { TunaProcessComponent } from './tuna-process/tuna-process.component';
import { TunaComponent } from './tuna/tuna.component';
import { MyRankedWinesComponent } from './wineclub/my-ranked-wines/my-ranked-wines.component';
import { RankedWinesComponent } from './wineclub/ranked-wines/ranked-wines.component';
import { BatchesComponent } from './wines/batches/batches.component';
import { ColoursComponent } from './wines/colours/colours.component';
import { GrapeVarietiesComponent } from './wines/grape-varieties/grape-varieties.component';
import { GrowersComponent } from './wines/growers/growers.component';
import { RegionsComponent } from './wines/regions/regions.component';
import { TanksComponent } from './wines/tanks/tanks.component';
import { WineArchiveComponent } from './wines/wine-archive/wine-archive.component';
import { WineCurrentComponent } from './wines/wine-current/wine-current.component';
import { WithdrawalTableComponent } from './withdrawal/withdrawal-table/withdrawal-table.component';
import { DriesComponent } from './wo/dries/dries.component';
import { MovesComponent } from './wo/moves/moves.component';
import { ReceivesComponent } from './wo/receives/receives.component';
import { TransformsComponent } from './wo/transforms/transforms.component';
import { WoProductTypeComponent } from './wo/wo-product-type/wo-product-type.component';
import { WoProductComponent } from './wo/wo-product/wo-product.component';
import { WoReceptionsComponent } from './wo/wo-receptions/wo-receptions.component';
import { EmployeesComponent } from './components/clients/employees/employees.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CompanyConnectionComponent } from './components/clients/company-connection/company-connection.component';
import { InvoiceDownloadComponent } from './invoice-download/invoice-download.component';
import { HomeComponent } from './components/chat/home/home.component';
import { JoinedRequestsComponent } from './joined-requests/joined-requests.component';
import { MyJoinedOrganisationsComponent } from './my-joined-organisations/my-joined-organisations.component';
import { MyJoinedRequestsComponent } from './my-joined-requests/my-joined-requests.component';
import { MyOrganisationsComponent } from './my-organisations/my-organisations.component';
import { OrganisationsComponent } from './organisations/organisations.component';
import { BinsArchiveComponent } from './bins-archive/bins-archive.component';
import { PaymentOffersComponent } from './payment-offers/payment-offers.component';
import { OffersComponent } from './offers/offers.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { EblocksComponent } from './eblocks/eblocks.component';
import { EblocksHistoryComponent } from './eblocks-history/eblocks-history.component';
const routes: Routes = [

  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'offers',  component: PaymentOffersComponent, pathMatch: 'full' },
  //{ path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'register-user', component: SignUpComponent },
  //{ path: 'dashboard', component: PalletBwsListComponent },
  { path: 'dashboard', component: WoProductComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },

  { path: 'z-long', component: ZLongComponent },
  { path: 'z-short', component: ZShortComponent },

  // Account
  { path: 'my-organisations', component: MyOrganisationsComponent, canActivate: [AuthGuard]},
  { path: 'my-joined-organisations', component: MyJoinedOrganisationsComponent, canActivate: [AuthGuard]},
  { path: 'my-joined-requests', component: MyJoinedRequestsComponent, canActivate: [AuthGuard]},
  { path: 'joined-requests', component: JoinedRequestsComponent, canActivate: [AuthGuard]},
  { path: 'organisations', component: OrganisationsComponent, canActivate: [AuthGuard]},



 // { path: 'pallets2/add', component: AddPalletComponent },
 // { path: 'pallets2/edit/:id', component: EditPalletComponent },

  // Wine club
  { path: 'wine-club/my-ranked-wines'         , component: MyRankedWinesComponent, canActivate: [AuthGuard] },
  { path: 'wine-club/ranked-wines'            , component: RankedWinesComponent, canActivate: [AuthGuard] },


  // Barrel module
  { path: 'barrel-management/types'           , component: TypesComponent, canActivate: [AuthGuard] },
  { path: 'barrel-management/coopers'         , component: CoopersComponent, canActivate: [AuthGuard] },
  { path: 'barrel-management/volumes'         , component: VolumesComponent, canActivate: [AuthGuard] },
  { path: 'barrel-management/woods'           , component: WoodsComponent, canActivate: [AuthGuard] },
  { path: 'barrel-management/owners'          , component: OwnersComponent, canActivate: [AuthGuard] },
  { path: 'barrel-management/barrels'         , component: BarrelCurrentComponent, canActivate: [AuthGuard] },
  { path: 'barrel-management/barrel-locations', component: BarrelLocationsComponent },
  { path: 'barrel-management/barrels-archive' , component: BarrelArchiveComponent, canActivate: [AuthGuard] },

  // Rack module
  { path: 'rack-management/racks'           , component: RacksComponent, canActivate: [AuthGuard] },
  { path: 'rack-management/rack-locations'  , component: RackLocationsComponent },
  

  // Bin module
  { path: 'bin-management/bins'               , component: BinsComponent, canActivate: [AuthGuard] },
  { path: 'bin-management/bin-locations'      , component: BinLocationsComponent, canActivate: [AuthGuard] },
  { path: 'bin-management/bin-archive'        , component: BinsArchiveComponent, canActivate: [AuthGuard] },
  { path: 'bin-management/event-table'        , component: BinEventsComponent, canActivate: [AuthGuard] },
  { path: 'bin-management/bin-event'          , component: BinEventsComponent, canActivate: [AuthGuard] },

  // Box module
  { path: 'box-management/boxes'              , component: BoxesComponent, canActivate: [AuthGuard] },
  { path: 'box-management/box-locations'      , component: BoxLocationsComponent, canActivate: [AuthGuard] },

  // Equipemnt module
  { path: 'fish-management/tuna-batches'      , component: TunaBatchesComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/tuna-process'      , component: TunaProcessComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/tunas'             , component: TunaComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/tuna-piece-process', component: TunaPieceProcessComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/tuna-pieces'       , component: TunaPiecesComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/harvests'          , component: HarvestsComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/packings'          , component: PackingsComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/dispatches1'       , component: Dispatches1Component, canActivate: [AuthGuard] },
  { path: 'fish-management/dispatches'        , component: DispatchesComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/cageToFreezer'     , component: CageToFreezerComponent, canActivate: [AuthGuard] },

  { path: 'fish-management/sales-orders'      , component: SalesOrdersComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/cages'             , component: CagesComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/boats'             , component: BoatsComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/captains'          , component: CaptainsComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/crews'             , component: CrewsComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/fridges'           , component: FridgesComponent, canActivate: [AuthGuard] },
  { path: 'fish-management/scales'            , component: ScalesComponent, canActivate: [AuthGuard] },


   // Pallet module
  { path: 'pallet-management/pallets'         , component: PalletCurrentComponent, canActivate: [AuthGuard] },
  { path: 'pallet-management/pallet-locations', component: PalletLocationsComponent, canActivate: [AuthGuard] },
  { path: 'pallet-management/pallet-archive' , component: PalletArchiveComponent, canActivate: [AuthGuard] },

  // Tank module
  { path: 'tank-management/tanks'            , component: TanksComponent, canActivate: [AuthGuard] },
  
  // Wine module
  { path: 'wine-management/colours'           , component: ColoursComponent, canActivate: [AuthGuard] },
  { path: 'wine-management/grape-varieties'   , component: GrapeVarietiesComponent, canActivate: [AuthGuard] },
  { path: 'wine-management/batches'           , component: BatchesComponent, canActivate: [AuthGuard] },
  { path: 'wine-management/growers'           , component: GrowersComponent, canActivate: [AuthGuard] },
  { path: 'wine-management/regions'           , component: RegionsComponent, canActivate: [AuthGuard] },
  { path: 'wine-management/wines'             , component: WineCurrentComponent, canActivate: [AuthGuard]},
  { path: 'wine-management/archive'           , component: WineArchiveComponent, canActivate: [AuthGuard] },

  // Parcel module
  { path: 'parcel-management/grape-varieties' , component: GrapeVarietiesComponent, canActivate: [AuthGuard] },
  { path: 'parcel-management/growth-stages' , component: GrowthStagesComponent, canActivate: [AuthGuard] },
  { path: 'parcel-management/blocks'   , component: BlocksComponent, canActivate: [AuthGuard]},
  { path: 'parcel-management/parcels' , component: ParcelsComponent, canActivate: [AuthGuard] },
  { path: 'parcel-management/eblocks', component: EblocksComponent, canActivate: [AuthGuard] },
  { path: 'parcel-management/eblocks-history', component: EblocksHistoryComponent, canActivate: [AuthGuard] },

  // Spray module
  { path: 'spray-management/sprays' , component: SpraysComponent, canActivate: [AuthGuard] },
  { path: 'spray-management/spreadings' , component: SpreadingsComponent, canActivate: [AuthGuard] },
  { path: 'spray-management/targets' , component: TargetsComponent, canActivate: [AuthGuard] },
  { path: 'spray-management/equipments', component: EquipmentComponent, canActivate: [AuthGuard] },


  // WO Product module
  { path: 'wo-product/manage/product-types', component: WoProductTypeComponent },

  // WO Product module
  { path: 'ta-product/products', component: TaProductsComponent },
  
  // Purchase module
  { path: 'purchase/orders', component: PurchaseOrdersComponent },
  { path: 'purchase/order', component: PurchaseOrderComponent },

  // Product module
  { path: 'product/manage/units', component: UnitComponent },
  { path: 'product/manage/content-types', component: ContentTypeComponent },
  { path: 'product/manage/packaging-types', component: PackagingTypesComponent },
  { path: 'product/manage/product-types', component: ProductTypesComponent },
  { path: 'product/manage/container-types', component: ContainerTypesComponent },
  { path: 'product/products', component: WoProductComponent },
  { path: 'product/products-by-location', component: ProductsPerLocationComponent },
  { path: 'product/products-per-type', component: ProductsPerTypeComponent },

  // Reception module
  { path: 'reception/manage/delivery-men', component: DeliveryMenComponent },
  { path: 'reception/manage/providers', component: ProvidersComponent },
  { path: 'reception/receptions', component: WoReceptionsComponent },
  { path: 'reception/receives', component: ReceivesComponent },

  //{ path: 'pallets', component: PalletBwsListComponent },
  { path: 'products', component: ProductTypeComponent },
  { path: 'stock', component: StockComponent },

  // Movement module
  { path: 'movement/move/selection', component: MoveSelectionComponent },
  { path: 'movement/move/to', component: MoveLocationComponent },
  { path: 'movement/move/confirmation', component: MoveStepsComponent },
  { path: 'movement/moves', component: MovesComponent },
  { path: 'movement/move', component: MoveStepsComponent },
  { path: 'movement/movement-table', component: MovementTableComponent },
  { path: 'movement/manage/locations', component: LocationListComponent },
  { path: 'movement/manage/rows', component: RowListComponent },
  { path: 'movement/manage/columns', component: ColumnListComponent },
  { path: 'movement/manage/levels', component: LevelListComponent },
   
  // Transformation module
  { path: 'transformation/manage/recipes', component: RecipesComponent },
  { path: 'transformation/selection', component: TransformSelectionComponent },
  { path: 'transformation/transformation', component: TransformToProductsComponent },
  { path: 'transformation/confirmation', component: TransformConfirmationComponent },
  { path: 'transformation/transformation-table', component: TransformTableComponent },
  { path: 'transformation/transforms', component: TransformsComponent },
  { path: 'transformation/dries', component: DriesComponent },
  { path: 'transformation/dry-table', component: DryTableComponent },
  { path: 'transformation/transform', component: TransformStepsComponent },

  // Withdrawal module
  { path: 'withdrawal/withdraws', component: WithdrawsComponent },
  { path: 'withdrawal/withdrawal-table', component: WithdrawalTableComponent },

  { path: 'stock', component: StockComponent },

  // Filling tank module
  { path: 'filling-tanks/filling-tanks', component: FillingTankComponent },

  // Client / Output module
  { path: 'clients/manage/outputs', component: OutputsComponent },
  { path: 'client-management/clients', component: ClientListComponent },
  { path: 'client-management/companies', component: CompaniesComponent },
  { path: 'organisation-management/employees', component: EmployeesComponent },
  { path: 'client-management/companies/profile', component: CompanyProfileComponent },
  { path: 'connect-company', component: CompanyConnectionComponent},
  { path: 'items', component: ItemListComponent },
  
{ path: 'myaccount', component: MyProfileComponent },
{ path:'paymentplans',component: PaymentplansComponent},
{ path:'offers',component: PaymentOffersComponent},
{ path:'organisation-offers',component: OffersComponent},
{ path: 'invoices', component: InvoiceDownloadComponent },

{ path: 'chat', component: HomeComponent },
  //{ path: 'my account', component: MyProfileComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }