import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { format } from 'date-fns';
import { ConfirmationService, MenuItem, SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { SprayCrudService } from '../shared/services/spray-crud.service';
import { TargetCrudService } from '../shared/services/target-crud.service';
import { EquipmentServices } from '../shared/services/equipment.service';
import { SpreadingCrudService } from '../shared/services/spreading-crud.service';
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';
import { Table } from 'primeng/table';
import { MultiSelect } from 'primeng/multiselect';
import { Spreading } from '../shared/interfaces/spreading';
import { Parcel } from './../shared/interfaces/parcel';
import { UsersService } from './../shared/services/users.service';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { dateStartEndValidator } from '../directives/spreading-date-validator.directive';
import { Spray } from '../shared/interfaces/spray';
import { Target } from '../shared/interfaces/target';
import { Equipment } from '../shared/interfaces/equipment';



@Component({
  selector: 'app-spreadings',
  templateUrl: './spreadings.component.html',
  styleUrls: ['./spreadings.component.scss']
  
})
export class SpreadingsComponent implements OnInit {
  @ViewChild('dt', {static: true}) dt : any;
  endDateFormControl= new UntypedFormControl('', [dateStartEndValidator('')] );

  tenantId?: string;
  collaborators?: string[];
  currentCollaborators?: string[];

  // ui management
  spreadings: Spreading[];
  spreading: Spreading;
  selectedSpreadings: Spreading[] = [];
  spreadingDialog?: boolean;
  beaufortDialog?: boolean;
  cfDialog?: boolean;
  waterDialog?: boolean;
  productUsedDialog?: boolean;
  submitted?: boolean;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  exportColumns?: any[];

  // ui slelect
  sprays: Spray[];
  spraysMap : Map<string,Spray>;

  equipments: Equipment[];
  equipmentsMap: Map<string,Equipment>;

  machines?: SelectItem<any>[] = [];
  operators?: SelectItem<any>[] = [];
  parcels?: SelectItem<any>[] = [];
  weathers: SelectItem<any>[] = [];
  visibleBlocks: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem;
  AWRIitems: MenuItem[];
  Glinkitems: MenuItem[];
  Gwebitems:  MenuItem[];
  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;
  // model
  //sprays: string[];
  targets: Target[];
  targetsMap: Map<string,Target>;

  targetTypes: string[];
  
  meteo: string[];
  beaufortScale: string[];
  windDirections: string[];
  
  targetToTargetType = new Map([]);
  grapeVarieties: any[] = [];
  growthStage: any[] = [];
  display?: boolean;
  unitAmoutOfWaterSprayed: string[] = ['L/ha', 'L/100m'];
  unitAmoutOfProductUsed: string[] = [ 'g', 'kg', 'ml', 'L'];
  unitTemp: string[] = [ '°C', '°F', '°K'];
  unitSoilTemp: string[] = ['°C', '°F', '°K'];
  AWRIHEADER: string[] = ["Variety", "Block", "Growth Stage", "Target pest, disease or weed",
  "Full registered product name(s)", "Winery restriction on use", "Chosen label rate of registered product used per 100L (g, kg, ml, L)",
  "Concfactor  (CF)", "Amount of water sprayed (L/ha or L/100m)", "Amount of registered product used (per ha or per 100m) (g, kg, ml, L)",
  "Comments (Compulsory NSW & Vic)","Start","Finish","Wind Sp./Dir.","Operator","Weather: Temp/Humidity","Equipment"];
  WEBHEADER: string[] = ["Status", " Data Start", "Date End", "GWID", "Block Section", "Final Spary?",
    "Pets/Diseases", "Treaments Products", "Rates", "CF", "Alert+Warning", "Action"];
  LINKHEADER: string[] = ["Variety", "GrapeLink Block ID/Block Name", "Growth Stage",
    "Row space", "Row M", "Ha", "Block finished date", "Earliest Harvest Date"];
  constructor(
    public confirmationService: ConfirmationService,
    private sprayService: SprayCrudService,
    private targetService: TargetCrudService,
    private spreadingService: SpreadingCrudService,
    private equipmentService: EquipmentServices,
    private messageService: MessageService,
    private exportService: ExportService,
  ) { 
    this.breadcrumbs = [
      {label: 'Spreadings'}
    ];
    this.homeBreadcrumb = { icon: 'pi pi-home', routerLink: '/' };
    
    this.spreadings = [];
    this.spreading = {} as Spreading;

    this.sprays = [];
    this.spraysMap = new Map<string,Spray>();

    this.targets = [];
    this.targetsMap = new Map<string,Target>();

    this.equipments = [];
    this.equipmentsMap = new Map<string,Equipment>();

    this.AWRIitems = [
      { label: 'Excel', icon: 'pi pi-file-excel', command: () => {  } },
      { label: 'Pdf', icon: 'pi pi-file-pdf', command: () => {  } },
      { label: 'Csv', icon: 'pi pi-file', command: () => {  } },
    ];
    this.Glinkitems = [
      { label: 'Excel', icon: 'pi pi-file-excel', command: () => {  } },
      { label: 'Pdf', icon: 'pi pi-file-pdf', command: () => {  } },
      { label: 'Csv', icon: 'pi pi-file', command: () => {  } },
    ];
    this.Gwebitems = [
      { label: 'Excel', icon: 'pi pi-file-excel', command: () => {  } },
      { label: 'Pdf', icon: 'pi pi-file-pdf', command: () => {  } },
      { label: 'Csv', icon: 'pi pi-file', command: () => {} },
      ];

    this.targetTypes = ['pest', 'disease' , 'weed'];
    this.meteo = [ 'sun' , 'sun-cloud', 'cloud', 'rain', 'snow', 'thunder' ];
    this.beaufortScale = [ '0 - calm', '1 - light air', '2 - light breeze', '3 - gentle breeze',
                           '4 - moderate breeze', '5 - fresh breeze', '6 - strong breeze' , '7 - high wind',
                           '8 - gale', '9 - strong gale', '10 - storm', '11 - violent storm', '12 - hurricane'
                         ]; // https://en.wikipedia.org/wiki/Beaufort_scale
    this.windDirections = [ 'North', 'North-East', 'East', 'South-East', 'South', 'South-West', 'West', 'North-West' ];

    this.weathers = [
      { label: 'sun', value: 'sun' },
      { label: 'sun/cloud', value: 'sun/cloud' },
      { label: 'cloud', value: 'cloud' },
    ];
  }

  ngOnInit(): void {
    // Get Spreading list
    let sp = this.spreadingService.getList();
    sp.snapshotChanges().subscribe(data => {
      this.spreadings = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ;
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Spreading = jsonItem as Spreading;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          if ( l.isEnabled ) {
            this.spreadings.push(l);
          }
        }
      });
      this.spreadings = this.spreadings.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1 );
    });


    // Get Spray list
    let s = this.sprayService.getList();
    s.snapshotChanges().subscribe(data => {
      this.sprays = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Spray = jsonItem as Spray;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          if ( l.isEnabled ) { 
            this.sprays.push(l);
          }
          this.spraysMap.set( l.$key , l);
        }
      });
      this.sprays = this.sprays.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);
   });

   //get target list
   let t = this.targetService.getList();
    t.snapshotChanges().subscribe(data => {
      this.targets = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Target = jsonItem as Target;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          if ( l.isEnabled ) { 
            this.targets.push(l);
          }
          this.targetsMap.set( l.$key , l);
        }
      });
      this.targets = this.targets.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);
   });

   // get equipment list
   let e = this.equipmentService.getList();
    e.snapshotChanges().subscribe(data => {
      this.equipments = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ;
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Equipment = jsonItem as Equipment;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          if ( l.isEnabled ) {
            this.equipments.push(l);
          }
          this.equipmentsMap.set( l.$key , l);
        }
      });
      this.equipments = this.equipments.sort((a, b) => ( (a.position || 0) < (b.position || 1) ) ? -1 : 1);
    });
   
    // column management
    this.cols = [
      { field: 'rank', header: 'Rank' },
      { field: 'id', header: 'Spreading' },
      { field: 'sStartDate', header: 'Date (start)' },
      { field: 'sStartTime', header: 'Time (start)' },
      { field: 'sEndDate', header: 'Date (end)' },
      { field: 'sEndTime', header: 'Time (end)' },
      { field: 'spray', header: 'Spray' },
      { field: 'actions', header: 'Actions' },
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
     this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
     this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'year');
    // this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  getSprayOptions() {
    return this.sprays.map(spray => ({
      label: spray.name, // or any other property you want to display
      value: spray
    }));
  }
  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.spreadings);
            doc.save('barrels.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.spreadings);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "spreadings");
    });
  }

  exportDTCSV() {
  // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
      });
      exportCols.push('position');
      this.exportService.exportToCsv(this.selectedSpreadings, 'spreadings', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
}

  isSelectedColumn(title: string) : boolean{
    const column = this._selectedColumns?.find(column => column.field === title);
    return column;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  openNew() {
    this.spreading = {} as Spreading;
    const currentDate = new Date();
    const year  = currentDate.getFullYear();
    const month = currentDate.getMonth()+1;
    const day   = currentDate.getDate();

    const time  = format(currentDate, 'HH:mm:ss');
    this.spreading.sStartTime = time;
    const timeString = year + '-' + month + '-' + day;
    this.spreading.sStartDate = this.formatDate(timeString);
    this.submitted = false;
    this.spreadingDialog = true;
  }

  formatDate(date: String): string {
    const [year, month, day] = date.split('-');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  openBeaufort() {
    this.beaufortDialog = true;
  }

  openWater() {
    this.waterDialog = true;
  }

  openCF() {
    this.cfDialog = true;
  }

  openProductUsed() {
    this.productUsedDialog = true;
  }

  deleteSelectedSpreadings() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected spreadings?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.spreadings = this.spreadings.filter(val => !this.selectedSpreadings.includes(val));
            
            this.selectedSpreadings.forEach( spreading => this.spreadingService.delete(spreading.$key));
            this.selectedSpreadings = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Spreadings Deleted', life: 3000});
        }
    });
  }

  editSpreading(spreading: Spreading) {
    this.spreading = {...spreading};
    this.spreadingDialog = true;
  }

  deleteSpreading(spreading: Spreading) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ' + spreading.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          this.spreadings = this.spreadings.filter(val => val.$key !== spreading.$key);
          this.spreading = {} as Spreading;
          this.spreadingService.delete(spreading.$key);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Spreading Deleted', life: 3000});
      }
    });
  }

  hideDialog() {
    this.spreadingDialog = false;
    this.submitted = false;
  }

  hideBeaufort() {
    this.beaufortDialog = false;
  }

  saveSpreading() {
    this.submitted = true;
    if ( this.spreading.name.trim() ) {
        this.spreading.isEnabled = true;

        if (this.spreading.$key) {
            this.spreadings[this.findIndexById(this.spreading.$key)] = this.spreading;                
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Spreading Updated', life: 3000});
            if(!this.spreading.key) {
              this.spreading.key = this.spreading.$key;
            }
            this.spreadingService.update(this.spreading);
          } else {
            this.spreading.position = this.spreadings.length;
            this.spreadingService.add(this.spreading);
        }
        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Barrel Created', life: 3000});
        this.spreadings = [...this.spreadings];
      }

      this.spreadingDialog = false;
      this.spreading = { } as Spreading;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.spreadings.length; i++) {
        if (this.spreadings[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  // // Filters
  clear(table: Table) {
    table.clear();
  }

  // // Reorder
  reorder(event: any) {
    console.log('Reorder event', event);
  }

  showColumnDialog() {
    this.display = true;
  }


  // // onRowEditSave(product: Spreading) {
  // //   this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
  // //   // if (spreading.price > 0) {
  // //   //     //delete this.clonedProducts[product.id];
  // //   //     this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
  // //   // }  
  // //   // else {
  // //   //     this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid Price'});
  // //   // }



  // // }


  // // https://www.techgeeknext.com/angular/primeng-datatable-row-edit

  // onEditInit(event): void {
  //   console.log(this.spreadings);
  //   console.log("Edit Init Event Called");
  // }

  // onEditSave(event): void {
  //   console.log(this.spreadings);
  //   console.log("Edit Init Event Called");
  // }

  // onEdit(event): void {
  //   console.log(this.spreadings);
  //   console.log("Edit Init Event Called");
  // }

  // onEditComplete(event): void {
  //   console.log(this.spreadings);
  //   console.log("Edit Init Event Called");
  // }

  // onEditCancel(): void {
  //   console.log(this.spreadings);
  //   console.log("Edit Cancel Event Called");
  // }


  // onRowEditInit(product: Spreading) {
  //   console.log(product);
  //   console.log("Edit Init Event Called");
  //   //this.clonedProducts[product.id] = {...product};
  // }

  // onRowEditSave(product: Spreading) {
  //   console.log(product);
  //   console.log("Edit Save Event Called");
  //   this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
  //   // if (product.price > 0) {
  //   //     delete this.clonedProducts[product.id];
  //   //     this.messageService.add({severity:'success', summary: 'Success', detail:'Product is updated'});
  //   // }  
  //   // else {
  //   //     this.messageService.add({severity:'error', summary: 'Error', detail:'Invalid Price'});
  //   // }
  // }

  // onRowEditCancel(product: Spreading, index: number) {
  //   console.log(product);
  //   console.log("Edit Cancel Event Called");
  //   // this.products2[index] = this.clonedProducts[product.id];
  //   // delete this.clonedProducts[product.id];
  // }



  // // reordeing
  // previousPosition : number;
  // newPosition : number;

  // modelChangeFn(event, spray) {
  //   this.previousPosition = spray.position;
  //   this.newPosition = event;
  //   console.log("previous" +this.previousPosition);
  //   console.log("new" +this.newPosition);
  // }

  onFieldEdit(spray: Spray, fieldName: string): void {

    // if (fieldName === 'position') {
    //   if ( this.newPosition ) {
    //     spray.position = this.newPosition;
    //     spray = this.checkPositionLimits(spray);
    //     this.reorderElements(spray);
    //     this.newPosition = undefined;
    //     this.previousPosition = undefined;
    //     return;
    //   }
      
    // } else {
    //   this.updateDB(spray);
    // }
  }

  // // checkPositionLimits(spray: Spray) {
  // //   if (spray.position < 0) {
  // //     spray.position = 0;
  // //     return spray;
  // //   }
  // //   if (spray.position > this.sprays.length) {
  // //     spray.position = this.sprays.length - 1;
  // //     return spray;
  // //   }
  // //   return spray;
  // // }

  // // async reorderElements(spray: Spray) {
  // //   this.newPosition = this.newPosition  > this.sprays.length ? this.sprays.length - 1 : this.newPosition;
  // //   this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
  // //   if (this.previousPosition === spray.position ) {
  // //     return;
  // //   }
  // //   console.log('Previous position: '+ this.previousPosition);
  // //   console.log('New position: '+ this.newPosition);
  // //   const startIndex = this.previousPosition;
  // //   const endIndex   = this.newPosition;
  // //   const sprayToMove = this.sprays[startIndex];
  // //   if (startIndex < endIndex) {
  // //     for(let index = startIndex; index < endIndex; index++) {
  // //       this.sprays[index] = this.sprays[index+1];
  // //       this.sprays[index].position = index;
  // //       console.log('Move: ', this.sprays[index+1]);
  // //       console.log('Move pos: ', this.sprays[index].position);
  // //       await this.updateDB(this.sprays[index]);
  // //       console.log('Update DB: ', this.sprays[index]);
  // //     }     
  // //   } else {
  // //     for(let index = startIndex; index > endIndex; index--) {
  // //       this.sprays[index] = this.sprays[index-1];
  // //       this.sprays[index].position = index;
  // //       await this.updateDB(this.sprays[index]);
  // //     }
  // //   } 
  // //   this.sprays[endIndex] = sprayToMove;
  // //   this.sprays[endIndex].position = endIndex;
  // //   this.previousPosition = undefined;
  // //   this.newPosition = undefined;
  // //   await this.updateDB(this.sprays[endIndex]);
  // // }

  // // updateDB(spray: Spray) : Spray {
  // //   // Prepare request
  // //   const updatedSpray : UpdateSprayInput = {
  // //     tenantId: this.tenantId,
  // //     id: spray.id,
  // //     name: spray.name || null,
  // //     description: spray.description || null,
  // //     isActive: spray.isActive,
  // //     position: spray.position
  // //   }

  // //   // Save data
  // //   this.api.UpdateSpray(updatedSpray);
  // //   return spray;
  // // }



  getTargetType(spreading: Spreading) : any {
    // console.log('spreading: getTargetType nico3 ' + this.targetToTargetType.has(spreading.target) );

    // console.log('spreading: nico3 ' + "'" + spreading.target + "'" );

    // for (let key of this.targetToTargetType.keys()) {
    //   console.log('nico3 '+key); 
    // }

    // if (spreading.target && spreading.target.length > 1 && this.targetToTargetType.has(spreading.target)) {
    //   const targetName : string = spreading.target;
    //   return this.targetToTargetType.get(targetName);
    // }
    // return 'weed';
  }

  // pad(n: number) {
  //   // console.log('n :' , n);
  //   return n < 10 ? '0'+n : n+'';
  // }

  // get target() : string {
  //   return this.spreading.target;
  // }

  // set target(val: string) {
  //   console.log(this.spreading);
  //   this.spreading.target = val;
  // }

  // async updateSpreadingBlocks(spreading, event) {
 
  //   console.log('event',event);

  //   console.log('target',spreading);

  //   if (spreading) {
  //     spreading.spreadingBlocks = event || [];
  //     spreading.spreadingBlocks.forEach(element => {
  //       delete element['__typename'];
  //       delete element.createdAt;
  //       delete element.description;
  //       // delete element.id;
  //       delete element.isActive;
  //       delete element.tenantId;
  //       delete element.updatedAt;
  //     });
  //   }
  //   if (spreading.id) {
  //     try {
  //       await this.updateSpreading2(spreading);
  //     } catch (error) { 
  //       console.log(error);
  //     }
  //   }
    
    
  }