
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedPacks()" [disabled]="!selectedPacks || !selectedPacks.length "></button>
        </div> 
    </div>

    <div class="page-content">

        <div #calendarWrapper></div>

        <p-table #dt 
            [value]="packs"                                                                                                      
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['position','qrcode','name','description','tags','status','filledAt','emptiedAt','fishWeightAverage','fishNumber','fishMortality','harvestName']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedPacks" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="qrcode" style="width: 8rem" name="qrcode" pResizableColumn pReorderableColumn pSortableColumn="qrcode" [class]="includesCol('qrcode') ? 'd' : 'h'">QRCode 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> 
                    </th>
                    


                    <th id="packedAt" name="packedAt" pResizableColumn pReorderableColumn [class]="includesCol('packedAt') ? 'd' : 'h'">At
                        <p-sortIcon field="packedAt"></p-sortIcon> 
                        <p-columnFilter type="text" field="packedAt" display="menu"></p-columnFilter>
                    </th>
                    <th id="packedBy" name="packedBy" pResizableColumn pReorderableColumn [class]="includesCol('packedBy') ? 'd' : 'h'">By
                        <p-sortIcon field="packedBy"></p-sortIcon> 
                        <p-columnFilter type="text" field="packedBy" display="menu"></p-columnFilter>
                    </th>

                    <th id="itemTypeName" name="itemTypeName" pResizableColumn pReorderableColumn [class]="includesCol('itemTypeName') ? 'd' : 'h'">Content
                        <p-sortIcon field="itemTypeName"></p-sortIcon> 
                        <p-columnFilter type="text" field="itemTypeName" display="menu"></p-columnFilter>
                    </th>
                    <th id="itemNumber" name="itemNumber" pResizableColumn pReorderableColumn [class]="includesCol('itemNumber') ? 'd' : 'h'">Number
                        <p-sortIcon field="itemNumber"></p-sortIcon> 
                        <p-columnFilter type="text" field="itemNumber" display="menu"></p-columnFilter>
                    </th>


                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description
                        <p-sortIcon field="description"></p-sortIcon> 
                        <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                    </th>
                    <th id="tags" name="tags" pResizableColumn pReorderableColumn [class]="includesCol('tags') ? 'd' : 'h'">Tags
                        <p-sortIcon field="tags"></p-sortIcon> 
                        <p-columnFilter type="text" field="tags" display="menu"></p-columnFilter>
                    </th>

                    <!-- <th id="harvestName" name="harvestName" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('harvestName') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_fishboat.png" width="24px" height="24px" [routerLink]="['/fish-management/harvests']"/> 
                        Harvest 
                        <p-sortIcon field="harvestName"></p-sortIcon> 
                        <p-columnFilter type="text" field="harvestName" display="menu"></p-columnFilter>
                    </th>


                    <th id="fishAverageWeight" name="fishAverageWeight" pResizableColumn pReorderableColumn pSortableColumn="fishAverageWeight" [class]="includesCol('fishAverageWeight') ? 'd' : 'h'">
                        Weight (avg) 
                        <p-sortIcon field="fishAverageWeight"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="fishAverageWeight" display="menu"></p-columnFilter>
                    </th>

                    <th id="fishNumber" name="fishNumber" pResizableColumn pReorderableColumn pSortableColumn="fishNumber" [class]="includesCol('fishNumber') ? 'd' : 'h'">
                        Fish (nb) 
                        <p-sortIcon field="fishNumber"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="fishNumber" display="menu"></p-columnFilter>
                    </th>

                    <th id="fishMortality" name="fishMortality" pResizableColumn pReorderableColumn pSortableColumn="fishMortality" [class]="includesCol('fishMortality') ? 'd' : 'h'">
                        Mortality (nb / %)
                        <p-sortIcon field="fishMortality"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="fishMortality" display="menu"></p-columnFilter>
                    </th>

                    <th id="filledAt" name="filledAt" pResizableColumn pReorderableColumn pSortableColumn="filledAt" [class]="includesCol('filledAt') ? 'd' : 'h'">
                        Filled at
                        <p-sortIcon field="filledAt"></p-sortIcon> 
                        <p-columnFilter type="datetime" field="filledAt" display="menu"></p-columnFilter>
                    </th>

                    <th id="emptiedAt" name="emptiedAt" pResizableColumn pReorderableColumn pSortableColumn="emptiedAt" [class]="includesCol('emptiedAt') ? 'd' : 'h'">
                        Emptied at
                        <p-sortIcon field="emptiedAt"></p-sortIcon> 
                        <p-columnFilter type="datetime" field="emptiedAt" display="menu"></p-columnFilter>
                    </th>

                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('isEnabled') ? 'show' : 'hide'">
                        Enabled
                        <p-sortIcon field="isEnabled"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th> -->
                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions 
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-pack let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" [pReorderableRowHandle]="index" style="margin-right: 1rem;"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                (ngModelChange)="modelChangeFn($event, pack)"
                                [ngModel]="pack.position" 
                                (blur)="completePosition(pack)" 
                                (keydown.tab)="completePosition(pack)" 
                                (keydown.enter)="completePosition(pack)"  
                                required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{pack.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('qrcode') ? 'd' : 'h'">
                        <qrcode [qrdata]="pack.name" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                    </td>

                    <td pEditableColumn [class]="includesCol('packedAt') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="pack.packedAt"  (blur)="completePack(pack)" (keydown.tab)="completePack(pack)" (keydown.enter)="completePack(pack)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pack.packedAt}}</span>
                            
                         
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('packedBy') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="pack.packedBy" 
                                (blur)="completePack(pack)" 
                                (keydown.tab)="completePack(pack)" 
                                (keydown.enter)="completePack(pack)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{pack.packedBy}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('itemTypeName') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="pack.itemTypeName" 
                                (blur)="completePack(pack)" 
                                (keydown.tab)="completePack(pack)" 
                                (keydown.enter)="completePack(pack)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{pack.itemTypeName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('itemNumber') ? 'd' : 'h'">
                        <p-cellEditor>
                            
                            <ng-template pTemplate="output" >
                                <span >{{pack.itemNumber}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>


                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="pack.name" 
                                (blur)="completePack(pack)" 
                                (keydown.tab)="completePack(pack)" 
                                (keydown.enter)="completePack(pack)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{pack.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="pack.description"  (blur)="completePack(pack)" (keydown.tab)="completePack(pack)" (keydown.enter)="completePack(pack)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pack.description}}</span>
                            
                         
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('tags') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-chips  id="tags" [(ngModel)]="pack.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3" (onBlur)="completePack(pack)" (onAdd)="completePack(pack)" (onRemove)="completePack(pack)"></p-chips>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="margin-right: 5px;" *ngFor="let tag of pack.tags">
                                    <p-tag >{{tag}}</p-tag>
                                </span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('harvestName') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                              
                                 <p-dropdown 
                                 appendTo="body" 
                                 [filter]="true"
                                 [showClear]="true" 
                                 [(ngModel)]="pack.harvestKey" 
                                 [options]="harvests" 
                                 optionLabel="name" 
                                 optionValue="key"  

                                 (blur)="completePack(pack)" 
                                 (keydown.tab)="completePack(pack)" 
                                 (keydown.enter)="completePack(pack)" 

                                placeholder="Any">
                             </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{displayHarvestName(pack.harvestKey)}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('fishAverageWeight') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="pack.fishAverageWeight"  (blur)="completePack(pack)" (keydown.tab)="completePack(pack)" (keydown.enter)="completePack(pack)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pack.fishAverageWeight}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('fishNumber') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="pack.fishNumber"  (blur)="completePack(pack)" (keydown.tab)="completePack(pack)" (keydown.enter)="completePack(pack)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pack.fishNumber}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    
                    <td pEditableColumn [class]="includesCol('fishMortality') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="pack.fishMortality"  (blur)="completePack(pack)" (keydown.tab)="completePack(pack)" (keydown.enter)="completePack(pack)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pack.fishMortality}} </span>
                                <span *ngIf="pack.fishNumber && pack.fishMortality">({{ pack.fishMortality / pack.fishNumber }} %)</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('filledAt') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                
                                    <p-calendar 
                                        [(ngModel)]="pack.filledAt"  
                                        [ngModelOptions]="{standalone: true}" 
                                        [showIcon]="true" 
                                        [showTime]="true" 
                                        [showSeconds]="true" 
                                        inputId="time" 
                                        [appendTo]="calendarWrapper"
                                        (onSelect)="onSelect($event,pack)" 
                                        (onBlur)="onBlur($event,pack)" 
                                        (onClose)="onClose($event,pack)"
                                      >
                                    </p-calendar>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pack.filledAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('emptiedAt') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                
                                    <p-calendar 
                                        [(ngModel)]="pack.emptiedAt"  
                                        [ngModelOptions]="{standalone: true}" 
                                        [showIcon]="true" 
                                        [showTime]="true" 
                                        [showSeconds]="true" 
                                        inputId="time" 
                                        [appendTo]="calendarWrapper"
                                        (onSelect)="onSelect($event,pack)" 
                                        (onBlur)="onBlur($event,pack)" 
                                        (onClose)="onClose($event,pack)"
                                      >
                                    </p-calendar>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pack.emptiedAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>


                    <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="pack.isEnabled" (onChange)="completePack(pack)"></p-inputSwitch>
                    </td>

                    <td class="row-actions">
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editPack(pack)"></button>
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deletePack(pack)"></button>
                        <p-tableCheckbox class="checkbox" [value]="pack"></p-tableCheckbox>
                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{packs ? packs.length : 0 }} pack{{packs.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV - Save the selected lines and columns" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS - Save all the lines and columns" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF - Save all the lines and columns" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>        
    </div>

    <!-- Dialog : confirm -->
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    

    <!-- Dialog : Popup -->
    <p-dialog [(visible)]="packDialog" [style]="{width: '450px', height: '450px'}" header="Pack" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="pack.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !pack.name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="pack.description" rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="tags" >Tags</label>
            <div style="height: 10px;">&nbsp;</div>
            <p-chips  id="tags" [(ngModel)]="pack.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
        </div>   
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="savePack()"></button>
        </div>
    </p-dialog>

    <!-- { field: 'harvestKey', header: 'Harvest' },
                    { field: 'fishAverageWeight', header: 'Weight (avg)' },
                    { field: 'fishNumber', header: 'Fish (nb)' },
                    { field: 'fishMortality', header: 'Mortality (nb / %)' },
                    { field: 'filledAt', header: 'Filled at' },
                    { field: 'emptiedAt', header: 'Emptied at' },
     -->
    

</div>






