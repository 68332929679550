
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedHarvests()" [disabled]="!selectedHarvests || !selectedHarvests.length "></button>
        </div> 
    </div>

    <div class="page-content">

        <div #calendarWrapper></div>

        <p-table #dt 
            [value]="harvests"                                                                                                      
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['position','qrcode','name','description','tags','status','latitude','longitude','location','captain','crews','boat']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedHarvests" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="qrcode" style="width: 8rem" name="qrcode" pResizableColumn pReorderableColumn pSortableColumn="qrcode" [class]="includesCol('qrcode') ? 'd' : 'h'">QRCode 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> 
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description
                        <p-sortIcon field="description"></p-sortIcon> 
                        <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                    </th>
                    <th id="tags" name="tags" pResizableColumn pReorderableColumn [class]="includesCol('tags') ? 'd' : 'h'">Tags
                        <p-sortIcon field="tags"></p-sortIcon> 
                        <p-columnFilter type="text" field="tags" display="menu"></p-columnFilter>
                    </th>
                    <th id="instant" name="instant" pResizableColumn pReorderableColumn pSortableColumn="instant" [class]="includesCol('instant') ? 'd' : 'h'">
                        Date time
                        <p-sortIcon field="instant"></p-sortIcon> 
                        <p-columnFilter type="date" field="instant" display="menu"></p-columnFilter>
                    </th>
                    <th id="boat" name="boat" pResizableColumn pReorderableColumn pSortableColumn="boat" [class]="includesCol('boat') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_cargo.png" width="24px" height="24px" [routerLink]="['/fish-management/boats']"/> 
                        Boat 
                        <p-sortIcon field="boat"></p-sortIcon> 
                        <p-columnFilter type="text" field="boat" display="menu"></p-columnFilter>
                    </th>
                    <th id="captain" name="captain" pResizableColumn pReorderableColumn pSortableColumn="captain" [class]="includesCol('captain') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_captain.png" width="24px" height="24px" [routerLink]="['/fish-management/captains']"/> 
                        Captain 
                        <p-sortIcon field="captain"></p-sortIcon> 
                        <p-columnFilter type="text" field="captain" display="menu"></p-columnFilter>
                    </th>
                    <th id="crews" name="crews" pResizableColumn pReorderableColumn pSortableColumn="crews" [class]="includesCol('crews') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_sailor_cap.png" width="24px" height="24px" [routerLink]="['/fish-management/crews']"/> 
                        Crews 
                        <p-sortIcon field="crews"></p-sortIcon> 
                        <p-columnFilter type="text" field="crews" display="menu"></p-columnFilter>
                    </th>
                    <th id="location" name="location" pResizableColumn pReorderableColumn pSortableColumn="location" [class]="includesCol('location') ? 'd' : 'h'">Area 
                        <p-sortIcon field="location"></p-sortIcon> 
                        <p-columnFilter type="text" field="location" display="menu"></p-columnFilter>
                    </th>
                    <th id="latitude" style="width: 8rem" name="latitude" pResizableColumn pReorderableColumn pSortableColumn="latitude" [class]="includesCol('latitude') ? 'd' : 'h'">Latitude 
                        <p-sortIcon field="latitude"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="latitude" display="menu"></p-columnFilter>
                    </th>
                    <th id="longitude" style="width: 8rem" name="longitude" pResizableColumn pReorderableColumn pSortableColumn="longitude" [class]="includesCol('longitude') ? 'd' : 'h'">Longitude
                        <p-sortIcon field="longitude"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="longitude" display="menu"></p-columnFilter>
                    </th>
                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('isEnabled') ? 'show' : 'hide'">
                        Enabled
                        <p-sortIcon field="isEnabled"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions 
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-harvest let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" [pReorderableRowHandle]="index" style="margin-right: 1rem;"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                (ngModelChange)="modelChangeFn($event, harvest)"
                                [ngModel]="harvest.position" 
                                (blur)="completePosition(harvest)" 
                                (keydown.tab)="completePosition(harvest)" 
                                (keydown.enter)="completePosition(harvest)"  
                                required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{harvest.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('qrcode') ? 'd' : 'h'">
                        <qrcode [allowEmptyString]="true" [qrdata]="harvest.name" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="harvest.name" 
                                (blur)="completeHarvest(harvest)" 
                                (keydown.tab)="completeHarvest(harvest)" 
                                (keydown.enter)="completeHarvest(harvest)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" > -->
                                <span >{{harvest.name}}</span>
                            <!-- </ng-template>
                        </p-cellEditor> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="harvest.description"  (blur)="completeHarvest(harvest)" (keydown.tab)="completeHarvest(harvest)" (keydown.enter)="completeHarvest(harvest)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{harvest.description}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('instant') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                
                                    <p-calendar 
                                        [(ngModel)]="harvest.instant"  
                                        [ngModelOptions]="{standalone: true}" 
                                        [showIcon]="true" 
                                        [showTime]="false" 
                                        [showSeconds]="false" 
                                        inputId="time" 
                                        [appendTo]="calendarWrapper"
                                        (onSelect)="onSelect($event,harvest)" 
                                        (onBlur)="onBlur($event,harvest)" 
                                        (onClose)="onClose($event,harvest)"
                                      >
                                    </p-calendar>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{harvest.instant | date: 'dd/MM/yyyy' }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>


                    <!-- <td pEditableColumn [class]="includesCol('boat') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="harvest.boat"  (blur)="completeHarvest(harvest)" (keydown.tab)="completeHarvest(harvest)" (keydown.enter)="completeHarvest(harvest)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{harvest.boatName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td> -->

                    <td pEditableColumn [class]="includesCol('boat') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                 <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [ngModel]="harvest.boatKey" 
                                    [options]="boats" 
                                    optionLabel="name" 
                                    optionValue="$key"  
                                    (onChange)="onChangeBoat(harvest, harvest.boatKey, $event)"
                                    (blur)="completeHarvest(harvest)" 
                                    (keydown.tab)="completeHarvest(harvest)" 
                                    (keydown.enter)="completeHarvest(harvest)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{harvest.boatName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('captain') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="harvest.captain"  (blur)="completeHarvest(harvest)" (keydown.tab)="completeHarvest(harvest)" (keydown.enter)="completeHarvest(harvest)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{harvest.captain}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('crews') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="harvest.crews"  (blur)="completeHarvest(harvest)" (keydown.tab)="completeHarvest(harvest)" (keydown.enter)="completeHarvest(harvest)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{harvest.crews}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('location') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="harvest.location"  (blur)="completeHarvest(harvest)" (keydown.tab)="completeHarvest(harvest)" (keydown.enter)="completeHarvest(harvest)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{harvest.location}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('latitude') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="harvest.latitude"  (blur)="completeHarvest(harvest)" (keydown.tab)="completeHarvest(harvest)" (keydown.enter)="completeHarvest(harvest)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{harvest.latitude}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('longitude') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="harvest.longitude"  (blur)="completeHarvest(harvest)" (keydown.tab)="completeHarvest(harvest)" (keydown.enter)="completeHarvest(harvest)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{harvest.longitude}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('tags') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-chips  id="tags" [(ngModel)]="harvest.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3" (onBlur)="completeHarvest(harvest)" (onAdd)="completeHarvest(harvest)" (onRemove)="completeHarvest(harvest)"></p-chips>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="margin-right: 5px;" *ngFor="let tag of harvest.tags">
                                    <p-tag >{{tag}}</p-tag>
                                </span>
                               
                       
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="harvest.isEnabled" (onChange)="completeHarvest(harvest)"></p-inputSwitch>
                    </td>

                    <td class="row-actions">
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editHarvest(harvest)"></button>
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteHarvest(harvest)"></button>
                        <p-tableCheckbox class="checkbox" [value]="harvest"></p-tableCheckbox>
                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{harvests ? harvests.length : 0 }} harvest{{harvests.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV - Save the selected lines and columns" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS - Save all the lines and columns" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF - Save all the lines and columns" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>        
    </div>

    <!-- Dialog : confirm -->
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    

    <!-- Dialog : Popup -->
    <p-dialog [(visible)]="harvestDialog" [style]="{width: '450px', height: '450px'}" header="Harvest" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <div class="p-field" style="margin-top: 10px;">
            <label for="title">Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="harvest.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !harvest.name">Name is required.</small>
        </div> -->
        <div class="p-field" style="margin-top: 10px;">
            <img style="vertical-align: middle;" src="assets/images-black/ic_cargo.png" width="24px" height="24px" [routerLink]="['/fish-management/boats']"/> 
            <label for="location">&nbsp;&nbsp; Boat</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="harvest.boat" 
                [options]="boats" 
                optionLabel="name" 
                optionValue="$key"
                placeholder="Any">
            </p-dropdown>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="harvest.description" rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="tags" >Tags</label>
            <div style="height: 10px;">&nbsp;</div>
            <p-chips  id="tags" [(ngModel)]="harvest.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
        </div>   
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveHarvest()"></button>
        </div>
    </p-dialog>
    
    

</div>






