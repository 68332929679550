import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Feature } from 'src/app/shared/interfaces/feature';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FeatureCrudService } from 'src/app/shared/services/feature-crud.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router , NavigationEnd} from '@angular/router';
import { Location } from '@angular/common';
import { GuidedTour, GuidedTourService, Orientation, ProgressIndicatorLocation } from 'ngx-guided-tour';
import { ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TriggerMenuService } from 'src/app/shared/services/trigger-menu.service';
import { combineLatest, Observable, Subscription, of, BehaviorSubject } from 'rxjs';
import { UserCrudService } from 'src/app/shared/services/user-crud.service';
import { OffersCrudService } from 'src/app/shared/services/offers-crud.service';
import { Offer } from 'src/app/shared/interfaces/offer';
import { Service } from 'src/app/shared/interfaces/service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { LocalStorageOrganisationService } from 'src/app/shared/services/local-storage-organisation.service';
import { Organisation } from 'src/app/shared/interfaces/organisation';
import { LocalStorageBService } from 'src/app/shared/services/local-storage-b.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, OnDestroy {

  public modules = environment.modules;

  public saas = environment.saas;

  public appLogo = environment.applicationLogo;

  // public features : Feature[];

  public displayIcon = true;
  public goBack = false;
  public offers = false;
  
  public offersArr : Offer[];
  public offersMap = new Map<string,Offer>();

  public services: Service[];

  public offers$: Observable<boolean> = of(this.offers);
  public offersMap$: Observable<Map<string,Offer>> = of(this.offersMap);
  
  // public combinedCondition$: Observable<boolean>;
  // private localStorageSubscription: Subscription = {} as Subscription;
  // organisationValue: string;
  // organisation: Organisation;
  // private organisationSubscription: Subscription;

  public companySelected = false;

  public companyName = '';

  private organisationString$ = new BehaviorSubject<string>('');
  private organisationString : string = '';

  getOrganisationString(): Observable<string> {
    // returns stream of values, which means, after you've subscribed, you'll always get the latest value from stream
    return this.organisationString$;
  }

  @Input() sidenav!: MatSidenav ;
  @ViewChild('userMenuTrigger') trigger!: MatMenuTrigger;
  private menuSubscriptionTriggered!: Subscription;
  constructor(
    public location: Location,
    public authService: AuthService,
    public userCrudService: UserCrudService,
    public router: Router,
    private guidedTourService: GuidedTourService,
    private changeDetectorRef: ChangeDetectorRef,
    private triggerMenuService: TriggerMenuService,
    private offersService: OffersCrudService,
    public localStorageService: LocalStorageBService
    // private localStorageService: LocalStorageService,
    // private localStorageOrganisationService: LocalStorageOrganisationService
   // private featureService: FeatureCrudService
    ) { 


      this.organisationString = localStorage.getItem('organisation') || '' ;
      this.organisationString$.next(this.organisationString);

      this.offersArr = [];
     // this.offersMap = new Map<string,Offer>();

      this.services = [];

      router.events.subscribe(val => {
        let company = JSON.parse(localStorage.getItem('company')!);

        let organisation = JSON.parse(localStorage.getItem('organisation')!);

        if (val instanceof NavigationEnd) {
          if(val.url === '/client-management/companies' || val.url === '/connect-company' || (val.url === '/paymentplans' && company.subscriptionPrice === undefined)) {
            
            this.goBack = (val.url === '/client-management/companies');
            this.displayIcon = false;
            this.companySelected = false;
          } else {
            this.displayIcon = true;
            this.goBack = false;
          }

          if (val.url === '/offers') {
            // this.logout();
            // localStorage.clear();
            // company = null;
            // console.log('offers', company);
          }
          
          //console.log(this.authService.userData);

         // console.log('url', val.url);
          this.offers = (val.url === '/offers2') && !company;
          //console.log('offers => ', this.offers);

        } 
       // console.log('COMPANY ', company);

       // BEFORE
        // if(company && typeof company !== 'undefined' && typeof company.subscriptionPrice !== 'undefined') {
        //   this.companySelected = true;
        //   if (typeof company !== 'undefined' && typeof company.name !== 'undefined') {
        //     this.companyName = company.name;
        //   }
        // }


        this.localStorageService.loadOrganisation();

        // If there is an offer
        if (organisation && organisation.offerKey && organisation.subscriptionId) {
            this.companySelected = true;

            const offer = this.offersMap.get( 'key'+organisation.offerKey );

            // console.log('SERVICES for MAP has:',this.offersMap.has( 'key'+organisation.offerKey ));

            this.services = offer?.services || [];
            // console.log('SERVICES for MAP:',this.offersMap);
            // console.log('SERVICES for ORG:',organisation);
            // console.log('SERVICES for OFFER:',offer);
            // console.log('SERVICES:',this.services);

            //const srvcs = offer.
        }
      });

      // this.organisationValue = this.localStorageOrganisationService.getVariableValue() || '';
      // this.organisation = this.localStorageOrganisationService.getOrganisationValue() || {} as Organisation;

      // this.organisationSubscription = this.localStorageOrganisationService.getVariableChanges().subscribe((newValue: string) => {
      //   console.log('FOLLOWING ORGANISATION ', newValue);
      //   this.organisationValue = newValue;
      // });

      //this.features = [];
  }

  ngOnInit(): void {
   // this.dataState();
  //  this.authService.checkStripeVerified();

  // this.combinedCondition$ = combineLatest([this.offersMap$, this.user$]).pipe(
  //   map(([condition1, condition2]) => {
  //     // Combine the two conditions using a logical AND (&&) operation or any other logic you need.
  //     // Here, we use the AND operator for illustration purposes.
  //     return condition1 && condition2;
  //   })
  // );


    this.menuSubscriptionTriggered = this.triggerMenuService.menuTriggered.subscribe((menuId) => {
      if(menuId === 'usermenu') {
        this.trigger.openMenu();
      }
    });
    let of = this.offersService.getList()
    of.snapshotChanges().subscribe(data => {
      this.offersArr = [];
      this.offersMap.clear();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Offer = jsonItem as Offer;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          // console.log(l);
          this.offersArr.push(l);
          console.log('PUSH KEY', l.$key);
          console.log('PUSH VAL', l);
          this.offersMap.set( 'key'+l.$key, l);
        }
      })
    })

    // this.localStorageSubscription = this.localStorageService
    //   .getLocalStorageChanges()
    //   .subscribe((event: StorageEvent) => {
    //     // Handle the localStorage changes here
    //     console.log('LocalStorage key:', event.key);
    //     console.log('LocalStorage new value:', event.newValue);
    //     console.log('LocalStorage old value:', event.oldValue);
    //   });
  }

  ngOnDestroy() {
    if(this.menuSubscriptionTriggered) {
      this.menuSubscriptionTriggered.unsubscribe();
    }
    // this.localStorageSubscription.unsubscribe();
    // this.organisationSubscription.unsubscribe();
  }

  async logout() {
    try {
      this.localStorageService.clearAllLocalStorage();
      this.authService.SignOut()
     
    } catch (error) {
      console.log('[ERROR] logout', error);
    }
  }

  async toChatMenu() {
    this.router.navigate(['chat']);
  }

  async onUserIconClicked() {
    setTimeout(() => {
      this.guidedTourService.nextStep();
    }, 100);
  }
}
