import { Injectable } from '@angular/core';
import { Employee } from '../interfaces/employee';
import { take } from 'rxjs';

import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeeCrudService {
  // employeesRef: AngularFireList<any> | undefined;
  // employeeRef: AngularFireObject<any> | undefined;


  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/employees/' ;
  }

  //
  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('company')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // Create Cooper
  add(record: Employee) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
// Fetch Single Cooper Object
get(id: string) {
  const org = this.getOrganisation();
  if (!org) {
    return {} as AngularFireObject<any>;
  }  
  this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
  return this.ref;
}
// Fetch Coopers List
getList() {
  const org = this.getOrganisation();
  if (!org) {
    return {} as AngularFireList<any>;
  } 
  this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
  return this.refs;
}
// Update Cooper Object
update(record: Employee) {
  const org = this.getOrganisation();
  if (!org) {
    return;
  } 
  this.ref = this.db.object( 'organisations/' + org + '/' + this.path + record.$key);
 // console.log("P inside", record);
  let item : Partial<Employee>  = {
    ...record
  }
  delete item?.$key;
  this.ref?.update(item);
}
// Delete Cooper Object
delete(id: string) {
  const org = this.getOrganisation();
  if (!org) {
    return;
  } 
  this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
  this.ref.remove();
}




  // MODULE_NAME : string = 'users';
  // TABLE_NAME : string = 'employees';

 // constructor(private db: AngularFireDatabase) {}

  // Add employee 
  // addEmployee(employee: Employee) {
  //   const item = {
  //     ...employee
  //   }
  //   this.employeesRef?.push( item );
  // }

  // Fetch Single Employee Object
  // getEmployee(id: string) {
  //   const user = JSON.parse(localStorage.getItem('user')!)
  //   const company = JSON.parse(localStorage.getItem('company')!);
  //   this.employeeRef = this.db.object(this.MODULE_NAME + '/' + user.uid + '/' + 'companies' + '/' + company.$key + '/' + this.TABLE_NAME + '/' + id);
  //   return this.employeeRef;
  // }

  // // Fetch Employees List
  // getEmployeesList() {
  //   const user = JSON.parse(localStorage.getItem('user')!)
  //   const company = JSON.parse(localStorage.getItem('company')!);
  //   this.employeesRef = this.db.list(this.MODULE_NAME + '/' + user.uid + '/' + 'companies' + '/' + company.$key + '/' + this.TABLE_NAME + '/');
  //   return this.employeesRef;
  // }

  // // Update Employee Object
  // updateEmployee(employee: Employee) {
  //   const user = JSON.parse(localStorage.getItem('user')!)
  //   const company = JSON.parse(localStorage.getItem('company')!);
  //   this.employeeRef = this.db.object(this.MODULE_NAME + '/' + user.uid + '/' + 'companies' + '/' + company.$key + '/' + this.TABLE_NAME + '/' + employee.$key);
  //  // console.log("P inside", provider);
  //   let item : Partial<Employee>  = {
  //     ...employee
  //   }
  //   delete item?.$key;
  //   this.employeeRef?.update(item);
  // }

  // // Delete Employee Object
  // deleteEmployee(id: string) {
  //   const user = JSON.parse(localStorage.getItem('user')!)
  //   const company = JSON.parse(localStorage.getItem('company')!);
  //   this.employeeRef = this.db.object(this.MODULE_NAME + '/' + user.uid + '/' + 'companies' + '/' + company.$key + '/' + this.TABLE_NAME + '/' + id);
  //   this.employeeRef.remove();
  // }
}
