import { Injectable } from '@angular/core';
import { Parcel } from '../interfaces/parcel';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParcelCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/parcels/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // Create Parcel
  add(record: Parcel) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single Parcel Object
  get(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
    return this.ref;
  }
  // Fetch Parcels List
  getList() {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path );
    return this.refs;
  }
  // Update Parcel Object
  update(record: Parcel) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + record.$key);
    let item : Partial<Parcel>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Parcel Object
  delete(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + id);
    this.ref.remove();
  }
}
