import { Injectable } from '@angular/core';
import { Client } from '../interfaces/client';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientCrudService {

  clientsRef: AngularFireList<any> | undefined;
  clientRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/clients/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // Create Client
  addClient(client: Client) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.clientsRef = this.db.list( 'organisations/' + org + '/' + this.path );
    //this.clientsRef = this.db.list( this.path );
    const item = {
      ...client
    }
    this.clientsRef?.push( item );
  }
  // Fetch Single Client Object
  getClient(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.clientRef = this.db.object( 'organisations/' + org + '/' + this.path + id);
    return this.clientRef;
  }
  // Fetch Clients List
  getClientsList() {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.clientsRef = this.db.list( 'organisations/' + org + '/' +  this.path );
    return this.clientsRef;
  }
  // Update Client Object
  updateClient(client: Client) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.clientRef = this.db.object( 'organisations/' + org + '/' + this.path + client.$key);
    let item : Partial<Client>  = {
      ...client
    }
    delete item?.$key;
    this.clientRef?.update(item);
  }
  // Delete Client Object
  deleteClient(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.clientRef = this.db.object( 'organisations/' + org + '/' + this.path + id);
    this.clientRef.remove();
  }
}
