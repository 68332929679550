
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedBarrels()" [disabled]="!selectedBarrels || !selectedBarrels.length "></button>
        </div> 
    </div>

    <div class="page-content">

        <div #calendarWrapper></div>

        <p-table #dt 
            [value]="barrels"                                                                                                      
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['position','isEnabled','name','description','tags','typeName','cooperName','year','volumeName','woodName','locationName','rackName','batchName','wineName','ownerName','quantity']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedBarrels" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="-1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="qrcode" style="width: 8rem" name="qrcode" pResizableColumn pReorderableColumn pSortableColumn="qrcode" [class]="includesCol('qrcode') ? 'd' : 'h'">QRCode 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> 
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Id. 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description
                        <p-sortIcon field="description"></p-sortIcon> 
                        <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                    </th>
                    <th id="tags" name="tags" pResizableColumn pReorderableColumn [class]="includesCol('tags') ? 'd' : 'h'">Tags
                        <p-sortIcon field="tags"></p-sortIcon> 
                        <p-columnFilter type="text" field="tags" display="menu"></p-columnFilter>
                    </th>

                    <th id="type" name="type" pResizableColumn pReorderableColumn [class]="includesCol('type') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_world.png" width="24px" height="24px" [routerLink]="['/barrel-management/types']"/> 
                        Type
                        <p-sortIcon field="typeNmae"></p-sortIcon> 
                        <p-columnFilter type="text" field="typeName" display="menu"></p-columnFilter>
                    </th>
                    <th id="cooper" name="cooper" pResizableColumn pReorderableColumn [class]="includesCol('cooper') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_carpenter.png" width="24px" height="24px" [routerLink]="['/barrel-management/coopers']"/> 
                        Cooper
                        <p-sortIcon field="cooperName"></p-sortIcon> 
                        <p-columnFilter type="text" field="cooperName" display="menu"></p-columnFilter>
                    </th>
                    <th id="year" name="year" pResizableColumn pReorderableColumn [class]="includesCol('year') ? 'd' : 'h'">Barrel year
                        <p-sortIcon field="year"></p-sortIcon> 
                        <p-columnFilter type="number" field="year" display="menu"></p-columnFilter>
                    </th>
                    <th id="volume" name="volume" pResizableColumn pReorderableColumn [class]="includesCol('volume') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/content.png" width="24px" height="24px" [routerLink]="['/barrel-management/volumes']"/> 
                        Vol.
                        <p-sortIcon field="volumeName"></p-sortIcon> 
                        <p-columnFilter type="text" field="volumeName" display="menu"></p-columnFilter>
                    </th>
                    <th id="wood" name="wood" pResizableColumn pReorderableColumn [class]="includesCol('wood') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_wood.png" width="24px" height="24px" [routerLink]="['/barrel-management/woods']"/> 
                        Wood
                        <p-sortIcon field="woodName"></p-sortIcon> 
                        <p-columnFilter type="text" field="woodName" display="menu"></p-columnFilter>
                    </th>
                    <th id="owner" name="owner" pResizableColumn pReorderableColumn [class]="includesCol('owner') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/client.png" width="24px" height="24px" [routerLink]="['/barrel-management/owners']"/> 
                        Owner
                        <p-sortIcon field="ownerName"></p-sortIcon> 
                        <p-columnFilter type="text" field="ownerName" display="menu"></p-columnFilter>
                    </th>
                    <th id="location" name="location" pResizableColumn pReorderableColumn [class]="includesCol('location') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/location.png" width="24px" height="24px" [routerLink]="['/barrel-management/barrel-locations']"/> 
                        Location
                        <p-sortIcon field="locationName"></p-sortIcon> 
                        <p-columnFilter type="text" field="locationName" display="menu"></p-columnFilter>
                    </th>
                    <th id="rack" name="rack" pResizableColumn pReorderableColumn [class]="includesCol('rack') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_stack.png" width="24px" height="24px" [routerLink]="['/rack-management/racks']"/> 
                        Rack
                        <p-sortIcon field="rackName"></p-sortIcon> 
                        <p-columnFilter type="text" field="rackName" display="menu"></p-columnFilter>
                    </th>
                    <!-- <th id="batch" name="batch" pResizableColumn pReorderableColumn [class]="includesCol('batch') ? 'd' : 'h'">Batch
                        <p-sortIcon field="batchName"></p-sortIcon> 
                        <p-columnFilter type="text" field="batchName" display="menu"></p-columnFilter>
                    </th> -->
                    <th id="wine" name="wine" pResizableColumn pReorderableColumn [class]="includesCol('wine') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_wine.png" width="24px" height="24px" [routerLink]="['/wine-management/wines']"/> 
                        Wine
                        <p-sortIcon field="wineName"></p-sortIcon> 
                        <p-columnFilter type="text" field="wineName" display="menu"></p-columnFilter>
                    </th>

                    <th id="quantity" name="quantity" pResizableColumn pReorderableColumn [class]="includesCol('quantity') ? 'd' : 'h'">
                        <!-- <img style="vertical-align: middle;" src="assets/images-black/ic_wine.png" width="24px" height="24px" [routerLink]="['/wine-management/wines']"/>  -->
                        Quantity
                        <p-sortIcon field="quantity"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="quantity" display="menu"></p-columnFilter>
                    </th>

                    <th id="filledDatetime" name="filledDatetime" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('filledDatetime') ? 'show' : 'hide'">
                        Filled
                        <p-sortIcon field="filledDatetime"></p-sortIcon> 
                        <p-columnFilter type="date" field="filledDatetime" display="menu"></p-columnFilter>
                    </th>

                    <th id="toppedDatetime" name="toppedDatetime" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('toppedDatetime') ? 'show' : 'hide'">
                        Topped
                        <p-sortIcon field="toppedDatetime"></p-sortIcon> 
                        <p-columnFilter type="date" field="toppedDatetime" display="menu"></p-columnFilter>
                    </th>

                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('isEnabled') ? 'show' : 'hide'">
                        Enabled
                        <p-sortIcon field="isEnabled"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>

                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions 
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-barrel let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" [pReorderableRowHandle]="index" style="margin-right: 1rem;"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                (ngModelChange)="modelChangeFn($event, barrel)"
                                [ngModel]="barrel.position" 
                                (blur)="completePosition(barrel)" 
                                (keydown.tab)="completePosition(barrel)" 
                                (keydown.enter)="completePosition(barrel)"  
                                required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{barrel.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('qrcode') ? 'd' : 'h'">
                        <qrcode [qrdata]="barrel.name" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="barrel.name" 
                                (blur)="completeBarrel(barrel)" 
                                (keydown.tab)="completeBarrel(barrel)" 
                                (keydown.enter)="completeBarrel(barrel)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="barrel.description"  (blur)="completeBarrel(barrel)" (keydown.tab)="completeBarrel(barrel)" (keydown.enter)="completeBarrel(barrel)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.description}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('tags') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-chips  id="tags" [(ngModel)]="barrel.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3" (onBlur)="completeBarrel(barrel)" (onAdd)="completeBarrel(barrel)" (onRemove)="completeBarrel(barrel)"></p-chips>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="margin-right: 5px;" *ngFor="let tag of barrel.tags">
                                    <p-tag >{{tag}}</p-tag>
                                </span>
                            
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('type') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="barrel.typeName" 
                                    [options]="types" 
                                    optionLabel="name" 
                                    optionValue="$key"  
                                    (onChange)="onChangeType(barrel, barrel.typeKey, $event)"
                                    (blur)="onChangeType(barrel, barrel.typeKey, $event)" 
                                    (keydown.tab)="onChangeType(barrel, barrel.typeKey, $event)" 
                                    (keydown.enter)="onChangeType(barrel, barrel.typeKey, $event)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.typeName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
<!-- 
                    (blur)="completeBarrel(barrel)"
                                    (keydown.tab)="completeBarrel(barrel)"
                                    (keydown.enter)="completeBarrel(barrel)" -->
                    <td pEditableColumn [class]="includesCol('cooper') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="barrel.cooperName" 
                                    [options]="coopers" 
                                    optionLabel="name" 
                                    optionValue="$key"  

                                    (onChange)="onChangeCooper(barrel, barrel.cooperKey, $event)"
                                    (blur)="onChangeCooper(barrel, barrel.cooperKey, $event)" 
                                    (keydown.tab)="onChangeCooper(barrel, barrel.cooperKey, $event)" 
                                    (keydown.enter)="onChangeCooper(barrel, barrel.cooperKey, $event)" 

                                    
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.cooperName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('year') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="barrel.year" 
                                    [options]="years" 
                                    (blur)="completeBarrel(barrel)"
                                    (keydown.tab)="completeBarrel(barrel)"
                                    (keydown.enter)="completeBarrel(barrel)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.year}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>


                    <td pEditableColumn [class]="includesCol('volume') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="barrel.volumeName" 
                                    [options]="volumes" 
                                    optionLabel="name" 
                                    optionValue="$key"  

                                    (onChange)="onChangeVolume(barrel, barrel.volumeKey, $event)"
                                    (blur)="onChangeVolume(barrel, barrel.volumeKey, $event)" 
                                    (keydown.tab)="onChangeVolume(barrel, barrel.volumeKey, $event)" 
                                    (keydown.enter)="onChangeVolume(barrel, barrel.volumeKey, $event)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.volumeName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('wood') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="barrel.woodName" 
                                    [options]="woods" 
                                    optionLabel="name" 
                                    optionValue="$key"  

                                    (onChange)="onChangeWood(barrel, barrel.woodKey, $event)"
                                    (blur)="onChangeWood(barrel, barrel.woodKey, $event)" 
                                    (keydown.tab)="onChangeWood(barrel, barrel.woodKey, $event)" 
                                    (keydown.enter)="onChangeWood(barrel, barrel.woodKey, $event)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.woodName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('owner') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="barrel.ownerName" 
                                    [options]="owners" 
                                    optionLabel="name" 
                                    optionValue="$key"  

                                    (onChange)="onChangeOwner(barrel, barrel.ownerKey, $event)"
                                    (blur)="onChangeOwner(barrel, barrel.ownerKey, $event)" 
                                    (keydown.tab)="onChangeOwner(barrel, barrel.ownerKey, $event)" 
                                    (keydown.enter)="onChangeOwner(barrel, barrel.ownerKey, $event)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.ownerName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('location') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                               <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="barrel.locationName" 
                                    [options]="locations" 
                                    optionLabel="name" 
                                    optionValue="$key"  

                                    (onChange)="onChangeLocation(barrel, barrel.locationKey, $event)"
                                    (blur)="onChangeLocation(barrel, barrel.locationKey, $event)" 
                                    (keydown.tab)="onChangeLocation(barrel, barrel.locationKey, $event)" 
                                    (keydown.enter)="onChangeLocation(barrel, barrel.locationKey, $event)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.locationName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <!-- (blur)="completeBarrel(barrel)" -->
                    <!-- (keydown.tab)="onChangeRack(barrel, barrel.rackKey, $event)" 
                    (keydown.enter)="onChangeRack(barrel, barrel.rackKey, $event)"  -->

                                    <!-- (keydown.tab)="completeBarrel(barrel)" -->
                                    <!-- (keydown.enter)="completeBarrel(barrel)" -->
                    <td pEditableColumn [class]="includesCol('rack') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [ngModel]="barrel.rackKey" 
                                    [options]="racks" 
                                    optionLabel="name" 
                                    optionValue="$key"

                                    (onChange)="onChangeRack(barrel, barrel.rackKey, $event)"
                                    (blur)="onChangeRack(barrel, barrel.rackKey, $event)" 
                                   
                                    
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{ displayRackName(barrel.rackKey)}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    
                    <!-- <td pEditableColumn [class]="includesCol('batch') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="barrel.batchName" 
                                    [options]="batches" 
                                    optionLabel="name" 
                                    optionValue="name"
                                    (blur)="completeBarrel(barrel)"
                                    (keydown.tab)="completeBarrel(barrel)"
                                    (keydown.enter)="completeBarrel(barrel)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{barrel.batchName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td> -->
                
                    <td pEditableColumn [class]="includesCol('wine') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="barrel.wineName" 
                                    [options]="wines" 
                                    optionLabel="name" 
                                    optionValue="$key"  

                                    (onChange)="onChangeWine(barrel, barrel.wineKey, $event)"
                                    (blur)="onChangeWine(barrel, barrel.wineKey, $event)" 
                                    (keydown.tab)="onChangeWine(barrel, barrel.wineKey, $event)" 
                                    (keydown.enter)="onChangeWine(barrel, barrel.wineKey, $event)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="font-size: 12px;">{{barrel.wineName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('quantity') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="number" [(ngModel)]="barrel.quantity" 
                                (blur)="completeBarrel(barrel)" 
                                (keydown.tab)="completeBarrel(barrel)" 
                                (keydown.enter)="completeBarrel(barrel)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{barrel.quantity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('filledDatetime') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-calendar 
                                    [(ngModel)]="barrel.filledDatetime"  
                                    [ngModelOptions]="{standalone: true}" 
                                    [showIcon]="true" 
                                    [showTime]="true" 
                                    [showSeconds]="true" 
                                    inputId="time" 
                                    [appendTo]="calendarWrapper"
                                    (onSelect)="onSelect($event,barrel)" 
                                    (onBlur)="onBlur($event,barrel)" 
                                    (onClose)="onClose($event,barrel)"
                                  >
                                </p-calendar>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{barrel.filledDatetime | date:'dd/MM/yyyy HH:mm' }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('toppedDatetime') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-calendar 
                                    [(ngModel)]="barrel.toppedDatetime"  
                                    [ngModelOptions]="{standalone: true}" 
                                    [showIcon]="true" 
                                    [showTime]="true" 
                                    [showSeconds]="true" 
                                    inputId="time" 
                                    [appendTo]="calendarWrapper"
                                    (onSelect)="onSelect($event,barrel)" 
                                    (onBlur)="onBlur($event,barrel)" 
                                    (onClose)="onClose($event,barrel)"
                                    >
                                </p-calendar>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{barrel.toppedDatetime | date:'dd/MM/yyyy HH:mm' }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                 
                    <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  
                            [(ngModel)]="barrel.isEnabled" 
                            (onChange)="completeBarrel(barrel)">
                        </p-inputSwitch>
                    </td>

                    <td class="row-actions">
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editBarrel(barrel)"></button>
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteBarrel(barrel)"></button>
                        <p-tableCheckbox class="checkbox" [value]="barrel"></p-tableCheckbox>
                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{barrels ? barrels.length : 0 }} barrel{{barrels.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV - Save the selected lines and columns" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS - Save all the lines and columns" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF - Save all the lines and columns" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>        
    </div>

    <!-- Dialog : confirm -->
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    

    <p-toast position="center"></p-toast>

    <p-messages [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'" [enableService]="false"></p-messages>


    <!-- Dialog : Popup -->
    <p-dialog [(visible)]="barrelDialog" [style]="{width: '450px', height: '450px'}" header="Barrel" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Id.</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="barrel.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !barrel.name">Identifier is required.</small>
        </div>
        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/ic_carpenter.png" width="24px" height="24px" [routerLink]="['/barrel-management/coopers']"/> 
            <label for="type">&nbsp;&nbsp; Cooper</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="barrel.cooperName" 
                [options]="coopers" 
                optionLabel="name" 
                optionValue="name"
                placeholder="Any">
            </p-dropdown>
        </div>
        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/ic_world.png" width="24px" height="24px" [routerLink]="['/barrel-management/types']"/> 
            <label for="type">&nbsp;&nbsp; Type</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="barrel.typeName" 
                [options]="types" 
                optionLabel="name" 
                optionValue="name"
                placeholder="Any">
            </p-dropdown>
        </div>
        
        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/content.png" width="24px" height="24px" [routerLink]="['/barrel-management/volumes']"/> 
            <label for="volume">&nbsp;&nbsp; Volume</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="barrel.volumeName" 
                [options]="volumes" 
                optionLabel="name" 
                optionValue="name"
                placeholder="Any">
            </p-dropdown>
        </div>
        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/ic_wood.png" width="24px" height="24px" [routerLink]="['/barrel-management/woods']"/> 
            <label for="wood">&nbsp;&nbsp; Wood</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="barrel.woodName" 
                [options]="woods" 
                optionLabel="name" 
                optionValue="name"
                placeholder="Any">
            </p-dropdown>
        </div>

        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/client.png" width="24px" height="24px" [routerLink]="['/barrel-management/owners']"/> 
            <label for="owner">&nbsp;&nbsp; Owner</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="barrel.ownerName" 
                [options]="owners" 
                optionLabel="name" 
                optionValue="name"
                placeholder="Any">
            </p-dropdown>
        </div>
        
        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/location.png" width="24px" height="24px" [routerLink]="['/barrel-management/barrel-locations']"/> 
            <label for="location">&nbsp;&nbsp; Location</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="barrel.locationName" 
                [options]="locations" 
                optionLabel="name" 
                optionValue="name"
                placeholder="Any">
            </p-dropdown>
        </div>
        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/ic_stack.png" width="24px" height="24px" [routerLink]="['/rack-management/racks']"/> 
            <label for="location">&nbsp;&nbsp; Rack</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="barrel.rackKey" 
                [options]="racks" 
                optionLabel="name" 
                optionValue="$key"
                placeholder="Any">
            </p-dropdown>
        </div>
        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/ic_wine.png" width="24px" height="24px" [routerLink]="['/wine-management/wines']"/> 
            <label for="location">&nbsp;&nbsp; Wine</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="barrel.wineKey" 
                [options]="wines" 
                optionLabel="name" 
                optionValue="$key"
                placeholder="Any">
            </p-dropdown>
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="quantity">Quantity of wine (L)</label>
            <input style="margin-top: 10px;" type="number" pInputText id="quantity" [(ngModel)]="barrel.quantity" autofocus />
            <!-- <small class="p-error" *ngIf="submitted && !barrel.name">Identifier is required.</small> -->
        </div>
        

            <!-- <div class="p-field">
                <label for="ownerName">Owner</label>
                <div style="margin-top: 10px;"></div>
                <p-dropdown 
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="true" 
                    [(ngModel)]="barrel.ownerName" 
                    [options]="owners" 
                    optionLabel="name" 
                    optionValue="name"
                    placeholder="Any">
                </p-dropdown>
            </div> -->
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="barrel.description" rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="tags" >Tags</label>
            <div style="height: 10px;">&nbsp;</div>
            <p-chips  id="tags" [(ngModel)]="barrel.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
        </div>   
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveBarrel()"></button>
        </div>
    </p-dialog>
    
   

</div>







