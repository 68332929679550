<mat-toolbar color="primary" class='white mat-elevation-z16'  >
    <div>
      <!-- <span class="gap icons"><a [routerLink]="['/']"><img style="vertical-align:middle;background-color: #000000" src="{{ appLogo }}" height="30px" ></a></span> -->

      <span *ngIf="goBack && authService.isLoggedIn && authService.user$ | async as user">
        <button pButton pRipple label="Go back to organisation selection" icon="pi pi-angle-double-left" class="button-action p-button-danger" (click)="this.location.back()" ></button>
      </span>

      <span class="gap" *ngIf="displayIcon && authService.isLoggedIn && authService.user$ | async as user">
        <button id="list_icon" mat-button (click)="sidenav.toggle()">
          <!-- <img style="vertical-align:middle;" src="assets/images/news-icon.png" height="20px" > -->
          <mat-icon style="color: white; vertical-align:middle;">list</mat-icon>
          <!-- <span class="greytext"> Settings </span> -->
        </button>

        <span *ngIf="(localStorageService.organisation$ | async) as organisation" style="color: white;font-size: small;font-style: italic;">{{ organisation?.name }}</span>
      </span>

  
      <!-- <span class="gap"><a [routerLink]="['/']"><img style="vertical-align:middle;background-color: #000000" src="{{ appLogo }}" height="55px" ></a></span> -->
      <span class="logotitle" style="background-color: #000000"></span>
    </div>

    <span id="middle_icons" class="middle">

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.wineclub && displayIcon">
        <button mat-button [routerLink]="['/wine-club/ranked-wines']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_champagne.png" />
          <span style="color: white">Global Rank</span>
        </button>
      </span>

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.wineclub && displayIcon">
        <button mat-button [routerLink]="['/wine-club/my-ranked-wines']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_wineglass.png" />
          <span style="color: white">My Preferences</span>
        </button>
      </span>

      <!-- <span *ngIf="authService.isLoggedIn && features && features.length > -1" class="semi_gap"  >
        <button mat-button [routerLink]="['/pallets']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/dashboard.png" />
            <span style="color: white">Dashboard</span>
        </button>
      </span> -->

      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn">
        <button mat-button [routerLink]="['/receptions']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/reception.png" />
            <span style="color: white">Reception</span>
        </button>
      </span> -->



      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish && displayIcon">
        <button mat-button [routerLink]="['/fish-management/harvests']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_fishboat.png" />
          <span style="color: white">Harvests</span>
        </button>
      </span>

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish && displayIcon">
        <button mat-button [routerLink]="['/fish-management/tuna-process']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_fish.png" />
          <span style="color: white">Full tuna</span>
        </button>
      </span>

      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/cages']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cage.png" />
          <span style="color: white">Cages</span>
        </button>
      </span> -->

      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/fridges']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_fridge.png" />
          <span style="color: white">Freezers</span>
        </button>
      </span> -->

      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/cageToFreezer']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cage.png" />
          <span style="color: white">Cage to Freezer</span>
        </button>
      </span> -->

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish && displayIcon">
        <button mat-button [routerLink]="['/fish-management/tuna-piece-process']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_fish_piece.png" />
          <span style="color: white">Tuna pieces</span>
        </button>
      </span>

      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/tuna-batches']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_fish.png" />
          <span style="color: white">Tuna</span>
        </button>
      </span>

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/tuna-pieces']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_fish_piece.png" />
          <span style="color: white">Pieces</span>
        </button>
      </span>

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/sales-orders']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_salesorder.png" />
          <span style="color: white">Sales Orders</span>
        </button>
      </span>

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/packings']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_pack.png" />
          <span style="color: white">Packings</span>
        </button>
      </span>

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/dispatches']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_dispatch.png" />
          <span style="color: white">Dispatches</span>
        </button>
      </span> -->

      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/dispatches1']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_dispatch.png" />
          <span style="color: white">Dispatches 1</span>
        </button>
      </span> -->

      
      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/fish-management/exports']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cargo.png" />
          <span style="color: white">Exports</span>
        </button>
      </span> -->

      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.fish">
        <button mat-button [routerLink]="['/barrel-management/barrels']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_fridge.png" />
          <span style="color: white">Fridges</span>
        </button>
      </span> -->

      <!-- <span class="semi_gap" *ngIf="displayIcon && authService.user$ | async as user">
        <button mat-button [routerLink]="['/client-management/companies']">
          <mat-icon style="vertical-align:middle; color: primary; width: 20px; height: 25px; margin-right: 10px;">business</mat-icon>
          <span style="color: white">Companies</span>
        </button>
      </span> -->

      <!-- <span *ngIf="(offersMap$ | async) as map">{{ map.has('key'+0)}} </span> -->

      <!-- <span *ngIf="organisation">{{ organisation.name }}</span> -->




      <!-- Bin Management  -->
      <ng-container *ngIf="saas == 'saas1' && (localStorageService.offer$ | async) as offer">
        <span *ngIf="displayIcon && (offer == '0' || offer == '3') && authService.isLoggedIn && (authService.user$ | async) as user">
          <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="binsMenu">
             <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_bin.png" />
             <span style="color: white">Bins</span>
          </button> 
        <mat-menu #binsMenu="matMenu">
          <div *ngIf="modules.bins" >
            <button mat-button [routerLink]="['/bin-management/bins']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_bin.png" />
              <span style="color: white">In use</span>
            </button>
          </div>
          <div *ngIf="modules.bins" >
            <button mat-button [routerLink]="['/bin-management/bin-archive']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
              <span style="color: white">Archive</span>
            </button>
          </div>
        </mat-menu>
      </span>
    </ng-container>

     <!-- Parcel Management  -->
    <ng-container *ngIf="saas == 'saas1' && (localStorageService.offer$ | async) as offer">
      <span 
      *ngIf="displayIcon && (offer == '1' || offer == '3') && authService.isLoggedIn && (authService.user$ | async) as user"
        class="semi_gap" >
        <button mat-button [routerLink]="['/parcel-management/parcels']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_parcel.png" />
          <span style="color: white">Parcels</span>
        </button>
      </span>
    </ng-container>

     <!-- Spreadings Management  -->
    <ng-container *ngIf="saas == 'saas1' && (localStorageService.offer$ | async) as offer">
      <span 
      *ngIf="displayIcon && (offer == '2' || offer == '3') && authService.isLoggedIn && (authService.user$ | async) as user"
        class="semi_gap" >
        <button mat-button [routerLink]="['/spray-management/spreadings']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_tractor.png" />
          <span style="color: white">Spreadings</span>
        </button>
      </span>
    </ng-container>

  
    <!-- Box Management -->
    <ng-container *ngIf="saas == 'saas5' && (localStorageService.offer$ | async) as offer">
      <span class="semi_gap" *ngIf="displayIcon && modules.boxes && authService.isLoggedIn && authService.user$ | async as user">
        <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="boxesMenu">
           <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_box.png" />
           <span style="color: white">Boxes</span>
        </button> 
        <mat-menu #boxesMenu="matMenu">
          <div *ngIf="modules.boxes" >
            <button mat-button [routerLink]="['/box-management/boxes']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_box.png" />
              <span style="color: white">In use</span>
            </button>
          </div>
          <div *ngIf="modules.boxes" >
            <button mat-button [routerLink]="['/box-management/box-archive']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
              <span style="color: white">Archive</span>
            </button>
          </div>
        </mat-menu>
      </span>
    </ng-container>





    <!-- Barrel Management / SaaS 2 / Offer 0 -->
    <ng-container *ngIf="saas == 'saas2' && (localStorageService.offer$ | async) as offer">
      <span class="semi_gap" *ngIf="displayIcon && (offer == '0' || offer == '2') && authService.isLoggedIn && authService.user$ | async as user">
        <button mat-button [routerLink]="['/barrel-management/barrels']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_barrel.png" />
          <span style="color: white">Barrels</span>
        </button>
      </span>
    </ng-container>
  
    <!-- Rack Management / SaaS 2 / Offer 0 -->
    <ng-container *ngIf="saas == 'saas2' && (localStorageService.offer$ | async) as offer">
      <span class="semi_gap" *ngIf="displayIcon && (offer == '0' || offer == '2')  && authService.isLoggedIn && authService.user$ | async as user">
        <button mat-button [routerLink]="['/rack-management/racks']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;padding-top: 5px;" src="/assets/images/ic_stack.png" />
          <span style="color: white">Racks</span>
        </button>
      </span>
    </ng-container>

    <!-- Pallet Management / SaaS 2 / Offer 1 -->  
    <ng-container *ngIf="saas == 'saas2' && (localStorageService.offer$ | async) as offer">
      <span class="semi_gap" *ngIf="displayIcon && (offer == '1' || offer == '2')  && authService.isLoggedIn && authService.user$ | async as user">
        <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="palletsMenu">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;padding-top: 5px;" src="/assets/images/pallet.png" />
          <span style="color: white">Pallets</span>
        </button>
        <mat-menu #palletsMenu="matMenu">
          <div *ngIf="modules.pallets" >
            <button mat-button [routerLink]="['/pallet-management/pallets']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/pallet.png" />
              <span style="color: white">In use</span>
            </button>
          </div>
          <div *ngIf="modules.pallets" >
            <button mat-button [routerLink]="['/pallet-management/pallet-archive']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
              <span style="color: white">Archive</span>
            </button>
          </div>
        </mat-menu>
      </span>
    </ng-container>
     
    <!-- Tank Management / SaaS 2 / Offer 2 -->  
    <ng-container *ngIf="saas == 'saas2' && (localStorageService.offer$ | async) as offer">
      <span class="semi_gap" *ngIf="displayIcon && offer == '2' && authService.isLoggedIn && authService.user$ | async as user">
        <button mat-button [routerLink]="['/tank-management/tanks']">
          <img style="vertical-align:middle; color: white; width: 30px; height: 30px; margin-right: 5px;padding-top: 5px;" src="/assets/images/ic_tank.png" />
          <span style="color: white">Tanks</span>
        </button>
      </span>
    </ng-container>
  

  

  <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.tanks">
    <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="tanksMenu">
       <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_tank.png" />
       <span style="color: white">Tanks</span>
    </button> 
  <mat-menu #tanksMenu="matMenu">
    <div *ngIf="modules.tanks" >
      <button mat-button [routerLink]="['/tank-management/tanks']">
        <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_tank.png" />
        <span style="color: white">In use</span>
      </button>
    </div>
    <div *ngIf="modules.tanks" >
      <button mat-button [routerLink]="['/tank-management/tanks-archive']">
        <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
        <span style="color: white">Archive</span>
      </button>
    </div>
  </mat-menu>
</span> -->

<!-- Wine Management / SaaS 2 / Offer 2 -->  
<ng-container *ngIf="saas == 'saas2' && (localStorageService.offer$ | async) as offer">
  <span class="semi_gap" *ngIf="displayIcon && (offer == '0' || offer == '1' || offer == '2') && authService.isLoggedIn && authService.user$ | async as user">
    <button mat-button [routerLink]="['/wine-management/wines']">
      <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;padding-top: 5px;" src="/assets/images/ic_wine.png" />
      <span style="color: white">Wines</span>
    </button>
  </span>
</ng-container>

 
  <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.wines">
    <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="winesMenu">
       <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_wine.png" />
       <span style="color: white">Wines</span>
    </button> 
  <mat-menu #winesMenu="matMenu">
    <div *ngIf="modules.wines" >
      <button mat-button [routerLink]="['/wine-management/wines']">
        <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_wine.png" />
        <span style="color: white">In stock</span>
      </button>
    </div>
    <div *ngIf="modules.pallets" >
      <button mat-button [routerLink]="['/wine-management/wines-archive']">
        <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
        <span style="color: white">Archive</span>
      </button>
    </div>
  </mat-menu>
</span> -->


<!-- 
<span class="semi_gap" *ngIf="authService.isLoggedIn && modules.wines">
  <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="palletsMenu">
     <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_wine.png" />
     <span style="color: white">Wines</span>
  </button> 
<mat-menu #palletsMenu="matMenu">
  <div *ngIf="modules.pallets" >
    <button mat-button [routerLink]="['/wines/current']">
      <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_wine.png" />
      <span style="color: white">Current</span>
    </button>
  </div>
  <div *ngIf="modules.pallets" >
    <button mat-button [routerLink]="['/wines/archive']">
      <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/ic_cabinet.png" />
      <span style="color: white">Archive</span>
    </button>
  </div>
</mat-menu>
</span> -->

    

      
      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.warehouse && false && displayIcon">
          <button mat-button [routerLink]="['/ta-product/products']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/products.png" />
            <span style="color: white">Stock</span>
          </button>   
      </span>

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.warehouse && displayIcon">
        <button mat-button [routerLink]="['/product/products']">
          <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/products.png" />
          <span style="color: white">Products</span>
        </button>   
    </span>
     
      <!-- <span class="semi_gap" *ngIf="authService.isLoggedIn">
          <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="productsMenu">
             <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/products.png" />
             <span style="color: white">Products</span>
          </button> 
        <mat-menu #productsMenu="matMenu">
          <div >
            <button mat-menu-item [routerLink]="['/product/products-by-location']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/location.png" />
             <span style="color: white">By location</span>
          </button> 
          </div>
          <div>
            <button mat-menu-item [routerLink]="['/product/products-by-type']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/packaging.png" />
              <span style="color: white">By product type</span>
           </button> 
          </div>
          <div>
            <button mat-menu-item [routerLink]="['/product/products']">
              <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/products.png" />
              <span style="color: white">All</span>
           </button> 
          </div>
        </mat-menu>
      </span> -->

      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.actions && displayIcon">
        <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="actionsMenu">
           <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/tables.png" />
           <span style="color: white">Actions</span>
        </button> 
      <mat-menu #actionsMenu="matMenu">
        <div *ngIf="modules.purchase" >
          <button mat-button [routerLink]="['/purchase/order']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/purchase_order.png" />
            <span style="color: white">Purchase</span>
          </button>
        </div>
        <div *ngIf="modules.receive" >
          <button mat-button [routerLink]="['/reception/receives']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/reception.png" />
            <span style="color: white">Receive</span>
          </button>
        </div>
        <div *ngIf="modules.dry" >
          <button mat-button [routerLink]="['/transformation/dries']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/dryer.png" />
            <span style="color: white">Dry</span>
          </button>
        </div>
        <div *ngIf="modules.move" >
          <button mat-button [routerLink]="['/movement/moves']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/move.png" />
            <span style="color: white">Move</span>
          </button>
        </div>
        <div *ngIf="modules.transform" >
          <button mat-button [routerLink]="['/transformation/transforms']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/transform.png" />
            <span style="color: white">Transform</span>
          </button>
        </div>
        <div *ngIf="modules.output" >
          <button mat-button [routerLink]="['/withdrawal/withdraws']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/outputs.png" />
            <span style="color: white">Withdraw</span>
          </button>
        </div>
      </mat-menu>
    </span>



      <span class="semi_gap" *ngIf="authService.isLoggedIn && modules.tables && displayIcon">
        <button *ngIf="authService.isLoggedIn" mat-button [matMenuTriggerFor]="tablesMenu">
           <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/tables.png" />
           <span style="color: white">Tables</span>
        </button> 
      <mat-menu #tablesMenu="matMenu">
        <div *ngIf="modules.purchase" >
          <button mat-button [routerLink]="['/purchase/orders']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/purchase_order.png" />
            <span style="color: white">Purchases</span>
          </button>
        </div>
        <div *ngIf="modules.receive" >
          <button mat-button [routerLink]="['/reception/receptions']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/reception.png" />
            <span style="color: white">Reception</span>
          </button>
        </div>
        <div *ngIf="modules.dry" >
          <button mat-button [routerLink]="['/transformation/dry-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/dryer.png" />
            <span style="color: white">Drying</span>
          </button>
        </div>
        <div *ngIf="modules.move" >
          <button mat-button [routerLink]="['/movement/movement-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/move.png" />
            <span style="color: white">Movement</span>
          </button>
        </div>
        <div *ngIf="modules.transform" >
          <button mat-button [routerLink]="['/transformation/transformation-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/transform.png" />
            <span style="color: white">Transformation</span>
          </button>
        </div>
        <div *ngIf="modules.output" >
          <button mat-button [routerLink]="['/withdrawal/withdrawal-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/outputs.png" />
            <span style="color: white">Withdrawal</span>
          </button>
        </div>
        <div *ngIf="modules.update" >
          <button mat-button [routerLink]="['/output/output-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/updates.png" />
            <span style="color: white">Updates</span>
          </button>
        </div>
        <div *ngIf="modules.event" >
          <button mat-button [routerLink]="['/output/output-table']">
            <img style="vertical-align:middle; color: white; width: 20px; height: 20px; margin-right: 10px;" src="/assets/images/events.png" />
            <span style="color: white">Events</span>
          </button>
        </div>
      </mat-menu>
    </span>

    
  
    </span>

    <!-- Chat Management / SaaS 2 / Offer 2 -->  
    <span class="gap" *ngIf="saas == 'true' && displayIcon && authService.isLoggedIn && authService.user$ | async as user">
      <button mat-button (click)="toChatMenu()">
        <mat-icon style="color: white; vertical-align:middle;">chat</mat-icon>
      </button>
    </span>
 
    
    <span *ngIf="offers">
      <button mat-button [routerLink]="['/sign-in']">
        <span style="color: white">Sign-in</span>
      </button>
    </span>
    
  
    <span>
      <div *ngIf="authService.user$ | async as user">
        <button id="user_icon" #userMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="usermenu" mat-button (click)="onUserIconClicked()">
          <mat-icon color="accent" style="color: white; vertical-align:middle;">account_circle</mat-icon>
        </button>
      </div>
      <mat-menu   backdropClass="customize" color="primary" #usermenu="matMenu">
        <!-- <div id="company_div" *ngIf="companySelected">
          <button mat-menu-item style="z-index: 1 !important;" [routerLink]="['client-management/companies/profile']">
            <mat-icon color="primary" style="color: white; vertical-align:middle;">business</mat-icon>
            <span style="color: white">Company Profile</span>
          </button>
        </div> -->
        <div *ngIf="authService.isLoggedIn">
          <button mat-menu-item [routerLink]="['myaccount']">
            <mat-icon color="primary" style="color: white; vertical-align:middle;">face</mat-icon>
            <span style="color: white">My account</span>
          </button>
        </div>
        <!-- <div >
          <button mat-menu-item [routerLink]="['my-organisations']">
            <mat-icon color="primary" style="color: white; vertical-align:middle;">business</mat-icon>
            <span style="color: white">My organisations (own)</span>
          </button>
        </div> -->
        <!-- <div >
          <button mat-menu-item [routerLink]="['my-joined-organisations']">
            <mat-icon color="primary" style="color: white; vertical-align:middle;">business</mat-icon>
            <span style="color: white">My organisations (joined)</span>
          </button>
        </div> -->
        <!-- <div >
          <button mat-menu-item [routerLink]="['my-joined-requests']">
            <mat-icon color="primary" style="color: white; vertical-align:middle;">business</mat-icon>
            <span style="color: white">My organisations (requests)</span>
          </button>
        </div>
        <div >
          <button mat-menu-item [routerLink]="['joined-requests']">
            <mat-icon color="primary" style="color: white; vertical-align:middle;">business</mat-icon>
            <span style="color: white">Received requests</span>
          </button>
        </div>
        <div >
          <button mat-menu-item [routerLink]="['invoices']">
            <mat-icon color="primary" style="color: white; vertical-align:middle;">payment</mat-icon>
            <span style="color: white">Payment History</span>
          </button>
        </div> -->
        <div>
          <button mat-menu-item (click)="sidenav.close();logout()">
              <mat-icon color="accent" style="color: white; vertical-align:middle;">settings_power</mat-icon>
              <span style="color: white">Log-out</span>
          </button>
        </div>
      </mat-menu>
    </span>
  
</mat-toolbar>
