import { Injectable } from '@angular/core';
import { Dispatch } from '../interfaces/dispatch';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DispatchCrudService {

   refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/dispatches/' ;
  }

  // Create Dispatch
  add(record: Dispatch) {
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single Dispatch Object
  get(id: string) {
    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch Dispatchs List
  getList() {
    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update Dispatch Object
  update(record: Dispatch) {
    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<Dispatch>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Dispatch Object
  delete(id: string) {
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}