import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, FilterService, MenuItem, SelectItemGroup } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { WithdrawCrudService } from 'src/app/shared/services/withdraw-crud.service';
import { Withdrawal } from 'src/app/shared/interfaces/withdrawal';
import { Unit } from 'src/app/shared/interfaces/unit';
import { ContentType } from 'src/app/shared/interfaces/content-type';
import { PackagingType } from 'src/app/shared/interfaces/packaging-type';
import { ContentTypeCrudService } from 'src/app/shared/services/content-type-crud.service';
import { PackagingTypeCrudService } from 'src/app/shared/services/packaging-type-crud.service';
import { WoProduct } from 'src/app/shared/interfaces/wo-product';



@Component({
  selector: 'app-withdrawal-table',
  templateUrl: './withdrawal-table.component.html',
  styleUrls: ['./withdrawal-table.component.scss']
})
export class WithdrawalTableComponent implements OnInit {

  withdrawals: Withdrawal[];
  withdrawal: Withdrawal;
  selectedWithdrawals: Withdrawal[] = [];

  withdrawalDialog: boolean = false;

  submitted: boolean = false;

  units: Unit[] = [];
  contentTypes: ContentType[] = [];
  packagingTypes: PackagingType[] = [];

  minActivityValues: number[] = [0, 1000];
  maxActivityValues: number[] = [0, 1000];

  contentTypeMap = new Map<string, ContentType>();
  packagingTypeMap = new Map<string, PackagingType>();

  dialogSelectContentKey : string = "";
  dialogSelectPackagingKey : string = "";

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  groupedCities: SelectItemGroup[];
  selectedCities4: any[] = [];

  constructor(
   // private api: APIService,
    private withdrawalService : WithdrawCrudService,
    private filterService : FilterService,
    // private unitService : UnitCrudService,
    private contentTypeService : ContentTypeCrudService,
    private packagingTypeService : PackagingTypeCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Tables'},
      {label: 'Withdrawal table'}
    ];

    this.withdrawals = [];
    this.withdrawal = { } as Withdrawal ;
    //this.getAllRecords();

    

    this.groupedCities = [
      {
          label: 'Germany', value: 'de',
          items: [
              {label: 'Berlin', value: 'Berlin'},
              {label: 'Frankfurt', value: 'Frankfurt'},
              {label: 'Hamburg', value: 'Hamburg'},
              {label: 'Munich', value: 'Munich'}
          ]
      },
      {
          label: 'USA', value: 'us',
          items: [
              {label: 'Chicago', value: 'Chicago'},
              {label: 'Los Angeles', value: 'Los Angeles'},
              {label: 'New York', value: 'New York'},
              {label: 'San Francisco', value: 'San Francisco'}
          ]
      },
      {
          label: 'Japan', value: 'jp',
          items: [
              {label: 'Kyoto', value: 'Kyoto'},
              {label: 'Osaka', value: 'Osaka'},
              {label: 'Tokyo', value: 'Tokyo'},
              {label: 'Yokohama', value: 'Yokohama'}
          ]
      }
  ];
  }

  ngOnInit(): void {


    const customFilterName = 'custom-equals';
    this.filterService.register(
      customFilterName,
      (value: any, filter: any): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }
      return value.toString() === filter.toString();
      }
    );
    




    
    this.dataState();
    let s = this.withdrawalService.getWithdrawalsList(); 
    s.snapshotChanges().subscribe(data => {
      this.withdrawals = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.withdrawals.push(jsonItem as Withdrawal);
        }
      })
    });

    // let u = this.unitService.GetUnitsList(); 
    // u.snapshotChanges().subscribe(data => {
    //   this.units = [];
    //   data.forEach(item => {
    //     let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
    //     if (jsonItem) {
    //       if (jsonItem) {
    //         jsonItem['$key'] = item.key;
    //       }
    //       this.units.push(jsonItem as unknown as Unit);
    //     }
    //   })
    // });

    let ct = this.contentTypeService.GetContentTypesList(); 
    ct.snapshotChanges().subscribe(data => {
      this.contentTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.contentTypes.push(jsonItem as unknown as ContentType);
          const content = jsonItem as unknown as ContentType;
          this.contentTypeMap.set( content.$key , content );
        }
      })
    });

    let pt = this.packagingTypeService.GetPackagingTypesList(); 
    pt.snapshotChanges().subscribe(data => {
      this.packagingTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.packagingTypes.push(jsonItem as unknown as PackagingType);
          const packaging = jsonItem as unknown as PackagingType;
          this.packagingTypeMap.set( packaging.$key , packaging );
        }
      })
    });

    // column management
    this.cols = [
      { field: 'when', header: 'Date and Time' },
      { field: 'who', header: 'User' },
      { field: 'fromWhat', header: 'Inputs' },
      { field: 'howMuch', header: 'Quantity' },
      { field: 'why', header: 'Type' },
      { field: 'toWho', header: 'Client' },
      { field: 'toWhat', header: 'Outputs' }
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.withdrawalService.getWithdrawalsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0) {
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.Withdrawals);
            doc.save('withdrawals.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.withdrawals);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "withdrawals");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    //this.exportService.exportToCsv(this.withdrawals, 'Withdrawals', ['companyId', 'companyName', '$key', 'actions']);
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => exportCols.push(sc.field));
    this.exportService.exportToCsv(this.selectedWithdrawals, 'withdrawals', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.withdrawal = {} as Withdrawal;
    this.submitted = false;
    this.withdrawalDialog = true;
    this.dialogSelectContentKey = "";
    this.dialogSelectPackagingKey = "";
  }

  deleteSelectedWithdrawals() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Withdrawals?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.withdrawals = this.withdrawals.filter(val => !this.selectedWithdrawals.includes(val));
          this.withdrawals.forEach( pallet => {
            this.withdrawalService.deleteWithdrawal(pallet.$key);
          } );
          
          this.selectedWithdrawals = [];
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Withdrawal deleted', life: 3000});
      }
  });
  }

  editWithdrawal(withdrawal: Withdrawal) {
    this.withdrawal = {...withdrawal};
    this.withdrawalDialog = true;
  }

  deleteWithdrawal(withdrawal: Withdrawal) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + withdrawal.key + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.withdrawals = this.withdrawals.filter(val => val.$key !== withdrawal.$key);
            this.withdrawal = {} as Withdrawal;
            this.withdrawalService.deleteWithdrawal(withdrawal.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Tranformation deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.withdrawalDialog = false;
    this.submitted = false;
  }

  saveWithdrawal() {
    this.submitted = true;
    if (this.withdrawal.$key) {
      this.withdrawals[this.findIndexById(this.withdrawal.$key)] = this.withdrawal;                
      this.withdrawalService.updateWithdrawal(this.withdrawal);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Withdrawal updated', life: 3000});
    } else {
      // this.pallet.id = this.createId();
      // this.pallet.image = 'product-placeholder.svg';

      if ( this.packagingTypeMap.has( this.dialogSelectPackagingKey ) ) {
        const packaging = this.packagingTypeMap.get( this.dialogSelectPackagingKey );
        // if (packaging) {
        //   this.withdrawal.container = packaging ;
        //   if (this.withdrawal.container.$key) {
        //     delete this.withdrawal.container.$key
        //     delete this.withdrawal.container.isEnabled
        //   }
        // }
      }

      if ( this.contentTypeMap.has( this.dialogSelectContentKey ) ) {
        const content = this.contentTypeMap.get( this.dialogSelectContentKey );
        // if (content) {
        //   this.withdrawal.content = content;
        //   if (this.withdrawal.content.$key) {
        //     delete this.withdrawal.content.$key
        //     delete this.withdrawal.content.isEnabled
        //   }
        // }
      }
      
      
      // dialogSelectContentKey = "";
      //  = "";)

      this.withdrawalService.addWithdrawal(this.withdrawal);
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Withdrawal created', life: 3000});
      this.withdrawals = [...this.withdrawals];
    }
    this.withdrawalDialog = false;
    this.withdrawal = { } as Withdrawal;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.withdrawals.length; i++) {
        if (this.withdrawals[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeWithdrawal(withdrawal : Withdrawal) {
    const withdrawalTmp = {
      ...withdrawal
    };
  //  console.log("SAVE", withdrawalTmp);
    this.withdrawalService.updateWithdrawal(  withdrawalTmp );
  }

  a(withdrawal : Withdrawal) : any[] {
    // console.log('DRU FROM WHAT', withdrawal.fromWhat['0']);
    // console.log('DRU FROM WHAT0', Object.values(withdrawal.fromWhat) );

    const ar : string[] = [];
    const rs = Object.values(withdrawal.fromWhat) ;
    rs.forEach( 
      (item : WoProduct) => { 
        rs.push(item); 
        console.log(item); 
        let s1 = item.name;
        let s0 = ' - ';
        let s2 = item.batch;
        if (item) {
          ar.push( s1 + s0 + s2 );
        }
      } 
    );
    return ar;
  }

  customFilter(value: any, filter: any): boolean {
    // Implement your custom filter logic here
    // Return true if the value matches the filter, false otherwise

    return true;
  }

}