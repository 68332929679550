<div class="content">  
    <div class="container column mat-elevation-z4">
        <div class="title">
            <span class="greytext title-size">Welcome!</span>
        </div>

        <div class="question">
            <span class="greytext">For which organisation<br> do you want to work<br> today?</span>
        </div>

        <form [formGroup]="companyForm" (ngSubmit)="submitForm()">
            <div class="form-group">
              <p-dropdown formControlName="selectedOrganisation" [options]="myOrganisations" optionLabel="name" placeholder="Select an organisation"></p-dropdown>
            </div>
            <div class="input-column" *ngIf="companyForm.valid">
              <button type="submit" mat-raised-button style="background-color: #e1e1e1; color: #58585e;" class="button-action p-button-success">Enter</button>
            </div>
        </form>

        <div style="width: 90%;height: 1px; background-color: black;margin-bottom: 10px;">

        </div>

        <div class="question">
            <span class="greytext" *ngIf="myOrganisations.length > 0">You haven't find your organisation?</span>
        </div>
        
        <div class="bottom" style="margin-top: 10px">
            <div style="margin-left: 10px" *ngIf="myOrganisations.length > 0" [routerLink]="['/client-management/companies']"><span class="options">- Check and manage your current organisations</span></div>
            <!-- <div style="margin-left: 10px" *ngIf="myOrganisations.length > 0" [routerLink]="['/client-management/companies']"><span class="options">- Ask to join a registered public organisation</span></div> -->
           
            <!-- <div class="input-column" *ngIf="myOrganisations.length > 0" [routerLink]="['/client-management/companies']"><span class="options">Manage your organisations</span></div> -->
            <div class="input-column"  *ngIf="myOrganisations.length <= 0" (click)="openNew()"><span class="options">Don't have any organisation yet? Create one</span></div>
            <!-- <div class="input-column"  *ngIf="myOrganisations.length > 0" (click)="openNew()"><span class="options">Create another one</span></div> -->
        </div>
    </div>


      <!-- Dialog : Popup -->
      <p-dialog [(visible)]="myOrganisationDialog" [style]="{width: '450px', height: '400px'}" header="My Organisation" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="myOrganisation.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !myOrganisation.name">Organisation is required.</small>
            <small class="p-error" *ngIf="alreadyTaken(myOrganisation.name)">This organisation name is not available.</small>
        </div>
        <!-- <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="myOrganisation.description" rows="3" cols="20"></textarea>
        </div> -->

        <div class="p-field">
            <label for="isPublic">Publicly visible for joining requests</label>
            <br>
            <p-inputSwitch  [(ngModel)]="myOrganisation.isPublic"></p-inputSwitch>
            <span style="vertical-align: middle;font-size: small;">(warning: do it only if necessary)</span>
            
        </div>        

        <div class="p-field">
            <label for="tags" >Tags</label>
            <div style="height: 10px;">&nbsp;</div>
            <p-chips  id="tags" [(ngModel)]="myOrganisation.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
        </div>   
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveMyOrganisation()"></button>
        </div>
    </p-dialog>


    <!-- <p-dialog [(visible)]="companyDialog" [style]="{width: '450px', height: '450px'}" header="Company Details" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field" style="margin-top: 10px;">
            <label for="id">ID</label>
            <input style="margin-top: 10px;" id="id" pInputTextarea [(ngModel)]="company.companyId" rows="3" cols="20">
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="name">Company Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="company.companyName" required autofocus />
            <small class="p-error" *ngIf="submitted && !company.companyName">Name is required.</small>
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="company.companyDescription" rows="3" cols="20"></textarea>
        </div>
    
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveCompany()"></button>
        </div>
    </p-dialog> -->
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

  </div>