
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div id="search_bar" class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple id="new_button" label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple id="delete_button" label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedWoProducts()" [disabled]="!selectedWoProducts || !selectedWoProducts.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="woProducts" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['name','deliveryMan','productType','receiver','instant']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedWoProducts" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>


            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="productType" name="productType" pResizableColumn pReorderableColumn pSortableColumn="productType" [class]="includesCol('productType') ? 'd' : 'h'">Product type 
                        <p-sortIcon field="productType"></p-sortIcon> 
                        <p-columnFilter type="productType" field="productType" display="menu"></p-columnFilter>
                    </th>
                    <th id="history" name="history" pResizableColumn pReorderableColumn pSortableColumn="history" [class]="includesCol('history') ? 'd' : 'h'">History 
                        <p-sortIcon field="history"></p-sortIcon> 
                        <p-columnFilter type="history" field="history" display="menu"></p-columnFilter>
                    </th>
                    <th id="deliveryMan" name="deliveryMan" pResizableColumn pReorderableColumn pSortableColumn="deliveryMan" [class]="includesCol('deliveryMan') ? 'd' : 'h'">Delivery man 
                        <p-sortIcon field="deliveryMan"></p-sortIcon> 
                        <!-- <p-columnFilter field="deliveryMan" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="minActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{minActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{minActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter> -->
                    </th>
                    <th id="receiver" name="receiver" pResizableColumn pReorderableColumn pSortableColumn="receiver" [class]="includesCol('receiver') ? 'd' : 'h'">Receiver 
                        <p-sortIcon field="receiver"></p-sortIcon> 
                        <!-- <p-columnFilter field="maxCapacity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="maxActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{maxActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{maxActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter> -->
                    </th>
                    <th id="provider" name="provider" pResizableColumn pReorderableColumn pSortableColumn="provider" [class]="includesCol('provider') ? 'd' : 'h'">Provider 
                        <p-sortIcon field="unit"></p-sortIcon> 
                        <p-columnFilter type="text" field="unit" display="menu"></p-columnFilter>
                        <img alt="Create provider" src="/assets/images/unit.png" style="margin-left: 10px;width: 24px; vertical-align: middle;" [routerLink]="['/manage/units']"/>
                    </th>
                    <th id="instant" name="instant" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('instant') ? 'd' : 'h'">Date time
                        <p-columnFilter type="datetime" field="instant" display="menu"></p-columnFilter>
                    </th>
                    <th id="items" name="items" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('items') ? 'd' : 'h'">Items
                        <p-columnFilter type="text" field="items" display="menu"></p-columnFilter>
                    </th>
                    <!-- <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('isEnabled') ? 'd' : 'h'">Enabled
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th> -->
                    <th pResizableColumn pReorderableColumn style="width: 8rem; vertical-align: middle;">
                        <div class="actions-th">
                            <span>Actions</span> 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                        </div>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-woProduct let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="woProduct.position" required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{woProduct.position}} {{index}}
                            </ng-template>
                        </p-cellEditor>
                        <!-- <p-tableCheckbox [value]="woProduct"></p-tableCheckbox> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="woProduct.name" (blur)="completeWoProduct(woProduct)" (keydown.tab)="completeWoProduct(woProduct)" (keydown.enter)="completeWoProduct(woProduct)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{woProduct.name}} - {{woProduct.batch}}</span>
                            </ng-template>
                        </p-cellEditor> -->

                        <span >{{woProduct.name}} - {{woProduct.batch}}</span>
                    </td>

                    <td pEditableColumn [class]="includesCol('productType') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="woProduct.productType" (blur)="completeWoProduct(woProduct)" (keydown.tab)="completeWoProduct(woProduct)" (keydown.enter)="completeWoProduct(woProduct)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{woProduct.productType}}</span>
                            </ng-template>
                        </p-cellEditor> -->
                        <div class="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: start">
                            <p-card styleClass="shadow-2">

                            <!-- <ng-template pTemplate="header"  >
                                <p align="center">
                                    <b>{{woProduct.productType?.name}}</b>
                                </p>
                            </ng-template> -->
                            
                            <!-- <div class="card-title"> <span>{{ woProduct.productType?.name }}</span> </div> -->
                            <div class="card-title"> <span>{{ displayProductTypeName(woProduct.productTypeKey) }}</span> </div>

                            

                            <!-- {{woProduct.productType?.receivable}} <br/>
                            {{woProduct.productType?.soldable}} <br/> -->
                            
                        </p-card>
                        </div>

                        
                    </td>

                    <td pEditableColumn [class]="includesCol('history') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="woProduct.history" (blur)="completeWoProduct(woProduct)" (keydown.tab)="completeWoProduct(woProduct)" (keydown.enter)="completeWoProduct(woProduct)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{woProduct.history}}</span>
                            </ng-template>
                        </p-cellEditor> -->
                        <!-- <p-timeline [value]="events">
                            <ng-template pTemplate="content" let-event>
                                <small class="p-text-secondary">{{event.date}}</small>
                            </ng-template>
                            <ng-template pTemplate="opposite" let-event>
                                {{event.status}}
                            </ng-template>
                        </p-timeline> -->

                        <p-inplace #inplace [closable]="true" >
                            <ng-template pTemplate="display">
                                View
                            </ng-template>
                            <ng-template pTemplate="content">
                                <!-- align="alternate" -->
                                
                                <p-timeline [value]="getHistory(woProduct.history)" align="left">
                                    <ng-template pTemplate="marker" let-event>
                                        <span class="custom-marker shadow-2" [style.backgroundColor]="event.color">
                                            <!-- <i [ngClass]="event.icon"></i> -->
                                            <img *ngIf="isReception(event.which)" style="vertical-align:middle; color: white; width: 20px; height: 20px;" src="/assets/images/reception.png" />
                                            <img *ngIf="isDry(event.which)" style="vertical-align:middle; color: white; width: 20px; height: 20px;" src="/assets/images/dryer.png" />
                                            <img *ngIf="isMove(event.which)" style="vertical-align:middle; color: white; width: 20px; height: 20px;" src="/assets/images/move.png" />
                                            <img *ngIf="isTransform(event.which)" style="vertical-align:middle; color: white; width: 20px; height: 20px;" src="/assets/images/transform.png" />
                                            <img *ngIf="isWithDrawal(event.which)" style="vertical-align:middle; color: white; width: 20px; height: 20px;" src="/assets/images/outputs.png" />
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="content" let-event>
        
                                        <p-card styleClass="shadow-2">  
                                            <div class="card-title"> <span>{{ event.which }}</span> 
                                                <i *ngIf="isFrom( event.where , 'WEBSERVER')" style="float: right" class="pi pi-desktop" title="origin" alt="origin" ></i>
                                                <i *ngIf="isFrom( event.where ,'MOBILE')" class="pi pi-mobile" title="origin" alt="origin" ></i>
                                                <i *ngIf="false" class="pi pi-map" title="location" alt="location" ></i>
                                            </div> 
                                            <br/>

                                            <div *ngIf="isReception(event.which)">
                                                <i class="pi pi-calendar-times" title="datetime" alt="datetime"></i>
                                                <span class="card-data">{{ event.what?.datetime }}</span> <br/>
                                                
                                                <i class="pi pi-user picon ui-icon-circle" title="receiver" alt="receiver"></i>
                                                <span class="card-data">{{ event.who }}</span> <br/>
                                                
                                                <i class="pi pi-telegram" title="provider" alt="provider"></i>
                                                <span class="card-data">{{ event.what?.provider }}</span> <br/>
                                                
                                                <i class="pi pi-box" title="delivery man" alt="delivery man"></i>
                                                <span class="card-data">{{ event.what?.deliveryMan }}</span> <br/>
                                                
                                                <i class="pi pi-code" title="receipt" alt="receipt"></i>
                                                <span class="card-data">{{ event.what?.receipt || 'none' }}</span> <br/>
                                                
                                                <i class="pi pi-qrcode" title="WO receipt" alt="Wo receipt"></i>
                                                <span class="card-data">{{ event.what?.woreceipt }}</span> <br/>
                                                
                                                <i class="pi pi-comment" title="notes" alt="notes"></i>
                                                <span class="card-data">{{ event.what?.notes || 'none' }}</span> <br/>
                                            
                                                 <!-- pi-desktop pi-mobile // ??? -->
                                                 <!-- <span> Cause: {{ event.why }}</span> <br/>
                                                 <span> What: {{ event.what }}</span> <br/> -->

                                                 <hr class="ui-hr-separator">

                                                 <span class="card-data" style="font-weight: bold">Products: </span> <br/>
                                                 <span *ngFor="let item of getItems(event.what)" class="card-data">{{ item.name }} - {{ item.description }}</span> <br/>
                               
                                            </div>
                                
                                            <div *ngIf="isDry(event.which)">
                                                <i class="pi pi-calendar-times" title="datetime" alt="datetime"></i>
                                                <span class="card-data">{{ event.when }}</span> <br/>

                                                <i class="pi pi-user picon ui-icon-circle" title="dryer" alt="dryer"></i>
                                                <span class="card-data">{{ event.who }}</span> <br/>

                                                <i class="pi pi-qrcode" title="batch" alt="batch"></i>
                                                <span class="card-data">{{ event.what?.toWhat.batch }}</span> <br/>

                                                <i class="pi pi-map-marker" title="location" alt="location"></i>
                                                <span class="card-data">{{ event.what?.toWhat.location }}</span> <br/>

                                                <i class="pi pi-credit-card" title="name" alt="name"></i>
                                                <span class="card-data">{{ event.what?.toWhat.name }}</span> <br/>
                                                <!-- <span> Cause: {{ event.why }}</span> <br/>
                                                <span> Location: {{ event.where }}</span> <br/>
                                                <span> What: {{ event.what }}</span> -->
                                            </div>


                                            <div *ngIf="isMove(event.which)">
                                                <i class="pi pi-calendar-times" title="datetime" alt="datetime"></i>
                                                <span class="card-data">{{ event.when }}</span> <br/>

                                                <i class="pi pi-user picon ui-icon-circle" title="dryer" alt="dryer"></i>
                                                <span class="card-data">{{ event.who }}</span> <br/>

                                                <i class="pi pi-qrcode" title="batch" alt="batch"></i>
                                                <span class="card-data">{{ event.what?.batch }}</span> <br/>

                                                <i class="pi pi-map-marker" title="location" alt="location"></i>
                                                <span class="card-data">{{ event.what?.newLocation }}</span> <br/>
                                                <!-- <span> Cause: {{ event.why }}</span> <br/>
                                                <span> Location: {{ event.where }}</span> <br/>
                                                <span> What: {{ event.what }}</span> -->
                                            </div>


                                            <div *ngIf="isTransform(event.which)">
                                                <i class="pi pi-calendar-times" title="datetime" alt="datetime"></i>
                                                <span class="card-data">{{ event.when }}</span> <br/>

                                                <i class="pi pi-user picon ui-icon-circle" title="dryer" alt="dryer"></i>
                                                <span class="card-data">{{ event.who }}</span> <br/>

                                                <i class="pi pi-qrcode" title="batch" alt="batch"></i>
                                                <span class="card-data">{{ event.what?.toWhat.batch }}</span> <br/>

                                                <i class="pi pi-map-marker" title="location" alt="location"></i>
                                                <span class="card-data">{{ event.what?.toWhat.location }}</span> <br/>

                                                <i class="pi pi-credit-card" title="name" alt="name"></i>
                                                <span class="card-data">{{ event.what?.toWhat.name }}</span> <br/>
                                            
                                            </div>

                                            <div *ngIf="isWithDrawal(event.which)">
                                                <i class="pi pi-calendar-times" title="datetime" alt="datetime"></i>
                                                <span class="card-data">{{ event.when }}</span> <br/>

                                                <i class="pi pi-user picon ui-icon-circle" title="dryer" alt="dryer"></i>
                                                <span class="card-data">{{ event.who }}</span> <br/>

                                                <i class="pi pi-qrcode" title="batch" alt="batch"></i>
                                                <span class="card-data">{{ event.what?.toWhat.batch }}</span> <br/>

                                                <i class="pi pi-map-marker" title="location" alt="location"></i>
                                                <span class="card-data">{{ event.what?.toWhat.location }}</span> <br/>
                                            
                                                <hr class="ui-hr-separator">

                                                <span class="card-data" style="font-weight: bold">Quantity: {{ event.what?.howMuch }}</span> <br/>
                                                <span class="card-data" style="font-weight: bold">Reason: {{ event.what?.why }}</span> <br/>
                                            </div>

                                            <div *ngIf="isSold(event.which)">
                                                <span> Receiver: {{ event.who }}</span> <br/>
                                                <span> Cause: {{ event.why }}</span> <br/>
                                                <span> Location: {{ event.where }}</span> <br/>
                                                <span> What: {{ event.what }}</span>
                                            </div>

                                        </p-card>
        
        

                                        <!-- <p-card [header]="event.status" [subheader]="event.date">
                                            <img *ngIf="event.image" [src]="'assets/showcase/images/demo/product/' + event.image" [alt]="event.name" width="200" class="shadow-2" />
                                            <p>Lorem , cul!</p>
                                            <button pButton label="Read more" class="p-button-text"></button>
                                        </p-card> -->




                                    </ng-template>
                                </p-timeline>

                                <!-- <button (click)="closeInplace(inplace)">Deactivate</button> -->
                            </ng-template>
                        </p-inplace>



                        
                    </td>

                    <td pEditableColumn [class]="includesCol('deliveryMan') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="woProduct.deliveryMan" (blur)="completeWoProduct(woProduct)" (keydown.tab)="completeWoProduct(woProduct)" (keydown.enter)="completeWoProduct(woProduct)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{woProduct.deliveryMan}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('receiver') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="woProduct.receiver" (blur)="completeWoProduct(woProduct)" (keydown.tab)="completeWoProduct(woProduct)" (keydown.enter)="completeWoProduct(woProduct)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{woProduct.receiver}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('provider') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="woProduct.unit" (blur)="completeWoProduct(woProduct)" (keydown.tab)="completeWoProduct(woProduct)" (keydown.enter)="completeWoProduct(woProduct)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{woProduct.unit}}</span>
                            </ng-template>
                        </p-cellEditor> -->

                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <!-- <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="false" 
                                    [(ngModel)]="woProduct.unit" 
                                    [options]="units" 
                                    optionLabel="name" 
                                    optionValue="name"
                                    (blur)="completeWoProduct(woProduct)"
                                    (keydown.tab)="completeWoProduct(woProduct)"
                                    (keydown.enter)="completeWoProduct(woProduct)"
                                    placeholder="Any">
                                </p-dropdown> -->
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{woProduct.provider}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('instant') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <!-- <p-inputSwitch  [(ngModel)]="woProduct.isEnabled" (onChange)="completeWoProduct(woProduct)"></p-inputSwitch> -->
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="woProduct.instant"  (blur)="completeWoProduct(woProduct)" (keydown.tab)="completeWoProduct(woProduct)" (keydown.enter)="completeWoProduct(woProduct)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{woProduct.instant}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('items') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <!-- <p-inputSwitch  [(ngModel)]="woProduct.isEnabled" (onChange)="completeWoProduct(woProduct)"></p-inputSwitch> -->
                        <!-- <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="woProduct.items"  (blur)="completeWoProduct(woProduct)" (keydown.tab)="completeWoProduct(woProduct)" (keydown.enter)="completeWoProduct(woProduct)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <ul>
                                    <li *ngFor="let item of woProductItems(woProduct.items)">
                                       T {{item.identifier}} - {{item.quantity}} - {{item.productType}} 
                                    </li>
                                </ul>
           
                            </ng-template>
                        </p-cellEditor> -->
                    </td>


                    <!-- <td>{{woProduct.name}}</td> -->
                    <!-- <td>nicolas</td> -->
                    <!-- <td><img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td> -->
                    <!-- <td>{{product.price | currency:'USD'}}</td>
                    <td>{{product.category}}</td>
                    <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                    <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                    <td class="row-actions">
                        <!-- <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editWoProduct(woProduct)"></button> -->
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteWoProduct(woProduct)"></button>
                        <p-tableCheckbox class="checkbox" [value]="woProduct"></p-tableCheckbox>
                        
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="woProduct"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{woProducts ? woProducts.length : 0 }} product{{ woProducts.length > 1? 's' : '' }}
                    </div>
                    <div id="export_document_button" class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="woProductDialog" [style]="{width: '450px', height: '450px'}" header="Packaging Type Details" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                <label for="name">Name</label>
                <input type="text" pInputText id="name" [(ngModel)]="woProduct.name" required autofocus />
                <small class="p-error" *ngIf="submitted && !woProduct.name">Name is required.</small>
            </div>

            <!-- <div style="margin-top: 20px;">
                <b>Capacity</b>
            </div>
            <div style="flex-direction: row; display: flex;">
                <label for="minCapacity" style="margin-left: 20px;margin-right: 10px;">Min</label>
                <input type="text" id="minCapacity" pInputText style="width: 50px" [(ngModel)]="woProduct.minCapacity" />

                <span style="width: 50px;"></span>

                <label for="maxCapacity" style="margin-left: 20px;margin-right: 10px;">Max</label>
                <input type="text" id="maxCapacity" pInputText style="width: 50px"   [(ngModel)]="woProduct.maxCapacity" />

                <span style="margin-left: 20px;margin-right: 10px;">Units</span>
                <p-dropdown 
                    [style]="{'width':'100px'}"
                    autoWidth="false"
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="woProduct.unit" 
                    [options]="units" 
                    optionLabel="name" 
                    optionValue="name"
                    placeholder="Any">
                </p-dropdown>
            </div> -->

            <div style="flex-direction: row; display: flex ">
                
            </div>
                   

        <!-- https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643 -->
        
        <!-- <ng-template pTemplate="footer"> -->
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveWoProduct()"></button>
        <!-- </ng-template> -->
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

    <button (click)="helpButtonClicked()" mat-fab color="black" class="help-button">
        <mat-icon>help_outline</mat-icon>
    </button>
    
    <ngx-guided-tour
        skipText="Skip"
        nextText="Next"
        backText="Back"
        doneText="Complete"
    ></ngx-guided-tour>
</div>





