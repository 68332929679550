
<div class="page">

    <!-- <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedTunas()" [disabled]="!selectedTunas || !selectedTunas.length "></button>
        </div> 
    </div> -->

    <div class="page-content">
        <p-table #dt2 
            [value]="tunas"                                                                                                      
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['position','qrcode','name','size','weight','description','tags','status']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedTunas" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>

            <ng-template pTemplate="caption">
                <div class="summary">
                    <div class="summary-total" style="text-decoration: none; font-weight: normal">
                        Total: {{tunas ? tunas.length : 0 }} tuna{{tunas.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV - Save the selected lines and columns" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS - Save all the lines and columns" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF - Save all the lines and columns" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
<!--             
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template> -->

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="qrcode" style="width: 8rem" name="qrcode" pResizableColumn pReorderableColumn pSortableColumn="qrcode" [class]="includesCol('qrcode') ? 'd' : 'h'">QRCode 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> 
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="size" name="size" pResizableColumn pReorderableColumn [class]="includesCol('size') ? 'd' : 'h'">Size (cm)
                        <p-sortIcon field="size"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="size" display="menu"></p-columnFilter>
                    </th>
                    <th id="weight" name="weight" pResizableColumn pReorderableColumn [class]="includesCol('weight') ? 'd' : 'h'">Weight (kg)
                        <p-sortIcon field="weight"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="weight" display="menu"></p-columnFilter>
                    </th>
                    <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description
                        <p-sortIcon field="description"></p-sortIcon> 
                        <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                    </th>
                    <th id="tags" name="tags" pResizableColumn pReorderableColumn [class]="includesCol('tags') ? 'd' : 'h'">Tags
                        <p-sortIcon field="tags"></p-sortIcon> 
                        <p-columnFilter type="text" field="tags" display="menu"></p-columnFilter>
                    </th>
                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('isEnabled') ? 'show' : 'hide'">
                        Enabled
                        <p-sortIcon field="isEnabled"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 2rem">
                    
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        <!-- Actions  -->
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:13px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tuna let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" [pReorderableRowHandle]="index" style="margin-right: 1rem;"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                (ngModelChange)="modelChangeFn($event, tuna)"
                                [ngModel]="tuna.position" 
                                (blur)="completePosition(tuna)" 
                                (keydown.tab)="completePosition(tuna)" 
                                (keydown.enter)="completePosition(tuna)"  
                                required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{tuna.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('qrcode') ? 'd' : 'h'">
                        <qrcode [qrdata]="tuna.name" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="tuna.name" 
                                (blur)="completeTuna(tuna)" 
                                (keydown.tab)="completeTuna(tuna)" 
                                (keydown.enter)="completeTuna(tuna)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{tuna.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="tuna.description"  (blur)="completeTuna(tuna)" (keydown.tab)="completeTuna(tuna)" (keydown.enter)="completeTuna(tuna)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tuna.description}}</span>
                            
                         
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('size') ? 'd' : 'h'" >
                        <p-cellEditor *ngIf="!tuna.size" >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="tuna.size"  (blur)="completeTuna(tuna)" (keydown.tab)="completeTuna(tuna)" (keydown.enter)="completeTuna(tuna)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <input pInputText type="number" [(ngModel)]="tuna.size"  (blur)="completeTuna(tuna)" (keydown.tab)="completeTuna(tuna)" (keydown.enter)="completeTuna(tuna)"  required>
                            </ng-template>
                        </p-cellEditor>
                        <p-cellEditor *ngIf="tuna.size" >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="tuna.size"  (blur)="completeTuna(tuna)" (keydown.tab)="completeTuna(tuna)" (keydown.enter)="completeTuna(tuna)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tuna.size}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('weight') ? 'd' : 'h'" >
                        <p-cellEditor *ngIf="!tuna.weight">
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="tuna.weight"  (blur)="completeTuna(tuna)" (keydown.tab)="completeTuna(tuna)" (keydown.enter)="completeTuna(tuna)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <input pInputText type="number" [(ngModel)]="tuna.weight"  (blur)="completeTuna(tuna)" (keydown.tab)="completeTuna(tuna)" (keydown.enter)="completeTuna(tuna)"  required>
                            </ng-template>
                        </p-cellEditor>

                        <p-cellEditor *ngIf="tuna.weight">
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="tuna.weight"  (blur)="completeTuna(tuna)" (keydown.tab)="completeTuna(tuna)" (keydown.enter)="completeTuna(tuna)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tuna.weight}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('tags') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-chips  id="tags" [(ngModel)]="tuna.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3" (onBlur)="completeTuna(tuna)" (onAdd)="completeTuna(tuna)" (onRemove)="completeTuna(tuna)"></p-chips>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="margin-right: 5px;" *ngFor="let tag of tuna.tags">
                                    <p-tag >{{tag}}</p-tag>
                                </span>
                               
                       
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="tuna.isEnabled" (onChange)="completeTuna(tuna)"></p-inputSwitch>
                    </td>

                    <td >
                        <button *ngIf=" (tunas.length -1) == index" style="margin-right: 10px" pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-success p-mr-2" (click)="addTunaLine()"></button>
                        
                    </td>

                    <td class="row-actions">
                        
                        <!-- <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editTuna(tuna)"></button> -->
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteTuna(tuna)"></button>
                        <p-tableCheckbox class="checkbox" [value]="tuna"></p-tableCheckbox>
                        
                    </td>
                </tr>
            </ng-template>
            
        </p-table>

    </div>

    <!-- <div class="page-footer">
        <div class="p-d-flex">
            <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button>
        </div>        
    </div> -->

    <!-- Dialog : confirm -->
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    

    <!-- Dialog : Popup -->
    <!-- <p-dialog [(visible)]="tunaDialog" [style]="{width: '450px', height: '450px'}" header="Tuna" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="tuna.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !tuna.name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="tuna.description" rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="tags" >Tags</label>
            <div style="height: 10px;">&nbsp;</div>
            <p-chips  id="tags" [(ngModel)]="tuna.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
        </div>   
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveTuna()"></button>
        </div>
    </p-dialog> -->
    
    

</div>






