import { Inject, Injectable } from '@angular/core';
import { MyOrganisation } from '../interfaces/my-organisation';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';
import { MyAccount } from '../interfaces/my-account';


@Injectable({
  providedIn: 'root'
})
export class MyAccountCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/myAccount/' ;
  }

  getUid() : string | null {
    const user = JSON.parse(localStorage.getItem('user')!);
    if (user && typeof user.uid !== 'undefined') {
        return user.uid;
    }
    return null;
  }

  // Create MyAccount
  // Create Pallet
  add(record: MyAccount) {
    const org = this.getUid();
    if (!org) {
      return;
    } 
    this.refs = this.db.list('users/' +  org + '/' + this.path );
    const item = {
      ...record
    }
    const newItem = this.refs?.push( item );
    const key = newItem.key;
    item.key = key || 'na';
    item.$key = key || 'na';
    this.update(item);
  }
  // Fetch Single Pallet Object
  get(id: string) {
    const org = this.getUid();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.ref = this.db.object( 'users/' + org + '/' + this.path + id);
    return this.ref;
  }
  // Fetch Parcels List
  getList() {
    const org = this.getUid();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.refs = this.db.list( 'users/' + org + '/' + this.path );
    return this.refs;
  }
  // Update Parcel Object
  update(record: MyAccount) {
    const org = this.getUid();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'users/' + org + '/' + this.path + record.$key);
    let item : Partial<MyAccount>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Parcel Object
  delete(id: string) {
    const org = this.getUid();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'users/' + org + '/' + this.path + id);
    this.ref.remove();
  }
}
