import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { JoinedRequestCrudService } from 'src/app/shared/services/joined-request-crud.service';
import { JoinedRequest } from 'src/app/shared/interfaces/joined-request';
import { AuthService } from '../shared/services/auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-joined-requests',
  templateUrl: './joined-requests.component.html',
  styleUrls: ['./joined-requests.component.scss']
})
export class JoinedRequestsComponent implements OnInit {

  userId : string = '';
  userId$: BehaviorSubject<string> = new BehaviorSubject<string>('undefined');

  joinedRequests: JoinedRequest[];
  joinedRequest: JoinedRequest;
  selectedJoinedRequests: JoinedRequest[] = [];

  joinedRequestDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    public authService : AuthService,
    private joinedRequestService : JoinedRequestCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Joined Requests'}
    ];

    this.joinedRequests = [];
    this.joinedRequest = { } as JoinedRequest ;

  }

  async ngOnInit() {

    this.isFilter = false;
    
    await this.dataState();

    this.authService.user$.subscribe( (user: any) => {
      if (user) {
        
        this.userId = user.uid;

        let s = this.joinedRequestService.getList( user.uid ); 
        s.snapshotChanges().subscribe(data => {
          this.joinedRequests = [];
          data.forEach(item => {
            let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
            if (jsonItem) {
              if (jsonItem) {
                jsonItem['$key'] = item.key;
              }
              let l : JoinedRequest = jsonItem as JoinedRequest;
              if (l.tags) {
                l.tags = Object.values(l.tags);
              }
              this.joinedRequests.push(l);
            }
          })
        });

      } else {
        this.userId = 'undef';
      }
    });

    
    
    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'qrcode', header: 'QRCode' },
      { field: 'description', header: 'Description' },
      { field: 'tags', header: 'Tags' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'qrcode');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  async dataState() {     
    const u = await this.authService.user$;
    //this.userId = u.uid;
    this.userId$.next( u.uid );
    // this.joinedRequestService.getList().valueChanges().subscribe(data => {
    //   this.preLoader = false;
    //   if(data.length <= 0){
    //     this.hideWhenNoPallet = false;
    //     this.noData = true;
    //   } else {
    //     this.hideWhenNoPallet = true;
    //     this.noData = false;
    //   }
    // })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.joinedRequests);
            doc.save('joinedRequests.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.joinedRequests);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "joinedRequests");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedJoinedRequests);
      // console.log('SAVEC CSV LOCATIONS ', this.joinedRequests);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedJoinedRequests, 'joinedRequests', exportCols);
   // this.exportService.exportToCsv(this.joinedRequests, 'joinedRequests', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.joinedRequest = {} as JoinedRequest;
    this.submitted = false;
    this.joinedRequestDialog = true;
  }

  deleteSelectedJoinedRequests() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected joinedRequests?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.joinedRequests = this.joinedRequests.filter(val => !this.selectedJoinedRequests.includes(val));
            
            this.selectedJoinedRequests.forEach( joinedRequest => this.joinedRequestService.delete(this.userId, joinedRequest.$key));
            this.selectedJoinedRequests = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'JoinedRequestss Deleted', life: 3000});
        }
    });
  }

  editJoinedRequest(joinedRequest: JoinedRequest) {
    this.joinedRequest = {...joinedRequest};
    this.joinedRequestDialog = true;
  }

  deleteJoinedRequest(joinedRequest: JoinedRequest) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + joinedRequest.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.joinedRequests = this.joinedRequests.filter(val => val.$key !== joinedRequest.$key);
            this.joinedRequest = {} as JoinedRequest;
            this.joinedRequestService.delete(this.userId, joinedRequest.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'JoinedRequest Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.joinedRequestDialog = false;
    this.submitted = false;
  }

  saveJoinedRequest() {
    this.submitted = true;

    // if ( this.joinedRequest.name.trim() ) {
    //   if (this.joinedRequest.$key) {
    //       this.joinedRequests[this.findIndexById(this.joinedRequest.$key)] = this.joinedRequest;                
    //       this.messageService.add({severity:'success', summary: 'Successful', detail: 'JoinedRequest Updated', life: 3000});
    //       this.joinedRequestService.update( this.userId, this.joinedRequest);
        
    //       if (this.joinedRequest.isPublic) {
    //         if (!this.organisationsMap.has(this.joinedRequest.$key) ) {
    //           const publicOrganisation : Organisation = Object.assign({}, this.joinedRequest);
    //           publicOrganisation.position = this.organisations.length;
    //           publicOrganisation.$key = this.joinedRequest.$key ;
    //           publicOrganisation.key = this.joinedRequest.$key ;
    //           this.organisationService.update( publicOrganisation );
    //         }
    //       } 
        
    //     } else {
    //       this.joinedRequest.position = this.joinedRequests.length;
    //       const ref = this.joinedRequestService.add(this.userId,this.joinedRequest);
    //       console.log('NEW KEY', ref.key);

    //       if (this.joinedRequest.isPublic) {
    //         const publicOrganisation : Organisation = Object.assign({}, this.joinedRequest);
    //         publicOrganisation.position = this.organisations.length;
    //         publicOrganisation.$key = ref.key || 'na';
    //         publicOrganisation.key = ref.key || 'na';
    //         this.organisationService.update( publicOrganisation );
    //       } else {

    //       }
    //   }
    //   this.messageService.add({severity:'success', summary: 'Successful', detail: 'JoinedRequest Created', life: 3000});
    //   this.joinedRequests = [...this.joinedRequests];
    // }

    this.joinedRequestDialog = false;
    this.joinedRequest = { } as JoinedRequest;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.joinedRequests.length; i++) {
        if (this.joinedRequests[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeJoinedRequest(joinedRequest : JoinedRequest) {
    this.joinedRequestService.update(this.userId,joinedRequest);
  }

  completePosition(joinedRequest : JoinedRequest) {
    if ( this.newPosition > -1 ) {
        joinedRequest.position = this.newPosition;
        joinedRequest = this.checkPositionLimits(joinedRequest);
        this.reorderElements(joinedRequest);
        //this.updateDB(joinedRequest);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.joinedRequests);
    // const myClonedArray = [];
    // this.joinedRequests.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.joinedRequests.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.joinedRequests.length - 1 - event.dropIndex;
    const joinedRequestToMove = this.joinedRequests[startIndex];

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.joinedRequests[index] = this.joinedRequests[index+1];
        this.joinedRequests[index].position = index;
        this.joinedRequestService.update(this.userId,this.joinedRequests[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.joinedRequests[index] = this.joinedRequests[index-1];
        this.joinedRequests[index].position = index;
        this.joinedRequestService.update(this.userId,this.joinedRequests[index]);
      }
    } 
    this.joinedRequests[endIndex] = joinedRequestToMove;
    this.joinedRequests[endIndex].position = endIndex;
    this.joinedRequestService.update(this.userId,this.joinedRequests[endIndex]);
  }

  // onFieldEdit(joinedRequest: Spray, fieldName: string): void {
  //   //console.log(this.joinedRequests);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + joinedRequest[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       joinedRequest.position = this.newPosition;
  //       joinedRequest = this.checkPositionLimits(joinedRequest);
  //       this.reorderElements(joinedRequest);
  //       //this.updateDB(joinedRequest);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(joinedRequest);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.joinedRequests.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, joinedRequest: JoinedRequest) {
    // console.log("event" +event);
    if (joinedRequest.position != null && joinedRequest.position > -1) {
      this.previousPosition = joinedRequest.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(joinedRequest: JoinedRequest) {
    console.log('check ',joinedRequest.position + ' ' + this.joinedRequests.length);
    if (!joinedRequest.position) {
     // console.log('check 1');
      return this.joinedRequest;
    }
    if (joinedRequest.position < 0) {
      joinedRequest.position = 0;
      // console.log('check 2');
      return joinedRequest;
    }
    if (joinedRequest.position > this.joinedRequests.length) {
      joinedRequest.position = this.joinedRequests.length - 1;
    //  console.log('check 3', joinedRequest.position);
      return joinedRequest;
    }
   // console.log('check 4');
    return joinedRequest;
  }

  async reorderElements(joinedRequest: JoinedRequest) {
    this.newPosition = this.newPosition  > this.joinedRequests.length ? this.joinedRequests.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === joinedRequest.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const joinedRequestToMove = this.joinedRequests[startIndex];
   // console.log('Item to move: ', this.joinedRequests[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.joinedRequests);
        console.log('Item to +1: ', this.joinedRequests[index+1]);

        this.joinedRequests[index] = this.joinedRequests[index+1];
        this.joinedRequests[index].position = index;
        // console.log('Move: ', this.joinedRequests[index+1]);
        // console.log('Move pos: ', this.joinedRequests[index].position);
        //await this.updateDB(this.joinedRequests[index]);
        this.joinedRequestService.update(this.userId,this.joinedRequests[index]);
     //   console.log('Update DB: ', this.joinedRequests[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.joinedRequests[index] = this.joinedRequests[index-1];
        this.joinedRequests[index].position = index;
        this.joinedRequestService.update(this.userId,this.joinedRequests[index]);
      }
    } 
    this.joinedRequests[endIndex] = joinedRequestToMove;
    this.joinedRequests[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.joinedRequestService.update(this.userId,this.joinedRequests[endIndex]);
    
    // const myClonedArray = [];
    // this.joinedRequests.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.joinedRequests = myClonedArray;
   // this.getAllRecords();
  }

  
}
