import { Injectable } from '@angular/core';
import { JoinedRequest } from '../interfaces/joined-request';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JoinedRequestCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/joinedRequests/' ;
  }

  // Create JoinedRequest
  add(uid : string, record: JoinedRequest) {
    this.path = environment.name + '/' + uid + '/settings/joinedRequests/' ;
 
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    // this.refs?.push( item );
    const key = this.refs?.push( item );
    return key;
  }
  // Fetch Single JoinedRequest Object
  get(uid : string, id: string) {
    this.path = environment.name + '/' + uid + '/settings/joinedRequests/' ;
 
    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch JoinedRequests List
  getList(uid : string) {
    this.path = environment.name + '/' + uid + '/settings/joinedRequests/' ;
 
    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update JoinedRequest Object
  update(uid : string, record: JoinedRequest) {
    this.path = environment.name + '/' + uid + '/settings/joinedRequests/' ;
 
    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<JoinedRequest>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete JoinedRequest Object
  delete(uid : string, id: string) {
    this.path = environment.name + '/' + uid + '/settings/joinedRequests/' ;
 
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}