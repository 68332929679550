<div class="all">
  <app-navigation-bar style="z-index: 1; margin-bottom: 2%;" [sidenav]="sidenav"></app-navigation-bar>
  <div class="content" style="z-index: 1;height: 100%; flex: 1; flex-grow: 1; ">
    <mat-sidenav-container class="sidenav-container" style="min-height: 75vh;height: 100%; flex: 1; flex-grow: 1; ">
      <mat-sidenav #sidenav mode="side" class="mat-elevation-z4" [opened]="false" position="start" style="border-radius: 5px; flex-grow: 1; width: 290px; margin-top:5px; margin-bottom:5px;">
        <app-settings [sidenav]="sidenav"></app-settings>
      </mat-sidenav>
      <mat-sidenav-content style="min-height: 75vh; flex: 1; flex-grow: 1">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <br>
  <app-footer></app-footer>
</div>