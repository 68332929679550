
<div class="page">

    <div class="page-header">
  
  
      <!-- <span class="step">STEP 1 of 3</span> -->
  
      <span class="title">Choose the plan that’s right for you</span>
      
      <span class="text">
        <i class="pi pi-check" style="color: #E68183;"></i> &nbsp;Take all you want.
      </span>
      <span class="text">
        <i class="pi pi-check" style="color: #E68183;"></i> &nbsp;Recommendations just for you.
      </span>
      <span class="text">
        <i class="pi pi-check" style="color: #E68183;"></i> &nbsp;Change or cancel your plan at any time.
      </span>
    
        <!-- <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedBarrels()" [disabled]="!selectedBarrels || !selectedBarrels.length "></button>
        </div>  -->
    </div>
  
      <div class="planGrid_header">
        <div class="plan-empty"></div>

        <div 
            *ngFor="let offer of offers" 
            [class.selected]="selectedDiv === offer" 
            (click)="selectDiv(offer)"
            class="plan" style="text-align: center;">

            <div>
                {{ offer.name }}
            </div>
            
            <div>
                <button [ngClass]="selectedDiv.name == offer.name? 'b-selected' : 'b-not-selected'"  [disabled]="selectedDiv.name != offer.name" pButton pRipple label="Buy" icon="pi pi-credit-card" class="button-action p-button-success" (click)="openPayment()"></button>
            </div>
            
        </div>

       

        <!-- <div class="plan" [class.selected]="selectedDiv === 0" (click)="selectDiv(0)">Plan 1</div>
        <div class="plan" [class.selected]="selectedDiv === 1" (click)="selectDiv(1)">Plan 2</div>
        <div class="plan" [class.selected]="selectedDiv === 2" (click)="selectDiv(2)">Plan 3</div> -->
      </div>



      <!-- <button pButton pRipple label="Purchase" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openPayment()"></button> -->
       
  
      <div class="planGrid_line">
        <div class="plan_line" >Monthly price</div>
        <div *ngFor="let offer of offers" class="plan_line" [ngClass]="selectedDiv.name == offer.name? 'selected' : 'not-selected'"  style="text-align: center;">
            A${{ offer.price }}
        </div>
      </div>
  
      <div class="line"></div>
  


      <div style="width: 100%" class="test" *ngFor="let service of services" >
        <div class="planGrid_line" >
            <div class="plan_line" >{{ service }}</div>
    
            <div *ngFor="let offer of offers" class="plan_line" style="text-align: center;">
                

                <i [ngClass]="selectedDiv.name == offer.name? 'selected' : 'not-selected'"  *ngIf="hasService(offer,service)" class="pi pi-check" ></i>
              
            </div>
    
            
    
            <!-- <div class="plan_line" style="text-align: center;"><i class="pi pi-check" style="color: #E68183;"></i></div>
            <div class="plan_line" style="text-align: center;"><i class="pi pi-check" style="color: #E68183;"></i></div>
            <div class="plan_line" style="text-align: center;">-</div> -->
        </div>
      
        <div class="line"></div>
      </div>

      
  
     
     <!-- <div class="test" style="margin-top: 20px">
        <button [disabled]="selectedDiv.name == null" pButton pRipple label="Purchase" icon="pi pi-credit-card" class="button-action p-button-success p-mr-2" (click)="openPayment()"></button>
             
     </div> -->
  
  
      
  
      <!-- <button class="button1" (click)="makePayment('price_1MdmDMHE9rvMqwXXjXJ9PyeL', 100)">$100</button>
  
      <button class="button2" (click)="makePayment('price_1Mful6HE9rvMqwXXeZe5G6Em', 200)">$200</button>
  
      <button class="button3" onclick="showPopup()">On Demand</button> -->
  
      <p-toast position="center"  key="bc"></p-toast>

  </div>
  
  
  
  
  
  