import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { EblockService } from '../shared/services/eblock.service';
import { BlockCrudService } from '../shared/services/block-crud.service';
import { MessageService } from 'primeng/api';
import { Block } from '../shared/interfaces/block';
import { Record } from '../shared/interfaces/record'
import { v4 as uuidv4 } from 'uuid'
import { GoogleMap } from '@angular/google-maps';
import { Vertex } from '../shared/interfaces/vertex';
import { Router } from '@angular/router';



@Component({
  selector: 'app-eblocks',
  templateUrl: './eblocks.component.html',
  styleUrls: ['./eblocks.component.scss']
})
export class EblocksComponent implements OnInit {

  //ui breadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  //ui management
  display:boolean = false;
  eBlockDialog: boolean = false;
  blocks:Block[];
  block:Block;
  selectedBlocks: Block[] = [];
  submitted: boolean = false;
  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  //google map management
  @ViewChild('mapSearchField') searchField: ElementRef;
  @ViewChild('recenterBtn') recenterBtn: ElementRef;
  @ViewChild(GoogleMap) map: GoogleMap;
  zoom = 18;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    disableDoubleClickZoom: true,
    disableDefaultUI: true,
    zoomControl:true,
    maxZoom: 30,
    minZoom: 8,
    scrollwheel: false,
  }

  drawingManager: google.maps.drawing.DrawingManager;
  pointList: { lat:number; lng: number}[] = [];
  selectedArea = 0;
  selectedShape: any;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  staticMapLink1 = "https://maps.googleapis.com/maps/api/staticmap?center=";
  staticMapLink2 ="&size=300x300&maptype=hybrid&key=AIzaSyBKhPLK7-jxxu2oz-bYNrDBsbU-GYQI73E&zoom=15";
  PathLink:string = "&path=color:0xFEA2A5FF|fillcolor:0x48BED5|weight:3";
  
  constructor(
    private eBlockService:EblockService, 
    private blockCrudService:BlockCrudService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
  ) { 
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Blocks'}
    ];
    this.blocks = [];
    this.block = {} as Block; 
  }
  
  ngOnInit(): void {
    this.breadcrumbs=[
      {label: 'Blocks'}
    ];
    this.homeBreadcrumb = {icon: 'pi pi-home', routerLink: '/'};

    this.dataState();
    let s = this.blockCrudService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.blocks = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Block = jsonItem as Block;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.deserializePolygon(l);
          this.blocks.push(l);
        }
      })
    })

    this.cols = [
      { field: 'id', header: 'ID'},
      { field: 'name', header: 'Name'},
      { field: 'grape', header: 'Grape'},
      { field: 'location', header: 'Location'},
      { field: 'size', header: 'Size'},
      { field: 'StartDate', header: 'Start Date'},
      { field: 'EndDate', header: 'End Date'}
    ];
    this._selectedColumns = this.cols;

    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    })
  }

  dataState() {     
    this.blockCrudService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  // this is for the google map search
  // these functions are from https://kevinkreuzer.medium.com/how-to-implement-an-address-search-with-angular-and-google-maps-32a2df09f8e9
  ngAfterViewInit(): void {
    const searchBox = new google.maps.places.SearchBox(
      this.searchField.nativeElement
    );
    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      this.searchField.nativeElement
    );
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
      this.recenterBtn.nativeElement
    );

    searchBox.addListener('places_changed',() =>{
      const places = searchBox.getPlaces();
      if (places!.length === 0){
        return;
      }
      const bounds = new google.maps.LatLngBounds();
      places!.forEach(place => {
        if (!place.geometry || !place.geometry.location){
          return;
        }
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
      this.center.lat = this.map.getCenter()!.lat();
      this.center.lng = this.map.getCenter()!.lng();
      this.get_address();
    });
  }
  
  recenter(){
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    })
  }

  //  get the address of the current possition
  get_address(){
    var geoCoder = new google.maps.Geocoder();
    geoCoder.geocode({location:  this.center},(result, status) =>{
      if(status !== google.maps.GeocoderStatus.OK){
        alert(status);
      } else {
        // console.log(result);
        var address = (result![0].formatted_address);
        this.block.location = address;
        this.block.latlng = this.center;
      }
    });
  }

  initDrawingManager(){
    this.clearSelection();
    this.pointList = [];
    this.selectedArea = 0;
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl: false,
      drawingControlOptions:{
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: {
        draggable:false,
        editable:false,
        fillColor:"#48BED5",
        strokeColor:"#FEA2A5",
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    });
    if (this.map && this.map.googleMap) {
        this.drawingManager.setMap(this.map.googleMap);
    } else {
        this.drawingManager.setMap(null);
    }

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: { type: google.maps.drawing.OverlayType; overlay: { getPath: () => any; }; }) =>{
      
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        this.updatePointList(event.overlay.getPath());
        this.drawingManager.setDrawingMode(null);
        this.setSelection(event.overlay);
      }
    });
  }

  clearSelection() {
    if (this.selectedShape) {
      this.selectedShape.setEditable(false);
      this.selectedShape.setMap(null);
      this.selectedArea = 0;
      this.selectedShape = null;
      this.pointList = [];
    }
  }

  setSelection(shape: { getPath: () => any; }) {
    this.clearSelection();
    this.block.drawing = shape;
    this.block.pointList = this.pointList;
    this.selectedShape = shape;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  updatePointList(path: google.maps.MVCArray) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
        this.pointList.push(path.getAt(i).toJSON());
    }
    this.selectedArea = google.maps.geometry.spherical.computeArea(path);
    this.block.size = Number(this.selectedArea.toPrecision(8));
  }

  instanceOfBlock(data: any): data is Block {
    return "name" in data;
  }

  static_map_zoom_in(input: Block|Record) {
    var zoom = "";
    if(this.instanceOfBlock(input)) {
      zoom = input.mapLink;
    }
    if(!this.instanceOfBlock(input)) {
      zoom = input.r_mapLink;
    }
    
    var parts = zoom.match(/(.+)(zoom=)([\d]*)/);

    if (!parts) {
        console.error("No match found for zoom in the given string.");
        return;
    }
    
    var zoomLevel  = Number(parts[3]);    

    if(zoomLevel == 18) {
      return;
    }
    var newZoom = parts[1] + parts[2] + (zoomLevel + 1);
    
    if(this.instanceOfBlock(input)) {
      input.mapLink = newZoom;
    }
    if(!this.instanceOfBlock(input)) {
      input.r_mapLink = newZoom;
    }
  }

  static_map_zoom_out(input: any) {
    var zoom = "";
    if(this.instanceOfBlock(input)) {
      zoom = input.mapLink;
    }
    if(!this.instanceOfBlock(input)) {
      zoom = input.r_mapLink;
    }

    var parts = zoom.match(/(.+)(zoom=)([\d]*)/);

    if (!parts) {
        console.error("No match found for zoom in the given string.");
        return;
    }
    
    var zoomLevel  = Number(parts[3]);
    

    if(zoomLevel == 8) {
      return;
    }
    var newZoom = parts[1] + parts[2] + (zoomLevel - 1);
    
    if(this.instanceOfBlock(input)) {
      input.mapLink = newZoom;
    }
    if(!this.instanceOfBlock(input)) {
      input.r_mapLink = newZoom;
    }
  }


  pad(n: number) {
    return n < 10 ? '0'+n : n+'';
  }

  openNew(){
    this.block = {} as Block;
    // this.searchField.nativeElement.value  = "";

    const currentDate = new Date();
    const year  = currentDate.getFullYear();
    const month = currentDate.getMonth()+1;
    const day   = currentDate.getDate();

    this.block.StartDate = year + '-' + this.pad(month) + '-' + this.pad(day) ;

    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.center = {
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   }
    // })

    // this.get_address();

    this.eBlockDialog=true;
    this.submitted =false;
  }

  // Reorder
  reorder(event: any) {
    console.log('Reorder event', event);
  }

  isSelectedColumn(title: string) : boolean{
    const column = this._selectedColumns.find(column => column.field === title);
    return column;
  }

  // Filters
  clear(table: Table) {
    table.clear();
  }

  createId(): string {
    return uuidv4();
  }

  showColumnDialog() {
    this.display = true;
  }

  hideDialog() {
    this.eBlockDialog = false;
    this.submitted =false;
    if(this.drawingManager)
    {
      this.drawingManager.setDrawingMode(null);
    }
    this.clearSelection();
  }

  addRecord(newRecord: Record[]) {
    this.block.records = newRecord;
  }

  getPath() {
    var path = "";
    for (let i = 0; i < this.block.pointList.length; i++) {
      path = path + "|" + this.block.pointList[i].lat.toString() + "," + this.block.pointList[i].lng.toString();
    }
    path = path + "|" + this.block.pointList[0].lat.toString() + "," + this.block.pointList[0].lng.toString();
    return path;
  }

  saveBlock() {
    this.submitted = true;
    if (this.block.name.trim() && this.block.location.trim() && this.block.grape.trim() && this.block.size > 0 && this.block.StartDate.trim())
    {
      if (this.block.EndDate)
      {
        if (this.block.StartDate > this.block.EndDate) {          
          return;
        }
      }
      this.block.isEnabled = true;
      this.block.mapLink = this.staticMapLink1+this.center.lat.toString()+","+this.center.lng.toString();
      if (this.block.pointList)
      {
        this.block.mapLink = this.block.mapLink + this.PathLink + this.getPath();
      }

  
      this.block.mapLink = this.block.mapLink + this.staticMapLink2
      delete this.block.drawing; 
      if (this.block.$key) {
          this.blocks[this.findIndexById(this.block.$key)] = this.block;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Block Updated', life: 3000});
          console.log(this.block.drawing);
          this.blockCrudService.update(this.block);
        } else {
          this.block.position = this.blocks.length;
          console.log(this.block.drawing);
          this.blockCrudService.add(this.block);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Block Created', life: 3000});
      this.blocks = [...this.blocks];
    }
    this.eBlockDialog = false;
    this.block = { } as Block;
  }

  deserializePolygon(block: Block): any[] {
    if (block.serializedPolygon) {
      return block.serializedPolygon.map((vertex: Vertex) => {
        return new google.maps.LatLng(vertex.lat, vertex.lng);
      });
    }
    return [];
  }

  findIndexById(id: string):number {
    let index = -1;

    for (let i = 0; i < this.blocks.length; i++) {
      if (this.blocks[i].id === id) {
        index = i;
        break;
      }
    } 
    return index;
  }

  objectToArray(obj: any): any[] {
      if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
          return Object.values(obj);
      }
      return obj;
  }


  editBlock(block: Block) {
      this.block = {...block};
      this.searchField.nativeElement.value  = "";
      let polygonCoordinates = this.objectToArray(this.block.pointList);
      console.log(polygonCoordinates);
      // Set the center of the map
      if (this.block.latlng) {
        this.center = this.block.latlng;
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
        });
      }

      // Check if the selectedShape is already defined
      if (!this.selectedShape) {
          this.selectedShape = new google.maps.Polygon({
              paths: polygonCoordinates,
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.35
          });
      } else {
          // If the polygon already exists, just update its paths
          this.selectedShape.setPaths(polygonCoordinates);
      }

      // Display the polygon on the map
      this.selectedShape.setMap(this.map.googleMap);

      this.eBlockDialog = true;
  }
  deleteSelectedBlocks() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected blocks?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      key: 'BlockDelete',
      accept: () => {
        this.blocks = this.blocks.filter(val => !this.selectedBlocks.includes(val));     
        this.selectedBlocks.forEach( block => this.blockCrudService.delete(block.$key));
        this.selectedBlocks = [];
        this.messageService.add({severity:'success', summary: 'Successful', detail: 'Blockss Deleted', life: 3000});
      }
    });
  }

  deleteBlock(block: Block) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + block.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        key: 'BlockDelete',
        accept: () => {
          this.blocks = this.blocks.filter(val => val.$key !== block.$key);
          this.block = {} as Block;
          this.blockCrudService.delete(block.$key);
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Block Deleted', life: 3000});
        }
    });
  }

  history(block: Block) {
    localStorage.setItem('block', JSON.stringify(block));
    console.log(block);
    this.router.navigate(['parcel-management/eblocks-history']);
  }
}
