import { Component, OnInit } from '@angular/core';
import { CompanyCrudService } from 'src/app/shared/services/company-crud.service';
import { Company } from 'src/app/shared/interfaces/company';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { MyOrganisationCrudService } from 'src/app/shared/services/my-organisation-crud.service';
import { MyOrganisation } from 'src/app/shared/interfaces/my-organisation';
import { OrganisationCrudService } from 'src/app/shared/services/organisation-crud.service';
import { Organisation } from 'src/app/shared/interfaces/organisation';
import { environment } from 'src/environments/environment';
import { MyJoinedOrganisationsCrudService } from 'src/app/shared/services/my-joined-organisations-crud.service';
import { PublicOrganisationsCrudService } from 'src/app/shared/services/public-organisations-crud.service';
import { OffersCrudService } from 'src/app/shared/services/offers-crud.service';
import { Offer } from 'src/app/shared/interfaces/offer';
import { LocalStorageOrganisationService } from 'src/app/shared/services/local-storage-organisation.service';
import { LocalStorageBService } from 'src/app/shared/services/local-storage-b.service';

@Component({
  selector: 'app-company-connection',
  templateUrl: './company-connection.component.html',
  styleUrls: ['./company-connection.component.scss']
})

export class CompanyConnectionComponent implements OnInit {

  userId : string = '';
  userId$: BehaviorSubject<string> = new BehaviorSubject<string>('undefined');

  companyForm!: FormGroup;
  // selectedCompany: Company;

  // companys: Company[];
  // company: Company;

  selectedOrganisation: MyOrganisation;
  myOrganisations: MyOrganisation[];
  myOrganisation: MyOrganisation;


  // selectedJoinedOrganisation: MyJoinedOrganisation;
  myJoinedOrganisations: MyOrganisation[];
  myJoinedOrganisation: MyOrganisation;

  myOrganisationDialog: boolean = false;
  submitted: boolean = false;

  separatorExp: string = "[,| ]";

  organisations: MyOrganisation[];
  organisationsMap : Map<string,boolean>;
  organisationNames: string[];

  offersMap : Map<string,Offer>;

  stripe = require('stripe')('sk_test_51J9P4XHE9rvMqwXXSJNChK2WoKcmFGVDLCJomoIiiEgWMvvtgOwcIStMagGuwLCTkZExPhERrjfLx2GsCa8ejpEx00sN6oUumJ');

  constructor( 
    private authService : AuthService,
    private myOrganisationService: MyOrganisationCrudService,
    private myJoinedOrganisationService: MyJoinedOrganisationsCrudService,
    private publicOrganisationService: PublicOrganisationsCrudService,
    private offerService: OffersCrudService,
    //private companyCRUDService : CompanyCrudService,
    private messageService: MessageService,
    public router: Router,
    private formBuilder: FormBuilder,
    private organisationService: OrganisationCrudService,
    private localStorageService: LocalStorageBService
    //private localStorageOrganisation : LocalStorageOrganisationService
    ) { 
    // this.companys = [];
    // this.company = {} as Company;
    // this.selectedCompany = {} as Company;

    this.myOrganisations = [];
    this.myOrganisation = {} as MyOrganisation;
    this.selectedOrganisation = {} as MyOrganisation;

    this.myJoinedOrganisations = [];
    this.myJoinedOrganisation = {} as MyOrganisation;


    // this.myJoinedOrganisations = [];
    // this.myJoinedOrganisation = {} as MyOrganisation;
    // this.selectedJoinedrganisation = {} as MyOrganisation;

    this.organisations = [];
    this.organisationsMap = new Map<string,boolean>();
    this.organisationNames = [];

    this.offersMap = new Map<string,Offer>();
  }

  ngOnInit(): void {
    this.companyForm = this.formBuilder.group({
      selectedOrganisation: ['', Validators.required]
    });


    this.authService.user$.subscribe( (user: any) => {
      if (user) {
        
        this.userId = user.uid;

       // let s = this.companyCRUDService.getList( user.uid ); 
        let s = this.myOrganisationService.getList( user.uid ); 
        s.snapshotChanges().subscribe(data => {
          //this.companys = [];

          this.myOrganisations = [];

          data.forEach(item => {
            let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
            if (jsonItem) {
              if (jsonItem) {
                jsonItem['$key'] = item.key;
              }
              //let l : Company = jsonItem as Company;
              let l : MyOrganisation = jsonItem as MyOrganisation;
              // if (l.tags) {
              //   l.tags = Object.values(l.tags);
              // }
              //this.companys.push(l);
              if (l.isEnabled) {
                this.myOrganisations.push(l);
              }
              
            }
          })


  
        });


        let s2 = this.myJoinedOrganisationService.getList( user.uid ); 
        s2.snapshotChanges().subscribe(data => {
          //this.companys = [];

          this.myJoinedOrganisations = [];

          data.forEach(item => {
            let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
            if (jsonItem) {
              if (jsonItem) {
                jsonItem['$key'] = item.key;
              }
              //let l : Company = jsonItem as Company;
              let l : MyOrganisation = jsonItem as MyOrganisation;
              // if (l.tags) {
              //   l.tags = Object.values(l.tags);
              // }
              //this.companys.push(l);
              if (l.isEnabled) {
                this.myJoinedOrganisations.push(l);
                this.myOrganisations.push(l);
              }

              this.myOrganisations.sort( (a,b) => (a.name > b.name ? 1 : -1) );
              
            }
          })


  
        });

      } else {
        this.userId = 'undef';
      }
    });


    let o = this.organisationService.getList(); 
    o.snapshotChanges().subscribe(data => {
      this.organisations = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : MyOrganisation = jsonItem as MyOrganisation;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.organisations.push(l);
          this.organisationsMap.set( l.$key , l.isPublic || false);
          this.organisationNames.push( l.name );
        }
      })
    });


    let of = this.offerService.getList(); 
    of.snapshotChanges().subscribe(data => {
      this.offersMap.clear();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Offer = jsonItem as Offer;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.offersMap.set( l.$key , l);
        }
      })
    });
  }

  submitForm() {
    if (this.companyForm.invalid) {
      return;
    }

    // Access the selected company from the form value
    const selectedCompany = this.companyForm.get('selectedOrganisation')?.value;
    // Proceed with further actions or navigation

    console.log('SELECTED COMPANY', selectedCompany);

   // this.toCompanyDashboard(selectedCompany);
   this.routeTo(selectedCompany);
  }

  openNew() {
    //this.company = {} as Company;
    this.submitted = false;
   // this.companyDialog = true;

    this.myOrganisation = {} as MyOrganisation;
    this.myOrganisationDialog = true;
  }

  hideDialog() {
   // this.companyDialog = false;
    this.myOrganisationDialog = false;
    this.submitted = false;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.myOrganisations.length; i++) {
        if (this.myOrganisations[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  saveMyOrganisation() {
    this.submitted = true;

    if ( this.myOrganisation.name.trim() ) {


      const alreadyExist = this.organisationNames.includes( this.myOrganisation.name.trim() );
      if (alreadyExist) {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'This organisation name is already taken', life: 3000});
        this.myOrganisationDialog = false;
        this.myOrganisation = { } as MyOrganisation;
        return;
      }


      if (this.myOrganisation.$key) {
        if (!this.myOrganisation.ownerKey) {
          this.myOrganisation.ownerKey = this.userId;
        }
        this.myOrganisation.isEnabled = true;
        this.myOrganisations[this.findIndexById(this.myOrganisation.$key)] = this.myOrganisation;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyOrganisation Updated', life: 3000});
          this.myOrganisationService.update( this.userId, this.myOrganisation);
        
          if (this.myOrganisation.isPublic) {
            if (!this.organisationsMap.has(this.myOrganisation.$key) ) {
              const publicOrganisation : Organisation = Object.assign({}, this.myOrganisation);
              publicOrganisation.position = this.organisations.length;
              publicOrganisation.$key = this.myOrganisation.$key ;
              publicOrganisation.key = this.myOrganisation.$key ;
              this.organisationService.update( publicOrganisation );
            }
          } 
        
        } else {
          this.myOrganisation.position = this.myOrganisations.length;

          this.myOrganisation.ownerKey = this.userId;
          this.myOrganisation.isEnabled = true;

          const refOrg = this.organisationService.add( this.myOrganisation );
          //console.log('NEW KEY2', refOrg.key);
          this.organisationService.update( this.myOrganisation);

          if (refOrg.key) {
            this.myOrganisation.key = refOrg.key;

            const ref = this.myOrganisationService.add(refOrg.key, this.userId, this.myOrganisation);
            //console.log('NEW KEY', ref.refOrg);

            if (this.myOrganisation.isPublic) {
              const publicOrganisation : Organisation = Object.assign({}, this.myOrganisation);
              publicOrganisation.position = this.organisations.length;
              publicOrganisation.$key = refOrg.key ;
              publicOrganisation.key = refOrg.key ;
              publicOrganisation.ownerKey = this.userId;
              this.publicOrganisationService.update( publicOrganisation );
            } else {

            }
          }
          
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyOrganisation Created', life: 3000});
      this.myOrganisations = [...this.myOrganisations];
    }

    this.myOrganisationDialog = false;
    this.myOrganisation = { } as MyOrganisation;
  }

  async toCompanyDashboard(company: Company) {

    // Authenticate subscription
    const user = JSON.parse(localStorage.getItem('user')!);
    const subscription = await this.stripe.subscriptions.search({
      query: `status:'active' AND metadata['customerEmail']:'${user.email}' AND metadata['companyKey']:'${company.$key}'`,
    });
    if (subscription.data.length === 0) {
      localStorage.setItem('company', JSON.stringify(company));

     // localStorageService.setOrganisation(company);

      this.router.navigate(['/paymentplans']);
    } else if (subscription.data.length === 1) {
      company.subscriptionPrice = subscription.data[0].items.data[0].price.unit_amount / 100;
      company.subscriptionId = subscription.data[0].id;

      console.log('subscriptions', subscription.data);

      console.log('subscription plan id', subscription.data[0].plan.id);

      localStorage.setItem('company', JSON.stringify(company));
      
      if ( subscription.data[0].plan.id === 'price_1MdmDMHE9rvMqwXXjXJ9PyeL' ) {
        this.router.navigate(['/pallet-management/pallets' ]);
      } else {
        this.router.navigate(['/' + environment.homePath])
      }
    }
  }

  alreadyTaken(value: string) {
    if (!value) {
      return false;
    }
    return this.organisationsMap.has(value);
  }



  async routeTo(organisation: Organisation) {

    // Error: Do we have a user?
    if (!this.userId) {
      return '';
    }

    // Error: Do we have a owner for this organisation
    if (!organisation.ownerKey) {
      return '';
    }
    
    // Do we have a subscription?
    // NO
    if (!organisation.offerKey || organisation.offerKey === '') {

      // Are we just a member and not the tenant of this organisation
      // if yes, go the organisation does not have yet a subscription
      if (organisation.ownerKey !== this.userId) {
        this.router.navigate(['/no-subscription']);
        return;
      }
      
      // if, no we are the tenant and we are allowed to purchase one offer
     // localStorage.setItem('organisation', JSON.stringify(organisation));
      this.localStorageService.setOrganisation(organisation);


      this.router.navigate(['/organisation-offers']);
      return;
    }

    // If we are here, it means the organisation has a subscription
    const offerKey = organisation.offerKey;

    if (!this.offersMap.has(offerKey)) {
      return;
    }

    const offer = this.offersMap.get(offerKey);
    const homePage = offer?.homePage;

    if (!homePage) {
      return;
    }

    localStorage.setItem('company', JSON.stringify(organisation));
    //localStorage.setItem('organisation', JSON.stringify(organisation));
    this.localStorageService.setOrganisation(organisation);

    //this.localStorageOrganisation.setVariableValue(JSON.stringify(organisation));

    this.router.navigate([ homePage ]);
    return;
    
    // Authenticate subscription
    //const user = JSON.parse(localStorage.getItem('user')!); user.uid
    
    // const subscription = await this.stripe.subscriptions.search({
    //   query: `status:'active' AND metadata['customerEmail']:'${user.email}' AND metadata['companyKey']:'${company.$key}'`,
    // });
    // if (subscription.data.length === 0) {
    //   localStorage.setItem('company', JSON.stringify(company));
    //   this.router.navigate(['/paymentplans']);
    // } else if (subscription.data.length === 1) {
    //   company.subscriptionPrice = subscription.data[0].items.data[0].price.unit_amount / 100;
    //   company.subscriptionId = subscription.data[0].id;

    //   console.log('subscriptions', subscription.data);

    //   console.log('subscription plan id', subscription.data[0].plan.id);

    //   localStorage.setItem('company', JSON.stringify(company));
      
    //   if ( subscription.data[0].plan.id === 'price_1MdmDMHE9rvMqwXXjXJ9PyeL' ) {
    //     this.router.navigate(['/pallet-management/pallets' ]);
    //   } else {
    //     this.router.navigate(['/' + environment.homePath])
    //   }
    // }
  }
}
