import { Component, OnInit, Input, ViewChild } from '@angular/core';

// View
import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Tuna } from 'src/app/shared/interfaces/tuna';

// Service
import { TunaCrudService } from 'src/app/shared/services/tuna-crud.service';
import { BatchTuna } from '../shared/interfaces/batch-tuna';
import { BatchTunaCrudService } from '../shared/services/batch-tuna-crud.service';



@Component({
  selector: 'app-tuna',
  templateUrl: './tuna.component.html',
  styleUrls: ['./tuna.component.scss']
})
export class TunaComponent implements OnInit {

  
  @Input() batchTuna: BatchTuna = {} as BatchTuna;
  @Input() tunas: Tuna[] = [];

  tuna: Tuna;
  selectedTunas: Tuna[] = [];

  tunaDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt2', {static: true}) dt2 : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private batchTunaService : BatchTunaCrudService,
    private tunaService : TunaCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Tunas'}
    ];

    this.tunas = [];
    this.tuna = { } as Tuna ;
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    //this.dataState();
    // let s = this.tunaService.getList(); 
    // s.snapshotChanges().subscribe(data => {
    //   this.tunas = [];
    //   data.forEach(item => {
    //     let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
    //     if (jsonItem) {
    //       if (jsonItem) {
    //         jsonItem['$key'] = item.key;
    //       }
    //       let l : Tuna = jsonItem as Tuna;
    //       if (l.tags) {
    //         l.tags = Object.values(l.tags);
    //       }
    //       this.tunas.push(l);
    //     }
    //   })
    // })

    if (!this.tunas) {
      this.tunas = [];
      this.tunas.push( { "name" : "1" , "position" : 0} as Tuna );
      this.batchTuna.tunas = this.tunas;
    } else {
      this.tunas = Object.assign([], this.batchTuna.tunas);
    }

    // column management
    this.cols = [
      { field: 'qrcode', header: 'QRCode' },
      { field: 'name', header: 'Name' },
      { field: 'size', header: 'Size' },
      { field: 'weight', header: 'Weight' },
      { field: 'description', header: 'Description' },
      { field: 'tags', header: 'Tags' },
      //{ field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'qrcode');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'name');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'tags');
   // this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.tunaService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.tunas);
            doc.save('tunas.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.tunas);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "tunas");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt2.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedTunas);
      // console.log('SAVEC CSV LOCATIONS ', this.tunas);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedTunas, 'tunas', exportCols);
   // this.exportService.exportToCsv(this.tunas, 'tunas', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.tuna = {} as Tuna;
    this.submitted = false;
    this.tunaDialog = true;
  }

  deleteSelectedTunas() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected tunas?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.tunas = this.tunas.filter(val => !this.selectedTunas.includes(val));
            
            //this.selectedTunas.forEach( tuna => this.tunaService.delete(tuna.$key));
            this.selectedTunas = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Tunass Deleted', life: 3000});
            this.batchTunaService.update(this.batchTuna);
          }
    });
  }

  editTuna(tuna: Tuna) {
    this.tuna = {...tuna};
    this.tunaDialog = true;
  }

  deleteTuna(tuna: Tuna) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + tuna.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.tunas = this.tunas.filter(val => val.$key !== tuna.$key);
            this.tuna = {} as Tuna;
           // this.tunaService.delete(tuna.$key);
            this.batchTunaService.update(this.batchTuna);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Tuna Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.tunaDialog = false;
    this.submitted = false;
  }

  saveTuna() {
    this.submitted = true;

    if ( this.tuna.name.trim() ) {
      if (this.tuna.$key) {
          this.tunas[this.findIndexById(this.tuna.$key)] = this.tuna;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Tuna Updated', life: 3000});
          //this.tunaService.update(this.tuna);
         // this.batchTunaService.update(this.batchTuna);
        } else {
          this.tuna.position = this.tunas.length;
          //this.tunaService.add(this.tuna);
          
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Tuna Created', life: 3000});
      this.tunas = [...this.tunas];
      this.batchTunaService.update(this.batchTuna);
    }

    this.tunaDialog = false;
    this.tuna = { } as Tuna;
  }

  addTunaLine() {
    

    console.log('add line');
    this.tuna = { } as Tuna;
    this.tuna.name = this.tunas.length + 1 + '';
    this.tuna.position = this.tunas.length || 0;
    //this.tunaService.add(this.tuna);
    
   //this.messageService.add({severity:'success', summary: 'Successful', detail: 'Tuna Created', life: 3000});
    //this.tunas = [...this.tunas];
    
    console.log('add line ', this.tuna);
    console.log('add line s ', this.tunas);
    this.tunas.push( this.tuna );
    console.log('add line s2 ', this.tunas);
    //

    this.batchTuna.tunas = this.tunas;

                                                                                                    this.batchTunaService.update(this.batchTuna);
    this.tuna = { } as Tuna;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.tunas.length; i++) {
        if (this.tunas[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt2 : Table, event : {target: any} ) {
    dt2.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeTuna(tuna : Tuna) {
    //console.log("SAVE", tuna);
   // this.tunaService.update(tuna);
    this.batchTunaService.update(this.batchTuna);
  }

  completePosition(tuna : Tuna) {
   // console.log("SAVE", tuna);
  //  this.tunaService.updateTuna(tuna);


    if ( this.newPosition > -1 ) {
        tuna.position = this.newPosition;
        tuna = this.checkPositionLimits(tuna);
        this.reorderElements(tuna);
        //this.updateDB(tuna);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt2') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.tunas);
    // const myClonedArray = [];
    // this.tunas.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.tunas.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.tunas.length - 1 - event.dropIndex;
    const tunaToMove = this.tunas[startIndex];
    
    
    // console.log('StartIndex', startIndex);
    // console.log('EndIndex', endIndex);

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.tunas[index] = this.tunas[index+1];
        this.tunas[index].position = index;
       // this.tunaService.update(this.tunas[index]);
        this.batchTunaService.update(this.batchTuna);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.tunas[index] = this.tunas[index-1];
        this.tunas[index].position = index;
       // this.tunaService.update(this.tunas[index]);
        this.batchTunaService.update(this.batchTuna);
      }
    } 
    this.tunas[endIndex] = tunaToMove;
    this.tunas[endIndex].position = endIndex;
   // this.tunaService.update(this.tunas[endIndex]);
    this.batchTunaService.update(this.batchTuna);
  }

  // onFieldEdit(tuna: Spray, fieldName: string): void {
  //   //console.log(this.tunas);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + tuna[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       tuna.position = this.newPosition;
  //       tuna = this.checkPositionLimits(tuna);
  //       this.reorderElements(tuna);
  //       //this.updateDB(tuna);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(tuna);
  //   }
  // }


  ngAfterViewInit() {
    this.dt2.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt2.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.tunas.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, tuna: Tuna) {
    // console.log("event" +event);
    if (tuna.position != null && tuna.position > -1) {
      this.previousPosition = tuna.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(tuna: Tuna) {
    console.log('check ',tuna.position + ' ' + this.tunas.length);
    if (!tuna.position) {
     // console.log('check 1');
      return this.tuna;
    }
    if (tuna.position < 0) {
      tuna.position = 0;
      // console.log('check 2');
      return tuna;
    }
    if (tuna.position > this.tunas.length) {
      tuna.position = this.tunas.length - 1;
    //  console.log('check 3', tuna.position);
      return tuna;
    }
   // console.log('check 4');
    return tuna;
  }

  async reorderElements(tuna: Tuna) {
    this.newPosition = this.newPosition  > this.tunas.length ? this.tunas.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === tuna.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const tunaToMove = this.tunas[startIndex];
   // console.log('Item to move: ', this.tunas[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.tunas);
        console.log('Item to +1: ', this.tunas[index+1]);

        this.tunas[index] = this.tunas[index+1];
        this.tunas[index].position = index;
        // console.log('Move: ', this.tunas[index+1]);
        // console.log('Move pos: ', this.tunas[index].position);
        //await this.updateDB(this.tunas[index]);
        //this.tunaService.update(this.tunas[index]);
        this.batchTunaService.update(this.batchTuna);
     //   console.log('Update DB: ', this.tunas[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.tunas[index] = this.tunas[index-1];
        this.tunas[index].position = index;
        //this.tunaService.update(this.tunas[index]);
        this.batchTunaService.update(this.batchTuna);
      }
    } 
    this.tunas[endIndex] = tunaToMove;
    this.tunas[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    //this.tunaService.update(this.tunas[endIndex]);
    this.batchTunaService.update(this.batchTuna);
    
    // const myClonedArray = [];
    // this.tunas.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.tunas = myClonedArray;
   // this.getAllRecords();
  }
}
