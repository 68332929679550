
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedReceptions()" [disabled]="!selectedReceptions || !selectedReceptions.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="receptions" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['woreceipt','receipt','deliveryMan','provider','receiver','instant','datetime','notes','items','items.0.name','items.0.batch','items.0.description', 'items.1.name','items.1.batch','items.2.name','items.2.batch','items.3.name','items.3.batch','items.4.name','items.4.batch']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedReceptions" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            [sortMode]="'multiple'"
            sortField="instant" 
            [sortOrder]="1"
            >

            <!-- [sortField]="'datetime'" 
            [sortOrder]="-1" -->

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <!-- <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Rank<p-sortIcon field="position"></p-sortIcon>
                    </th> -->

                    <th id="instant" name="instant" pResizableColumn pReorderableColumn pSortableColumn="datetime" style="width: 12rem" [class]="includesCol('instant') ? 'd' : 'h'">Date time
                        <p-sortIcon field="datetime"></p-sortIcon> 
                        <p-columnFilter type="datetime" field="instant" display="menu"></p-columnFilter>
                    </th>

                    <th id="woreceipt" name="woreceipt" pResizableColumn pReorderableColumn pSortableColumn="woreceipt" [class]="includesCol('woreceipt') ? 'd' : 'h'">Internal code
                        <p-sortIcon field="woreceipt"></p-sortIcon> 
                        <p-columnFilter type="text" field="woreceipt" display="menu"></p-columnFilter>
                    </th>

                    

                    <th id="items" name="items" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('items') ? 'd' : 'h'">Items
                        <!-- <ng-template pTemplate="filter"> -->
                            <p-columnFilter  type="text" field="items.name" display="menu" [matchMode]="'in'" [showMatchModes]="false"></p-columnFilter> 
                            <p-columnFilter  type="text " field="items.description" display="menu"></p-columnFilter> 


                            <!-- // https://levelup.gitconnected.com/working-with-beautiful-primeng-data-table-filter-menu-4d318f40069e -->
                            
                        <!-- <p-columnFilter
                            field="items"
                            [showMenu]="true"
                            [showClearButton]="false"
                            [matchModeOptions]="matchModeOptions"
                            [matchMode]="'custom-equals'"                                        
                            type="text"
                            display="menu"
                        >
                            <ng-template pTemplate="header">
                                <div class="p-px-3 p-pt-3 p-pb-0">
                                <span class="p-text-bold">Assignee</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="genreList" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                                    <ng-template let-option pTemplate="item">
                                        <div class="p-multiselect-representative-option">
                                            <span class="p-ml-1">{{option.name}}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>  -->

                            <!-- https://stackblitz.com/edit/github-mmzi8y-peatrj?file=src%2Fapp%2Fapp.component.html,src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.module.ts -->
                        <!-- </ng-template> --> 
                        <!-- <p-columnFilter
                            field="items"
                            [showMenu]="true"
                            [showClearButton]="false"
                            [matchMode]="'multi-select-in'"
                            type="text"
                            display="menu"
                        > -->
                            <!-- <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect
                                    [ngModel]="value"
                                    [options]="genreList"
                                    placeholder="Any"
                                    (onChange)="filter($event.value)"
                                >
                                </p-multiSelect>
                            </ng-template> -->
                        <!-- </p-columnFilter> -->


                         </th>

                    <th id="receipt" name="receipt" pResizableColumn pReorderableColumn pSortableColumn="receipt" [class]="includesCol('receipt') ? 'd' : 'h'">Receipt
                        <p-sortIcon field="receipt"></p-sortIcon> 
                        <p-columnFilter type="text" field="receipt" display="menu"></p-columnFilter>
                    </th>
                    <th id="receiver" name="receiver" pResizableColumn pReorderableColumn pSortableColumn="receiver" [class]="includesCol('receiver') ? 'd' : 'h'">Receiver 
                        <p-sortIcon field="receiver"></p-sortIcon> 
                        <p-columnFilter type="text" field="receiver" display="menu"></p-columnFilter>
                        <!-- <p-columnFilter field="maxCapacity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="maxActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{maxActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{maxActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter> -->
                    </th>
                    
                    
                    <th id="provider" name="provider" pResizableColumn pReorderableColumn pSortableColumn="provider" [class]="includesCol('provider') ? 'd' : 'h'">Provider 
                        <p-sortIcon field="unit"></p-sortIcon> 
                        <p-columnFilter type="text" field="unit" display="menu"></p-columnFilter>
                        
                        <!-- <img alt="Create provider" src="/assets/images/unit.png" style="margin-left: 10px;width: 24px; vertical-align: middle;" [routerLink]="['/manage/units']"/> -->
                    </th>
                    <th id="deliveryMan" name="deliveryMan" pResizableColumn pReorderableColumn pSortableColumn="deliveryMan" [class]="includesCol('deliveryMan') ? 'd' : 'h'">Delivery man 
                        <p-sortIcon field="deliveryMan"></p-sortIcon> 
                        <p-columnFilter type="text" field="deliveryMan" display="menu"></p-columnFilter>
                        <!-- <p-columnFilter field="deliveryMan" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [ngModel]="minActivityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-center px-2">
                                    <span>{{minActivityValues[0]}}</span> &lt; capacity &lt;
                                    <span>{{minActivityValues[1]}}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter> -->
                    </th>
                    
                   
                    <!-- <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('isEnabled') ? 'd' : 'h'">Enabled
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th> -->
                    <th pResizableColumn pReorderableColumn style="width: 8rem; vertical-align: middle;">
                        <div class="actions-th">
                            <span>Actions&nbsp;</span> 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                        </div>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-reception let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >

                    <td pEditableColumn [class]="includesCol('instant') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <span >{{reception.datetime | date: 'dd/MM/yyyy HH:mm' }}</span>
                    </td>

                    <td pEditableColumn [class]="includesCol('woreceipt') ? 'd' : 'h'">
                        <span style="font-size: 12px"><b>{{reception.woreceipt}}</b></span>
                    </td>
                    
                    <td pEditableColumn [class]="includesCol('items') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <div *ngFor="let item of receptionItems(reception.items)">
                            <p-tag styleClass="mr-2" >
                                {{item.name}} {{ item.description ? '-' : '' }} {{item.description}} 
                            </p-tag>
                            <div style="height: 5px"></div>
                        </div>
                    </td>

                    <td pEditableColumn [class]="includesCol('receipt') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="reception.receipt" (blur)="completeReception(reception)" (keydown.tab)="completeReception(reception)" (keydown.enter)="completeReception(reception)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{reception.receipt}}</span>
                            </ng-template>
                        </p-cellEditor> -->
                        <span >{{reception.receipt}}</span>
                    </td>

                    <td pEditableColumn [class]="includesCol('receiver') ? 'd' : 'h'">
                        <!-- <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="reception.receiver" (blur)="completeReception(reception)" (keydown.tab)="completeReception(reception)" (keydown.enter)="completeReception(reception)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{reception.receiver}}</span>
                            </ng-template>
                        </p-cellEditor> -->
                        <span >{{reception.receiver}}</span>
                    </td>

                    <td pEditableColumn [class]="includesCol('provider') ? 'd' : 'h'">
                        <span >{{reception.provider}}</span>
                    </td>

                    <td pEditableColumn [class]="includesCol('deliveryMan') ? 'd' : 'h'">
                        <span >{{reception.deliveryMan}}</span>
                    </td>

                    <td class="row-actions">
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <!-- <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editReception(reception)"></button> -->
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteReception(reception)"></button>
                        <p-tableCheckbox class="checkbox" [value]="reception"></p-tableCheckbox>
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="reception"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{receptions ? receptions.length : 0 }} reception{{ receptions.length > 1? 's' : '' }}
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
            <!-- <p-column field="items" filterFunction="customFilter3"></p-column> -->
        </p-table>

    </div>

    <div class="page-footer">
       
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
            
    </div>
    
    <p-dialog [(visible)]="receptionDialog" [style]="{width: '450px', height: '450px'}" header="Packaging Type Details" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                <label for="woreceipt">Wo Receipt</label>
                <input type="text" pInputText id="woreceipt" [(ngModel)]="reception.woreceipt" required autofocus />
                <small class="p-error" *ngIf="submitted && !reception.woreceipt">Name is required.</small>
            </div>

            <!-- <div style="margin-top: 20px;">
                <b>Capacity</b>
            </div>
            <div style="flex-direction: row; display: flex;">
                <label for="minCapacity" style="margin-left: 20px;margin-right: 10px;">Min</label>
                <input type="text" id="minCapacity" pInputText style="width: 50px" [(ngModel)]="reception.minCapacity" />

                <span style="width: 50px;"></span>

                <label for="maxCapacity" style="margin-left: 20px;margin-right: 10px;">Max</label>
                <input type="text" id="maxCapacity" pInputText style="width: 50px"   [(ngModel)]="reception.maxCapacity" />

                <span style="margin-left: 20px;margin-right: 10px;">Units</span>
                <p-dropdown 
                    [style]="{'width':'100px'}"
                    autoWidth="false"
                    appendTo="body" 
                    [filter]="true"
                    [showClear]="false" 
                    [(ngModel)]="reception.unit" 
                    [options]="units" 
                    optionLabel="name" 
                    optionValue="name"
                    placeholder="Any">
                </p-dropdown>
            </div> -->

            <div style="flex-direction: row; display: flex ">
                
            </div>
                   

        <!-- https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643 -->
        
        <!-- <ng-template pTemplate="footer"> -->
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveReception()"></button>
        <!-- </ng-template> -->
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

</div>





