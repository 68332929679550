import { Injectable } from '@angular/core';
import { Offer } from '../interfaces/offer';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OffersCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = '/environments/'+ environment.name + '/offers/' + environment.saas +'/' ;
  }


  // Fetch Single Wine Object
  // getOrganisation() : string | null {
  //   const organisation = JSON.parse(localStorage.getItem('organisation')!);
  //   if (organisation && typeof organisation.$key !== 'undefined') {
  //       return organisation.$key;
  //   }
  //   return null;
  // }
  // getServices(offerKey: string) {
  //   this.ref = this.db.object( this.path + offerKey);
  //   return this.ref;
  // }


  // Create Wine
  add(record: Offer) {
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single Wine Object
  get(id: string) {
    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch Wines List
  getList() {
    console.log("list"+ this.path);
    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update Wine Object
  update(record: Offer) {
    this.ref = this.db.object( this.path + record.$key);
    let item : Partial<Offer>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Wine Object
  delete(id: string) {
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}
