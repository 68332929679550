
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedWoProductTypes()" [disabled]="!selectedWoProductTypes || !selectedWoProductTypes.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="woProductTypes" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['name','receivable','dryable','dried','transformable','transformed','soldable','isEnabled','code']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedWoProductTypes" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="receivable" name="receivable" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('receivable') ? 'd' : 'h'">Rec.
                        <p-columnFilter type="boolean" field="receivable" display="menu"></p-columnFilter>
                    </th>
                    <th id="dryable" name="dryable" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('dryable') ? 'd' : 'h'">Dry
                        <p-columnFilter type="boolean" field="dryable" display="menu"></p-columnFilter>
                    </th>
                    <th id="dried" name="dried" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('dried') ? 'd' : 'h'">Dried
                        <p-columnFilter type="boolean" field="dried" display="menu"></p-columnFilter>
                    </th>
                    <th id="transformable" name="transformable" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('transformable') ? 'd' : 'h'">Transf.
                        <p-columnFilter type="boolean" field="transformable" display="menu"></p-columnFilter>
                    </th>
                    <th id="transformed" name="transformed" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('transformed') ? 'd' : 'h'">Transf.ed
                        <p-columnFilter type="boolean" field="transformed" display="menu"></p-columnFilter>
                    </th>
                    <th id="soldable" name="soldable" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('soldable') ? 'd' : 'h'">Sales
                        <p-columnFilter type="boolean" field="soldable" display="menu"></p-columnFilter>
                    </th>
                    <th id="code" name="code" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('code') ? 'd' : 'h'">Code
                        <p-columnFilter type="text" field="code" display="menu"></p-columnFilter>
                    </th>
                    <th id="lastCode" name="lastCode" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('lastCode') ? 'd' : 'h'">Last Code
                        <p-columnFilter type="text" field="lastCode" display="menu"></p-columnFilter>
                    </th>
                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn style="width: 8rem" [class]="includesCol('isEnabled') ? 'd' : 'h'">Enabled
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem; vertical-align: middle;">
                        <div class="actions-th">
                            <span>Actions&nbsp;</span> 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                        </div>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-woProductType let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                    (ngModelChange)="modelChangeFn($event, woProductType)"
                                    [ngModel]="woProductType.position" 
                                    (blur)="completePosition(woProductType)" 
                                    (keydown.tab)="completePosition(woProductType)" 
                                    (keydown.enter)="completePosition(woProductType)"  
                                    required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{woProductType.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                        <!-- <p-tableCheckbox [value]="woProductType"></p-tableCheckbox> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="woProductType.name" (blur)="completeWoProductType(woProductType)" (keydown.tab)="completeWoProductType(woProductType)" (keydown.enter)="completeWoProductType(woProductType)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{woProductType.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('receivable') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="woProductType.receivable" (onChange)="completeWoProductType(woProductType)"></p-inputSwitch>
                    </td>
                    <td pEditableColumn [class]="includesCol('dryable') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="woProductType.dryable" (onChange)="completeWoProductType(woProductType)"></p-inputSwitch>
                    </td>
                    <td pEditableColumn [class]="includesCol('dried') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="woProductType.dried" (onChange)="completeWoProductType(woProductType)"></p-inputSwitch>
                    </td>
                    <td pEditableColumn [class]="includesCol('transformable') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="woProductType.transformable" (onChange)="completeWoProductType(woProductType)"></p-inputSwitch>
                    </td>
                    <td pEditableColumn [class]="includesCol('transformed') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="woProductType.transformed" (onChange)="completeWoProductType(woProductType)"></p-inputSwitch>
                    </td>
                    <td pEditableColumn [class]="includesCol('soldable') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="woProductType.soldable" (onChange)="completeWoProductType(woProductType)"></p-inputSwitch>
                    </td>

                    <td pEditableColumn [class]="includesCol('code') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="woProductType.code" (blur)="completeWoProductType(woProductType)" (keydown.tab)="completeWoProductType(woProductType)" (keydown.enter)="completeWoProductType(woProductType)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{woProductType.code}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>     
                    
                    <td pEditableColumn [class]="includesCol('lastCode') ? 'd' : 'h'">
                       
                                <span >{{woProductType.lastCode}}</span>
                         
                    </td>   
                    
                    <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="woProductType.isEnabled" (onChange)="completeWoProductType(woProductType)"></p-inputSwitch>
                        <!-- <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="woProductType.isEnabled"  (blur)="completeWoProductType(woProductType)" (keydown.tab)="completeWoProductType(woProductType)" (keydown.enter)="completeWoProductType(woProductType)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{woProductType.isEnabled}}</span>
                            </ng-template>
                        </p-cellEditor> -->
                    </td>


                    <!-- <td>{{woProductType.name}}</td> -->
                    <!-- <td>nicolas</td> -->
                    <!-- <td><img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.name" width="100" class="p-shadow-4" /></td> -->
                    <!-- <td>{{product.price | currency:'USD'}}</td>
                    <td>{{product.category}}</td>
                    <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                    <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                    <td class="row-actions">
                        <a *ngIf="woProductType.dried && modules.dry" (click)="editWoProductTypeDrying(woProductType)">
                            <div class="b" > <img style="color: white; width: 20px; height: 20px;" src="/assets/images/dryer.png" /></div> 
                        </a>
                        <a *ngIf="!woProductType.dried && modules.dry">
                            <div class="b disabled" > <img style="color: white; width: 20px; height: 20px;" src="/assets/images/dryer.png" /></div> 
                        </a>
                            
                        <!-- <button title="Drying" *ngIf="woProductType.dried" pButton style="margin-right: 10px"  pRipple icon="pi pi-filter" class="p-button-rounded p-button-success p-mr-2" (click)="editWoProductTypeDrying(woProductType)"></button> -->
                        <button title="Recipe" *ngIf="woProductType.transformed && modules.transform" pButton style="margin-right: 10px"  pRipple icon="pi pi-file-o" class="p-button-rounded p-button-success p-mr-2" (click)="editWoProductTypeCreation(woProductType)"></button>
                        <button title="Recipe is disabled" style="visibility: hidden;background-color: #b7b7b7;" *ngIf="!woProductType.transformed && modules.transform" pButton style="margin-right: 10px"  pRipple icon="pi pi-file-o" class="p-button-rounded  p-mr-2 disabled" ></button>
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editWoProductType(woProductType)"></button>
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteWoProductType(woProductType)"></button>
                        <p-tableCheckbox class="checkbox" [value]="woProductType"></p-tableCheckbox>
                        
                    </td>
                    <!-- <td>
                        <p-tableCheckbox [value]="woProductType"></p-tableCheckbox>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{woProductTypes ? woProductTypes.length : 0 }} product type{{woProductTypes.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
    </div>
    
    <p-dialog [(visible)]="woProductTypeDialog" [style]="{width: '450px', height: '540px'}" header="Product Type - Add or Update" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                <label for="name">Name</label>
                <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="woProductType.name" required autofocus />
                <small class="p-error" *ngIf="submitted && !woProductType.name">Name is required.</small>
            </div>

            <div class="p-field">
                <label for="description">Description</label>
                <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="woProductType.isEnabled" rows="3" cols="20"></textarea>
            </div>

            <div class="in-out">
                <div class="p-field" *ngIf="modules.receive">
                    <label for="receivable">Can be received</label>
                    <p-inputSwitch style="vertical-align: middle; margin-left: 10px;" id="receivable" [(ngModel)]="woProductType.receivable"></p-inputSwitch>
                </div>
                <div class="p-field" *ngIf="modules.dry">
                    <label style="vertical-align: middle;" for="dryable">Can be dried</label>
                    <p-inputSwitch style="vertical-align: middle; margin-left: 10px;" id="dryable" [(ngModel)]="woProductType.dryable"></p-inputSwitch>
                </div>
            </div>

            <div class="in-out">
                <div class="p-field" *ngIf="modules.transform">
                    <label for="transformable">Can be transformed</label>
                    <p-inputSwitch style="vertical-align: middle; margin-left: 10px;" id="transformable" [(ngModel)]="woProductType.transformable"></p-inputSwitch>
                </div>
                <div class="p-field">
                    <label style="vertical-align: middle;" for="soldable">Can be sold</label>
                    <p-inputSwitch style="vertical-align: middle; margin-left: 10px;" id="dryable" [(ngModel)]="woProductType.soldable"></p-inputSwitch>
                </div>
            </div>

            <div class="in-out">
                <div class="p-field" *ngIf="modules.dry">
                    <label for="transformable">Dried</label>
                    <p-inputSwitch 
                        style="vertical-align: middle; margin-left: 10px;" 
                        id="transformed" 
                        [(ngModel)]="woProductType.dried">
                    </p-inputSwitch>
                </div>
                <div class="p-field" *ngIf="modules.transform">
                    <label for="transformable">Transformed</label>
                    <p-inputSwitch 
                        style="vertical-align: middle; margin-left: 10px;" 
                        id="transformed" 
                        [(ngModel)]="woProductType.transformed">
                    </p-inputSwitch>
                </div>
            </div>

            <div class="in-out" style="margin-top: 20px;">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveWoProductType()"></button>
            </div>
            

        <!-- https://dev.to/idrisrampurawala/exporting-data-to-excel-and-csv-in-angular-3643 -->
        
        <!-- <ng-template pTemplate="footer"> -->
           
        <!-- </ng-template> -->
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>




    <p-dialog [(visible)]="woProductTypeDryDialog" [style]="{width: '650px', height: '600px'}" header="Product Type - Drying" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field">
                Name: {{ woProductType.name }}
        </div>
        <br/>
        <div class="p-field">
            <p-pickList #pl 
                [source]="listDry1" 
                [target]="listDry2" 
                [dragdrop]="true"
                sourceHeader="Available" 
                targetHeader="Selected" 
                [responsive]="true" 
                filterBy="name,batch,location"
                (onMoveToTarget)="moveToTargetDry($event)"
                (onMoveAllToTarget)="moveToTargetDry($event)"
                (onMoveToSource)="moveToSource($event)"
                (onMoveAllToSource)="moveToSource($event)"
                (onSourceReorder)="sourceReorder($event)"
                sourceFilterPlaceholder="Search" 
                targetFilterPlaceholder="Search"
            >
                <ng-template let-receipt pTemplate="item">
                    <div>
                        <!-- <img src="assets/showcase/images/demo/car/{{car.brand}}.png" style="display:inline-block;margin:2px 0 2px 2px" width="48"> -->
                        <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{receipt.name}}</div>
                    </div>
                </ng-template>
            </p-pickList>
        </div>
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDryDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveWoProductTypeDrying()"></button>
        </div>   
    </p-dialog>








    <p-dialog [(visible)]="woProductTypeCreationDialog" [style]="{width: '650px', height: '600px'}" header="Product Type - Creation / Recipe" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <ng-template > -->
            <!-- <img [src]="'assets/showcase/images/demo/product/' + product.image" [alt]="product.image" class="product-image" *ngIf="product.image"> -->
            <div class="p-field">
                Name: {{ woProductType.name }}
                <!-- <label for="name">Name</label>
                <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="woProductType.name"  />
                <small class="p-error" *ngIf="submitted && !woProductType.name">Name is required.</small> -->
            </div>
            <br/>
            <div class="p-field" >
                <p-pickList #pl 
                    styleClass="picklist"
                    [source]="list1" 
                    [target]="list2" 
                    [dragdrop]="true"
                    sourceHeader="Available" 
                    targetHeader="Selected" 
                    [responsive]="true" 
                    filterBy="name,batch,location"
                    (onMoveToTarget)="moveToTarget($event)"
                    (onMoveAllToTarget)="moveToTarget($event)"
                    (onMoveToSource)="moveToSource($event)"
                    (onMoveAllToSource)="moveToSource($event)"
                    (onSourceReorder)="sourceReorder($event)"
                    sourceFilterPlaceholder="Search" 
                    targetFilterPlaceholder="Search"
            >
            
            <ng-template let-receipt pTemplate="item">
                <div>
                    <!-- <img src="assets/showcase/images/demo/car/{{car.brand}}.png" style="display:inline-block;margin:2px 0 2px 2px" width="48"> -->
                    <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{receipt.name}}</div>
                </div>
            </ng-template>
           
        </p-pickList>
            </div>

            

            <div class="in-out" style="margin-top: 20px;">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideCreationDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveWoProductTypeCreation()"></button>
            </div>
            
    </p-dialog>

</div>






