import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable, of } from 'rxjs';
import { Offer } from 'src/app/shared/interfaces/offer';
import { LocalStorageBService } from 'src/app/shared/services/local-storage-b.service';
import { OffersCrudService } from 'src/app/shared/services/offers-crud.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public modules = environment.modules;
  public saas = environment.saas;

  public offersArr : Offer[] = [];
  public offersMap = new Map<string,Offer>();
  public offersMap$: Observable<Map<string,Offer>> = of(this.offersMap);

  @Input() sidenav!: MatSidenav ;

  constructor(
    public localStorageService: LocalStorageBService,
    private offersService: OffersCrudService,
  ) { 
    this.localStorageService.loadOrganisation();
  }

  ngOnInit(): void {

    let of = this.offersService.getList()
    of.snapshotChanges().subscribe(data => {
      this.offersArr = [];
      this.offersMap.clear();
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Offer = jsonItem as Offer;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          // console.log(l);
          this.offersArr.push(l);
          console.log('PUSH KEY', l.$key);
          console.log('PUSH VAL', l);
          this.offersMap.set( 'key'+l.$key, l);
        }
      })
    })

  }

}
