<div class="form1">
    <form>
   <div class="row">
   <div class="fullName">
   <div class="firstName">
   <div class="col-md-6">
   <div class="form-group">
   <label for="firstname">Company Name</label>
   <input type="text" class="form-control" id="firstname" [(ngModel)]="companyName" name="companyName"/>
   </div>
   </div>
   </div>
   <div class="lastname">
    <div class="form-group">
<div class="col-md-6">
    <label for="lastname">ID</label><input type="text" class="form-control" id="lastname" [(ngModel)]="companyId" name="companyId"/>
    </div>
   </div>
    </div>
    </div>
    </div>
   
    <div class="form-group">
    <label for="email">Email:</label>
    <input type="email" class="form-control" id="email" [(ngModel)]="email" name="email"/>
    </div>
   
    <div class="form-group">
   <label for="mobile">Company Description</label>
   <input type="mobile" class="form-control" id="mobile" [(ngModel)]="companyDescription" name="companyDescription"/>
    </div>
   
   <button type="submit" class="btn btn-primary">Submit</button>
    </form>
   </div>
   
   <div class="plan-wrapper"> 
       <div *ngIf="priceId === 100" class="plan basic-plan">
       <h3>Checklists and Audits</h3>
       <p class="price">$100/month</p>
       <ul>
       <li>Phone Calls and alerts (emergency, responsible clients)</li>
       <li>Reporting</li>
       <li>Photos</li>
       <li>Voice Message</li>
       <li>Checklist</li>
       <li>Audits</li>
       <li>QR Code Scan</li>
       </ul>
       <div class="button-wrapper">
           <button class="button2" (click)="accessChangePlan()">Change</button>
           <button class="button3" (click)="cancelPlan()">Cancel</button>
       </div>
      </div>
      
       <div *ngIf="priceId === 200"  class="plan gold-plan">
       <h3>Reception & Movements</h3>
       <p class="price">$200/month</p>
       <ul>
       <li>Dashboard</li>
       <li>Stock alert</li>
       <li>Receipt tracking</li>
       <li>Photo</li>
       <li>Voice Message</li>
       <li>Product transformation</li> <li>Item and batch tracking</li>
       <li>Location tracking</li>
       <li>QR code scan</li>
       <li>Product transformation</li>
       </ul>
       <div class="button-wrapper">
           <button class="button2" (click)="accessChangePlan()">Change</button>
           <button class="button3" (click)="cancelPlan()">Cancel</button>
       </div>
       </div>
   </div>
