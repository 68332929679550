
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedTunaProcesses()" [disabled]="!selectedTunaProcesses || !selectedTunaProcesses.length "></button>
        </div> 
    </div>

    <div class="page-content">

        <div #calendarWrapper></div>

        <p-table #dt 
            [value]="tunaProcesses"                                                                                                      
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['position','qrcode','name','description','tags','status','filledAt','emptiedAt','fishWeightAverage','fishNumber','fishMortality','harvestName','cageName','fridgeName','customerName', 'customerMarket', 'fishStatus','customerUnitPrice']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedTunaProcesses" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="qrcode" style="width: 8rem" name="qrcode" pResizableColumn pReorderableColumn pSortableColumn="qrcode" [class]="includesCol('qrcode') ? 'd' : 'h'">QRCode 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> 
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Process Id 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>

                    <th id="harvestName" name="harvestName" pResizableColumn pReorderableColumn pSortableColumn="harvestName" [class]="includesCol('harvestName') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_fishboat.png" width="24px" height="24px" [routerLink]="['/fish-management/harvests']"/> 
                        Harvest 
                        <p-sortIcon field="harvestName"></p-sortIcon> 
                        <p-columnFilter type="text" field="harvestName" display="menu"></p-columnFilter>
                    </th>

                    <th id="cageName" name="cageName" pResizableColumn pReorderableColumn pSortableColumn="cageName" [class]="includesCol('cageName') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_cage.png" width="24px" height="24px" [routerLink]="['/fish-management/cages']"/> 
                        Cage
                        <p-sortIcon field="cageName"></p-sortIcon> 
                        <p-columnFilter type="text" field="cageName" display="menu"></p-columnFilter>
                    </th>

                    <th id="fishWeight" name="fishWeight" pResizableColumn pReorderableColumn pSortableColumn="fishWeight" [class]="includesCol('fishWeight') ? 'd' : 'h'">
                        Fish Total Weight
                        <p-sortIcon field="fishWeight"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="fishWeight" display="menu"></p-columnFilter>
                    </th>

                    <th id="fishAverageWeight" name="fishAverageWeight" pResizableColumn pReorderableColumn pSortableColumn="fishAverageWeight" [class]="includesCol('fishAverageWeight') ? 'd' : 'h'">
                        Fish Weight (avg) 
                        <p-sortIcon field="fishAverageWeight"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="fishAverageWeight" display="menu"></p-columnFilter>
                    </th>

                    <th id="fishNumber" name="fishNumber" pResizableColumn pReorderableColumn pSortableColumn="fishNumber" [class]="includesCol('fishNumber') ? 'd' : 'h'">
                        Fish (nb) 
                        <p-sortIcon field="fishNumber"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="fishNumber" display="menu"></p-columnFilter>
                    </th>

                    <th id="fishMortality" name="fishMortality" pResizableColumn pReorderableColumn pSortableColumn="fishMortality" [class]="includesCol('fishMortality') ? 'd' : 'h'">
                        Fish Mortality (nb / %)
                        <p-sortIcon field="fishMortality"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="fishMortality" display="menu"></p-columnFilter>
                    </th>

                    <th id="fishLocation" name="fishLocation" pResizableColumn pReorderableColumn pSortableColumn="fishLocation" [class]="includesCol('fishLocation') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_fridge.png" width="24px" height="24px" [routerLink]="['/fish-management/fridges']"/> 
                        Freezer
                        <p-sortIcon field="fishLocation"></p-sortIcon> 
                        <p-columnFilter type="text" field="fishLocation" display="menu"></p-columnFilter>
                    </th>

                    <th id="fishStatus" name="fishStatus" pResizableColumn pReorderableColumn pSortableColumn="nafishStatusme" [class]="includesCol('fishStatus') ? 'd' : 'h'">
                        <!-- <img style="vertical-align: middle;" src="assets/images-black/location.png" width="24px" height="24px" [routerLink]="['/fish-management/status']"/>  -->
                        Fish Status
                        <p-sortIcon field="fishStatus"></p-sortIcon> 
                        <p-columnFilter type="text" field="fishStatus" display="menu"></p-columnFilter>
                    </th>

                    <th id="customerMarket" name="customerMarket" pResizableColumn pReorderableColumn pSortableColumn="customerMarket" [class]="includesCol('customerMarket') ? 'd' : 'h'">
                        <!-- <img style="vertical-align: middle;" src="assets/images-black/location.png" width="24px" height="24px" [routerLink]="['/fish-management/markets']"/>  -->
                        Markets
                        <p-sortIcon field="customerMarket"></p-sortIcon> 
                        <p-columnFilter type="text" field="customerMarket" display="menu"></p-columnFilter>
                    </th>

                    <th id="customerName" name="customerName" pResizableColumn pReorderableColumn pSortableColumn="customerName" [class]="includesCol('customerName') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/location.png" width="24px" height="24px" [routerLink]="['/fish-management/clients']"/> 
                        Customers
                        <p-sortIcon field="customerName"></p-sortIcon> 
                        <p-columnFilter type="text" field="customerName" display="menu"></p-columnFilter>
                    </th>

                    <th id="customerUnitPrice" name="customerUnitPrice" pResizableColumn pReorderableColumn pSortableColumn="customerUnitPrice" [class]="includesCol('customerUnitPrice') ? 'show' : 'hide'">
                        Unit price
                        <p-sortIcon field="customerUnitPrice"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="customerUnitPrice" display="menu"></p-columnFilter>
                    </th>

                    <th id="customerIsInvoiced" name="customerIsInvoiced" pResizableColumn pReorderableColumn pSortableColumn="customerIsInvoiced" style="width: 10rem" [class]="includesCol('customerIsInvoiced') ? 'show' : 'hide'">
                        Invoiced
                        <p-sortIcon field="customerIsInvoiced"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="customerIsInvoiced" display="menu"></p-columnFilter>
                    </th>

                   
                    <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description
                        <p-sortIcon field="description"></p-sortIcon> 
                        <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                    </th>
                    <th id="tags" name="tags" pResizableColumn pReorderableColumn [class]="includesCol('tags') ? 'd' : 'h'">Tags
                        <p-sortIcon field="tags"></p-sortIcon> 
                        <p-columnFilter type="text" field="tags" display="menu"></p-columnFilter>
                    </th>

                    

                    <th id="filledAt" name="filledAt" pResizableColumn pReorderableColumn pSortableColumn="filledAt" [class]="includesCol('filledAt') ? 'd' : 'h'">
                        Filled at
                        <p-sortIcon field="filledAt"></p-sortIcon> 
                        <p-columnFilter type="datetime" field="filledAt" display="menu"></p-columnFilter>
                    </th>

                    <th id="emptiedAt" name="emptiedAt" pResizableColumn pReorderableColumn pSortableColumn="emptiedAt" [class]="includesCol('emptiedAt') ? 'd' : 'h'">
                        Emptied at
                        <p-sortIcon field="emptiedAt"></p-sortIcon> 
                        <p-columnFilter type="datetime" field="emptiedAt" display="menu"></p-columnFilter>
                    </th>

                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('isEnabled') ? 'show' : 'hide'">
                        Enabled
                        <p-sortIcon field="isEnabled"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions 
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tunaProcess let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" [pReorderableRowHandle]="index" style="margin-right: 1rem;"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                (ngModelChange)="modelChangeFn($event, tunaProcess)"
                                [ngModel]="tunaProcess.position" 
                                (blur)="completePosition(tunaProcess)" 
                                (keydown.tab)="completePosition(tunaProcess)" 
                                (keydown.enter)="completePosition(tunaProcess)"  
                                required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{tunaProcess.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('qrcode') ? 'd' : 'h'">
                        <qrcode [qrdata]="tunaProcess.name" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="tunaProcess.name" 
                                (blur)="completeTunaProcess(tunaProcess)" 
                                (keydown.tab)="completeTunaProcess(tunaProcess)" 
                                (keydown.enter)="completeTunaProcess(tunaProcess)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{tunaProcess.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    

                    <!-- (blur)="completeTunaProcess(tunaProcess)" 
                    (keydown.tab)="completeTunaProcess(tunaProcess)" 
                    (keydown.enter)="completeTunaProcess(tunaProcess)"  -->

                    <!-- (blur)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                    (keydown.tab)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                    (keydown.enter)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)"  -->


                    <td pEditableColumn [class]="includesCol('harvestName') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <!-- <input pInputText type="text" [(ngModel)]="tunaProcess.harvestName"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                                (blur)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.tab)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.enter)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                               (onChange)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)"
                                 
                                -->
                                 <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [ngModel]="tunaProcess.harvestKey" 
                                    [options]="harvests" 
                                    optionLabel="name" 
                                    optionValue="$key"  
                                    (onChange)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)"
                                    (blur)="completeTunaProcess(tunaProcess)" 
                                    (keydown.tab)="completeTunaProcess(tunaProcess)" 
                                    (keydown.enter)="completeTunaProcess(tunaProcess)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <!-- <span >{{ tunaProcess.harvestKey }}</span>
                                <span >{{ tunaProcess.harvestName }}</span> -->
                                <span >{{displayHarvestName(tunaProcess.harvestKey)}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('cageName') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <!-- <input 
                                pInputText type="text" 
                                [(ngModel)]="tunaProcess.cageName" 
                                (blur)="completeTunaProcess(tunaProcess)" 
                                (keydown.tab)="completeTunaProcess(tunaProcess)" 
                                (keydown.enter)="completeTunaProcess(tunaProcess)" 
                                 required> -->


                                 <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [ngModel]="tunaProcess.cageKey" 
                                    [options]="cages" 
                                    optionLabel="name" 
                                    optionValue="$key"  
                                    (onChange)="onChangeCage(tunaProcess, tunaProcess.cageKey, $event)"
                                    (blur)="completeTunaProcess(tunaProcess)" 
                                    (keydown.tab)="completeTunaProcess(tunaProcess)" 
                                    (keydown.enter)="completeTunaProcess(tunaProcess)" 
                                    placeholder="Any">
                                </p-dropdown>

                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{tunaProcess.cageName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('fishWeight') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="tunaProcess.fishWeight"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tunaProcess.fishWeight}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('fishAverageWeight') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="tunaProcess.fishAverageWeight"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tunaProcess.fishAverageWeight}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('fishNumber') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="tunaProcess.fishNumber"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tunaProcess.fishNumber}}</span>

                                <!-- <br>
                                <br> 1000 disposal
                                <br> 2500 reserved toto
                                <br> 2500 reserved titi -->
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    
                    <td pEditableColumn [class]="includesCol('fishMortality') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="number" [(ngModel)]="tunaProcess.fishMortality"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tunaProcess.fishMortality}} </span>
                                <span *ngIf="tunaProcess.fishNumber && tunaProcess.fishMortality">({{ tunaProcess.fishMortality / tunaProcess.fishNumber }} %)</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>


                    <td pEditableColumn [class]="includesCol('fishLocation') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <!-- <input pInputText type="text" [(ngModel)]="tunaProcess.harvestName"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                                (blur)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.tab)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.enter)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                               (onChange)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)"
                                 
                                -->
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [ngModel]="tunaProcess.fridgeKey" 
                                    [options]="fridges" 
                                    optionLabel="name" 
                                    optionValue="$key"  
                                    (onChange)="onChangeFridge(tunaProcess, tunaProcess.fridgeKey, $event)"
                                    (blur)="completeTunaProcess(tunaProcess)" 
                                    (keydown.tab)="completeTunaProcess(tunaProcess)" 
                                    (keydown.enter)="completeTunaProcess(tunaProcess)" 
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <!-- <span >{{ tunaProcess.harvestKey }}</span>
                                <span >{{ tunaProcess.harvestName }}</span> -->
                                <span >{{ tunaProcess.fridgeName }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('fishStatus') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <!-- <input pInputText type="text" [(ngModel)]="tunaProcess.harvestName"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                                (blur)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.tab)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.enter)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                               (onChange)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)"
                                 
                                -->
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="tunaProcess.status" 
                                    [options]="status" 
                                    (blur)="completeTunaProcess(tunaProcess)" 
                                    (keydown.tab)="completeTunaProcess(tunaProcess)" 
                                    (keydown.enter)="completeTunaProcess(tunaProcess)" 
                                    placeholder="Any">
                                </p-dropdown>

                            </ng-template>
                            <ng-template pTemplate="output">
                                <!-- <span >{{ tunaProcess.harvestKey }}</span>
                                <span >{{ tunaProcess.harvestName }}</span> -->
                                <span >{{ tunaProcess.status }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('customerMarket') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <!-- <input pInputText type="text" [(ngModel)]="tunaProcess.harvestName"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                                (blur)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.tab)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.enter)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                               (onChange)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)"
                                 
                                -->
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="tunaProcess.market" 
                                    [options]="markets" 
                                    (blur)="completeTunaProcess(tunaProcess)" 
                                    (keydown.tab)="completeTunaProcess(tunaProcess)" 
                                    (keydown.enter)="completeTunaProcess(tunaProcess)" 
                                    placeholder="Any">
                                </p-dropdown>
                    
                            </ng-template>
                            <ng-template pTemplate="output">
                                <!-- <span >{{ tunaProcess.harvestKey }}</span>
                                <span >{{ tunaProcess.harvestName }}</span> -->
                                <span >{{tunaProcess.market}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('customerName') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <!-- <input pInputText type="text" [(ngModel)]="tunaProcess.harvestName"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                                (blur)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.tab)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                                 (keydown.enter)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)" 
                               (onChange)="onChangeHarvest(tunaProcess, tunaProcess.harvestKey, $event)"
                                 
                                -->
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [ngModel]="tunaProcess.customerKey" 
                                    [options]="clients" 
                                    optionLabel="companyName" 
                                    optionValue="$key"  
                                    (onChange)="onChangeCustomer(tunaProcess, tunaProcess.customerKey, $event)"
                                    (blur)="completeTunaProcess(tunaProcess)" 
                                    (keydown.tab)="completeTunaProcess(tunaProcess)" 
                                    (keydown.enter)="completeTunaProcess(tunaProcess)" 
                                    placeholder="Any">
                                </p-dropdown>
                                
                            </ng-template>
                            <ng-template pTemplate="output">
                                <!-- <span >{{ tunaProcess.harvestKey }}</span>
                                <span >{{ tunaProcess.harvestName }}</span> -->
                                <span >{{ tunaProcess.customerName }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('customerUnitPrice') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="number" [(ngModel)]="tunaProcess.customerUnitPrice" 
                                (blur)="completeTunaProcess(tunaProcess)" 
                                (keydown.tab)="completeTunaProcess(tunaProcess)" 
                                (keydown.enter)="completeTunaProcess(tunaProcess)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{tunaProcess.customerUnitPrice}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('customerIsInvoiced') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="tunaProcess.customerIsInvoiced" (onChange)="completeTunaProcess(tunaProcess)"></p-inputSwitch>
                    </td>

                    <!-- { field: 'fishLocation', header: 'Fish Location' },
                    { field: 'fishStatus', header: 'Fish status' },
              
                    { field: 'customerMarket', header: 'Market' },
                    { field: 'customerName', header: 'Customer' },
                    { field: 'customerIsInvoiced', header: 'IsInvoiced' },
 -->

                    <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="tunaProcess.description"  (blur)="completeTunaProcess(tunaProcess)" (keydown.tab)="completeTunaProcess(tunaProcess)" (keydown.enter)="completeTunaProcess(tunaProcess)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tunaProcess.description}}</span>
                            
                         
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('tags') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-chips  id="tags" [(ngModel)]="tunaProcess.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3" (onBlur)="completeTunaProcess(tunaProcess)" (onAdd)="completeTunaProcess(tunaProcess)" (onRemove)="completeTunaProcess(tunaProcess)"></p-chips>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="margin-right: 5px;" *ngFor="let tag of tunaProcess.tags">
                                    <p-tag >{{tag}}</p-tag>
                                </span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('filledAt') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                
                                    <p-calendar 
                                        [(ngModel)]="tunaProcess.filledAt"  
                                        [ngModelOptions]="{standalone: true}" 
                                        [showIcon]="true" 
                                        [showTime]="true" 
                                        [showSeconds]="true" 
                                        inputId="time" 
                                        [appendTo]="calendarWrapper"
                                        (onSelect)="onSelect($event,tunaProcess)" 
                                        (onBlur)="onBlur($event,tunaProcess)" 
                                        (onClose)="onClose($event,tunaProcess)"
                                      >
                                    </p-calendar>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tunaProcess.filledAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('emptiedAt') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                
                                    <p-calendar 
                                        [(ngModel)]="tunaProcess.emptiedAt"  
                                        [ngModelOptions]="{standalone: true}" 
                                        [showIcon]="true" 
                                        [showTime]="true" 
                                        [showSeconds]="true" 
                                        inputId="time" 
                                        [appendTo]="calendarWrapper"
                                        (onSelect)="onSelect($event,tunaProcess)" 
                                        (onBlur)="onBlur($event,tunaProcess)" 
                                        (onClose)="onClose($event,tunaProcess)"
                                      >
                                    </p-calendar>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{tunaProcess.emptiedAt | date: 'dd/MM/yyyy HH:mm' }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>


                    <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="tunaProcess.isEnabled" (onChange)="completeTunaProcess(tunaProcess)"></p-inputSwitch>
                    </td>

                    <td class="row-actions">
                        <!-- <button style="margin-right: 10px" pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-success p-mr-2" (click)="moveToFridge(tunaProcess)"></button> -->
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editTunaProcess(tunaProcess)"></button>
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteTunaProcess(tunaProcess)"></button>
                        <p-tableCheckbox class="checkbox" [value]="tunaProcess"></p-tableCheckbox>
                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{tunaProcesses ? tunaProcesses.length : 0 }} tuna process{{tunaProcesses.length > 1? 'es': ''}}
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV - Save the selected lines and columns" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS - Save all the lines and columns" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF - Save all the lines and columns" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
        <div class="p-d-flex" >
            Reports: <br/>
            - Invoiced: {{ getInvoicedAmount() }} AUD
            - Not invoiced: {{ getNotInvoicedAmount() }} AUD
            <!-- <ul>
                <li >test</li>
            </ul> -->
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>        
    </div>

    <!-- Dialog : confirm -->
    <p-confirmDialog [style]="{width: '450px'}">

        <ng-template pTemplate="header">
            <h3>Header Content</h3>
        </ng-template>
        <ng-template pTemplate="">
mess
        </ng-template>
        <!-- <ng-template pTemplate="footer">
            <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
            <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
        </ng-template> -->
        
    </p-confirmDialog>

     <!-- Dialog : Popup move -->
     <p-dialog [(visible)]="moveDialog" [style]="{width: '450px', height: '375px'}" header="Move to freezer" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        

        

        <div class="p-field" style="margin-top: 10px;">
            <label><b>From</b></label>
            <span> {{ tunaProcess.name }} </span>
        </div>

        

        <div class="p-field" style="margin-top: 20px;">
            <label><b>To</b></label>
            
            <label for="location">&nbsp;&nbsp; Freezer &nbsp;&nbsp; </label>
            <img style="vertical-align: middle;" src="assets/images-black/ic_fridge.png" width="24px" height="24px" [routerLink]="['/fish-management/fridges']"/> 
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="fridgeKey" 
                [options]="fridges" 
                optionLabel="name" 
                optionValue="$key"
                placeholder="Any">
            </p-dropdown>
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="quantity">Quantity</label>
            <input style="margin-top: 10px;" type="number" pInputText id="quantity" [(ngModel)]="quantity" required autofocus value="{{ tunaProcess.fishNumber }}"/>
            <small class="p-error" *ngIf="submitted && !tunaProcess.name">Quantity is required.</small>
        </div>


        <!-- <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="tunaProcess.description" rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="tags" >Tags</label>
            <div style="height: 10px;">&nbsp;</div>
            <p-chips  id="tags" [(ngModel)]="tunaProcess.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
        </div>    -->
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideMoveDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveTunaProcess()"></button>
        </div>
    </p-dialog>
    

    <!-- Dialog : Popup -->
    <p-dialog [(visible)]="tunaProcessDialog" [style]="{width: '450px', height: '450px'}" header="TunaProcess" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Process Id.</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="tunaProcess.name" required autofocus />
            <small class="p-error" *ngIf="submitted && !tunaProcess.name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="tunaProcess.description" rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="tags" >Tags</label>
            <div style="height: 10px;">&nbsp;</div>
            <p-chips  id="tags" [(ngModel)]="tunaProcess.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
        </div>   
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveTunaProcess()"></button>
        </div>
    </p-dialog>

    <!-- { field: 'harvestKey', header: 'Harvest' },
                    { field: 'fishAverageWeight', header: 'Weight (avg)' },
                    { field: 'fishNumber', header: 'Fish (nb)' },
                    { field: 'fishMortality', header: 'Mortality (nb / %)' },
                    { field: 'filledAt', header: 'Filled at' },
                    { field: 'emptiedAt', header: 'Emptied at' },
     -->
    

</div>






