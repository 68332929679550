import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { MyJoinedRequestCrudService } from 'src/app/shared/services/my-joined-request-crud.service';
import { MyJoinedRequest } from 'src/app/shared/interfaces/my-joined-request';
import { MyOrganisation } from '../shared/interfaces/my-organisation';
import { Organisation } from '../shared/interfaces/organisation';
import { OrganisationCrudService } from '../shared/services/organisation-crud.service';
import { MyOrganisationCrudService } from '../shared/services/my-organisation-crud.service';
import { JoinedRequestCrudService } from '../shared/services/joined-request-crud.service';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { JoinedRequest } from '../shared/interfaces/joined-request';


@Component({
  selector: 'app-my-joined-requests',
  templateUrl: './my-joined-requests.component.html',
  styleUrls: ['./my-joined-requests.component.scss']
})
export class MyJoinedRequestsComponent implements OnInit {

  userId : string = '';
  userId$: BehaviorSubject<string> = new BehaviorSubject<string>('undefined');


  myJoinedRequests: MyJoinedRequest[];
  myJoinedRequest: MyJoinedRequest;
  selectedMyJoinedRequests: MyJoinedRequest[] = [];

  myJoinedRequestDialog: boolean = false;

  submitted: boolean = false;

  myOrganisations: MyOrganisation[];
  organisations: Organisation[];
  organisationsMap: Map<string,Organisation>;


  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    public authService : AuthService,
    private myJoinedRequestService : MyJoinedRequestCrudService,
    private myOrganisationService : MyOrganisationCrudService,
    private joinedRequestService : JoinedRequestCrudService,
    private organisationService : OrganisationCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'My Joined Requests'}
    ];

    this.myJoinedRequests = [];
    this.myJoinedRequest = { } as MyJoinedRequest ;

    this.myOrganisations = [];
    this.organisations = [];
    this.organisationsMap = new Map<string,Organisation>();
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();

    this.authService.user$.subscribe( (user: any) => {
      if (user) {
        
        this.userId = user.uid;

        let s = this.myJoinedRequestService.getList( this.userId ); 
        s.snapshotChanges().subscribe(data => {
          this.myJoinedRequests = [];
          data.forEach(item => {
            let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
            if (jsonItem) {
              if (jsonItem) {
                jsonItem['$key'] = item.key;
              }
              let l : MyJoinedRequest = jsonItem as MyJoinedRequest;
              if (l.tags) {
                l.tags = Object.values(l.tags);
              }
              this.myJoinedRequests.push(l);
            }
          })
        });

      } else {
        this.userId = 'undef';
      }
    });

    let o = this.organisationService.getList(); 
    o.snapshotChanges().subscribe(data => {
      this.organisations = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Organisation = jsonItem as Organisation;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.organisations.push(l);
          this.organisationsMap.set(l.$key,l);
        }
      })
    });

    // let mo = this.myOrganisationService.getList(); 
    // mo.snapshotChanges().subscribe(data => {
    //   this.myOrganisations = [];
    //   data.forEach(item => {
    //     let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
    //     if (jsonItem) {
    //       if (jsonItem) {
    //         jsonItem['$key'] = item.key;
    //       }
    //       let l : MyOrganisation = jsonItem as MyOrganisation;
    //       if (l.tags) {
    //         l.tags = Object.values(l.tags);
    //       }
    //       this.myOrganisations.push(l);
    //     }
    //   })
    // });


    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'qrcode', header: 'QRCode' },
     // { field: 'description', header: 'Description' },
     // { field: 'tags', header: 'Tags' },
      { field: 'status', header: 'Status' },
     // { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'qrcode');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'tags');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    // this.myJoinedRequestService.getList().valueChanges().subscribe(data => {
    //   this.preLoader = false;
    //   if(data.length <= 0){
    //     this.hideWhenNoPallet = false;
    //     this.noData = true;
    //   } else {
    //     this.hideWhenNoPallet = true;
    //     this.noData = false;
    //   }
    // })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.myJoinedRequests);
            doc.save('myJoinedRequests.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.myJoinedRequests);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "myJoinedRequests");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedMyJoinedRequests);
      // console.log('SAVEC CSV LOCATIONS ', this.myJoinedRequests);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedMyJoinedRequests, 'myJoinedRequests', exportCols);
   // this.exportService.exportToCsv(this.myJoinedRequests, 'myJoinedRequests', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.myJoinedRequest = {} as MyJoinedRequest;
    this.submitted = false;
    this.myJoinedRequestDialog = true;
  }

  deleteSelectedMyJoinedRequests() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected myJoinedRequests?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.myJoinedRequests = this.myJoinedRequests.filter(val => !this.selectedMyJoinedRequests.includes(val));
            
            this.selectedMyJoinedRequests.forEach( myJoinedRequest => this.myJoinedRequestService.delete(this.userId, myJoinedRequest.$key));
            this.selectedMyJoinedRequests = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyJoinedRequestss Deleted', life: 3000});
        }
    });
  }

  editMyJoinedRequest(myJoinedRequest: MyJoinedRequest) {
    this.myJoinedRequest = {...myJoinedRequest};
    this.myJoinedRequestDialog = true;
  }

  deleteMyJoinedRequest(myJoinedRequest: MyJoinedRequest) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + myJoinedRequest.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.myJoinedRequests = this.myJoinedRequests.filter(val => val.$key !== myJoinedRequest.$key);
            this.myJoinedRequest = {} as MyJoinedRequest;
            this.myJoinedRequestService.delete(this.userId, myJoinedRequest.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyJoinedRequest Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.myJoinedRequestDialog = false;
    this.submitted = false;
  }

  saveMyJoinedRequest() {
    this.submitted = true;

    if ( this.myJoinedRequest.organisationKey ) {
      if (this.myJoinedRequest.$key) {
          this.myJoinedRequests[this.findIndexById(this.myJoinedRequest.$key)] = this.myJoinedRequest;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyJoinedRequest Updated', life: 3000});
          this.myJoinedRequestService.update(this.userId, this.myJoinedRequest);
        
        
        } else {

          this.myJoinedRequest.createdAt = new Date().toISOString();
          this.myJoinedRequest.status = 'in progress';
          this.myJoinedRequest.position = this.myJoinedRequests.length;
          this.myJoinedRequest.organisationName = this.organisationsMap.get(this.myJoinedRequest.organisationKey)?.name || 'noname';
          this.myJoinedRequest.organisationOwnerKey = this.organisationsMap.get(this.myJoinedRequest.organisationKey)?.ownerKey;
          
          this.myJoinedRequestService.add(this.userId, this.myJoinedRequest);

          const request : JoinedRequest = {} as JoinedRequest ;
          request.createdAt = new Date().toISOString();
          request.status = 'in progress';
          request.fromUID = this.userId;
          request.fromEmail = 'nicolas.foata@gmail.com' ;
          const ref = this.joinedRequestService.add( (this.myJoinedRequest.organisationOwnerKey || 'aie') , request);
          request.key = ref.key || 'undefined';
          request.$key = ref.key || 'undefined';
          this.joinedRequestService.update( (this.myJoinedRequest.organisationOwnerKey || 'aie') , request);

      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyJoinedRequest Created', life: 3000});
      this.myJoinedRequests = [...this.myJoinedRequests];
    }

    this.myJoinedRequestDialog = false;
    this.myJoinedRequest = { } as MyJoinedRequest;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.myJoinedRequests.length; i++) {
        if (this.myJoinedRequests[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeMyJoinedRequest(myJoinedRequest : MyJoinedRequest) {
    this.myJoinedRequestService.update(this.userId, myJoinedRequest);
  }

  completePosition(myJoinedRequest : MyJoinedRequest) {
    if ( this.newPosition > -1 ) {
        myJoinedRequest.position = this.newPosition;
        myJoinedRequest = this.checkPositionLimits(myJoinedRequest);
        this.reorderElements(myJoinedRequest);
        //this.updateDB(myJoinedRequest);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.myJoinedRequests);
    // const myClonedArray = [];
    // this.myJoinedRequests.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.myJoinedRequests.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.myJoinedRequests.length - 1 - event.dropIndex;
    const myJoinedRequestToMove = this.myJoinedRequests[startIndex];

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.myJoinedRequests[index] = this.myJoinedRequests[index+1];
        this.myJoinedRequests[index].position = index;
        this.myJoinedRequestService.update(this.userId, this.myJoinedRequests[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.myJoinedRequests[index] = this.myJoinedRequests[index-1];
        this.myJoinedRequests[index].position = index;
        this.myJoinedRequestService.update(this.userId, this.myJoinedRequests[index]);
      }
    } 
    this.myJoinedRequests[endIndex] = myJoinedRequestToMove;
    this.myJoinedRequests[endIndex].position = endIndex;
    this.myJoinedRequestService.update(this.userId, this.myJoinedRequests[endIndex]);
  }

  // onFieldEdit(myJoinedRequest: Spray, fieldName: string): void {
  //   //console.log(this.myJoinedRequests);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + myJoinedRequest[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       myJoinedRequest.position = this.newPosition;
  //       myJoinedRequest = this.checkPositionLimits(myJoinedRequest);
  //       this.reorderElements(myJoinedRequest);
  //       //this.updateDB(myJoinedRequest);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(myJoinedRequest);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.myJoinedRequests.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, myJoinedRequest: MyJoinedRequest) {
    // console.log("event" +event);
    if (myJoinedRequest.position != null && myJoinedRequest.position > -1) {
      this.previousPosition = myJoinedRequest.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(myJoinedRequest: MyJoinedRequest) {
    console.log('check ',myJoinedRequest.position + ' ' + this.myJoinedRequests.length);
    if (!myJoinedRequest.position) {
     // console.log('check 1');
      return this.myJoinedRequest;
    }
    if (myJoinedRequest.position < 0) {
      myJoinedRequest.position = 0;
      // console.log('check 2');
      return myJoinedRequest;
    }
    if (myJoinedRequest.position > this.myJoinedRequests.length) {
      myJoinedRequest.position = this.myJoinedRequests.length - 1;
    //  console.log('check 3', myJoinedRequest.position);
      return myJoinedRequest;
    }
   // console.log('check 4');
    return myJoinedRequest;
  }

  async reorderElements(myJoinedRequest: MyJoinedRequest) {
    this.newPosition = this.newPosition  > this.myJoinedRequests.length ? this.myJoinedRequests.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === myJoinedRequest.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const myJoinedRequestToMove = this.myJoinedRequests[startIndex];
   // console.log('Item to move: ', this.myJoinedRequests[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.myJoinedRequests);
        console.log('Item to +1: ', this.myJoinedRequests[index+1]);

        this.myJoinedRequests[index] = this.myJoinedRequests[index+1];
        this.myJoinedRequests[index].position = index;
        // console.log('Move: ', this.myJoinedRequests[index+1]);
        // console.log('Move pos: ', this.myJoinedRequests[index].position);
        //await this.updateDB(this.myJoinedRequests[index]);
        this.myJoinedRequestService.update(this.userId, this.myJoinedRequests[index]);
     //   console.log('Update DB: ', this.myJoinedRequests[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.myJoinedRequests[index] = this.myJoinedRequests[index-1];
        this.myJoinedRequests[index].position = index;
        this.myJoinedRequestService.update(this.userId, this.myJoinedRequests[index]);
      }
    } 
    this.myJoinedRequests[endIndex] = myJoinedRequestToMove;
    this.myJoinedRequests[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.myJoinedRequestService.update(this.userId, this.myJoinedRequests[endIndex]);
    
    // const myClonedArray = [];
    // this.myJoinedRequests.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.myJoinedRequests = myClonedArray;
   // this.getAllRecords();
  }

}
