
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Archive" icon="pi pi-upload" class="button-action p-button-warning" (click)="archiveSelectedPallets()" [disabled]="!selectedPallets || !selectedPallets.length "></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedPallets()" [disabled]="!selectedPallets || !selectedPallets.length "></button>
        </div> 
    </div>

    <div class="page-content">

        <div #calendarWrapper></div>

        <p-table #dt 
            [value]="pallets"                                                                                                      
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['position','isEnabled','name','description','tags','typeName','clientName','year','volumeName','woodName','locationName','rackName','batchName','wineName','ownerName','quantity']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedPallets" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="-1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="qrcode" style="width: 8rem" name="qrcode" pResizableColumn pReorderableColumn pSortableColumn="qrcode" [class]="includesCol('qrcode') ? 'd' : 'h'">QRCode 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> 
                    </th>
                    <th id="id" name="id" pResizableColumn pReorderableColumn pSortableColumn="id" [class]="includesCol('id') ? 'd' : 'h'">Id
                        <p-sortIcon field="id"></p-sortIcon> 
                        <p-columnFilter type="text" field="id" display="menu"></p-columnFilter>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" [class]="includesCol('name') ? 'd' : 'h'">Name 
                        <p-sortIcon field="name"></p-sortIcon> 
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="description" name="description" pResizableColumn pReorderableColumn [class]="includesCol('description') ? 'd' : 'h'">Description
                        <p-sortIcon field="description"></p-sortIcon> 
                        <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
                    </th>
                    <th id="category" name="category" pResizableColumn pReorderableColumn [class]="includesCol('category') ? 'd' : 'h'">Category
                        <p-sortIcon field="category"></p-sortIcon> 
                        <p-columnFilter type="text" field="category" display="menu"></p-columnFilter>
                    </th>
                    <th id="year" name="year" pResizableColumn pReorderableColumn [class]="includesCol('year') ? 'd' : 'h'">Year
                        <p-sortIcon field="year"></p-sortIcon> 
                        <p-columnFilter type="text" field="year" display="menu"></p-columnFilter>
                    </th>
                    <th id="vol" name="vol" pResizableColumn pReorderableColumn [class]="includesCol('volume') ? 'd' : 'h'">Volume
                        <p-sortIcon field="vol"></p-sortIcon> 
                        <p-columnFilter type="text" field="vol" display="menu"></p-columnFilter>
                    </th>

                    <th id="units" name="units" pResizableColumn pReorderableColumn [class]="includesCol('units') ? 'd' : 'h'">Units
                        <p-sortIcon field="units"></p-sortIcon> 
                        <p-columnFilter type="text" field="units" display="menu"></p-columnFilter>
                    </th>
                    <th id="tags" name="tags" pResizableColumn pReorderableColumn [class]="includesCol('tags') ? 'd' : 'h'">Tags
                        <p-sortIcon field="tags"></p-sortIcon> 
                        <p-columnFilter type="text" field="tags" display="menu"></p-columnFilter>
                    </th>
                
                    <th id="client" name="client" pResizableColumn pReorderableColumn [class]="includesCol('client') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_carpenter.png" width="24px" height="24px" [routerLink]="['/pallet-management/clients']"/> 
                        Client
                        <p-sortIcon field="clientName"></p-sortIcon> 
                        <p-columnFilter type="text" field="clientName" display="menu"></p-columnFilter>
                    </th>
                   
                    <th id="location" name="location" pResizableColumn pReorderableColumn [class]="includesCol('location') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/location.png" width="24px" height="24px" [routerLink]="['/pallet-management/pallet-locations']"/> 
                        Location
                        <p-sortIcon field="locationName"></p-sortIcon> 
                        <p-columnFilter type="text" field="locationName" display="menu"></p-columnFilter>
                    </th>
                    
                    <th id="wine" name="wine" pResizableColumn pReorderableColumn [class]="includesCol('wine') ? 'd' : 'h'">
                        <img style="vertical-align: middle;" src="assets/images-black/ic_wine.png" width="24px" height="24px" [routerLink]="['/wine-management/wines']"/> 
                        Wine
                        <p-sortIcon field="wineName"></p-sortIcon> 
                        <p-columnFilter type="text" field="wineName" display="menu"></p-columnFilter>
                    </th>

                    <th id="quantity" name="quantity" pResizableColumn pReorderableColumn [class]="includesCol('quantity') ? 'd' : 'h'">
                        <!-- <img style="vertical-align: middle;" src="assets/images-black/ic_wine.png" width="24px" height="24px" [routerLink]="['/wine-management/wines']"/>  -->
                        Quantity
                        <p-sortIcon field="quantity"></p-sortIcon> 
                        <p-columnFilter type="numeric" field="quantity" display="menu"></p-columnFilter>
                    </th>

                    <!-- <th id="filledDatetime" name="filledDatetime" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('filledDatetime') ? 'show' : 'hide'">
                        Filled
                        <p-sortIcon field="filledDatetime"></p-sortIcon> 
                        <p-columnFilter type="date" field="filledDatetime" display="menu"></p-columnFilter>
                    </th> -->

                    <th id="isEnabled" name="isEnabled" pResizableColumn pReorderableColumn pSortableColumn="isEnabled" style="width: 10rem" [class]="includesCol('isEnabled') ? 'show' : 'hide'">
                        Enabled
                        <p-sortIcon field="isEnabled"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isEnabled" display="menu"></p-columnFilter>
                    </th>

                    <th pResizableColumn pReorderableColumn style="width: 8rem">
                        Actions 
                        <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-pallet let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index" >
                    <td pEditableColumn>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" [pReorderableRowHandle]="index" style="margin-right: 1rem;"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                (ngModelChange)="modelChangeFn($event, pallet)"
                                [ngModel]="pallet.position" 
                                (blur)="completePosition(pallet)" 
                                (keydown.tab)="completePosition(pallet)" 
                                (keydown.enter)="completePosition(pallet)"  
                                required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{pallet.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('qrcode') ? 'd' : 'h'">
                        <qrcode [qrdata]="pallet.name" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                    </td>

                    <td pEditableColumn [class]="includesCol('id') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="pallet.name" 
                                (blur)="completePallet(pallet)" 
                                (keydown.tab)="completePallet(pallet)" 
                                (keydown.enter)="completePallet(pallet)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{pallet.id}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('name') ? 'd' : 'h'">
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="pallet.name"  (blur)="completePallet(pallet)" (keydown.tab)="completePallet(pallet)" (keydown.enter)="completePallet(pallet)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pallet.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('description') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="pallet.description"  (blur)="completePallet(pallet)" (keydown.tab)="completePallet(pallet)" (keydown.enter)="completePallet(pallet)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pallet.description}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>


                    <td pEditableColumn [class]="includesCol('category') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="pallet.category"  (blur)="completePallet(pallet)" (keydown.tab)="completePallet(pallet)" (keydown.enter)="completePallet(pallet)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pallet.category}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('year') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="pallet.year"  (blur)="completePallet(pallet)" (keydown.tab)="completePallet(pallet)" (keydown.enter)="completePallet(pallet)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pallet.year}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    
                    <td pEditableColumn [class]="includesCol('volume') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="pallet.volume"  (blur)="completePallet(pallet)" (keydown.tab)="completePallet(pallet)" (keydown.enter)="completePallet(pallet)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pallet.volume}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('units') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <input pInputText type="text" [(ngModel)]="pallet.units"  (blur)="completePallet(pallet)" (keydown.tab)="completePallet(pallet)" (keydown.enter)="completePallet(pallet)"  required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pallet.units}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('tags') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-chips  id="tags" [(ngModel)]="pallet.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3" (onBlur)="completePallet(pallet)" (onAdd)="completePallet(pallet)" (onRemove)="completePallet(pallet)"></p-chips>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="margin-right: 5px;" *ngFor="let tag of pallet.tags">
                                    <p-tag >{{tag}}</p-tag>
                                </span>
                            
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('client') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="pallet.clientName" 
                                    [options]="clients" 
                                    optionLabel="companyName" 
                                    optionValue="companyName"
                                    (blur)="completePallet(pallet)"
                                    (keydown.tab)="completePallet(pallet)"
                                    (keydown.enter)="completePallet(pallet)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pallet.clientName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('location') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                               <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="pallet.locationName" 
                                    [options]="locations" 
                                    optionLabel="name" 
                                    optionValue="name"
                                    (blur)="completePallet(pallet)"
                                    (keydown.tab)="completePallet(pallet)"
                                    (keydown.enter)="completePallet(pallet)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{pallet.locationName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('wine') ? 'd' : 'h'" >
                        <p-cellEditor >
                            <ng-template pTemplate="input" >
                                <p-dropdown 
                                    appendTo="body" 
                                    [filter]="true"
                                    [showClear]="true" 
                                    [(ngModel)]="pallet.wineName" 
                                    [options]="wines" 
                                    optionLabel="name" 
                                    optionValue="name"
                                    (blur)="completePallet(pallet)"
                                    (keydown.tab)="completePallet(pallet)"
                                    (keydown.enter)="completePallet(pallet)"
                                    placeholder="Any">
                                </p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span style="font-size: 12px;">{{pallet.wineName}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                  
                    

                    <td pEditableColumn [class]="includesCol('quantity') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="number" [(ngModel)]="pallet.quantity" 
                                (blur)="completePallet(pallet)" 
                                (keydown.tab)="completePallet(pallet)" 
                                (keydown.enter)="completePallet(pallet)" 
                                 required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{pallet.quantity}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                 
                    
                     <td pEditableColumn [class]="includesCol('isEnabled') ? 'd' : 'h'" class="enabled" style="text-align: center;">
                        <p-inputSwitch  [(ngModel)]="pallet.isEnabled" (onChange)="completePallet(pallet)"></p-inputSwitch>
                    </td>


                    

                    <td class="row-actions">
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editPallet(pallet)"></button>
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deletePallet(pallet)"></button>
                        <p-tableCheckbox class="checkbox" [value]="pallet"></p-tableCheckbox>
                        
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{pallets ? pallets.length : 0 }} pallet{{pallets.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV - Save the selected lines and columns" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS - Save all the lines and columns" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF - Save all the lines and columns" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>        
    </div>


    <div class="stats">
            
        <h2>Selection </h2>
        <ul>
            <li><b>Number of pallets:</b> {{selectedPallets ? selectedPallets.length : 0 }}</li>
            <li><b>Quantity of boxes:</b> {{ computeQuantity() }} boxes</li>
            <li><b>Number of clients:</b> {{ computeClients().length }} distinct clients</li>
            <li><b>List of clients:</b>  {{ computeClients() }}</li>
            <li><b>Number of locations:</b> {{ computeLocations().length }} distinct locations</li>
            <li><b>List of locations:</b>  {{ computeLocations() }}</li>
        </ul>

</div>

    <!-- Dialog : confirm -->
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    

    <!-- Dialog : Popup -->
    <p-dialog [(visible)]="palletDialog" [style]="{width: '450px', height: '450px'}" header="Pallet" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
        <!-- <div class="p-field" style="margin-top: 10px;">
            <label for="title">Id.</label>
            <input style="margin-top: 10px;" type="text" pInputText id="id" [(ngModel)]="pallet.id" required autofocus />
            <small class="p-error" *ngIf="submitted && !pallet.name">Identifier is required.</small>
        </div> -->

        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Name</label>
            <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="pallet.name" required autofocus />
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Category</label>
            <input style="margin-top: 10px;" type="text" pInputText id="category" [(ngModel)]="pallet.category" required autofocus />
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Year</label>
            <input style="margin-top: 10px;" type="text" pInputText id="year" [(ngModel)]="pallet.year" required autofocus />
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Volumes</label>
            <input style="margin-top: 10px;" type="text" pInputText id="volume" [(ngModel)]="pallet.volume" required autofocus />
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="title">Units</label>
            <input style="margin-top: 10px;" type="text" pInputText id="units" [(ngModel)]="pallet.units" required autofocus />
        </div>

        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/ic_carpenter.png" width="24px" height="24px" [routerLink]="['/pallet-management/clients']"/> 
            <label for="type">&nbsp;&nbsp; Client</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="pallet.clientName" 
                [options]="clients" 
                optionLabel="companyName" 
                optionValue="companyName"
                placeholder="Any">
            </p-dropdown>
        </div>
       
        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/location.png" width="24px" height="24px" [routerLink]="['/pallet-management/pallet-locations']"/> 
            <label for="location">&nbsp;&nbsp; Location</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="pallet.locationName" 
                [options]="locations" 
                optionLabel="name" 
                optionValue="name"
                placeholder="Any">
            </p-dropdown>
        </div>
        
        <div class="p-field" style="margin-top: 20px;">
            <img style="vertical-align: middle;" src="assets/images-black/ic_wine.png" width="24px" height="24px" [routerLink]="['/wine-management/wines']"/> 
            <label for="location">&nbsp;&nbsp; Wine</label>
            <div style="margin-top: 10px;"></div>
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="pallet.wineKey" 
                [options]="wines" 
                optionLabel="name" 
                optionValue="$key"
                placeholder="Any">
            </p-dropdown>
        </div>

        <div class="p-field" style="margin-top: 10px;">
            <label for="quantity">Quantity of boxes</label>
            <input style="margin-top: 10px;" type="number" pInputText id="quantity" [(ngModel)]="pallet.quantity" autofocus />
            <!-- <small class="p-error" *ngIf="submitted && !pallet.name">Identifier is required.</small> -->
        </div>
        
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="pallet.description" rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="tags" >Tags</label>
            <div style="height: 10px;">&nbsp;</div>
            <p-chips  id="tags" [(ngModel)]="pallet.tags" [separator]="separatorExp" placeholder="tag1, tag2 tag3"></p-chips>
        </div>   
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="savePallet()"></button>
        </div>
    </p-dialog>
    
    

</div>







