import { Injectable } from '@angular/core';
import { BarrelOwner } from '../interfaces/barrel-owner';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BarrelOwnerCrudService {


  ownersRef: AngularFireList<any> | undefined;
  ownerRef: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/barrelOwners/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // Create Owner
  add(owner: BarrelOwner) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ownersRef = this.db.list( 'organisations/' + org + '/' + this.path );
   // this.ownersRef = this.db.list( this.path );
    const item = {
      ...owner
    }
    this.ownersRef?.push( item );
  }
  // Fetch Single Owner Object
  get(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.ownerRef = this.db.object( 'organisations/' + org + '/' + this.path + id);
    return this.ownerRef;
  }
  // Fetch Owners List
  getList() {
    const org = this.getOrganisation();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.ownersRef = this.db.list( 'organisations/' + org + '/' + this.path );
    return this.ownersRef;
  }
  // Update Owner Object
  update(record: BarrelOwner) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ownerRef = this.db.object( 'organisations/' + org + '/' + this.path + record.$key);
    let item : Partial<BarrelOwner>  = {
      ...record
    }
    delete item?.$key;
    this.ownerRef?.update(item);
  }
  // Delete Owner Object
  delete(id: string) {
    const org = this.getOrganisation();
    if (!org) {
      return;
    } 
    this.ownerRef = this.db.object( 'organisations/' + org + '/' + this.path + id);
    this.ownerRef.remove();
  }
}
