<div class="page">
    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div>
            
        </div>
    </div>
    <div class="page-content">
        
        <div class="center" >

            <h1>1 - My account details</h1><br>

            <div class="form-group">
                <p-inplace [active]="true">
                    <ng-template pTemplate="display">
                    <div>{{ accountKey }}</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                    <label for="accountKey">Account key:</label>
                    <input pInputText [(ngModel)]="accountKey" (keydown.enter)="saveValue()" (keydown.escape)="cancelEdit()" disabled>
                    </ng-template>
                </p-inplace>
            </div>

            <div class="row">
                <!-- <div class="form-group" style="width: 300px">
                    <label for="firstname">First Name</label>
                    <input type="text" class="form-control" id="firstname" />
                </div> -->
                <p-inplace [active]="true">
                    <ng-template pTemplate="display">
                      <div>{{ myAccount.firstName }}</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <label for="firstName">First Name:</label>
                      <input pInputText [(ngModel)]="myAccount.firstName" (keydown.enter)="saveValue()" (keydown.escape)="cancelEdit()">
                    </ng-template>
                </p-inplace>
    
                <!-- <div class="form-group" style="width: 300px; margin-left: 50px">
                    <label for="lastname">Last Name</label>
                    <input type="text" class="form-control" id="lastname"  />
                </div> -->
                <p-inplace [active]="true">
                    <ng-template pTemplate="display">
                      <div>{{ myAccount.lastName }}</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <label for="lastName">Last Name:</label>
                      <input pInputText [(ngModel)]="myAccount.lastName" (keydown.enter)="saveValue()" (keydown.escape)="cancelEdit()">
                    </ng-template>
                </p-inplace>
            </div>

            
            <div class="form-group">
                <p-inplace [active]="true">
                    <ng-template pTemplate="display">
                    <div>{{ email }}</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                    <label for="email">Email:</label>
                    <input pInputText [(ngModel)]="email" (keydown.enter)="saveValue()" (keydown.escape)="cancelEdit()" disabled>
                    </ng-template>
                </p-inplace>
            </div>

             <!-- <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" class="form-control" id="email" />
             </div> -->
            
             <!-- <div class="form-group">
                <label for="mobile">Phone</label>
                <input type="mobile" class="form-control" id="mobile" />
             </div> -->

             <div class="form-group">
                <p-inplace [active]="true">
                    <ng-template pTemplate="display">
                        <div>{{ myAccount.phone }}</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <label for="phone">Phone:</label>
                        <input pInputText [(ngModel)]="myAccount.phone" (keydown.enter)="saveValue()" (keydown.escape)="cancelEdit()" >
                    </ng-template>
                </p-inplace>
            </div>
            

             <!-- <div class="save">
                <button style="width: 100px" pButton pRipple label="Save" icon="pi pi-save" class="button-action p-button-success p-mr-2" type="submit"></button>
             </div> -->

             <ng-container *ngIf="(organisation$ | async) as org">
                <h1>2 - My Organisation and Plan</h1><br>


                <div class="row">
   
                   <div style="width: 100%">
   
                       <div class="form-group" style="width: 100%">
                        <input pInputText  value='{{ org.$key }}' disabled style="width: 100%"/>
                           <!-- <p-inplace [active]="true">
                                
                               <ng-template pTemplate="display">
                                   <div>{{ org.key }}</div>
                               </ng-template>
                               <ng-template pTemplate="content">
                                   <label for="organisationKey">Organisation key:</label>
                                   <input pInputText [(ngModel)]="myAccount.organisationKey" (keydown.enter)="saveValue()" (keydown.escape)="cancelEdit()" >
                               </ng-template>
                           </p-inplace> -->
                       </div>
   
                       <div class="form-group">
                          <input pInputText  value='{{ org.name }}' disabled/>
                        
                           <!-- <p-inplace [active]="true">
                               <ng-template pTemplate="display">
                                   <div>{{ org.name }}</div>
                               </ng-template>
                               <ng-template pTemplate="content">
                                   <label for="companyName">Organisation name:</label>
                                   <input pInputText [(ngModel)]="myAccount.organisationName" (keydown.enter)="saveValue()" (keydown.escape)="cancelEdit()" >
                               </ng-template>
                           </p-inplace> -->
                       </div>
   
                       <div class="form-group">
                           <button pButton pRipple label="Manage my organisations (own)" icon="pi " class="button-action p-button-success p-mr-2" [routerLink]="['/my-organisations']"></button>
   
                           <!-- <button pButton pRipple label="Manage my organisations (own)" icon="pi " class="button-action p-button-success p-mr-2" [routerLink]="['/invoices']"></button> -->
                           <button mat-menu-item [routerLink]="['invoices']">
                               <mat-icon color="primary" style="color: white; vertical-align:middle;">payment</mat-icon>
                               <span style="color: white">Payment History</span>
                             </button>
   
                       </div>
   
                   </div>
                   
   
   
   
                   <div class="plan-wrapper"> 
                       <div *ngIf="priceId === 100" class="plan basic-plan">
                           <h3>Checklists and Audits</h3>
                           <p class="price">$100/month</p>
                           <!-- <ul>
                               <li>Phone Calls and alerts (emergency, responsible clients)</li>
                               <li>Reporting</li>
                               <li>Photos</li>
                               <li>Voice Message</li>
                               <li>Checklist</li>
                               <li>Audits</li>
                               <li>QR Code Scan</li>
                           </ul> -->
                           <div class="button-wrapper">
                               <button class="button2" (click)="accessChangePlan()">Change</button>
                               <button class="button3" (click)="cancelPlan()">Cancel</button>
                           </div>
                       </div>
                      
                       <div *ngIf="priceId === 200"  class="plan gold-plan">
                           <h3>Reception & Movements</h3>
                           <p class="price">$200/month</p>
                           <!-- <ul>
                               <li>Dashboard</li>
                               <li>Stock alert</li>
                               <li>Receipt tracking</li>
                               <li>Photo</li>
                               <li>Voice Message</li>
                               <li>Product transformation</li> <li>Item and batch tracking</li>
                               <li>Location tracking</li>
                               <li>QR code scan</li>
                               <li>Product transformation</li>
                           </ul> -->
                           <div class="button-wrapper">
                               <button class="button2" (click)="accessChangePlan()">Change</button>
                               <button class="button3" (click)="cancelPlan()">Cancel</button>
                           </div>
                       </div>
                   </div>
   
   
   
   
                </div>
             </ng-container>
            
        
            

             <h1>3 - Joined organisations</h1><br>

             <div class="row">
                <div class="form-group">
                    <button pButton pRipple label="Manage my organisations (joined)" icon="pi " class="button-action p-button-success p-mr-2" [routerLink]="['/my-joined-organisations']"></button>
                </div>
             </div>


             <!-- <div >
                <button mat-menu-item [routerLink]="['my-joined-requests']">
                  <mat-icon color="primary" style="color: white; vertical-align:middle;">business</mat-icon>
                  <span style="color: white">My organisations (requests)</span>
                </button>
              </div>
              <div >
                <button mat-menu-item [routerLink]="['joined-requests']">
                  <mat-icon color="primary" style="color: white; vertical-align:middle;">business</mat-icon>
                  <span style="color: white">Received requests</span>
                </button>
              </div>
              <div >
                <button mat-menu-item [routerLink]="['invoices']">
                  <mat-icon color="primary" style="color: white; vertical-align:middle;">payment</mat-icon>
                  <span style="color: white">Payment History</span>
                </button>
              </div> -->


        </div>
          
    </div>
</div>





    
    <!-- <div class="plan-wrapper"> 
        <div *ngIf="priceId === priceID100" class="plan basic-plan">
        <h3>Checklists and Audits</h3>
        <p class="price">$100/month</p>
        <ul>
        <li>Phone Calls and alerts (emergency, responsible clients)</li>
        <li>Reporting</li>
        <li>Photos</li>
        <li>Voice Message</li>
        <li>Checklist</li>
        <li>Audits</li>
        <li>QR Code Scan</li>
        </ul>
        <div class="button-wrapper">
            <button class="button2">$100</button>
        </div>
       </div>
       
        <div *ngIf="priceId === priceID200"  class="plan gold-plan">
        <h3>Reception & Movements</h3>
        <p class="price">$200/month</p>
        <ul>
        <li>Dashboard</li>
        <li>Stock alert</li>
        <li>Receipt tracking</li>
        <li>Photo</li>
        <li>Voice Message</li>
        <li>Product transformation</li> <li>Item and batch tracking</li>
        <li>Location tracking</li>
        <li>QR code scan</li>
        <li>Product transformation</li>
        </ul>
        <div class="button-wrapper">
            <button class="button2">$200</button>
        </div>
        </div>
    </div> -->