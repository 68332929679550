import { Injectable } from '@angular/core';
import { Record } from '../interfaces/record';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecordService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/records/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  getBlock() : string | null {
    const block = JSON.parse(localStorage.getItem('block')!);
    if(block && typeof block.$key !== 'undefined') {
      return block.$key;
    }
    return null;
  }

  // Create Record
  add(record: Record) {
    const org = this.getOrganisation();
    const block =  this.getBlock();
    if (!org) {
      return;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path + block);
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single Block Object
  get(id: string) {
    const org = this.getOrganisation();
    const block = this.getBlock();
    if (!org) {
      return {} as AngularFireObject<any>;
    }  
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + block + '/' + id);
    return this.ref;
  }
  // Fetch Blocks List
  getList() {
    const org = this.getOrganisation();
    const block = this.getBlock();
    if (!org) {
      return {} as AngularFireList<any>;
    } 
    this.refs = this.db.list( 'organisations/' + org + '/' + this.path + block);
    return this.refs;
  }
  // Update Block Object
  update(record: Record) {
    const org = this.getOrganisation();
    const block = this.getBlock();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + block + '/' + record.$key);
    let item : Partial<Record>  = {
      ...record
    }
    delete item?.r_id;
    this.ref?.update(item);
  }
  // Delete Block Object
  delete(id: string) {
    const org = this.getOrganisation();
    const block =  this.getBlock();
    if (!org) {
      return;
    } 
    this.ref = this.db.object( 'organisations/' + org + '/' + this.path + block + '/' + id);
    this.ref.remove();
  }
}
