import { Injectable } from '@angular/core';
import { Company } from '../interfaces/company';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/settings/joinedRequests/' ;
  }



  // // update the table variable
  // updatePath() {
  //   const user = JSON.parse(localStorage.getItem('user')!);
  //   this.TABLE_NAME = 'companies';
  //   this.TABLE_NAME = user.uid +'/' +this.TABLE_NAME;
  // }

  // Create Company
  add(uid: string, record: Company) {
    this.path = environment.name + '/' + uid + '/companies/' ;
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    // this.refs?.push( item );
    const key = this.refs?.push( item );
    return key;
  }
  // Fetch Single JoinedRequest Object
  get(uid : string, id: string) {
    this.path = environment.name + '/' + uid + '/companies/' ;
 
    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch JoinedRequests List
  getList(uid : string) {
    this.path = environment.name + '/' + uid + '/companies/' ;
 
    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update JoinedRequest Object
  update(uid : string, record: Company) {
    this.path = environment.name + '/' + uid + '/companies/' ;
 
    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<Company>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete JoinedRequest Object
  delete(uid : string, id: string) {
    this.path = environment.name + '/' + uid + '/companies/' ;
 
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }


  // // Fetch Single Company Object
  // getCompany(id: string) {
  //   this.updatePath();
  //   this.companyRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + id);
  //   return this.companyRef;
  // }
  // // Fetch Companys List
  // getCompanysList() {
  //   this.updatePath();
  //   this.companysRef = this.db.list(this.MODULE_NAME + '/' + this.TABLE_NAME + '/');
  //   return this.companysRef;
  // }
  // // Update Company Object
  // updateCompany(company: Company) {
  //   this.updatePath();
  //   this.companyRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + company.$key);
  //  // console.log("P inside", provider);
  //   let item : Partial<Company>  = {
  //     ...company
  //   }
  //   delete item?.$key;
  //   this.companyRef?.update(item);
  // }
  // // Delete Company Object
  // deleteCompany(id: string) {
  //   this.updatePath();
  //   this.companyRef = this.db.object(this.MODULE_NAME + '/' + this.TABLE_NAME + '/' + id);
  //   this.companyRef.remove();
  // }
}
