import { Injectable } from '@angular/core';
import { Row } from '../interfaces/row';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class RowCrudService {

  rowsRef: AngularFireList<any> | undefined;
  rowRef: AngularFireObject<any> | undefined;

  constructor(private db: AngularFireDatabase) {}

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('organisation')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // Create Row
  AddRow(row: Row) {
    const item = {
      ...row
    }
    this.rowsRef?.push( item );
  }
  // Fetch Single Row Object
  GetRow(id: string) {
    this.rowRef = this.db.object('rows-list/' + id);
    return this.rowRef;
  }
  // Fetch Rows List
  GetRowsList() {
    this.rowsRef = this.db.list('rows-list');
    return this.rowsRef;
  }
  // Update Row Object
  UpdateRow(row: Row) {
    this.rowRef = this.db.object('rows-list/' + row.$key);
   // console.log("P inside", row);
    let item : Partial<Row>  = {
      ...row
    }
    delete item?.$key;
    this.rowRef?.update(item);
  }
  // Delete Row Object
  DeleteRow(id: string) {
    this.rowRef = this.db.object('rows-list/' + id);
    this.rowRef.remove();
  }
}
