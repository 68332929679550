<div class="invoice-title"><h2>Invoices</h2></div>

<div class="table-container">
    <table class="invoice-table">
        <thead>
            <tr>
                <th>Invoice ID</th>
                <th>Email</th>
                <th>Company Name</th>
                <th>Total</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let invoice of invoices">
                <td>{{ invoice.number }}</td>
                <td>{{ invoice.lines.data[0].metadata.customerEmail }}</td>
                <td>{{ invoice.lines.data[0].metadata.companyName }}</td>
                <td>{{ (invoice.total / 100) | currency: 'AUD' }}</td>
                <td><a [href]="invoice.invoice_pdf" class="download-button" download>Download</a></td>
            </tr>
        </tbody>
    </table>
</div>





