import { Injectable } from '@angular/core';
import { Organisation } from '../interfaces/organisation';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicOrganisationsCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    // if (environment.name === 'production') {
    //   this.path = '/organisations/' ;
    // } else {
      this.path = '/environments/' + environment.name + '/public/organisations/' ;
   // }
    
  }

  // Create Organisation
  add(record: Organisation) {
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    const key = this.refs?.push( item );
    return key;
  }
  // Fetch Single Organisation Object
  get(id: string) {
    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch Organisations List
  getList() {
    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update Organisation Object
  update(record: Organisation) {
    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<Organisation>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Organisation Object
  delete(id: string) {
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}
