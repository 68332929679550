import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-growth-stages',
  templateUrl: './growth-stages.component.html',
  styleUrls: ['./growth-stages.component.scss']
})
export class GrowthStagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
