import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { DispatchCrudService } from 'src/app/shared/services/dispatch-crud.service';
import { Dispatch } from 'src/app/shared/interfaces/dispatch';
import { Client } from '../shared/interfaces/client';
import { Output as OutputType } from 'src/app/shared/interfaces/output';
import { ClientCrudService } from '../shared/services/client-crud.service';
import { OutputCrudService } from '../shared/services/output-crud.service';

@Component({
  selector: 'app-dispatches',
  templateUrl: './dispatches.component.html',
  styleUrls: ['./dispatches.component.scss']
})
export class DispatchesComponent implements OnInit {

  dispatches: Dispatch[];
  dispatch: Dispatch;
  selectedDispatches: Dispatch[] = [];

  dispatchDialog: boolean = false;

  submitted: boolean = false;


  outputTypes: OutputType[] = [];
  outputType: OutputType = {} as OutputType;
  outputTypeKey : string = "";
  clients: Client[];
  client: Client;
  clientKey : string = "";

  list1: any[];
  originalList1: any[];

  list2: any[];
  list2Map = new Map<string, boolean>();

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private dispatchService : DispatchCrudService,
    private confirmationService: ConfirmationService,
    private outputTypeCrudService : OutputCrudService,
    private clientCrudService : ClientCrudService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Dispatches'}
    ];

    this.dispatches = [];
    this.dispatch = { } as Dispatch ;

    this.clients = [];
    this.client = {} as Client;

    this.list1 = [];
     this.originalList1 = Object.assign([], this.list1);
     this.list2 = [];
    //  this.locations = [];
    //  this.location = {} as Location;
    //  this.dropdown = {} as Dropdown;
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let s = this.dispatchService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.dispatches = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Dispatch = jsonItem as Dispatch;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.dispatches.push(l);
        }
      })
    });

    let ot = this.outputTypeCrudService.getOutputsList(); 
    ot.snapshotChanges().subscribe(data => {
      this.outputTypes = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.outputTypes.push(jsonItem as OutputType);
        }
      })
    })
    this.outputTypes = this.outputTypes.sort((a, b) => (a.name < b.name) ? -1 : 1);

    let c = this.clientCrudService.getClientsList(); 
    c.snapshotChanges().subscribe(data => {
      this.clients = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.clients.push(jsonItem as Client);
        }
      })
    });
    this.clients = this.clients.sort((a, b) => (a.companyName < b.companyName) ? -1 : 1);


    // column management
    this.cols = [
      { field: 'dispatchAt', header: 'Date and time' },
      { field: 'dispatchBy', header: 'By' },
      { field: 'items', header: 'Packs' },
      { field: 'name', header: 'Name' },
      { field: 'qrcode', header: 'QRCode' },
      { field: 'description', header: 'Notes' },

      { field: 'tags', header: 'Tags' },
     
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'qrcode');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'name');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'tags');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.dispatchService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.dispatches);
            doc.save('dispatches.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.dispatches);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "dispatches");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedDispatches);
      // console.log('SAVEC CSV LOCATIONS ', this.dispatches);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedDispatches, 'dispatches', exportCols);
   // this.exportService.exportToCsv(this.dispatches, 'dispatches', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.dispatch = {} as Dispatch;
    this.submitted = false;
    this.dispatchDialog = true;
  }

  deleteSelectedDispatches() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected dispatches?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.dispatches = this.dispatches.filter(val => !this.selectedDispatches.includes(val));
            
            this.selectedDispatches.forEach( dispatch => this.dispatchService.delete(dispatch.$key));
            this.selectedDispatches = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Dispatchess Deleted', life: 3000});
        }
    });
  }

  editDispatch(dispatch: Dispatch) {
    this.dispatch = {...dispatch};
    this.dispatchDialog = true;
  }

  deleteDispatch(dispatch: Dispatch) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + dispatch.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.dispatches = this.dispatches.filter(val => val.$key !== dispatch.$key);
            this.dispatch = {} as Dispatch;
            this.dispatchService.delete(dispatch.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Dispatch Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.dispatchDialog = false;
    this.submitted = false;
  }

  saveDispatch() {
    this.submitted = true;

    if ( this.dispatch.name.trim() ) {
      if (this.dispatch.$key) {
          this.dispatches[this.findIndexById(this.dispatch.$key)] = this.dispatch;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Dispatch Updated', life: 3000});
          this.dispatchService.update(this.dispatch);
        } else {
          this.dispatch.position = this.dispatches.length;
          this.dispatchService.add(this.dispatch);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Dispatch Created', life: 3000});
      this.dispatches = [...this.dispatches];
    }

    this.dispatchDialog = false;
    this.dispatch = { } as Dispatch;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.dispatches.length; i++) {
        if (this.dispatches[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeDispatch(dispatch : Dispatch) {
    this.dispatchService.update(dispatch);
  }

  completePosition(dispatch : Dispatch) {
    if ( this.newPosition > -1 ) {
        dispatch.position = this.newPosition;
        dispatch = this.checkPositionLimits(dispatch);
        this.reorderElements(dispatch);
        //this.updateDB(dispatch);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.dispatches);
    // const myClonedArray = [];
    // this.dispatches.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.dispatches.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.dispatches.length - 1 - event.dropIndex;
    const dispatchToMove = this.dispatches[startIndex];

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.dispatches[index] = this.dispatches[index+1];
        this.dispatches[index].position = index;
        this.dispatchService.update(this.dispatches[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.dispatches[index] = this.dispatches[index-1];
        this.dispatches[index].position = index;
        this.dispatchService.update(this.dispatches[index]);
      }
    } 
    this.dispatches[endIndex] = dispatchToMove;
    this.dispatches[endIndex].position = endIndex;
    this.dispatchService.update(this.dispatches[endIndex]);
  }

  // onFieldEdit(dispatch: Spray, fieldName: string): void {
  //   //console.log(this.dispatches);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + dispatch[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       dispatch.position = this.newPosition;
  //       dispatch = this.checkPositionLimits(dispatch);
  //       this.reorderElements(dispatch);
  //       //this.updateDB(dispatch);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(dispatch);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.dispatches.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, dispatch: Dispatch) {
    // console.log("event" +event);
    if (dispatch.position != null && dispatch.position > -1) {
      this.previousPosition = dispatch.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(dispatch: Dispatch) {
    console.log('check ',dispatch.position + ' ' + this.dispatches.length);
    if (!dispatch.position) {
     // console.log('check 1');
      return this.dispatch;
    }
    if (dispatch.position < 0) {
      dispatch.position = 0;
      // console.log('check 2');
      return dispatch;
    }
    if (dispatch.position > this.dispatches.length) {
      dispatch.position = this.dispatches.length - 1;
    //  console.log('check 3', dispatch.position);
      return dispatch;
    }
   // console.log('check 4');
    return dispatch;
  }

  async reorderElements(dispatch: Dispatch) {
    this.newPosition = this.newPosition  > this.dispatches.length ? this.dispatches.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === dispatch.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const dispatchToMove = this.dispatches[startIndex];
   // console.log('Item to move: ', this.dispatches[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.dispatches);
        console.log('Item to +1: ', this.dispatches[index+1]);

        this.dispatches[index] = this.dispatches[index+1];
        this.dispatches[index].position = index;
        // console.log('Move: ', this.dispatches[index+1]);
        // console.log('Move pos: ', this.dispatches[index].position);
        //await this.updateDB(this.dispatches[index]);
        this.dispatchService.update(this.dispatches[index]);
     //   console.log('Update DB: ', this.dispatches[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.dispatches[index] = this.dispatches[index-1];
        this.dispatches[index].position = index;
        this.dispatchService.update(this.dispatches[index]);
      }
    } 
    this.dispatches[endIndex] = dispatchToMove;
    this.dispatches[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.dispatchService.update(this.dispatches[endIndex]);
    
    // const myClonedArray = [];
    // this.dispatches.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.dispatches = myClonedArray;
   // this.getAllRecords();
  }

  isSales() : boolean {
    //console.log(this.outputTypeKey);
    if (!this.outputTypeKey) {
      return false;
    }
    if (this.outputTypeKey == 'Sales' ) {
      return true;
    }
    return false;
   }

   moveToSource(event: any)  {
    // const items = event.items;
    // items.forEach( (item : any) => {
    //   const found = this.list1.find((litem) => {
    //     return litem.name === item.name && litem.batch === item.batch;
    //   });
    //   if (found !== undefined) {
    //    // console.log('✅ the object is contained in the array');
    //   } else {
    //    // console.log('⛔️ the object is NOT contained in the array');
    //     this.list1.push(item);
    //   }
    // }); 
   this.list1 = [...new Set(this.list1)];
   }

   moveToTarget(event: any) {
   // this.confirm({} as Event, event.items);
    this.list2 = [...new Set(this.list2)];
   }

   disabledPickList() : boolean {
    return false;
   // return this.list1.length < 1 && this.list2.length < 1 ;
   // return this.withdrawalProductTypeKey === '' || this.withdrawalProductTypeKey === undefined;
    // return !this.driedProductTypeKey;
    //  return this.driedProductTypeKey === '' || this.driedProductTypeKey === undefined ; 
  
   }

   sourceReorder(event: any) {
 
   }
}
