<h1> Select Your Plan</h1>
<div class="plan-wrapper"> <div class="plan basic-plan">
 <h3>Bin Management System</h3>
<p class="price">$55/month</p>
 <ul>
  <li>Phone Calls and alerts (emergency, responsible clients)</li>
  <li>Reporting</li>
  <li>Photos</li>
  <li>Voice Message</li>
  <li>Checklist</li>
  <li>Audits</li>
  <li>QR Code Scan</li>
 </ul>
<button class="button1" (click)="makePayment('price_1NY4ILHE9rvMqwXX9UuwIY1h', 55)">A$55</button>
</div>

 <div class="plan gold-plan">
 <h3>Bin Management System</h3>
 <p class="price">$200/month</p>
 <ul>
    <li>Dashboard</li>
    <li>Stock alert</li>
    <li>Receipt tracking</li>
    <li>Photo</li>
    <li>Voice Message</li>
    <li>Product transformation</li> <li>Item and batch tracking</li>
    <li>Location tracking</li>
    <li>QR code scan</li>
    <li>Product transformation</li>
 </ul>
  <button class="button2" (click)="makePayment('price_1Mful6HE9rvMqwXXeZe5G6Em', 200)">$200</button>
 </div>

 <div class="plan premium-plan">
    <h3>Customise Projects</h3>
  
    <button class="button3" onclick="showPopup()">On Demand</button>
  </div>

</div>
