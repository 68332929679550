import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { Record } from '../shared/interfaces/record';
import { RecordService } from '../shared/services/record.service';



@Component({
  selector: 'app-eblocks-history',
  templateUrl: './eblocks-history.component.html',
  styleUrls: ['./eblocks-history.component.scss']
})
export class EblocksHistoryComponent implements OnInit {
  records: Record[];
  record: Record;
  selectedRecords: Record[] = [];

  recordDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    private recordService : RecordService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Block Management'},
      {label: 'Records'}
    ];

    this.records = [];
    this.record = { } as Record;
  }

  ngOnInit(): void {

    this.isFilter = false;
    
    this.dataState();
    let s = this.recordService.getList(); 
    s.snapshotChanges().subscribe(data => {
      this.records = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : Record = jsonItem as Record;
          if (l.r_tags) {
            l.r_tags = Object.values(l.r_tags);
          }
          this.records.push(l);
        }
      })
    })

    // column management
    this.cols = [
      { field: 'id', header: 'Order' },
      { field: 'name', header: 'Name' },
      { field: 'grape', header: 'Grape' },
      { field: 'location', header: 'Location' },
      { field: 'size', header: 'Size' },
      { field: 'StartDate', header: 'Start Date' },
      { field: 'EndDate', header: 'End Date' },
      { field: 'isActive', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  dataState() {     
    this.recordService.getList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.records);
            doc.save('records.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.records);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "records");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
      });
      exportCols.push('position');
      this.exportService.exportToCsv(this.selectedRecords, 'records', exportCols);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  pad(n: number) {
    return n < 10 ? '0'+n : n+'';
  }

  // CRUD management
  openNew() {

    this.record = {} as Record;

    const currentDate = new Date();
    const year  = currentDate.getFullYear();
    const month = currentDate.getMonth()+1;
    const day   = currentDate.getDate();

    this.record.r_StartDate = year + '-' + this.pad(month) + '-' + this.pad(day) ;
    this.submitted = false;
    this.recordDialog = true;
  }

  deleteSelectedRecords() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected records?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.records = this.records.filter(val => !this.selectedRecords.includes(val));  
            this.selectedRecords.forEach( record => this.recordService.delete(record.$key));
            this.selectedRecords = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Records Deleted', life: 3000});
        }
    });
  }

  editRecord(record: Record) {
    this.record = {...record};
    this.recordDialog = true;
  }

  deleteRecord(record: Record) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + record.r_name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.records = this.records.filter(val => val.$key !== record.$key);
            this.record = {} as Record;
            this.recordService.delete(record.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Record Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.recordDialog = false;
    this.submitted = false;
  }

  saveRecord() {
    this.submitted = true;

    if ( this.record.r_name.trim() ) {
      this.record.r_isEnabled = true;

      if (this.record.$key) {
          this.records[this.findIndexById(this.record.$key)] = this.record;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Record Updated', life: 3000});
          this.recordService.update(this.record);
        } else {
          this.record.r_position = this.records.length;
          this.recordService.add(this.record);
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'Record Created', life: 3000});
      this.records = [...this.records];
    }

    this.recordDialog = false;
    this.record = { } as Record;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.records.length; i++) {
        if (this.records[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeRecord(record : Record) {
    this.recordService.update(record);
  }

  completePosition(record : Record) {
    if ( this.newPosition > -1 ) {
        record.r_position = this.newPosition;
        record = this.checkPositionLimits(record);
        this.reorderElements(record);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.records.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.records.length - 1 - event.dropIndex;
    const recordToMove = this.records[startIndex];
    
    
    // console.log('StartIndex', startIndex);
    // console.log('EndIndex', endIndex);

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.records[index] = this.records[index+1];
        this.records[index].r_position = index;
        this.recordService.update(this.records[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.records[index] = this.records[index-1];
        this.records[index].r_position = index;
        this.recordService.update(this.records[index]);
      }
    } 
    this.records[endIndex] = recordToMove;
    this.records[endIndex].r_position = endIndex;
    this.recordService.update(this.records[endIndex]);
  }

  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
  }; field: string; order: number; 
  }) => {
        if (data && data.filteredValue) {
          this.isFilter = !(this.records.length === data.filteredValue['length']);
        } else {
          this.isFilter = false;
        }
    });
    
  }


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, record: Record) {
    // console.log("event" +event);
    if (record.r_position != null && record.r_position > -1) {
      this.previousPosition = record.r_position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(record: Record) {
    console.log('check ',record.r_position + ' ' + this.records.length);
    if (!record.r_position) {
     // console.log('check 1');
      return this.record;
    }
    if (record.r_position < 0) {
      record.r_position = 0;
      // console.log('check 2');
      return record;
    }
    if (record.r_position > this.records.length) {
      record.r_position = this.records.length - 1;
      return record;
    }
   // console.log('check 4');
    return record;
  }

  async reorderElements(record: Record) {
    this.newPosition = this.newPosition  > this.records.length ? this.records.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === record.r_position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const recordToMove = this.records[startIndex];
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.records);
        console.log('Item to +1: ', this.records[index+1]);

        this.records[index] = this.records[index+1];
        this.records[index].r_position = index;
        this.recordService.update(this.records[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.records[index] = this.records[index-1];
        this.records[index].r_position = index;
        this.recordService.update(this.records[index]);
      }
    } 
    this.records[endIndex] = recordToMove;
    this.records[endIndex].r_position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.recordService.update(this.records[endIndex]);
  }
}
