import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Modelxx
import { Table } from 'primeng/table';
import { ColumnCrudService } from 'src/app/shared/services/column-crud.service';
import { Column } from 'src/app/shared/interfaces/column';

@Component({
  selector: 'app-column-list',
  templateUrl: './column-list.component.html',
  styleUrls: ['./column-list.component.scss']
})
export class ColumnListComponent implements OnInit {

  columns1: Column[];
  column1: Column;
  selectedColumns1: Column[] = [];

  columnDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  constructor(
   // private api: APIService,
    private columnService : ColumnCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'Settings'},
      {label: 'Columns'}
    ];

    this.columns1 = [];
    this.column1 = { } as Column ;
    //this.getAllRecords();
  }

  ngOnInit(): void {
    
    this.dataState();
    let s = this.columnService.GetColumnsList(); 
    s.snapshotChanges().subscribe(data => {
      this.columns1 = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          this.columns1.push(jsonItem as Column);
        }
      })
    })


   // this.getAllRecords();

    // column management
    this.cols = [
      { field: 'title', header: 'Title' },
      { field: 'description', header: 'Description' },
      { field: 'actions', header: 'Actions' } 
    ];

    this._selectedColumns = this.cols;

    // Exports
    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  dataState() {     
    this.columnService.GetColumnsList().valueChanges().subscribe(data => {
      this.preLoader = false;
      if(data.length <= 0){
        this.hideWhenNoPallet = false;
        this.noData = true;
      } else {
        this.hideWhenNoPallet = true;
        this.noData = false;
      }
    })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.levels);
            doc.save('levels.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.columns1);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "levels");
    });
  }

  exportDTCSV() {
    console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    this.exportService.exportToCsv(this.columns1, 'levels', ['title', 'description', '$key', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.column1 = {} as Column;
    this.submitted = false;
    this.columnDialog = true;
  }

  deleteSelectedColumns() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected levels?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            // this.levels = this.levels.filter(val => !this.selectedLevels.includes(val));
            // this.selectedLevels = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Levelss Deleted', life: 3000});
        }
    });
  }

  editColumn(c: Column) {
    this.column1 = {...c};
    this.columnDialog = true;
  }

  deleteColumn(level: Column) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + level.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.columns1 = this.columns1.filter(val => val.$key !== level.$key);
            this.column1 = {} as Column;
            this.columnService.DeleteColumn(level.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Column Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.columnDialog = false;
    this.submitted = false;
  }

  saveColumn() {
    this.submitted = true;

    // if (this.level.companyName.trim()) {
    //     if (this.level.$key) {
    //         this.levels[this.findIndexById(this.level.$key)] = this.level;                
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Level Updated', life: 3000});
    //     }
    //     else {
    //         // this.level.id = this.createId();
    //         // this.level.image = 'product-placeholder.svg';
    //         this.levels.push(this.level);
    //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Level Created', life: 3000});
    //     }
    //}

    this.columnService.AddColumn(this.column1);
    this.messageService.add({severity:'success', summary: 'Successful', detail: 'Column Created', life: 3000});

        this.columns1 = [...this.columns1];
        this.columnDialog = false;
        this.column1 = { } as Column;
    
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.columns1.length; i++) {
        if (this.columns1[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  // createId(): string {
  //   let id = '';
  //   var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   for ( var i = 0; i < 5; i++ ) {
  //       id += chars.charAt(Math.floor(Math.random() * chars.length));
  //   }
  //   return id;
  // }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeLevel(level : Column) {
    console.log("SAVE", level);
  }

}
