import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';

// Export
import * as FileSaver from 'file-saver';
import { ExportService } from 'src/app/shared/services/export.service';

// Model
import { Table } from 'primeng/table';
import { MyOrganisationCrudService } from 'src/app/shared/services/my-organisation-crud.service';
import { MyOrganisation } from 'src/app/shared/interfaces/my-organisation';
import { OrganisationCrudService } from '../shared/services/organisation-crud.service';
import { Organisation } from '../shared/interfaces/organisation';
import { AuthService } from '../shared/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Company } from '../shared/interfaces/company';

@Component({
  selector: 'app-my-organisations',
  templateUrl: './my-organisations.component.html',
  styleUrls: ['./my-organisations.component.scss'],
  //providers: [BehaviorSubject]
  // providers: [
  //   { provide: 'uid', useValue: this.userId }
  // ]
})
export class MyOrganisationsComponent implements OnInit {

  userId : string = '';
  userId$: BehaviorSubject<string> = new BehaviorSubject<string>('undefined');

  myOrganisations: MyOrganisation[];
  myOrganisation: MyOrganisation;
  selectedMyOrganisations: MyOrganisation[] = [];

  organisations: Organisation[];
  organisationsMap : Map<string,boolean>;

  myOrganisationDialog: boolean = false;

  submitted: boolean = false;

  // column management
  cols: any[] = [];
  _selectedColumns: any[] = [];

  // export
  @ViewChild('dt', {static: true}) dt : any;
  exportColumns: any[] = [];

  // ui beadcrumb
  breadcrumbs: MenuItem[];
  homeBreadcrumb: MenuItem = {} as MenuItem;

  hideWhenNoPallet: boolean = false;
  noData: boolean = false;
  preLoader: boolean = true;

  separatorExp: string = "[,| ]";

  constructor(
    public authService : AuthService,
    private myOrganisationService : MyOrganisationCrudService,
    private organisationService : OrganisationCrudService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private exportService: ExportService,
    public router: Router
  ) { 
    // ui breadcrumbs
    this.breadcrumbs = [
      {label: 'My Organisations'}
    ];

    this.myOrganisations = [];
    this.myOrganisation = { } as MyOrganisation ;

    this.organisations = [];
    this.organisationsMap = new Map<string,boolean>();
  }

  async ngOnInit() {

    this.isFilter = false;
    
    await this.dataState();

    this.authService.user$.subscribe( (user: any) => {
      if (user) {
        
        this.userId = user.uid;

        let s = this.myOrganisationService.getList( user.uid ); 
        s.snapshotChanges().subscribe(data => {
          this.myOrganisations = [];
          data.forEach(item => {
            let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
            if (jsonItem) {
              if (jsonItem) {
                jsonItem['$key'] = item.key;
              }
              let l : MyOrganisation = jsonItem as MyOrganisation;
              if (l.tags) {
                l.tags = Object.values(l.tags);
              }
              this.myOrganisations.push(l);
            }
          })
        });

      } else {
        this.userId = 'undefined';
      }
    });

    
    let o = this.organisationService.getList(); 
    o.snapshotChanges().subscribe(data => {
      this.organisations = [];
      data.forEach(item => {
        let jsonItem : { '$key': string | null } = item.payload.toJSON() as { '$key': string | null } ; 
        if (jsonItem) {
          if (jsonItem) {
            jsonItem['$key'] = item.key;
          }
          let l : MyOrganisation = jsonItem as MyOrganisation;
          if (l.tags) {
            l.tags = Object.values(l.tags);
          }
          this.organisations.push(l);
          this.organisationsMap.set( l.$key , l.isPublic || false);
        }
      })
    });

    // column management
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'qrcode', header: 'QRCode' },
      { field: 'description', header: 'Description' },
      { field: 'tags', header: 'Tags' },
      { field: 'isPublic', header: 'Public' },
      { field: 'isEnabled', header: 'Enabled' },
      { field: 'actions', header: 'Actions' } 
    ];

    // column that we shoa at the beginning
    this._selectedColumns = this.cols;
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'qrcode');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'description');
    this._selectedColumns = this._selectedColumns.filter( item => item.field !== 'isEnabled');

    // Exports
   // this.exportColumns = this.cols.map(col => ( {title: col.header, dataKey: col.field} ) );
   // For the PDF 
   this.exportColumns = this.cols.map(col => {
      if (col.field === 'position' || col.field === 'rank')  
        return {title: col.header, dataKey: 'position'};
      if (col.field !== 'actions')  
        return {title: col.header, dataKey: col.field};
      return {};
    } );
  }

  async dataState() {     
    const u = await this.authService.user$;
    //this.userId = u.uid;
    this.userId$.next( u.uid );
    // this.myOrganisationService.getList().valueChanges().subscribe(data => {
    //   this.preLoader = false;
    //   if(data.length <= 0){
    //     this.hideWhenNoPallet = false;
    //     this.noData = true;
    //   } else {
    //     this.hideWhenNoPallet = true;
    //     this.noData = false;
    //   }
    // })
  }

  includesCol(val: string) : boolean{
    let c = this._selectedColumns.find(c => c.field === val);
    return c;
  }

  // Column management
  @Input() get selectedColumns(): any[] {
    // console.log('selected columns', this._selectedColumns);
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    // console.log('before selected columns', this._selectedColumns);
    // console.log('before cols', this._selectedColumns);
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    // console.log('after selected columns', this._selectedColumns);
  }

  // Export management
  exportPdf() {
    import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
            const doc = new jsPDF.default('l','pt');
            // @ts-ignore
            doc.autoTable(this.exportColumns, this.myOrganisations);
            doc.save('myOrganisations.pdf');
        })
    })
  }

  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.myOrganisations);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "myOrganisations");
    });
  }

  exportDTCSV() {
   // console.log('export csv');
    this.dt.exportCSV();
  }

  exportToCsv(): void {
    let exportCols : string[] = [];
    this.selectedColumns.forEach( sc => 
      { exportCols.push(sc.field);
        // console.log('SAVEC CSV COL F ',sc.field);
        // console.log('SAVEC CSV COL ',sc);
      });
      exportCols.push('position');
      // console.log('SAVEC CSV SELECTED LOCATIONS ', this.selectedMyOrganisations);
      // console.log('SAVEC CSV LOCATIONS ', this.myOrganisations);
      // console.log('SAVEC CSV EXPORT COL ', exportCols);
      this.exportService.exportToCsv(this.selectedMyOrganisations, 'myOrganisations', exportCols);
   // this.exportService.exportToCsv(this.myOrganisations, 'myOrganisations', ['$key', 'name', 'description', 'tags', 'isEnabled', 'actions']);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // CRUD management
  openNew() {
    this.myOrganisation = {} as MyOrganisation;
    this.submitted = false;
    this.myOrganisationDialog = true;
  }

  deleteSelectedMyOrganisations() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected myOrganisations?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.myOrganisations = this.myOrganisations.filter(val => !this.selectedMyOrganisations.includes(val));
            
            this.selectedMyOrganisations.forEach( myOrganisation => this.myOrganisationService.delete(this.userId, myOrganisation.$key));
            this.selectedMyOrganisations = [];
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyOrganisationss Deleted', life: 3000});
        }
    });
  }

  editMyOrganisation(myOrganisation: MyOrganisation) {
    this.myOrganisation = {...myOrganisation};
    this.myOrganisationDialog = true;
  }

  deleteMyOrganisation(myOrganisation: MyOrganisation) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + myOrganisation.name + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.myOrganisations = this.myOrganisations.filter(val => val.$key !== myOrganisation.$key);
            this.myOrganisation = {} as MyOrganisation;
            this.myOrganisationService.delete(this.userId, myOrganisation.$key);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyOrganisation Deleted', life: 3000});
        }
    });
  }

  hideDialog() {
    this.myOrganisationDialog = false;
    this.submitted = false;
  }

  saveMyOrganisation() {
    this.submitted = true;

    if ( this.myOrganisation.name.trim() ) {
      if (this.myOrganisation.$key) {
        
        if (!this.myOrganisation.ownerKey) {
          this.myOrganisation.ownerKey = this.userId;
        }
        
        this.myOrganisations[this.findIndexById(this.myOrganisation.$key)] = this.myOrganisation;                
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyOrganisation Updated', life: 3000});
          this.myOrganisationService.update( this.userId, this.myOrganisation);
        
          if (this.myOrganisation.isPublic) {
            if (!this.organisationsMap.has(this.myOrganisation.$key) ) {
              const publicOrganisation : Organisation = Object.assign({}, this.myOrganisation);
              publicOrganisation.position = this.organisations.length;
              publicOrganisation.$key = this.myOrganisation.$key ;
              publicOrganisation.key = this.myOrganisation.$key ;
              this.organisationService.update( publicOrganisation );
            }
          } 
        
        } else {
          this.myOrganisation.position = this.myOrganisations.length;

          this.myOrganisation.ownerKey = this.userId;


          // TO REDO
          // const ref = this.myOrganisationService.add(this.userId,this.myOrganisation);
          // console.log('NEW KEY', ref.key);

          // if (this.myOrganisation.isPublic) {
          //   const publicOrganisation : Organisation = Object.assign({}, this.myOrganisation);
          //   publicOrganisation.position = this.organisations.length;
          //   publicOrganisation.$key = ref.key || 'na';
          //   publicOrganisation.key = ref.key || 'na';
          //   publicOrganisation.ownerKey = this.userId;
          //   this.organisationService.update( publicOrganisation );
          // } else {

          // }
      }
      this.messageService.add({severity:'success', summary: 'Successful', detail: 'MyOrganisation Created', life: 3000});
      this.myOrganisations = [...this.myOrganisations];
    }

    this.myOrganisationDialog = false;
    this.myOrganisation = { } as MyOrganisation;
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.myOrganisations.length; i++) {
        if (this.myOrganisations[i].$key === id) {
            index = i;
            break;
        }
    } 
    return index;
  }

  filter( dt : Table, event : {target: any} ) {
    dt.filterGlobal( (event.target as HTMLInputElement).value, 'contains')
  }

  completeMyOrganisation(myOrganisation : MyOrganisation) {
    this.myOrganisationService.update(this.userId,myOrganisation);
  }

  completePosition(myOrganisation : MyOrganisation) {
    if ( this.newPosition > -1 ) {
        myOrganisation.position = this.newPosition;
        myOrganisation = this.checkPositionLimits(myOrganisation);
        this.reorderElements(myOrganisation);
        //this.updateDB(myOrganisation);
        this.newPosition = -1;
        this.previousPosition = -1;
        return;
      }
  }


  @ViewChild('dt') tableElement: any; // already dt above
  isReordering : boolean = false;
  isAscReordering : boolean = false;
  isFilter : boolean = false;

  // Reorder
  reorder(event: any) {
    //console.log('Reorder event', event);
    // console.log('Before', this.myOrganisations);
    // const myClonedArray = [];
    // this.myOrganisations.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // console.log('Before2', myClonedArray);
    if (event.dragIndex === event.dropIndex ) {
      return;
    }
    const startIndex = this.isAscReordering? event.dragIndex : this.myOrganisations.length - 1 - event.dragIndex;
    const endIndex   = this.isAscReordering? event.dropIndex : this.myOrganisations.length - 1 - event.dropIndex;
    const myOrganisationToMove = this.myOrganisations[startIndex];

    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {
        this.myOrganisations[index] = this.myOrganisations[index+1];
        this.myOrganisations[index].position = index;
        this.myOrganisationService.update(this.userId,this.myOrganisations[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.myOrganisations[index] = this.myOrganisations[index-1];
        this.myOrganisations[index].position = index;
        this.myOrganisationService.update(this.userId,this.myOrganisations[index]);
      }
    } 
    this.myOrganisations[endIndex] = myOrganisationToMove;
    this.myOrganisations[endIndex].position = endIndex;
    this.myOrganisationService.update(this.userId,this.myOrganisations[endIndex]);
  }

  // onFieldEdit(myOrganisation: Spray, fieldName: string): void {
  //   //console.log(this.myOrganisations);
  //   console.log("Edit Init Event Called");
  //   console.log('Field name :'+fieldName);
  //   console.log('Field value :' + myOrganisation[fieldName]);
  //   if (fieldName === 'position') {
  //     if ( this.newPosition ) {
  //       myOrganisation.position = this.newPosition;
  //       myOrganisation = this.checkPositionLimits(myOrganisation);
  //       this.reorderElements(myOrganisation);
  //       //this.updateDB(myOrganisation);
  //       this.newPosition = undefined;
  //       this.previousPosition = undefined;
  //       return;
  //     }
      
  //   } else {
  //     this.updateDB(myOrganisation);
  //   }
  // }


  ngAfterViewInit() {
    this.dt.onSort.subscribe((data: { field: string; order: number; }) => {
        if (data.field && data.field === "position") {
          this.isReordering = true;
          this.isAscReordering = (data.order == 1);
          // console.log("ASC", this.isAscReordering);
          // console.log("data.order", data.order);
        } else {
          this.isReordering = false;
        }
    });
    this.dt.onFilter.subscribe( (data: {
      filteredValue: {
        [x: string]: any; field: string; order: number; 
}; field: string; order: number; 
}) => {
      if (data && data.filteredValue) {
        this.isFilter = !(this.myOrganisations.length === data.filteredValue['length']);
      } else {
        this.isFilter = false;
      }
  });
  
}


  previousPosition : number = -1;
  newPosition : number = -1;

  modelChangeFn(event: number, myOrganisation: MyOrganisation) {
    // console.log("event" +event);
    if (myOrganisation.position != null && myOrganisation.position > -1) {
      this.previousPosition = myOrganisation.position;
      this.newPosition = event;
    }
   
    // console.log("previous" +this.previousPosition);
    // console.log("new" +this.newPosition);
  }

  checkPositionLimits(myOrganisation: MyOrganisation) {
    console.log('check ',myOrganisation.position + ' ' + this.myOrganisations.length);
    if (!myOrganisation.position) {
     // console.log('check 1');
      return this.myOrganisation;
    }
    if (myOrganisation.position < 0) {
      myOrganisation.position = 0;
      // console.log('check 2');
      return myOrganisation;
    }
    if (myOrganisation.position > this.myOrganisations.length) {
      myOrganisation.position = this.myOrganisations.length - 1;
    //  console.log('check 3', myOrganisation.position);
      return myOrganisation;
    }
   // console.log('check 4');
    return myOrganisation;
  }

  async reorderElements(myOrganisation: MyOrganisation) {
    this.newPosition = this.newPosition  > this.myOrganisations.length ? this.myOrganisations.length - 1 : this.newPosition;
    this.newPosition = this.newPosition  < 0 ? 0 : this.newPosition;
    if (this.previousPosition === myOrganisation.position ) {
      return;
    }
    // console.log('Previous position: '+ this.previousPosition);
    // console.log('New position: '+ this.newPosition);
    const startIndex = this.previousPosition;
    const endIndex   = this.newPosition;
    const myOrganisationToMove = this.myOrganisations[startIndex];
   // console.log('Item to move: ', this.myOrganisations[startIndex]);
    if (startIndex < endIndex) {
      for(let index = startIndex; index < endIndex; index++) {

        console.log('LOC', this.myOrganisations);
        console.log('Item to +1: ', this.myOrganisations[index+1]);

        this.myOrganisations[index] = this.myOrganisations[index+1];
        this.myOrganisations[index].position = index;
        // console.log('Move: ', this.myOrganisations[index+1]);
        // console.log('Move pos: ', this.myOrganisations[index].position);
        //await this.updateDB(this.myOrganisations[index]);
        this.myOrganisationService.update(this.userId,this.myOrganisations[index]);
     //   console.log('Update DB: ', this.myOrganisations[index]);
      }     
    } else {
      for(let index = startIndex; index > endIndex; index--) {
        this.myOrganisations[index] = this.myOrganisations[index-1];
        this.myOrganisations[index].position = index;
        this.myOrganisationService.update(this.userId,this.myOrganisations[index]);
      }
    } 
    this.myOrganisations[endIndex] = myOrganisationToMove;
    this.myOrganisations[endIndex].position = endIndex;
    this.previousPosition = -1;
    this.newPosition = -1;
    this.myOrganisationService.update(this.userId,this.myOrganisations[endIndex]);
    
    // const myClonedArray = [];
    // this.myOrganisations.forEach(val => myClonedArray.push(Object.assign({}, val)));
    // this.myOrganisations = myClonedArray;
   // this.getAllRecords();
  }

  alreadyTaken(value: string) {
    if (!value) {
      return false;
    }
    return this.organisationsMap.has(value);
  }

  employeesPage(company: Company) {
    localStorage.setItem('company', JSON.stringify(company));
    this.router.navigate(['organisation-management/employees']);
  }

  stripe = require('stripe')('sk_test_51J9P4XHE9rvMqwXXSJNChK2WoKcmFGVDLCJomoIiiEgWMvvtgOwcIStMagGuwLCTkZExPhERrjfLx2GsCa8ejpEx00sN6oUumJ');
  

  async toCompanyDashboard(company: Company) {
    // Authenticate subscription
    const user = JSON.parse(localStorage.getItem('user')!);
    const subscription = await this.stripe.subscriptions.search({
      query: `status:'active' AND metadata['customerEmail']:'${user.email}' AND metadata['companyKey']:'${company.$key}'`,
    });
    if (subscription.data.length === 0) {
      localStorage.setItem('company', JSON.stringify(company));
      this.router.navigate(['/paymentplans']);
    } else if (subscription.data.length === 1) {
      company.subscriptionPrice = subscription.data[0].items.data[0].price.unit_amount / 100;
      company.subscriptionId = subscription.data[0].id;
      localStorage.setItem('company', JSON.stringify(company));
      this.router.navigate(['/dashboard'])
    }
  }
}
