<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>

        <button type="button" pButton (click)="pl.resetFilter()" label="Reset"></button>
    </div>

    <div>
        <h1> Filling </h1>
    </div>

    <div >

        <h1> Tank </h1>

        <div class="my-dropdown">
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="outputTypeKey" 
                [options]="outputTypes" 
                optionLabel="name" 
                optionValue="name"
                placeholder="Any">
            </p-dropdown>  
        </div>

        <h1 style="margin-left: 20px;"> Quantity </h1>

        <div class="my-dropdown">
            <input pInputText type="text" [(ngModel)]="quantity" placeholder="e.g: 3 Liter" />           
        </div>
        <h1 *ngIf="isSales()" style="margin-left: 20px;" > For </h1>

        <div *ngIf="isSales()" class="my-dropdown">
            <p-dropdown 
                appendTo="body" 
                [filter]="true"
                [showClear]="true" 
                [(ngModel)]="clientKey" 
                [options]="clients" 
                optionLabel="companyName" 
                optionValue="companyName"
                placeholder="Any">
        </p-dropdown>  
        </div>
    </div>

        <!-- (change)="selectRecipe(dt)"
            (blur)="selectRecipe(dt)"
            (keydown.tab)="selectRecipe(dt)"
            (keydown.enter)="selectRecipe(dt)" -->
            <!-- (onMoveToTarget)="moveToTarget($event)"
            (onMoveAllToTarget)="moveToTarget($event)"
            (onMoveToSource)="moveToSource($event)"
            (onMoveAllToSource)="moveToSource($event)"
            (onSourceReorder)="sourceReorder($event)"
            [disabled]="disabledPickList()" -->
            
    <p-pickList #pl 
        [responsive]="true" 
        [source]="list1" 
        [target]="list2" 
        [dragdrop]="true"
        sourceHeader="Available" 
        targetHeader="Selected" 
        [responsive]="true" 
        [sourceStyle]="{'width':'350px','height':'300px'}" 
        [targetStyle]="{'width':'350px','height':'300px'}" 
        filterBy="name,batch,location"
        (onMoveToTarget)="moveToTarget($event)"
        (onMoveAllToTarget)="moveToTarget($event)"
        (onMoveToSource)="moveToSource($event)"
        (onMoveAllToSource)="moveToSource($event)"
        (onSourceReorder)="sourceReorder($event)"
        sourceFilterPlaceholder="Search" 
        targetFilterPlaceholder="Search"
        [disabled]="disabledPickList()"
        >
        <ng-template let-recipe pTemplate="item">
            <div>
                <!-- <img src="assets/showcase/images/demo/car/{{car.brand}}.png" style="display:inline-block;margin:2px 0 2px 2px" width="48"> -->
                <div style="font-size:14px;float:right;margin:15px 5px 0 0">{{recipe.name}} - ( {{recipe.locationName}} )</div>
            </div>
        </ng-template>
    </p-pickList>


    <p-confirmPopup  #confirmPopup id="confirmPopup">
    </p-confirmPopup>
<!-- [disabled]="disabled()" -->
<!-- onSubmit() -->
    <div style="margin-top: 20px">
        <button type="button" pButton pRipple label="Submit" [disabled]="disabled()" icon="pi pi-plus" class="button-action p-button-success p-mr-2"(click)="pl.resetFilter(); onSubmit()" ></button>
    </div>


</div>
