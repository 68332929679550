
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedTunas()" [disabled]="!selectedTunas || !selectedTunas.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="tunas" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['audit.title','datetime']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedTunas" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th  style="background-color: #1F4254;width: 1rem">
                    </th>
                    <th pResizableColumn pSortableColumn="position" style="width: 8rem">
                        Order<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="title" name="title" pResizableColumn pReorderableColumn pSortableColumn="title" [class]="includesCol('title') ? 'd' : 'h'">
                        Harvest Id.
                        <p-sortIcon field="title"></p-sortIcon> 
                        <p-columnFilter type="text" field="title" display="menu"></p-columnFilter>
                    </th>
                    <th id="datetime" name="datetime" pResizableColumn pReorderableColumn pSortableColumn="title" [class]="includesCol('datetime') ? 'd' : 'h'">Date and time
                        <p-sortIcon field="datetime"></p-sortIcon> 
                        <p-columnFilter type="text" field="datetime" display="menu"></p-columnFilter>
                    </th>
                    <th pResizableColumn pReorderableColumn style="width: 8rem; vertical-align: middle;">
                        <div class="actions-th">
                            <span>Actions &nbsp;</span> 
                            <!-- <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt.exportCSV();" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button> -->
                            <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                        </div>
                    </th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tuna let-columns="columns" let-index="rowIndex" let-expanded="expanded">
                <tr [pReorderableRow]="index" >
                    
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="tuna" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    
                    <td pEditableColumn>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" [pReorderableRowHandle]="index"></span>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                    (ngModelChange)="modelChangeFn($event, tuna)"
                                    [ngModel]="tuna.position" 
                                    (blur)="completePosition(tuna)" 
                                    (keydown.tab)="completePosition(tuna)" 
                                    (keydown.enter)="completePosition(tuna)"  
                                    required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{index}}
                                <!-- ( {{tuna.position}} ) -->
                            </ng-template>
                        </p-cellEditor>
                        <!-- <p-tableCheckbox [value]="tuna"></p-tableCheckbox> -->
                    </td>

                    <td pEditableColumn [class]="includesCol('title') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="tuna.name" (blur)="completeTuna(tuna)" (keydown.tab)="completeTuna(tuna)" (keydown.enter)="completeTuna(tuna)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{tuna.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn [class]="includesCol('datetime') ? 'd' : 'h'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="tuna.title" (blur)="completeTuna(tuna)" (keydown.tab)="completeTuna(tuna)" (keydown.enter)="completeTuna(tuna)"  required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{tuna?.datetime | date: 'dd/MM/yyyy HH:mm' }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td class="row-actions">
                        <!-- <button pButton style="margin-right: 10px"  pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" (click)="editTuna(tuna)"></button> -->
                        <button pButton style="margin-right: 10px"  pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteTuna(tuna)"></button>
                        <p-tableCheckbox class="checkbox" [value]="tuna"></p-tableCheckbox>
                        
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-a>
                <tr style="background-color: white;">
                    <td colspan="5">


                        <app-tuna [batchTuna]="a" [tunas]="a.tunas"></app-tuna>

                       
                        <!-- <div class="section" *ngFor="let section of toSections(a)">
                            <div class="section-title">{{ section.title }}</div>
                            <div class="section-line" *ngFor="let field of toFields(section)">
                               <img style="vertical-align: middle;" src="assets/images/1.png" width="20px" *ngIf="field.help != 'true'"/>
                               <img style="vertical-align: middle;" src="assets/images/3.png" width="20px" *ngIf="field.help == 'true'"/>
                               {{ field.title }} 
                               <span class="field-value" *ngIf="field.value && field.value.trim() !== ''"><b> : {{ field.value }}  </b></span><br>

                            </div> -->
                        <!-- </div> -->
                    </td>
                </tr>
            </ng-template>


            <ng-template pTemplate="summary">
                <div class="summary" style="border-width: 0px;;">
                    <div class="summary-total">
                        Total: {{tunas ? tunas.length : 0 }} process{{tunas.length > 1? 'es': ''}}
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>

    </div>

    <div class="page-footer">
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>
    </div>
    
    <p-dialog [(visible)]="tunaDialog" [style]="{width: '450px', height: '350px'}" header="Harvest of tunas" [modal]="true" styleClass="p-fluid" [baseZIndex]='1000'>
            <div class="p-field">
                <label for="name">Identifier</label>
                <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="tuna.name" required autofocus />
            </div>
            <div class="p-field">
                <label for="datetime">Date and time</label>
                <p-calendar [(ngModel)]="harvestDate"  [ngModelOptions]="{standalone: true}" [showIcon]="true" [showTime]="true" [showSeconds]="true" inputId="time" (onSelect)="onSelect($event)" (onSelect)="onBlur($event)" (onClose)="onClose($event)"></p-calendar>
            </div>
            <div class="in-out" style="margin-top: 20px;">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveTuna()"></button>
            </div>
    </p-dialog>
    
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


</div>







