<div class="page">
    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedBlocks()" [disabled]="!selectedBlocks || !selectedBlocks.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table
        #dt 
        [value]="blocks" 
        [rows]="5" 
        [paginator]="true" 
        [globalFilterFields]="['id','name','location','grape','size','StartDate','EndDate']"
        [rowsPerPageOptions]="[5,10,25,50,100]"
        [(selection)]="selectedBlocks" 
        [resizableColumns]="true"
        [reorderableColumns]="true"
        [rowHover]="true"
        dataKey="id"
        styleClass="p-datatable-striped"
        currentPageReportTemplate="{first} to {last} of {totalRecords} blocks" 
        [showCurrentPageReport]="true"
        selectionMode="multiple"
        [columns]="_selectedColumns"
        (onRowReorder)="reorder($event)" 
        [multiSortMeta]="[{field: 'StartDate', order: -1}]"
        sortMode="multiple"
        >
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>
    
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 0.8rem"></th>
                    <th id="id" name="id" pResizableColumn pReorderableColumn pSortableColumn="id" style="width: 3rem;font-size: 0.75rem" [class]="isSelectedColumn('id') ? 'show' : 'hide'" >
                        ID<p-sortIcon field="id"></p-sortIcon>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" style="width: 3rem;font-size: 0.75rem" [class]="isSelectedColumn('name') ? 'show' : 'hide'">
                        Name<p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="grape" name="grape" pResizableColumn pReorderableColumn pSortableColumn="grape" style="width: 3rem;font-size: 0.75rem" [class]="isSelectedColumn('grape') ? 'show' : 'hide'">
                        Grape<p-sortIcon field="grape"></p-sortIcon>
                        <p-columnFilter type="text" field="grapes" display="menu"></p-columnFilter>
                    </th>
                    <th id="location" name="location" pResizableColumn pReorderableColumn pSortableColumn="location" style="width: 3rem;font-size: 0.75rem" [class]="isSelectedColumn('location') ? 'show' : 'hide'">
                        Location<p-sortIcon field="location"></p-sortIcon>
                        <p-columnFilter type="text" field="location" display="menu"></p-columnFilter>
                    </th>
                    <th id="size" name="size" pResizableColumn pReorderableColumn pSortableColumn="size" style="width: 3rem;font-size: 0.75rem" [class]="isSelectedColumn('size') ? 'show' : 'hide'">
                        Size (m<sup>2</sup>)<p-sortIcon field="size"></p-sortIcon>
                        <p-columnFilter type="text" field="size" display="menu"></p-columnFilter>
                    </th>
                    <th id="StartDate" name="StartDate" pResizableColumn pReorderableColumn pSortableColumn="StartDate" style="width: 3rem;font-size: 0.75rem" [class]="isSelectedColumn('StartDate') ? 'show' : 'hide'">
                        Start Date<p-sortIcon field="StartDate"></p-sortIcon>
                        <p-columnFilter type="text" field="StartDate" display="menu"></p-columnFilter>
                    </th>
                    <th id="EndDate" name="EndDate" pResizableColumn pReorderableColumn pSortableColumn="EndDate" style="width: 3rem;font-size: 0.75rem" [class]="isSelectedColumn('EndDate') ? 'show' : 'hide'">
                        End Date<p-sortIcon field="EndDate"></p-sortIcon>
                        <p-columnFilter type="text" field="EndDate" display="menu"></p-columnFilter>
                    </th>
                    <th id="actions" name="actions" pResizableColumn pReorderableColumn style="width:4rem">
                        Actions 
                    </th> 
                </tr>
            </ng-template>

            <ng-template 
            pTemplate="body"
            let-block
            let-expanded="expanded"
            >
                <tr>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="block"  class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td [class]="isSelectedColumn('id') ? 'show' : 'hide'">
                        {{block.id}}
                    </td>
                    <td [pEditableColumn]="block" [pEditableColumnField]="'name'" [class]="isSelectedColumn('name') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText type="text" 
                                    [(ngModel)]="block.name"
                                    required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{block.name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td [pEditableColumn]="block" [pEditableColumnField]="'grape'" [class]="isSelectedColumn('grape') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText type="text" 
                                    [(ngModel)]="block.grape"
                                    required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{block.grape}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td [pEditableColumn]="block" [pEditableColumnField]="'location'" [class]="isSelectedColumn('location') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText type="text" 
                                    [(ngModel)]="block.location"
                                    required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{block.location}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td [pEditableColumn]="block" [pEditableColumnField]="'size'" [class]="isSelectedColumn('size') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText type="text" 
                                    [(ngModel)]="block.size"
                                    required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{block.size}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td [pEditableColumn]="block" [pEditableColumnField]="'StartDate'" [class]="isSelectedColumn('StartDate') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText type="date" 
                                    [(ngModel)]="block.StartDate"
                                    required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{block.StartDate | date: 'dd/MM/YYYY'}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td [pEditableColumn]="block" [pEditableColumnField]="'EndDate'" [class]="isSelectedColumn('EndDate') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText type="date" 
                                    [(ngModel)]="block.EndDate"
                                    required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{block.EndDate | date: 'dd/MM/YYYY'}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td class="row-actions">
                        <button style="margin-right: 10px" pButton pRipple icon="pi pi-history" class="p-button-rounded p-button-success" (click)="history(block)"></button>
                        <button pButton pRipple icon="pi pi-pencil" class="p-button-success p-mr-2" style="margin-right: 5px;" (click)="editBlock(block)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-warning p-mr-2" style="color: white; margin-right: 5px;" (click)="deleteBlock(block)"></button>
                        <p-tableCheckbox class="checkbox" [value]="block" ></p-tableCheckbox>
                    </td>
                </tr>

            </ng-template>

            <ng-template pTemplate="rowexpansion" let-block>
                <tr>
                    <td colspan="9">
                        <div class="row-expansion-inline">
                            <div class="row-expansion-inline-box">
                                <img *ngIf="block.mapLink" src="{{block.mapLink}}" alt="">
                                <div class="row-expansion-inline-box-buttons"> 
                                    <button pButton label="Zoom in" (click)="static_map_zoom_in(block)"></button>
                                    <button pButton label="Zoom out" (click)="static_map_zoom_out(block)"></button>
                                </div>
                            </div>
                            <p-table *ngIf="block.records" [value]="block.records" dataKey="r_name">
                                <ng-template pTemplate="header" >
                                    <tr>
                                        <th style="width: 3rem"></th>
                                        <th id="r_name" style="width: 3rem;font-size: 0.75rem"  [class]="isSelectedColumn('name') ? 'show' : 'hide'" >Name</th>
                                        <th id="r_grape" style="width: 3rem;font-size: 0.75rem"  [class]="isSelectedColumn('grape') ? 'show' : 'hide'">Grape</th>
                                        <th id="r_location" style="width: 3rem;font-size: 0.75rem"  [class]="isSelectedColumn('location') ? 'show' : 'hide'">Location</th>
                                        <th id= "r_size" style="width: 3rem;font-size: 0.75rem"  [class]="isSelectedColumn('size') ? 'show' : 'hide'">Size (m<sup>2</sup>)</th>
                                        <th id="r_StartDate" style="width: 3rem;font-size: 0.75rem"  [class]="isSelectedColumn('StartDate') ? 'show' : 'hide'">Start Date</th>
                                        <th id="r_EndDate" style="width: 3rem;font-size: 0.75rem"  [class]="isSelectedColumn('EndDate') ? 'show' : 'hide'" >End Date</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-record let-expanded="expanded">
                                    <tr>
                                        <td>
                                            <button type="button" pButton pRipple [pRowToggler]="record"  class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                        </td>
                                       <td  [class]="isSelectedColumn('name') ? 'show' : 'hide'">{{record.r_name}}</td>
                                       <td  [class]="isSelectedColumn('grape') ? 'show' : 'hide'">{{record.r_grape}}</td>
                                       <td  [class]="isSelectedColumn('location') ? 'show' : 'hide'">{{record.r_location}}</td>
                                       <td  [class]="isSelectedColumn('size') ? 'show' : 'hide'">{{record.r_size}}</td>
                                       <td  [class]="isSelectedColumn('StartDate') ? 'show' : 'hide'">{{record.r_StartDate}}</td>
                                       <td  [class]="isSelectedColumn('EndDate') ? 'show' : 'hide'">{{record.r_EndDate}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="rowexpansion" let-record>
                                    <div>
                                        <img src="{{record.r_mapLink}}" alt="">
                                        <div class="row-expansion-inline-box-buttons" style="width: 200%;"> 
                                            <button pButton label="Zoom in" (click)="static_map_zoom_in(record)"></button>
                                            <button pButton label="Zoom out" (click)="static_map_zoom_out(record)"></button>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-table>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="paginatorleft" ></ng-template>

            <ng-template pTemplate="paginatorright" >
                <div style="text-align: center; display: flex; align-items: center;">
                    <button pButton pRipple icon="pi pi-trash" class="button-action p-button-danger p-mr-2" (click)="deleteSelectedBlocks()" [disabled]="!selectedBlocks || !selectedBlocks.length "></button>
                    <p-tableHeaderCheckbox style="float: right;margin-right:1rem" class="checkbox"></p-tableHeaderCheckbox>
                </div>
            </ng-template>

        </p-table>
    
        <p-dialog 
            [(visible)]="eBlockDialog" 
            [style]="{width: '750px', height: '500px'}" 
            header="Block Details" 
            [modal]="true" 
            [maximizable]="true"
            styleClass="p-fluid" 
            [baseZIndex]="1000" 
            (onHide)="hideDialog()">
            <div class="p-field" style="margin-top: 10px;">


                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;">
                    <div class="p-field" style="margin-top: 10px; width: 100%; margin-right: 10px;">
                        <label for="title">Name</label>
                        <input style="margin-top: 10px;" type="text" pInputText id="name" [(ngModel)]="block.name" required autofocus />
                        <small class="p-error" *ngIf="submitted && !block.name">Name is required.</small>
    
                        <!-- <label for="description">Grape variety</label>
                        <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="block.description" rows="3" cols="20"></textarea> -->
                    </div>
    
                    <div class="p-field" style="margin-top: 10px; width: 100%; margin-left: 10px;">
                        <label for="grape">Grape variety</label>
                        <input style="margin-top: 10px;" type="text" pInputText id="grape" [(ngModel)]="block.grape" required autofocus />
                        <!-- <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="block.description" rows="3" cols="20"></textarea> -->
                    </div>
                </div>

                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end;">
                    <div class="p-field" style="margin-top: 10px; width: 100%;margin-right: 10px;">
                        <label for="startDate">From (date)</label>
                        <input style="margin-top: 10px; width:100%" type="text" pInputText id="startDate" [(ngModel)]="block.StartDate" required autofocus />
                    </div>
                    <div class="p-field" style="margin-top: 10px; width: 100%;margin-left: 10px;">
                        <label for="endDate">To (date)</label>
                        <input style="margin-top: 10px; width:100%" type="text" pInputText id="endDate" [(ngModel)]="block.EndDate" required autofocus />
                    </div>
                </div>  

                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end;">
                    <div class="p-field" style="margin-top: 10px; width: 100%;margin-right: 10px;">
                        <label for="location">Location</label>
                        <input style="margin-top: 10px; width:100%" type="text" pInputText id="location" [(ngModel)]="block.location" required autofocus />
                    </div>
                    <button style="width: 120px" pButton label="Locate" (click)="get_address()"></button>    
                </div>   

                 

               
                <div class="container">
                    <div style="width: 30%;">
                        <!-- <span style="width: 100%;">
                            <label for="f_name">Name</label>
                            <input id="f_name" type="text" pInputText [(ngModel)]="block.name" autofocus> 
                        </span>
                        <small class="p-error" *ngIf="submitted && !block.name">Name is required.</small>
                        <br> -->
                        <!-- <span style="width: 100%;">
                            <label for="f_grape">Grape</label>
                            <input id="f_grape" type="text" pInputText [(ngModel)]="block.grape" > 
                        </span>
                        <small class="p-error" *ngIf="submitted && !block.grape">Input required.</small>
                        <br> -->
                        <!-- <span style="width: 100%;">
                            <label for="f_location">Location</label>
                            <input id="f_location" type="text" pInputText [(ngModel)]="block.location" > 
                        </span> 
                        <br> -->
                        <small class="p-error" *ngIf="submitted && !block.location">Location is required</small>
                        <br>
                        <!-- <button style="width: auto;" pButton label="Get Location" (click)="get_address()"></button>
                        <br> -->
                        <!-- <span style="width: 100%; margin-top: 10px;">
                            <label for="size">Size (m<sup>2</sup>)</label>
                            <input id="size" min=0 type="number" pInputText [(ngModel)]="block.size" > 
                        </span>
                        <small class="p-error" *ngIf="submitted && block.size<0 || block.size==0">size required</small> -->
                        <!-- <br>
                        <label for="f_StartDate">Start Date</label>
                        <input id="f_StartDate" type="date" pInputText [(ngModel)]="block.StartDate" style="width: 100%;" > 
                        <small class="p-error" *ngIf="submitted && !block.StartDate">Date is required.</small>
                        <label for="f_EndDate">End Date</label>
                        <input id="f_EndDate" type="date" pInputText [(ngModel)]="block.EndDate" style="width: 100%;" >  -->
                    </div>
                    <div class="google_map">
                        <button style="width: 100%; height: 10%;" pButton (click)="initDrawingManager()" icon="pi pi-pencil" label="draw"></button>
                        <pre></pre>
                        <input style="width: 75%;" pInputText #mapSearchField type="text">
                        <google-map
                            #test
                            height = "700px"
                            width = "700px"
                            [zoom] = "zoom"
                            [center] = "center"
                            [options] ="options"
                        >
                        </google-map>
                        <br>
                        <button style="width: 10%; height: 10%;" pButton class="p-button-rounded" #recenterBtn (click)="recenter()" icon="pi pi-map-marker"></button>
                    </div>

                </div>

                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end;">
                    <div class="p-field" style="margin-top: 10px; width: 100%;margin-right: 10px;">
                        <label for="size">Size (m<sup>2</sup>)</label>
                        <input style="margin-top: 10px; width:100%" type="text" pInputText id="size" [(ngModel)]="block.size" required autofocus />
                    </div>
                    <!--    <button style="width: 120px" pButton label="Locate" (click)="get_address()"></button>     -->
                </div>   

                <!-- <div class="spreading-dialog-line" style="margin-top: 5%;">
                    <div class="spreading-dialog-line">
                        <div class="spreading-dialog-block" style="width: 830px; height: 300px; overflow-y: auto;">
                        </div>
                    </div>
                </div> -->
                
                <div class="in-out" style="margin-top: 20px;">
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-danger p-mr-2" (click)="hideDialog()"></button>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" (click)="saveBlock()"></button>
                </div>
            </div>
    
        </p-dialog>
    </div>
    <p-confirmDialog key="BlockDelete" [style]="{width: '450px'}"></p-confirmDialog>
</div>


