
<div class="page">
    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />
        </div>
        <div style="margin-right: 1.75rem">
            <button pButton pRipple icon="pi pi-plus" label="New" class="button-action p-button-success p-mr-2" (click)="openNew()" pTooltip="Add a new Spreading" tooltipPosition="top"></button>  
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedSpreadings()" [disabled]="!selectedSpreadings || !selectedSpreadings.length "></button>
        </div>
        <!-- <div style="margin-left: 1.75rem">
           <p-splitButton class="download_icon" label="AWRI"  [model]="AWRIitems"></p-splitButton>
           <p-splitButton class="download_icon" label="GrapeLink"  [model]="Glinkitems"></p-splitButton>
           <p-splitButton class="download_icon" label="GrapeWeb"  [model]="Gwebitems"></p-splitButton>
        </div> -->
    </div>

    <div class="page-content">
        <p-table #dt
            [value]="spreadings" 
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['name','description','sStartDate','sStartTime','sEndDate','sEndTime','target','taregtType','spray','sprayAmount','unitSprayAmount','waterAmount','unitWaterAmount','concentrationFactor','operatorFullname',
                                   'equipment','weather','humidity','temperature','unitTemp','soilTemperature','unitSoilTemp',
                                   'windDirection','windForce','comment','spreadingBlocks']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedSpreadings" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="-1"
            >
            
            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header" let-columns="columns">
                <tr>
                    <th id="rank" name="rank" pResizableColumn pSortableColumn="position" style="width: 8rem" [class]="isSelectedColumn('rank') ? 'show' : 'hide'">
                        Pos.<p-sortIcon field="position"></p-sortIcon>
                    </th>
                    <th id="id" name="id" pResizableColumn pReorderableColumn pSortableColumn="id"  [class]="isSelectedColumn('id') ? 'show' : 'hide'">Spreading 
                        <p-sortIcon field="id"></p-sortIcon> 
                        <p-columnFilter type="text" field="id" display="menu"></p-columnFilter>
                    </th>
                    <th id="sStartDate" name="sStartDate" pResizableColumn pReorderableColumn [class]="isSelectedColumn('sStartDate') ? 'show' : 'hide'">
                        Date (start)
                        <p-columnFilter type="sStartDate" field="sStartDate" display="menu"></p-columnFilter>
                    </th>
                    <th id="sStartTime" name="sStartTime" pResizableColumn pReorderableColumn [class]="isSelectedColumn('sStartTime') ? 'show' : 'hide'">
                        Time (start)
                        <p-columnFilter type="sStartTime" field="sStartTime" display="menu"></p-columnFilter>
                    </th>
                    <th id="sEndDate" name="sEndDate" pResizableColumn pReorderableColumn [class]="isSelectedColumn('sEndDate') ? 'show' : 'hide'">
                        Date (end)
                        <p-columnFilter type="sEndDate" field="sEndDate" display="menu"></p-columnFilter>
                    </th>
                    <th id="sEndTime" name="sEndTime" pResizableColumn pReorderableColumn [class]="isSelectedColumn('sEndTime') ? 'show' : 'hide'">
                        Time (end)
                        <p-columnFilter type="sEndTime" field="sEndTime" display="menu"></p-columnFilter>
                    </th>
                    <th id="actions" name="actions" pResizableColumn pReorderableColumn style="width: 10rem" [class]="isSelectedColumn('actions') ? 'show' : 'hide'">
                        Actions 
                        <p-tableHeaderCheckbox style="float: right;margin-right:3px" class="checkbox"></p-tableHeaderCheckbox>
                    </th>
                </tr>
            </ng-template>
            <ng-template 
                pTemplate="body" 
                let-spreading 
                let-expanded="expanded"
                let-columns="columns" 
                let-index="rowIndex"
                >

                <tr [pReorderableRow]="index" [pEditableRow]="spreading">
                    <td [class]="isSelectedColumn('rank') ? 'show' : 'hide'">
                        <span class="pi pi-bars" style="margin-right: 1rem;"></span>
                        <!-- <p-cellEditor  [pEditableColumnField]="spreading.position">
                            <ng-template pTemplate="input">
                                <input pInputText type="text"  style="width: 2.25rem;margin-right:0.75 rem"
                                [ngModel]="spreading.position" 
                                (ngModelChange)="modelChangeFn($event, spreading)"
                                (blur)="onFieldEdit( spreading, 'position' )"
                                (keydown.tab)="onFieldEdit( spreading, 'position' )"
                                (keydown.enter)="onFieldEdit( spreading, 'position' )"
                                required>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{spreading.position}} 
                            </ng-template>
                        </p-cellEditor> -->
                        {{spreading.position}} 
                    </td>

                    <td [class]="isSelectedColumn('id') ? 'show' : 'hide'">
                        <span>{{spreading.name}}</span>
                    </td>
                   
                    <td [pEditableColumn]="spreading" [pEditableColumnField]="'sStartDate'" [class]="isSelectedColumn('sStartDate') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText type="date" 
                                    [(ngModel)]="spreading.sStartDate" 
                                    >
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{spreading.sStartDate | date: 'dd/MM/YYYY'}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td [pEditableColumn]="spreading" [pEditableColumnField]="'sStartTime'" [class]="isSelectedColumn('sStartTime') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText type="time" 
                                    appendTo="body" 
                                    [(ngModel)]="spreading.sStartTime" 
                                    >
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{spreading.sStartTime }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td [pEditableColumn]="spreading" [pEditableColumnField]="'sEndDate'" [class]="isSelectedColumn('sEndDate') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="date" [(ngModel)]="spreading.sEndDate" 
                                    >
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{spreading.sEndDate | date: 'dd/MM/yyyy'   }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td [pEditableColumn]="spreading" [pEditableColumnField]="'sEndTime'" [class]="isSelectedColumn('sEndTime') ? 'show' : 'hide'">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText type="time" 
                                    [(ngModel)]="spreading.sEndTime" 
                                    >
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span >{{spreading.sEndTime }}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td class="row-actions" [class]="isSelectedColumn('actions') ? 'show' : 'hide'">
                        <button pButton pRipple icon="pi pi-pencil" style="margin-right: 5px;" class="p-button-success p-mr-2" (click)="editSpreading(spreading)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-warning p-mr-2" style="color: white" (click)="deleteSpreading(spreading)"></button>
                        <p-tableCheckbox class="checkbox" [value]="spreading"></p-tableCheckbox>
                    </td>
                </tr>
            </ng-template>

            <ng-template 
                pTemplate="rowexpansion"
                let-spreading >
                <tr>
                    <td colspan="8">
                        <div class="expansion">

                            <div class="expansion-line">
                                <div class="card">
                                    <div  class="card-header">
                                        Target
                                    </div>
                                    <div class="target">
                                        <div class="target-disease">
                                            
                                            <p-inplace #pi1 [closable]="true">
                                                <ng-template pTemplate="content" >
                                                    <p-dropdown 
                                                        [options]="targets!" 
                                                        appendTo="body" 
                                                        [(ngModel)]="spreading.target" 
                                                        [style]="{'width':'80%'}"  
                                                        (onChange)="pi1.deactivate();" 
                                                        (blur)="onFieldEdit( spreading, 'target' )"
                                                        (keydown.tab)="onFieldEdit( spreading, 'target' )"
                                                        (keydown.enter)="onFieldEdit( spreading, 'target' )">
                                                    </p-dropdown>
                                                </ng-template>
                                                <ng-template pTemplate="display" >
                                                    <span 
                                                    pTooltip="Specific target that we want to remove (pest, disease, weed)" 
                                                    tooltipPosition="bottom"
                                                    >{{spreading.target}}
                                                </span>
                                                </ng-template>
                                            </p-inplace>

                                        </div>
                                        <div class="target-type">
                                            <img src="assets/images/targets/{{getTargetType(spreading)}}2.png" width="100px" pTooltip="Target schema, here it is {{getTargetType(spreading)}}" tooltipPosition="bottom">
                                        </div>
                                    </div>
                                </div>
    
                                <div class="card-center">
                                    <div  class="card-header">
                                        Product
                                    </div>
                                    <div class="product">

                                        <div class="product-col">
                                            <div class="product-line">
                                                <div class="product-block">
                                                    <img src="assets/images/spray.png" width="60px">  
                                                </div>
                                                <div class="product-block">
                                                 
                                                    <p-inplace #piSpray [closable]="true" >
                                                        <ng-template pTemplate="content" >
                                                            <p-dropdown [options]="sprays" [style]="{'width':'80%', 'margin-right':'5px'}" appendTo="body" ></p-dropdown>
                                                        </ng-template>
                                                        <ng-template pTemplate="display">
                                                            <span 
                                                            pTooltip="Name of psray used" tooltipPosition="bottom"
                                                            >{{spreading.spray}}</span>
                                                        </ng-template>
                                                    </p-inplace>

                                                </div>
                                            </div>
                                            <div class="product-line">
                                                <div class="product-block">
                                                    <img src="assets/images/tmp/beakers.png" (click)="openCF()" width="60px">  
                                                </div>
                                                <div class="product-block">

                                                    <p-inplace #piCF [closable]="true">
                                                        <ng-template pTemplate="content" >
                                                            <input pInputText type="number"  style="width: 80%;margin-right: 5px;" (onClick)="piCF.deactivate();" [(ngModel)]="spreading.concentrationFactor" >
                                                        </ng-template>
                                                        <ng-template pTemplate="display">
                                                            <span 
                                                            pTooltip="Concentration factor used for concentrate spraying" tooltipPosition="bottom"
                                                            >{{spreading.concentrationFactor}}</span>
                                                        </ng-template>
                                                    </p-inplace>
         
                                                </div>
                                            </div>
                                        </div>

                                        <div class="product-col">
                                            <div class="product-line">
                                                <div class="product-block" >
                                                    <img src="assets/images/meteo/water.png" (click)="openWater()" width="40px">
                                                </div>
                                                <div class="product-block">

                                                    <p-inplace #piWaterAmount [closable]="true" >
                                                        <ng-template pTemplate="content" >
                                                            <input pInputText type="text"  style="width: 80%;margin-right: 5px;" (onClick)="piWaterAmount.deactivate();" [(ngModel)]="spreading.waterAmount">
                                                        </ng-template>
                                                        <ng-template pTemplate="display">
                                                            <span 
                                                            pTooltip="It is the actual amount of water or spray mixture applied to the canopy or ground expressed as litres per hectare or per 100m as required. The amount of water sprayed is often referred to as spray volume. State the actual chosen rate used NOT a range that appears on some labels." tooltipPosition="bottom"
                                                            >{{spreading.waterAmount}}</span>
                                                        </ng-template>
                                                    </p-inplace>

                                                    <p-inplace #piUnitWater [closable]="true" >
                                                        <ng-template pTemplate="content" >
                                                            <p-dropdown 
                                                                [options]="unitAmoutOfWaterSprayed" 
                                                                appendTo="body" 
                                                                [(ngModel)]="spreading.unitWaterAmount" 
                                                                [filter]="false" 
                                                                [style]="{'width':'50px'}" 
                                                                (onChange)="piUnitWater.deactivate();" >
                                                                <ng-template let-unitWater pTemplate="item">
                                                                    <div class="unit">
                                                                        <div>{{unitWater}}</div>
                                                                    </div>
                                                                </ng-template>
                                                            </p-dropdown>
                                                        </ng-template>                                              
                                                        <ng-template pTemplate="display">
                                                            <span>
                                                                {{ spreading.unitWaterAmount }}
                                                            </span>
                                                            <span *ngIf="!spreading.unitWaterAmount">
                                                                L/ha
                                                            </span>
                                                        </ng-template>
                                                    </p-inplace>

                                                </div>
                                            </div>
                                            <div class="product-line">
                                                <div class="product-line">
                                                    <div class="product-block">
                                                        <img src="assets/images/tmp/quantityOfProductUsed.png" (click)="openProductUsed()" width="60px"> 
                                                    </div>
                                                    <div class="product-block">
                                                        <p-inplace #piSprayAmount [closable]="true" >
                                                            <ng-template pTemplate="content" >
                                                                <input pInputText type="text"  style="width: 80%;margin-right: 5px;" (onClick)="piSprayAmount.deactivate();" [(ngModel)]="spreading.sprayAmount" >
                                                            </ng-template>
                                                            <ng-template pTemplate="display">
                                                                <span 
                                                                pTooltip="It is the total amount of registered agrochemical used expressed as product per hectare or product per 100m as required" tooltipPosition="bottom"
                                                                >{{spreading.sprayAmount}}</span>
                                                            </ng-template>
                                                        </p-inplace>

                                                        <p-inplace #piUnitProduct [closable]="true" >
                                                            <ng-template pTemplate="content" >
                                                                <p-dropdown 
                                                                    [options]="unitAmoutOfProductUsed" 
                                                                    appendTo="body" 
                                                                    [(ngModel)]="spreading.unitSprayAmount" 
                                                                    [filter]="false" 
                                                                    [style]="{'width':'50px'}" 
                                                                    (onChange)="piUnitProduct.deactivate();">
                                                                    <ng-template let-unitProduct pTemplate="item">
                                                                        <div class="unit">
                                                                            <div>{{unitProduct}}</div>
                                                                        </div>
                                                                    </ng-template>
                                                                </p-dropdown>
                                                            </ng-template>                                              
                                                            <ng-template pTemplate="display">
                                                                <span>
                                                                    {{ spreading.unitSprayAmount }}
                                                                </span>
                                                                <span *ngIf="!spreading.unitSprayAmount">
                                                                    g
                                                                </span>
                                                            </ng-template>
                                                        </p-inplace>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
    
                                <div class="card">
                                    <div class="card-header">
                                        Miscellaneous
                                    </div>
                                    <div class="misc">
                                        <div class="misc-block">
                                            <div class="misc-title">
                                                Operator
                                            </div>
                                            <div class="misc-value">
                                                <p-inplace #piOperator [closable]="true" >
                                                    <ng-template pTemplate="content" >
                                                        <!-- [(ngModel)]="spreading.operatorFullname" -->
                                                        <p-dropdown [options]="operators!" appendTo="body" [style]="{'width':'100%'}"></p-dropdown>
                                                    </ng-template>
                                                    <ng-template pTemplate="display">
                                                        <span >{{spreading.operatorFullname}}</span>
                                                    </ng-template>
                                                </p-inplace>
                                            </div>
                                        </div>
                                        <div class="misc-block">
                                            <div class="misc-title">
                                                Equipment
                                            </div>
                                            <div class="misc-value">
                                                <p-inplace #piEquipment [closable]="true" >
                                                    <ng-template pTemplate="content" >
                                                        <p-dropdown [options]="machines!" appendTo="body" [style]="{'width':'100%'}"></p-dropdown>
                                                    </ng-template>
                                                    <ng-template pTemplate="display">
                                                        <span >{{spreading.equipment}}</span>
                                                    </ng-template>
                                                </p-inplace>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="expansion-line">

                                <div class="card-center" style="width: 35%">
                                    <div class="card-header">
                                        Weather
                                    </div>
                                    <div class="weather">
                                        <div class="weather-line" >
                                            
                                            <div class="weather-block" >

                                                <div class="weather-block-p1">
                                                    <img src="assets/images/meteo/{{ spreading.weather? spreading.weather : 'question' }}.png" height="35px" style="vertical-align: middle;margin-right: 10px" pTooltip="Weather that we had during the spreading." tooltipPosition="bottom">
                                                </div>
                                                
                                                    <p-inplace #piMeteo [closable]="true"  class="weather-block-p2" >
                                                        <ng-template pTemplate="content" >
                                                            <!-- optionLabel="label" filterBy="label"  <input pInputText type="text" [(ngModel)]="spreading.target" required> -->
                                                       
                                                            <p-dropdown 
                                                                [options]="meteo" 
                                                                appendTo="body" 
                                                                [(ngModel)]="spreading.weather" 
                                                                [filter]="true" 
                                                                [style]="{'width':'80%'}" 
                                                                (onChange)="piMeteo.deactivate();">
                                                                <ng-template let-weather pTemplate="item">
                                                                    <div class="weather-block">
                                                                        <img src="assets/images/meteo/{{ weather }}.png" height="35px" style="vertical-align: middle;margin-right: 10px">
                                                                        <div >{{weather}}</div>
                                                                    </div>
                                                                </ng-template>
                                                            </p-dropdown>
                                                      
                                                         
                                                        </ng-template>                                              
                                                        <ng-template pTemplate="display">
                                                            <div class="weather-block-p2-display" *ngIf="!spreading.weather || spreading.weather.length < 3">
                                                                <!-- <span *ngIf="!spreading.weather || spreading.weather.length < 3">&nbsp;</span> -->
                                                                &nbsp;
                                                            </div>
                                                            <div class="weather-block-p2-display" *ngIf="spreading.weather && spreading.weather.length >= 3">
                                                                {{spreading.weather}}
                                                            </div>
                                                        </ng-template>
                                                    </p-inplace>

                                                </div>
                                            <div class="weather-block">

                                                <div class="weather-block-p1">
                                                    <img src="assets/images/meteo/humidity.png" width="35px" pTooltip="Humidity rate if available" tooltipPosition="bottom">
                                                </div>
                                            
                                                <div class="weather-block-p2p">
                                                    <p-inplace #piHumidity [closable]="true"  class="weather-block-p2p-int" >
                                                        <ng-template pTemplate="content" >
                                                            <input pInputText type="number"  style="width: 80%;margin-right: 5px;" (onClick)="piHumidity.deactivate();" [(ngModel)]="spreading.humidity">
                                                        </ng-template>
                                                        <ng-template pTemplate="display">
                                                            
                                                            <div class="weather-block-p2-display" *ngIf="!spreading.humidity || spreading.humidity < 0 ">
                                                                &nbsp;
                                                            </div>
                                                            <div class="weather-block-p2-display" *ngIf="spreading.humidity && spreading.humidity >= 0">
                                                                {{spreading.humidity}}
                                                            </div>
                                                            
                                                        </ng-template>
                                                    </p-inplace>
                                                </div>
                                                <div class="weather-block-p3">
                                                    &nbsp;%
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="weather-line">
                                            <div class="weather-block">
                                                <div class="weather-block-p1">
                                                    <img src="assets/images/meteo/temperature.png" width="35px" pTooltip="Outside temperature by defualt in °C but you can change" tooltipPosition="bottom">
                                                </div>
                                               
                                                <div class="weather-block-p2p">
                                                    <p-inplace #piTemp [closable]="true"  class="weather-block-p2p-int" >
                                                        <ng-template pTemplate="content" >
                                                            <input pInputText type="number"  style="width: 80%;margin-right: 5px;" (onClick)="piTemp.deactivate();" [(ngModel)]="spreading.temperature">
                                                        </ng-template>
                                                        <ng-template pTemplate="display">
                                                            <div class="weather-block-p2-display" *ngIf="!spreading.temperature">
                                                                &nbsp;
                                                            </div>
                                                            <div class="weather-block-p2-display" *ngIf="spreading.temperature">
                                                                {{spreading.temperature}}
                                                            </div>
                                                        </ng-template>
                                                    </p-inplace>
                                                </div>
                                                
                                                <div class="weather-block-p3p">
                                                    <p-inplace #piUnitTemp [closable]="true"  class="weather-block-p2p-int">
                                                        <ng-template pTemplate="content" >
                                                            <p-dropdown 
                                                                [options]="unitTemp" 
                                                                appendTo="body" 
                                                                [(ngModel)]="spreading.unitTemp" 
                                                                [filter]="false" 
                                                                [style]="{'width':'50px'}" 
                                                                (onChange)="piUnitTemp.deactivate();">
                                                                <ng-template let-unitTemp pTemplate="item">
                                                                    <div class="unit">
                                                                        <div>{{unitTemp}}</div>
                                                                    </div>
                                                                </ng-template>
                                                            </p-dropdown>
                                                        </ng-template>                                              
                                                        <ng-template pTemplate="display">
                                                            <span class="weather-block-p2-display" *ngIf="spreading.unitTemp && spreading.unitTemp.trim().length >= 1">
                                                                {{ spreading.unitTemp }}
                                                            </span>
                                                            <span *ngIf="!spreading.unitTemp || spreading.unitTemp.trim().length < 1" class="weather-block-p2-display">
                                                                °C
                                                            </span>
                                                        </ng-template>
                                                    </p-inplace>
                                                </div>
                                                

                                            </div>
                                            <div class="weather-block">
                                                <div class="weather-block-p1">
                                                    <img src="assets/images/meteo/soilTemperature.png" width="40px" style="padding-right: 5px" pTooltip="Soil temperature if known" tooltipPosition="bottom">
                                                </div>
                                                
                                                <div class="weather-block-p2p">
                                                    <p-inplace #piSoilTemp [closable]="true"  class="weather-block-p2p-int" >
                                                        <ng-template pTemplate="content" >
                                                            <input pInputText type="number"  style="width: 80%;margin-right: 5px;" (onClick)="piSoilTemp.deactivate();" [(ngModel)]="spreading.soilTemperature">
                                                        </ng-template>
                                                        <ng-template pTemplate="display">
                                                            <div class="weather-block-p2-display" *ngIf="!spreading.soilTemperature">
                                                                &nbsp;
                                                            </div>
                                                            <div class="weather-block-p2-display" *ngIf="spreading.soilTemperature">
                                                                {{spreading.soilTemperature}}
                                                            </div>
                                                        </ng-template>
                                                    </p-inplace>
                                                </div>
                                                
                                                <div class="weather-block-p3p">
                                                    <p-inplace #piUnitTemp [closable]="true"  class="weather-block-p2p-int">
                                                        <ng-template pTemplate="content" >
                                                            <p-dropdown 
                                                                [options]="unitSoilTemp" 
                                                                appendTo="body" 
                                                                [(ngModel)]="spreading.unitSoilTemp" 
                                                                [filter]="false" 
                                                                [style]="{'width':'50px'}" 
                                                                >
                                                                <ng-template let-unitSoilTemp pTemplate="item">
                                                                    <div class="unit">
                                                                        <div>{{unitSoilTemp}}</div>
                                                                    </div>
                                                                </ng-template>
                                                            </p-dropdown>
                                                        </ng-template>                                              
                                                        <ng-template pTemplate="display">
                                                            <span class="weather-block-p2-display" *ngIf="spreading.unitSoilTemp && spreading.unitSoilTemp.trim().length >= 1">
                                                                {{ spreading.unitSoilTemp }}
                                                            </span>
                                                            <span *ngIf="!spreading.unitSoilTemp || spreading.unitSoilTemp.trim().length < 1" class="weather-block-p2-display">
                                                                °C
                                                            </span>
                                                        </ng-template>
                                                    </p-inplace>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                        <div class="weather-line">
                                            <div class="weather-block">
                                                <div class="weather-block-p1">
                                                    <img src="assets/images/meteo/wind.png" width="100%" (click)="openBeaufort()" pTooltip="Wind Force according the Beaufort scale (click on the icon for help)" tooltipPosition="bottom"> 
                                                </div>
                                                <div class="weather-block-p2" style="display: flex; align-items: center;">
                                                    <p-inplace #piWindForce [closable]="true"  class="weather-block-p2p-int" >
                                                        <ng-template pTemplate="content" >
                                                            <input pInputText type="number"  style="width: 80%;margin-right: 5px;" (onClick)="piWindForce.deactivate();" [(ngModel)]="spreading.windForce">
                                                        </ng-template>
                                                        <ng-template pTemplate="display">
                                                            <div class="weather-block-p2-display" *ngIf="!spreading.windForce || spreading.windForce < 0">
                                                                &nbsp;
                                                            </div>
                                                            <div class="weather-block-p2-display" *ngIf="spreading.windForce && spreading.windForce >=0">
                                                                {{spreading.windForce}}
                                                            </div>
                                                        </ng-template>
                                                    </p-inplace>
                                                </div>
                                            </div>
                                            
                                            <div class="weather-block">
                                                <div class="weather-block-p1">
                                                    <img src="assets/images/meteo/compass.png" width="35px" style="padding-right: 5px" pTooltip="Wind direction" tooltipPosition="bottom">
                                                </div>

                                                <!-- <div class="weather-block-p2"> -->
                                                    <p-inplace #piWindDirection [closable]="true"  class="weather-block-p2">
                                                        <ng-template pTemplate="content" >
                                                            <p-dropdown 
                                                                [options]="windDirections"  
                                                                appendTo="body" 
                                                                [(ngModel)]="spreading.windDirection" 
                                                                [filter]="true" 
                                                                [style]="{'width':'100%'}" 
                                                                (onChange)="piWindDirection.deactivate();">
                                                                <ng-template let-windDirection pTemplate="item">
                                                                    <div class="weather-block">
                                                                        <!-- <img src="assets/images/meteo/{{ weather }}.png" width="45px"> -->
                                                                        <div>{{windDirection}}</div>
                                                                    </div>
                                                                </ng-template>
                                                            </p-dropdown>
                                                        </ng-template>                                              
                                                        <ng-template pTemplate="display">
                                                            <div class="weather-block">
                                                              
                                                            </div>
                                                            <div class="weather-block-p2-display" *ngIf="!spreading.windDirection || spreading.windDirection.length < 3">
                                                                &nbsp;
                                                            </div>
                                                            <div class="weather-block-p2-display" *ngIf="spreading.windDirection && spreading.windDirection.length >= 3">
                                                                {{spreading.windDirection}}
                                                            </div>
                                                        </ng-template>
                                                    </p-inplace>
                                                <!-- </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div class="card-center" style="width: 50%;overflow-y: auto;">
                                    <div class="card-header">
                                        Blocks
                                    </div>
                                    <div class="blocks" style="overflow-y: auto;">
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft" ></ng-template>

            <ng-template pTemplate="paginatorright" >
                <div style="text-align: center; display: flex; align-items: center;">
                    
                </div>
            </ng-template>

            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{spreadings ? spreadings.length : 0 }} spreading{{spreadings.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                        <button type="button" style="margin-right: 10px" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>
    </div>

    <div class="page-footer"> 
        <div class="p-d-flex">
        </div> 
    </div>
    




    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    <p-dialog [(visible)]="spreadingDialog" [style]="{width: '900px', height: '700px'}" header="Spreading Details" [modal]="true" styleClass="p-fluid" [baseZIndex]="1000">
        
        <div class="spreading-dialog">

            <div class="spreading-dialog-line">
                <div class="spreading-dialog-line">
                    <div class="spreading-dialog-block" style="width: 830px">
                        <div class="spreading-dialog-title" >Dates</div>

                        <div class="spreading-dialog-dateline">
                                <input pInputText type="date" style="width: 170px;" [(ngModel)]="spreading.sStartDate">
                                <input pInputText type="time" style="width: 112px;" [(ngModel)]="spreading.sStartTime">
                                <input pInputText type="date" style="width: 170px;" [formControl]="endDateFormControl" [(ngModel)]="spreading.sEndDate">
                                <input pInputText type="time" style="width: 100px;" [(ngModel)]="spreading.sEndTime">

                        </div>
                    </div>
                </div>
            </div>


            <div class="spreading-dialog-line">
                <div class="spreading-dialog-line">
                    <div class="spreading-dialog-block" style="width: 830px">
                        <div class="spreading-dialog-title" >Spreading Name</div>

                        <div class="comment" >
                            <textarea [(ngModel)]="spreading.name" style="flex: 1" pInputTextarea ></textarea> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="spreading-dialog-line">
                <div class="spreading-dialog-line">
                    <div class="spreading-dialog-block" style="width: 830px;">
                        <div class="spreading-dialog-title" >Product</div>

                        <div class="spreading-dialog-weather-line" style="height: 100%;align-items: center;vertical-align: middle;">
                            
                            
                            <div style="display: flex; flex-direction: row;">
                                <img src="../../assets/spray.png" width="30px"> 
                                <p-dropdown [options]="sprays" optionLabel="name" optionValue="$key" [(ngModel)]="spreading.spray" placeholder="Select a Spray" [showClear]="true" [style]="{'width':'175px', 'margin-right':'5px','margin-left': '5px'}"    ></p-dropdown>
                            </div >
                            <div >
                                <img src="../../assets/tmp/beakers.png" (click)="openCF()" width="30px">  
                                <input pInputText type="text" [(ngModel)]="spreading.concentrationFactor" style="width: 40px;margin-right: 5px;margin-left: 5px;">
                            </div>
                            <div style="display: flex; flex-direction: row;align-items: center;">
                                <img src="../../assets/meteo/water.png" (click)="openWater()" width="30px">  
                                <input pInputText type="text" [(ngModel)]="spreading.waterAmount" style="width: 40px;margin-right: 5px;margin-left: 5px;" > 
                                <p-dropdown 
                                    [options]="unitAmoutOfWaterSprayed" 
                                    appendTo="body"
                                    [filter]="false" 
                                    [style]="{'width':'50px'}" 
                                    [(ngModel)]="spreading.unitWaterAmount"
                                    >
                                    <ng-template let-unitWater pTemplate="item">
                                        <div class="unit">
                                            <div>{{unitWater}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>       
                            </div>
                            <div style="display: flex; flex-direction: row;align-items: center;">
                                <img src="assets/tmp/quantityOfProductUsed.png" (click)="openProductUsed()" width="30px"> 
                                <input pInputText type="text" [(ngModel)]="spreading.sprayAmount" style="width: 40px;margin-right: 5px;margin-left: 5px;" >         
                                <p-dropdown 
                                    [options]="unitAmoutOfProductUsed" 
                                    appendTo="body" 
                                    [filter]="false" 
                                    [(ngModel)]="spreading.unitSprayAmount"
                                    [style]="{'width':'70px'}">
                                    <ng-template let-unitProduct pTemplate="item">
                                        <div class="unit">
                                            <div>{{unitProduct}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="spreading-dialog-line">
                <div class="spreading-dialog-block" style="width: 250px">
                    <div class="spreading-dialog-title" >Target</div>
                    <div class="spreading-dialog-content" style="height: 100%;">
                        <div style="margin-top: 15px;">
                            <p-dropdown placeholder="Select a target" [(ngModel)]="spreading.target" [options]="targets!" optionLabel="name" optionValue="$key" [showClear]="true" [style]="{'width':'100%'}" ></p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="spreading-dialog-block" style="width: 250px">
                    <div class="spreading-dialog-title" >Operator</div>
                    <div style="margin-top: 15px;">
                        <input pInputText type="text" [(ngModel)]="spreading.operatorFullname" style="width: 80%;margin-right: 5px;"  placeholder="Enter an Operator">
                    </div>
                </div>
                <div class="spreading-dialog-block" style="width: 250px">
                    <div class="spreading-dialog-title" >Equipment</div>
                    <div style="margin-top: 15px;">
                        <p-dropdown [options]="equipments!" optionLabel="name" optionValue="$key" [(ngModel)]="spreading.equipment" placeholder="Select an equipment" [showClear]="true" [style]="{'width':'100%'}" ></p-dropdown>
                    </div>
                </div>
            </div>

            <div class="spreading-dialog-line">
                <div class="spreading-dialog-line" >
                    <div class="spreading-dialog-block" style="width: 830px; height: 150px">
                        <div class="spreading-dialog-title" >Weather</div>
                        <div class="spreading-dialog-weather-line">
                            <div class="spreading-dialog-weather-line-b" >
                                <div style="display: flex; flex-direction: row; align-items: center; margin-left: -40px">
                                    <img *ngIf="true" width="45px">
                                    <img src="assets/meteo/sun.png" [(ngModel)]="spreading.weather" width="20px">
                                    <p-dropdown 
                                        [options]="meteo" 
                                        placeholder="Meteo" 
                                        [showClear]="true"
                                        [filter]="true" 
                                        [style]="{'width':'100%'}" >
                                        <ng-template let-weather pTemplate="item">
                                            <div class="weather-block">
                                                <img src="assets/meteo/{{ weather }}.png" width="35px">
                                                <div>{{weather}}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <img src="assets/meteo/wind.png" width="20px">
                                    <input placeholder="Wind" [(ngModel)]="spreading.wind" pInputText type="text"  style="width: 80px;margin-right: 5px;margin-left: 5px;"  >
                                </div>
                            </div>
                            <div class="spreading-dialog-weather-line-b">
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <img src="assets/meteo/humidity.png" width="20px">
                                    <input [(ngModel)]="spreading.humidity" placeholder="Humidity" pInputText type="number"  style="width: 80px;margin-right: 5px;margin-left: 5px;"  >
                                </div>
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <img src="assets/meteo/compass.png" width="20px">
                                    <p-dropdown 
                                        [options]="windDirections" 
                                        placeholder="Wind direction" 
                                        [(ngModel)]="spreading.windDirection"
                                        [showClear]="true" 
                                        [filter]="true" 
                                        [style]="{'width':'100%'}" >
                                        <ng-template let-dir pTemplate="item">
                                            <div class="weather-block">
                                                <div>{{dir}}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                            </div>
                            <div class="spreading-dialog-weather-line-b">
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <img src="assets/meteo/temperature.png" width="20px">
                                    <input [(ngModel)]="spreading.temperature" placeholder="Temperature" pInputText type="text"  style="width: 80px;margin-right: 5px;margin-left: 5px;"  >     
                                </div>
                                <div style="display: flex; flex-direction: row; align-items: center;">
                                    <img src="assets/meteo/soilTemperature.png" width="20px">
                                    <input [(ngModel)]="spreading.soilTemperature" placeholder="Soil Temperature" pInputText type="text"  style="width: 80px;margin-right: 5px;margin-left: 5px;" >     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="spreading-dialog-line">
                <div class="spreading-dialog-line">
                    <div class="spreading-dialog-block" style="width: 830px; height: 380px; overflow-y: auto;">
                        <div class="spreading-dialog-title" >Description</div>
                        <div class="comment" >
                            <textarea [(ngModel)]="spreading.description" style="flex: 1" pInputTextarea ></textarea> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="spreading-dialog-line">
                <div class="spreading-dialog-line">
                    <div class="spreading-dialog-block" style="width: 830px">
                        <div class="spreading-dialog-title" >Comment</div>
                        <div class="comment" >
                            <textarea [(ngModel)]="spreading.comment" style="flex: 1" pInputTextarea ></textarea> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="spreading-dialog-line" style="margin-bottom: 20px">
                <div>
                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                </div>
                <div>
                    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveSpreading()"></button>
                </div>
            </div>
            <div class="spreading-dialog-line" style="color: white">.</div> 
        </div>
    </p-dialog>
    
    

    <p-dialog header="Beaufort scale" [(visible)]="beaufortDialog" [style]="{width: '800px'}" [baseZIndex]="10000">
        <table border="1" style="border-collapse: collapse;font-size: 0.75rem;">
            <tr>
                <th>Beaufort number</th>
                <th>Description</th>
                <th>Wind speed</th>
                <th>Wave height</th>
                <th width="130">Sea conditions</th>
                <th width="120">Land conditions</th>
                <th>Sea conditions (photo)</th>
            </tr>
            <tr>
                <th style="background-color: #FFFFFF; font-size: large;" id="Beaufort_Number_0">0
                </th>
                <td>Calm
                </td>
                <td>&lt; 1&nbsp;<a href="/wiki/Knot_(unit)" title="Knot (unit)">knot</a><br>&lt; 1&nbsp;<a href="/wiki/Miles_per_hour" title="">mph</a><br>&lt; 2&nbsp;<a href="/wiki/Kilometres_per_hour" title="Kilometres per hour">km/h</a><br>  &lt; 0.5&nbsp;<a href="/wiki/Metre_per_second" title="Metre per second">m/s</a>
                </td>
                <td>0&nbsp;ft (0&nbsp;m)
                </td>
                <td>Sea like a mirror
                </td>
                <td>Smoke rises vertically.
                </td>
                <td><a href="/wiki/File:Beaufort_scale_0.jpg" class="image"><img alt="Beaufort scale 0.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Beaufort_scale_0.jpg/120px-Beaufort_scale_0.jpg" decoding="async" width="120" height="111" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Beaufort_scale_0.jpg/180px-Beaufort_scale_0.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Beaufort_scale_0.jpg/240px-Beaufort_scale_0.jpg 2x" data-file-width="400" data-file-height="371"></a>
                </td>
            </tr>
            <tr>
                <th style="background-color: #AEF1F9; font-size: large;" rowspan="4" id="Beaufort_Number_1">1
                </th>
                <td rowspan="4">Light air</td>
                <td>1–3&nbsp;knots</td>
                <td rowspan="2">0–1&nbsp;ft</td>
                <td rowspan="4">Ripples with appearance of scales are formed, without foam crests</td>
                <td rowspan="4">Direction shown by smoke drift but not by wind vanes.</td>
                <td rowspan="4"><a href="/wiki/File:Beaufort_scale_1.jpg" class="image"><img alt="Beaufort scale 1.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/1/18/Beaufort_scale_1.jpg/120px-Beaufort_scale_1.jpg" decoding="async" width="120" height="119" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/1/18/Beaufort_scale_1.jpg/180px-Beaufort_scale_1.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/1/18/Beaufort_scale_1.jpg/240px-Beaufort_scale_1.jpg 2x" data-file-width="400" data-file-height="397"></a></td>
            </tr>
            <tr>
            <td>1–3&nbsp;mph
            </td></tr>
            <tr>
            <td>2–5&nbsp;km/h</td>
            <td rowspan="2">0–0.3&nbsp;m
            </td></tr>
            <tr>
            <td>0.5–1.5&nbsp;m/s
            </td></tr>
            <tr>
                <th style="background-color: #96F7DC; font-size: large;" rowspan="4" id="Beaufort_Number_2">2
                </th>
                <td rowspan="4">Light breeze</td>
                <td>4–6&nbsp;knots</td>
                <td rowspan="2">1–2&nbsp;ft</td>
                <td rowspan="4">Small wavelets still short but more pronounced; crests have a glassy appearance but do not break</td>
                <td rowspan="4">Wind felt on face; leaves rustle; <a href="/wiki/Weather_vane" title="Weather vane">wind vane</a> moved by wind.</td>
                <td rowspan="4"><a href="/wiki/File:Beaufort_scale_2.jpg" class="image"><img alt="Beaufort scale 2.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Beaufort_scale_2.jpg/120px-Beaufort_scale_2.jpg" decoding="async" width="120" height="119" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Beaufort_scale_2.jpg/180px-Beaufort_scale_2.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Beaufort_scale_2.jpg/240px-Beaufort_scale_2.jpg 2x" data-file-width="400" data-file-height="397"></a></td>
            </tr>
            <tr>
            <td>4–7&nbsp;mph
            </td></tr>
            <tr>
            <td>6–11&nbsp;km/h</td>
            <td rowspan="2">0.3–0.6&nbsp;m
            </td></tr>
            <tr>
            <td>1.6–3.3&nbsp;m/s
            </td></tr>
            <tr>
                <th style="background-color: #96F7B4; font-size: large;" rowspan="4" id="Beaufort_Number_3">3
                </th>
                <td rowspan="4">Gentle breeze</td>
                <td>7–10&nbsp;knots</td>
                <td rowspan="2">2–4&nbsp;ft</td>
                <td rowspan="4">Large wavelets; crests begin to break; foam of glassy appearance; perhaps scattered white horses</td>
                <td rowspan="4">Leaves and small twigs in constant motion; light flags extended.</td>
                <td rowspan="4"><a href="/wiki/File:Beaufort_scale_3.jpg" class="image"><img alt="Beaufort scale 3.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/5/51/Beaufort_scale_3.jpg/120px-Beaufort_scale_3.jpg" decoding="async" width="120" height="119" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/5/51/Beaufort_scale_3.jpg/180px-Beaufort_scale_3.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/5/51/Beaufort_scale_3.jpg/240px-Beaufort_scale_3.jpg 2x" data-file-width="400" data-file-height="397"></a></td>
             </tr>
            <tr>
            <td>8–12&nbsp;mph
            </td></tr>
            <tr>
            <td>12–19&nbsp;km/h</td>
            <td rowspan="2">0.6–1.2&nbsp;m
            </td></tr>
            <tr>
            <td>3.4–5.5&nbsp;m/s
            </td></tr>
            <tr>
                <th style="background-color: #6FF46F; font-size: large;" rowspan="4" id="Beaufort_Number_4">4
                </th>
                <td rowspan="4">Moderate breeze</td>
                <td>11–16&nbsp;knots</td>
                <td rowspan="2">3.5–6&nbsp;ft</td>
                <td rowspan="4">Small waves becoming longer; fairly frequent white horses</td>
                <td rowspan="4">Raises dust and loose paper; small branches moved.</td>
                <td rowspan="4"><a href="/wiki/File:Beaufort_scale_4.jpg" class="image"><img alt="Beaufort scale 4.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Beaufort_scale_4.jpg/120px-Beaufort_scale_4.jpg" decoding="async" width="120" height="116" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Beaufort_scale_4.jpg/180px-Beaufort_scale_4.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Beaufort_scale_4.jpg/240px-Beaufort_scale_4.jpg 2x" data-file-width="400" data-file-height="386"></a></td>
            </tr>
            <tr>
            <td>13–18&nbsp;mph
            </td></tr>
            <tr>
            <td>20–28&nbsp;km/h</td>
            <td rowspan="2">1–2&nbsp;m
            </td></tr>
            <tr>
            <td>5.5–7.9&nbsp;m/s
            </td></tr>
            <tr>
                <th style="background-color: #73ED12; font-size: large;" rowspan="4" id="Beaufort_Number_5">5
                </th>
                <td rowspan="4">Fresh breeze</td>
                <td>17–21&nbsp;knots</td>
                <td rowspan="2">6–10&nbsp;ft</td>
                <td rowspan="4">Moderate waves taking a more pronounced long form; many white horses are formed; chance of some spray</td>
                <td rowspan="4">Small trees in leaf begin to sway; crested wavelets form on inland waters.</td>
                <td rowspan="4"><a href="/wiki/File:Beaufort_scale_5.jpg" class="image"><img alt="Beaufort scale 5.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Beaufort_scale_5.jpg/120px-Beaufort_scale_5.jpg" decoding="async" width="120" height="116" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Beaufort_scale_5.jpg/180px-Beaufort_scale_5.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Beaufort_scale_5.jpg/240px-Beaufort_scale_5.jpg 2x" data-file-width="400" data-file-height="386"></a></td>
            </tr>
            <tr>
            <td>19–24&nbsp;mph
            </td></tr>
            <tr>
            <td>29–38&nbsp;km/h</td>
            <td rowspan="2">2–3&nbsp;m
            </td></tr>
            <tr>
            <td>8–10.7&nbsp;m/s
            </td></tr>
            <tr>
                <th style="background-color: #A4ED12; font-size: large;" rowspan="4" id="Beaufort_Number_6">6
                </th>
                <td rowspan="4">Strong breeze</td>
                <td>22–27&nbsp;knots</td>
                <td rowspan="2">9–13&nbsp;ft</td>
                <td rowspan="4">Large waves begin to form; the white foam crests are more extensive everywhere; probably some spray</td>
                <td rowspan="4">Large branches in motion; whistling heard in telegraph wires; umbrellas used with difficulty.</td>
                <td rowspan="4"><a href="/wiki/File:Beaufort_scale_6.jpg" class="image"><img alt="Beaufort scale 6.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Beaufort_scale_6.jpg/120px-Beaufort_scale_6.jpg" decoding="async" width="120" height="116" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Beaufort_scale_6.jpg/180px-Beaufort_scale_6.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/e/e9/Beaufort_scale_6.jpg/240px-Beaufort_scale_6.jpg 2x" data-file-width="400" data-file-height="386"></a></td>
            </tr>
            <tr>
            <td>25–31&nbsp;mph
            </td></tr>
            <tr>
            <td>39–49&nbsp;km/h</td>
            <td rowspan="2">3–4&nbsp;m
            </td></tr>
            <tr>
            <td>10.8–13.8&nbsp;m/s
            </td></tr>
            <tr>
            <th style="background-color: #DAED12; font-size: large;" rowspan="4" id="Beaufort_Number_7">7
            </th>
            <td rowspan="4">High wind,<br>moderate gale,<br>near gale</td>
            <td>28–33&nbsp;knots</td>
            <td rowspan="2">13–19&nbsp;ft</td>
            <td rowspan="4">Sea heaps up and white foam from breaking waves begins to be blown in streaks along the direction of the wind; <a href="/wiki/Spindrift" title="Spindrift">spindrift</a> begins to be seen</td>
            <td rowspan="4">Whole trees in motion; inconvenience felt when walking against the wind.</td>
            <td rowspan="4"><a href="/wiki/File:Beaufort_scale_7.jpg" class="image"><img alt="Beaufort scale 7.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Beaufort_scale_7.jpg/120px-Beaufort_scale_7.jpg" decoding="async" width="120" height="116" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Beaufort_scale_7.jpg/180px-Beaufort_scale_7.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Beaufort_scale_7.jpg/240px-Beaufort_scale_7.jpg 2x" data-file-width="400" data-file-height="386"></a></td>
            </tr>
            <tr>
            <td>32–38&nbsp;mph
            </td></tr>
            <tr>
            <td>50–61&nbsp;km/h</td>
            <td rowspan="2">4–5.5&nbsp;m
            </td></tr>
            <tr>
            <td>13.9–17.1&nbsp;m/s
            </td></tr>
            <tr>
            <th style="background-color: #EDC212; font-size: large;" rowspan="4" id="Beaufort_Number_8">8
            </th>
            <td rowspan="4"><a href="/wiki/Gale" title="Gale">Gale</a>,<br>fresh gale</td>
            <td>34–40&nbsp;knots</td>
            <td rowspan="2">18–25&nbsp;ft</td>
            <td rowspan="4">Moderately high waves of greater length; edges of crests break into spindrift; foam is blown in well-marked streaks along the direction of the wind</td>
            <td rowspan="4">Twigs break off trees; generally impedes progress.</td>
            <td rowspan="4"><a href="/wiki/File:Beaufort_scale_8.jpg" class="image"><img alt="Beaufort scale 8.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/0/00/Beaufort_scale_8.jpg/120px-Beaufort_scale_8.jpg" decoding="async" width="120" height="118" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/0/00/Beaufort_scale_8.jpg/180px-Beaufort_scale_8.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/0/00/Beaufort_scale_8.jpg/240px-Beaufort_scale_8.jpg 2x" data-file-width="400" data-file-height="394"></a></td>
            </tr>
            <tr>
            <td>39–46&nbsp;mph
            </td></tr>
            <tr>
            <td>62–74&nbsp;km/h</td>
            <td rowspan="2">5.5–7.5&nbsp;m
            </td></tr>
            <tr>
            <td>17.2–20.7&nbsp;m/s
            </td></tr>
            <tr>
            <th style="background-color: #ED8F12; font-size: large;" rowspan="4" id="Beaufort_Number_9">9
            </th>
            <td rowspan="4">Strong/severe gale</td>
            <td>41–47&nbsp;knots</td>
            <td rowspan="2">23–32&nbsp;ft</td>
            <td rowspan="4">High waves; dense streaks of foam along the direction of the wind; sea begins to roll; spray affects visibility</td>
            <td rowspan="4">Slight structural damage (chimney pots and slates removed).</td>
            <td rowspan="4"><a href="/wiki/File:Beaufort_scale_9.jpg" class="image"><img alt="Beaufort scale 9.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Beaufort_scale_9.jpg/120px-Beaufort_scale_9.jpg" decoding="async" width="120" height="118" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Beaufort_scale_9.jpg/180px-Beaufort_scale_9.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Beaufort_scale_9.jpg/240px-Beaufort_scale_9.jpg 2x" data-file-width="400" data-file-height="394"></a></td>
            </tr>
            <tr>
            <td>47–54&nbsp;mph
            </td></tr>
            <tr>
            <td>75–88&nbsp;km/h</td>
            <td rowspan="2">7–10&nbsp;m
            </td></tr>
            <tr>
            <td>20.8–24.4&nbsp;m/s
            </td></tr>
            <tr id="Storm-force">
            <th style="background-color: #ED6312; font-size: large;" rowspan="4" id="Beaufort_Number_10">10
            </th>
            <td rowspan="4"><a href="/wiki/Storm" title="Storm">Storm</a>,<sup id="cite_ref-forcenamenote_13-0" class="reference"><a href="#cite_note-forcenamenote-13">[13]</a></sup><br>whole gale</td>
            <td>48–55&nbsp;knots</td>
            <td rowspan="2">29–41&nbsp;ft</td>
            <td rowspan="4">Very high waves with long overhanging crests; resulting foam in great patches is blown in dense white streaks along the direction of the wind; on the whole the surface of the sea takes on a white appearance; rolling of the sea becomes heavy; visibility affected</td>
            <td rowspan="4">Seldom experienced inland; trees uprooted; considerable structural damage.</td>
            <td rowspan="4"><a href="/wiki/File:Beaufort_scale_10.jpg" class="image"><img alt="Beaufort scale 10.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/8/86/Beaufort_scale_10.jpg/120px-Beaufort_scale_10.jpg" decoding="async" width="120" height="126" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/8/86/Beaufort_scale_10.jpg/180px-Beaufort_scale_10.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/8/86/Beaufort_scale_10.jpg/240px-Beaufort_scale_10.jpg 2x" data-file-width="400" data-file-height="419"></a></td>
            </tr>
            <tr>
            <td>55–63&nbsp;mph
            </td></tr>
            <tr>
            <td>89–102&nbsp;km/h</td>
            <td rowspan="2">9–12.5&nbsp;m
            </td></tr>
            <tr>
            <td>24.5–28.4&nbsp;m/s
            </td></tr>
            <tr>
            <th style="background-color: #ED2912; font-size: large;" rowspan="4" id="Beaufort_Number_11">11
            </th>
            <td rowspan="4">Violent storm</td>
            <td>56–63&nbsp;knots</td>
            <td rowspan="2">37–52&nbsp;ft</td>
            <td rowspan="4">Exceptionally high waves; small- and medium-sized ships might be for a long time lost to view behind the waves; sea is covered with long white patches of foam; everywhere the edges of the wave crests are blown into foam; visibility affected</td>
            <td rowspan="4">Very rarely experienced; accompanied by widespread damage.</td>
            <td rowspan="4"><a href="/wiki/File:Beaufort_scale_11.jpg" class="image"><img alt="Beaufort scale 11.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/1/16/Beaufort_scale_11.jpg/120px-Beaufort_scale_11.jpg" decoding="async" width="120" height="126" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/1/16/Beaufort_scale_11.jpg/180px-Beaufort_scale_11.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/1/16/Beaufort_scale_11.jpg/240px-Beaufort_scale_11.jpg 2x" data-file-width="400" data-file-height="419"></a></td>
            </tr>
            <tr>
            <td>64–72&nbsp;mph
            </td></tr>
            <tr>
            <td>103–117&nbsp;km/h</td>
            <td rowspan="2">11.5–16&nbsp;m
            </td></tr>
            <tr>
            <td>28.5–32.6&nbsp;m/s
            </td></tr>
            <tr id="Hurricane_force">
            <th style="background-color: #D5102D; font-size: large;" rowspan="4" id="Beaufort_Number_12">12
            </th>
            <td rowspan="4"><a href="/wiki/Hurricane" class="mw-redirect" title="Hurricane">Hurricane</a> force<sup id="cite_ref-forcenamenote_13-1" class="reference"><a href="#cite_note-forcenamenote-13">[13]</a></sup></td>
            <td>≥ 64&nbsp;knots</td>
            <td rowspan="2">≥ 46&nbsp;ft</td>
            <td rowspan="4">The air is filled with foam and spray; sea is completely white with driving spray; visibility very seriously affected</td>
            <td rowspan="4">Devastation.</td>
            <td rowspan="4"><a href="/wiki/File:Beaufort_scale_12.jpg" class="image"><img alt="Beaufort scale 12.jpg" src="//upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Beaufort_scale_12.jpg/120px-Beaufort_scale_12.jpg" decoding="async" width="120" height="120" srcset="//upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Beaufort_scale_12.jpg/180px-Beaufort_scale_12.jpg 1.5x, //upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Beaufort_scale_12.jpg/240px-Beaufort_scale_12.jpg 2x" data-file-width="400" data-file-height="400"></a></td>
            </tr>
            <tr>
            <td>≥ 73&nbsp;mph
            </td></tr>
            <tr>
            <td>≥ 118&nbsp;km/h</td>
            <td rowspan="2">≥ 14&nbsp;m
            </td></tr>
            <tr>
            <td>≥ 32.7&nbsp;m/s
            </td></tr>
        </table>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="hideBeaufort()" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>



    <p-dialog [(visible)]="cfDialog" [style]="{width: '900px', height: '700px'}" header="Concentration Factor" [modal]="true" styleClass="p-fluid" [baseZIndex]="2000">
        <div class="spreading-dialog">
            CF
        </div>
    </p-dialog>

    <p-dialog [(visible)]="waterDialog" [style]="{width: '600px', height: '600px'}" header="Water Rate" [modal]="true" styleClass="p-fluid" [baseZIndex]="2000">
        <div class="spreading-dialog">

            <div>
                AMOUNT OF WATER SPRAYED (L/ha or L/100m): is the actual amount of water or spray mixture applied to the canopy or ground expressed as litres per hectare or per 100m as required. The amount of water sprayed is often referred to as spray volume. State the actual chosen rate used NOT a range that appears on some labels.
            </div>
            
            <div>
                Water Rate Conversion Table (from L/Ha to L/100m)
            </div>
            
            <table border="1" style="border-collapse: collapse;font-size: 0.75rem;width:90%">
                <tr>
                    <td rowspan="2" colspan="2" style="font-weight: bold;text-align: center;">
                        ROW WIDTH
                    </td>
                    <td>7'</td><td>8'</td><td>9'</td><td>10'</td><td>11'</td><td>12'</td><td>13'</td><td colspan="2" style="font-weight: bold;text-align: center;">feet</td>
                </tr>
                <tr>
                    <td>2.1</td><td>2.4</td><td>2.7</td><td>3</td><td>3.3</td><td>3.6'</td><td>3.9</td><td colspan="2" style="font-weight: bold;text-align: center;">metres</td>
                </tr>
                <tr>
                    <td colspan="11">&nbsp;</td>
                </tr>
                <tr>
                    <td colspan="2" style="font-weight: bold;text-align: center;">
                        m/Ha
                    </td>
                    <td>7'</td><td>8'</td><td>9'</td><td>10'</td><td>11'</td><td>12'</td><td>13'</td><td colspan="2" style="font-weight: bold;text-align: center;">m/Ha</td>
                </tr>
                <tr>
                    <td colspan="2" style="font-weight: bold;text-align: center;">
                        100m/Ha
                    </td>
                    <td>2.1</td><td>2.4</td><td>2.7</td><td>3</td><td>3.3</td><td>3.6'</td><td>3.9</td><td colspan="2" style="font-weight: bold;text-align: center;">100m/Ha</td>
                </tr>
                <tr>
                    <td colspan="11">&nbsp;</td>
                </tr>
                <tr>
                    <td rowspan="24" style="text-orientation: mixed;writing-mode: vertical-lr; vertical-align: middle; text-align: center;font-weight: bold;">Spray Volume</td>
                    <td style="font-weight: bold;text-align: center;">L/Ha</td>
                    <td colspan="7" style="font-weight: bold;text-align: center;">L/100m</td>
                    <td style="font-weight: bold;text-align: center;">L/Ha</td>
                    <td rowspan="24" style="text-orientation: mixed;writing-mode: vertical-lr; vertical-align: middle; text-align: center;font-weight: bold;">Spray Volume</td>
                </tr>
                <tr>
                    <td>300</td>
                    <td>6.3</td>
                    <td>7.2</td>
                    <td>8.1</td>
                    <td>9.0</td>
                    <td>9.9</td>
                    <td>10.8</td>
                    <td>11.7</td>
                    <td>300</td>
                </tr>
                <tr>
                    <td>400</td>
                    <td>8.4</td>
                    <td>9.6</td>
                    <td>10.8</td>
                    <td>12.0</td>
                    <td>13.2</td>
                    <td>14.4</td>
                    <td>15.6</td>
                    <td>400</td>
                </tr>
                <tr>
                    <td>500</td>
                    <td>10.5</td>
                    <td>12.0</td>
                    <td>13.5</td>
                    <td>15.0</td>
                    <td>16.5</td>
                    <td>18</td>
                    <td>19.5</td>
                    <td>500</td>
                </tr>
                <tr>
                    <td>600</td>
                    <td>12.6</td>
                    <td>14.4</td>
                    <td>16.2</td>
                    <td>18.0</td>
                    <td>19.8</td>
                    <td>21.6</td>
                    <td>23.4</td>
                    <td>600</td>
                </tr>
                <tr>
                    <td>700</td>
                    <td>14.7</td>						
                    <td>16.8</td>
                    <td>18.9</td>
                    <td>21.0</td>
                    <td>23.1</td>
                    <td>25.2</td>
                    <td>27.3</td>
                    <td>700</td>
                </tr>
                <tr>
                    <td>800</td>
                    <td>16.8</td> 						
                    <td>19.2</td>
                    <td>21.6</td>
                    <td>24.0</td>
                    <td>26.4</td>
                    <td>28.8</td>
                    <td>31.2</td>
                    <td>800</td>
                </tr>
                <tr>
                    <td>900</td>
                    <td>18.9</td> 						
                    <td>21.6</td>
                    <td>24.3</td>
                    <td>27.0</td>
                    <td>29.7</td>
                    <td>32.4</td>
                    <td>35.1</td>
                    <td>900</td>
                </tr>
                <tr>
                    <td>1000</td>
                    <td>21.0</td> 						
                    <td>24.0</td>
                    <td>27.0</td>
                    <td>30.0</td>
                    <td>33.0</td>
                    <td>36.0</td>
                    <td>39.0</td>
                    <td>1000</td>
                </tr>
                <tr>
                    <td>1100</td>
                    <td>23.1</td> 						
                    <td>26.4</td>
                    <td>29.7</td>
                    <td>33.0</td>
                    <td>36.3</td>
                    <td>39.6</td>
                    <td>42.9</td>
                    <td>1100</td>
                </tr>
                <tr>
                    <td>1200</td>
                    <td>25.2</td> 						
                    <td>28.8</td>
                    <td>32.4</td>
                    <td>36.0</td>
                    <td>39.6</td>
                    <td>43.2</td>
                    <td>46.8</td>
                    <td>1200</td>
                </tr>
                <tr>
                    <td>1300</td>
                    <td>27.3</td> 						
                    <td>31.2</td>
                    <td>35.1</td>
                    <td>39.0</td>
                    <td>42.9</td>
                    <td>46.8</td>
                    <td>50.7</td>
                    <td>1300</td>
                </tr>
                <tr>
                    <td>1400</td>
                    <td>29.4</td> 						
                    <td>33.6</td>
                    <td>37.8</td>
                    <td>42.0</td>
                    <td>46.2</td>
                    <td>50.4</td>
                    <td>54.6</td>
                    <td>1400</td>
                </tr>
                <tr>
                    <td>1500</td>
                    <td>31.5</td> 						
                    <td>36.0</td>
                    <td>40.5</td>
                    <td>45.0</td>
                    <td>49.5</td>
                    <td>54.0</td>
                    <td>58.5</td>
                    <td>1500</td>
                </tr>
                <tr>
                    <td>1600</td>
                    <td>33.6</td> 
                    <td>38.4</td>
                    <td>43.2</td>
                    <td>48.0</td>
                    <td>52.8</td>
                    <td>57.6</td>
                    <td>62.4</td>
                    <td>1600</td>
                </tr>
                <tr>
                    <td>1700</td>
                    <td>35.7</td> 						
                    <td>40.8</td>
                    <td>45.9</td>
                    <td>51.0</td>
                    <td>56.1</td>
                    <td>61.2</td>
                    <td>66.3</td>
                    <td>1700</td>
                </tr>
                <tr>
                    <td>1800</td>
                    <td>37.8</td> 						
                    <td>43.2</td>
                    <td>48.6</td>
                    <td>54.0</td>
                    <td>59.4</td>
                    <td>64.8</td>
                    <td>70.2</td>
                    <td>1800</td>
                </tr>
                <tr>
                    <td>1900</td>
                    <td>39.9</td> 						
                    <td>45.6</td>
                    <td>51.3</td>
                    <td>57.0</td>
                    <td>62.7</td>
                    <td>68.4</td>
                    <td>74.1</td>
                    <td>1900</td>
                </tr>
                <tr>
                    <td>2000</td>
                    <td>42.0</td> 						
                    <td>48.0</td>
                    <td>54.0</td>
                    <td>60.0</td>
                    <td>66.0</td>
                    <td>72.0</td>
                    <td>78.0</td>
                    <td>2000</td>
                </tr>
                <tr>
                    <td>2100</td>
                    <td>44.1</td> 
                    <td>50.4</td>				
                    <td>56.7</td>
                    <td>63.0</td>
                    <td>69.3</td>
                    <td>75.6</td>
                    <td>81.9</td>
                    <td>2100</td>
                </tr>
                <tr>
                    <td>2200</td>
                    <td>46.2</td> 						
                    <td>52.8</td>
                    <td>59.4</td>
                    <td>66.0</td>
                    <td>72.6</td>
                    <td>79.2</td>
                    <td>85.8</td>
                    <td>2200</td>
                </tr>
                <tr>
                    <td>2300</td>
                    <td>48.3</td> 						
                    <td>55.2</td>
                    <td>62.1</td>
                    <td>69.0</td>
                    <td>75.9</td>
                    <td>82.8</td>
                    <td>89.7</td>
                    <td>2300</td>
                </tr>
                <tr>
                    <td>2400</td>
                    <td>50.4</td> 						
                    <td>57.6</td>
                    <td>64.8</td>
                    <td>72.0</td>
                    <td>79.2</td>
                    <td>86.4</td>
                    <td>93.6</td>
                    <td>2400</td>
                </tr>
                <tr>
                    <td>2500</td>
                    <td>52.5</td> 						
                    <td>60.0</td>
                    <td>67.5</td>
                    <td>75.0</td>
                    <td>82.5</td>
                    <td>90.0</td>
                    <td>97.5</td>
                    <td>2500</td>
                </tr>
            </table>

            <br>
            <div style="font-weight: bold;">How to use this table:</div>
            <div> 1) Choose the ROW WIDTH matching your vineyard block from the top rows.<br>
                     Where there is not an exact match, choose the nearest ROW WIDTH.
            </div>
            <div> 2) Select the SPRAY VOLUME to be sprayed (in L/Ha) from the side columns.<br>
                Where there is not an exact match, choose the nearest SPRAY VOLUME.
            </div>
            <div> 3) Follow across the appropriate SPRAY VOLUME and down the specified ROW WIDTH to where they intersect - read the specified value.
            </div>
            <div> 4) This value is the SPRAY VOLUME in L/100m that matches your vineyard block.</div>
For example: On 3.0m wide rows, a spray volume of 1500L/Ha is equivalent to 45.0L/100m.


        </div>
    </p-dialog>

    <p-dialog [(visible)]="productUsedDialog" [style]="{width: '900px', height: '700px'}" header="Amount of registered product used" [modal]="true" styleClass="p-fluid" [baseZIndex]="2000">
        
        <div class="productDialog-definition">
            Definition: is the total amount of registered agrochemical used expressed as product per hectare or product per 100m as required.
        </div>

        <div class="">
            To calculate the amount of registered product used per hectare or per 100m use the formula(s) below:
            <br/>

            Product/ha = Chosen label rate of registered product per 100L x CF x actual amount of water sprayed per ha ÷ 100:
            <div>
                <div style="font-weight: bold">Product/ha = </div> 
                <div><input type="number" value="" placeholder="Chosen label rate of registered product per 100L"/></div> x 
                <div><input type="number" value="" placeholder="Concentration Factor"/></div> x 
                <div><input type="number" value="" placeholder="actual amount of water sprayed per ha"/></div> / 100
            </div>

            <br/>
            <br/>
            Product/100m = Chosen label rate of registered product per 100L x CF x actual amount of water sprayed per 100m ÷ 100:
            <div>
                <div style="font-weight: bold">Product/ha = </div> 
                <div><input type="number" value="" placeholder="Chosen label rate of registered product per 100L"/></div> x 
                <div><input type="number" value="" placeholder="Concentration Factor"/></div> x 
                <div><input type="number" value="" placeholder="actual amount of water sprayed per 100m"/></div> / 100
            </div>

            <br/>
            <br/>


            <div class="productDialog-example">

            </div>
            Fungicide and Insecticide example:
            
            Actual spraying volume = 500L/ha
            Concentration factor (CF) = 3
            Chosen Kumulus DF label rate = 350g/100L
            
            Product/ha = Chosen label rate per 100L x CF x Actual spraying volume (L/ha) ÷ 100
            = 350 x 3 x 500 ÷ 100
            = 5.25 kg/ha
            
            Actual spraying volume = 15L/100m
            Concentration factor (CF) = 3
            Chosen Kumulus DF label rate = 350g/100L
            
            Product/100m = Chosen label rate per 100L x CF x Actual spraying volume (L/100m) ÷ 100
            = 350 x 3 x 15 ÷ 100
            = 157.5 g/100m
            
            Herbicide example expressed per ha:
            
            Actual spraying volume = 500 L/ha
            Concentration factor (CF) always = 1
            Chosen Basta label rate = 3L/ha
            
            Product/ha = 3L
        </div>

    </p-dialog>

</div>