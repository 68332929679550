import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bin-brands',
  templateUrl: './bin-brands.component.html',
  styleUrls: ['./bin-brands.component.scss']
})
export class BinBrandsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
