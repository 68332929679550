
<div class="page">

    <div class="page-header">
        <div class="breadcrumb">
            <p-breadcrumb [model]="breadcrumbs" [home]="homeBreadcrumb"></p-breadcrumb>
        </div>
        <div class="search">
            <input pInputText type="text" (input)="filter(dt, $event)" placeholder="Search..." />           
        </div>
        <div class="actions">
            <button pButton pRipple label="New" icon="pi pi-plus" class="button-action p-button-success p-mr-2" (click)="openNew()"></button>
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="button-action p-button-danger" (click)="deleteSelectedRecords()" [disabled]="!selectedRecords || !selectedRecords.length "></button>
        </div> 
    </div>

    <div class="page-content">
        <p-table #dt 
            [value]="records"                                                                                                      
            [rows]="10" 
            [paginator]="true" 
            [globalFilterFields]="['id','name','location','grape','size','StartDate','EndDate']"
            [rowsPerPageOptions]="[5,10,25,50,100]"
            [(selection)]="selectedRecords" 
            [resizableColumns]="true"
            [reorderableColumns]="true"
            [rowHover]="true" 
            dataKey="$key"
            styleClass="p-datatable-striped"
            currentPageReportTemplate="{first} to {last} of {totalRecords}" 
            [showCurrentPageReport]="true"
            selectionMode="multiple"
            [columns]="selectedColumns"
            (onRowReorder)="reorder($event)"
            sortField="position" 
            [sortOrder]="1"
            >

            <ng-template pTemplate="colgroup">
                <colgroup>
                    <col style="max-width:10%">
                    <col style="max-width:20%">
                    <col style="min-width:10%;max-width:80%">
                    <col style="max-width:20%">
                </colgroup>
            </ng-template>
            
            <ng-template pTemplate="caption">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}" placeholder="Choose Columns"></p-multiSelect>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th id="id" name="id" pResizableColumn pReorderableColumn pSortableColumn="id" style="width: 3rem;" >
                        Order<p-sortIcon field="id"></p-sortIcon>
                    </th>
                    <th id="name" name="name" pResizableColumn pReorderableColumn pSortableColumn="name" style="width: 3rem;">
                        Name<p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </th>
                    <th id="grape" name="grape" pResizableColumn pReorderableColumn pSortableColumn="grape" style="width: 3rem;" >
                        Grape<p-sortIcon field="grape"></p-sortIcon>
                        <p-columnFilter type="text" field="grapes" display="menu"></p-columnFilter>
                    </th>
                    <th id="location" name="location" pResizableColumn pReorderableColumn pSortableColumn="location" style="width: 3rem;" >
                        Location<p-sortIcon field="location"></p-sortIcon>
                        <p-columnFilter type="text" field="location" display="menu"></p-columnFilter>
                    </th>
                    <th id="size" name="size" pResizableColumn pReorderableColumn pSortableColumn="size" style="width: 3rem;" >
                        Size (m<sup>2</sup>)<p-sortIcon field="size"></p-sortIcon>
                        <p-columnFilter type="text" field="size" display="menu"></p-columnFilter>
                    </th>
                    <th id="StartDate" name="StartDate" pResizableColumn pReorderableColumn pSortableColumn="StartDate" style="width: 3rem;" >
                        Start Date<p-sortIcon field="StartDate"></p-sortIcon>
                        <p-columnFilter type="text" field="StartDate" display="menu"></p-columnFilter>
                    </th>
                    <th id="EndDate" name="EndDate" pResizableColumn pReorderableColumn pSortableColumn="EndDate" style="width: 3rem;">
                        End Date<p-sortIcon field="EndDate"></p-sortIcon>
                        <p-columnFilter type="text" field="EndDate" display="menu"></p-columnFilter>
                    </th>
                    <th id="isActive" name="isActive" pResizableColumn pReorderableColumn pSortableColumn="isActive" style="width: 10rem">
                        Enabled
                        <p-sortIcon field="isActive"></p-sortIcon> 
                        <p-columnFilter type="boolean" field="isActive" display="menu"></p-columnFilter>
                    </th>
                    <th id="actions" name="actions" pResizableColumn pReorderableColumn style="width:4rem">
                        Actions 
                    </th> 
                </tr>
            </ng-template>

            <ng-template 
                pTemplate="body" 
                let-record 
                let-columns="columns" 
                let-index="rowIndex">
                
                <tr [pReorderableRow]="index" [pEditableRow]="record">
                    <td>
                        <span *ngIf="isReordering && !isFilter" class="pi pi-bars" style="margin-right: 1rem;"></span>
                        {{record.r_position}} 
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                [(ngModel)]="record.r_name" 
                                (blur)="completeRecord(record)"
                                 (keydown.tab)="completeRecord(record)"
                                 (keydown.enter)="completeRecord(record)"
                                required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{record.r_name}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                [(ngModel)]="record.r_grape" 
                                (blur)="completeRecord(record)"
                                 (keydown.tab)="completeRecord(record)"
                                 (keydown.enter)="completeRecord(record)"
                                required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{record.r_grape}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                [(ngModel)]="record.r_location" 
                                (blur)="completeRecord(record)"
                                 (keydown.tab)="completeRecord(record)"
                                 (keydown.enter)="completeRecord(record)"
                                required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{record.r_location}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                [(ngModel)]="record.size" 
                                (blur)="completeRecord(record)"
                                 (keydown.tab)="completeRecord(record)"
                                 (keydown.enter)="completeRecord(record)"
                                required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{record.r_size}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                [(ngModel)]="record.r_StartDate" 
                                (blur)="completeRecord(record)"
                                 (keydown.tab)="completeRecord(record)"
                                 (keydown.enter)="completeRecord(record)"
                                required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{record.r_StartDate}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" 
                                [(ngModel)]="record.r_EndDate" 
                                (blur)="completeRecord(record)"
                                 (keydown.tab)="completeRecord(record)"
                                 (keydown.enter)="completeRecord(record)"
                                required>
                            </ng-template>
                            <ng-template pTemplate="output" >
                                <span >{{record.r_EndDate}}</span>
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    <td>
                        <div style="display: flex;align-items: center;flex-direction: column;justify-content: center;">
                            <p-inputSwitch [(ngModel)]="record.r_isActive" ></p-inputSwitch>
                        </div>
                    </td>

                    <td class="row-actions">
                        <button pButton pRipple style="margin-right: 5px;" icon="pi pi-pencil" class="p-button-success p-mr-2" (click)="editRecord(record)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-warning p-mr-2" style="margin-right: 5px; color: white" (click)="deleteRecord(record)"></button>
                        <p-tableCheckbox class="checkbox" [value]="record" ></p-tableCheckbox>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="summary">
                <div class="summary">
                    <div class="summary-total">
                        Total: {{records ? records.length : 0 }} record{{records.length > 1? 's': ''}}
                    </div>
                    <div class="summary-export">
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-o" (click)="exportToCsv()" class="p-mr-2" pTooltip="CSV - Save the selected lines and columns" tooltipPosition="bottom"></button>
                        <button style="margin-right: 10px"type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2"  pTooltip="XLS - Save all the lines and columns" tooltipPosition="bottom"></button>
                        <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()" class="p-button-warning p-mr-2" pTooltip="PDF - Save all the lines and columns" tooltipPosition="bottom"></button>
                    </div>
                </div>
            </ng-template>
        </p-table>
    </div>

    <div class="page-footer">
        <div class="p-d-flex">
            <!-- <button type="button" pButton pRipple icon="pi pi-filter" (click)="dt.exportCSV({selectionOnly:true})" class="p-button-info p-ml-auto" pTooltip="Selection Only" tooltipPosition="bottom"></button> -->
        </div>        
    </div>

    <!-- Dialog : confirm -->
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
    

    <!-- Dialog : Popup -->
    <p-dialog [(visible)]="recordDialog" [style]="{width: '450px', height: '385px'}" header="Record Details" [modal]="true" styleClass="p-fluid" [baseZIndex]="1000">
        <div class="p-field">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" [(ngModel)]="record.r_name" required autofocus />
            <small class="p-error" *ngIf="submitted && !record.r_name">Name is required.</small>
        </div>
        <div class="p-field">
            <label for="name">Grape</label>
            <input type="text" pInputText id="grape" [(ngModel)]="record.r_grape" />
        </div>
        <div class="p-field">
            <label for="name">Location</label>
            <input type="text" pInputText id="location" [(ngModel)]="record.r_location" />
        </div>

        <div class="p-field">
            <label for="name">Size</label>
            <input type="text" pInputText id="size" [(ngModel)]="record.r_size"/>
        </div>

        <div class="p-field">
            <label for="name">Start Date</label>
            <input id="r_StartDate" type="date" pInputText [(ngModel)]="record.r_StartDate"> 
        </div>

        <div class="p-field">
            <label for="name">End Date</label>
            <input id="r_EndDate" type="date" pInputText [(ngModel)]="record.r_EndDate">
        </div>
        
        <div class="p-field">
            <label for="description">Description</label>
            <textarea style="margin-top: 10px;" id="description" pInputTextarea [(ngModel)]="record.r_description" rows="3" cols="20"></textarea>
        </div>
        <div class="in-out" style="margin-top: 20px;">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="button-action p-button-warning" style="color: white; width: 140px;" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="button-action p-button-success" style="color: white; width: 140px;" (click)="saveRecord()" ></button>
        </div>
    </p-dialog>
</div>







