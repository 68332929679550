import { Injectable } from '@angular/core';
import { BatchTuna } from '../interfaces/batch-tuna';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from '@angular/fire/compat/database';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BatchTunaCrudService {

  refs: AngularFireList<any> | undefined;
  ref: AngularFireObject<any> | undefined;

  path: string;

  constructor(private db: AngularFireDatabase) {
    this.path = environment.name + '/tunaBatches/' ;
  }

  getOrganisation() : string | null {
    const company = JSON.parse(localStorage.getItem('company')!);
    if (company && typeof company.$key !== 'undefined') {
        return company.$key;
    }
    return null;
  }

  // Create Tuna
  add(record: BatchTuna) {
    this.refs = this.db.list( this.path );
    const item = {
      ...record
    }
    this.refs?.push( item );
  }
  // Fetch Single Tuna Object
  get(id: string) {
    this.ref = this.db.object( this.path + id);
    return this.ref;
  }
  // Fetch Tunas List
  getList() {
    this.refs = this.db.list( this.path );
    return this.refs;
  }
  // Update Tuna Object
  update(record: BatchTuna) {
    this.ref = this.db.object( this.path + record.$key);
   // console.log("P inside", record);
    let item : Partial<BatchTuna>  = {
      ...record
    }
    delete item?.$key;
    this.ref?.update(item);
  }
  // Delete Tuna Object
  delete(id: string) {
    this.ref = this.db.object( this.path + id);
    this.ref.remove();
  }
}
