import { environment } from 'src/environments/environment';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';


import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AuthService } from './shared/services/auth.service';
import { SettingsComponent } from './components/settings/settings.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppMaterialModule } from './modules/app-material/app-material.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZLongComponent } from './components/z-long/z-long.component';
import { ZShortComponent } from './components/z-short/z-short.component';
//import { AddPalletComponent } from './components/pallets/add-pallet/add-pallet.component';
//import { EditPalletComponent } from './components/pallets/edit-pallet/edit-pallet.component';
//import { PalletListComponent } from './components/pallets/pallet-list/pallet-list.component';

//import { ToastrModule } from 'ngx-toastr';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { GoogleChartsModule } from 'angular-google-charts';
import { ItemListComponent } from './components/items/item-list/item-list.component';


import {FilterService} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';  
import {ListboxModule} from 'primeng/listbox';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule } from 'primeng/radiobutton';
import {InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import { MenuItem } from 'primeng/api';
import { PickListModule } from 'primeng/picklist';
import { TreeModule } from 'primeng/tree';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CardModule } from 'primeng/card';
import { TimelineModule } from 'primeng/timeline';
import { TagModule } from 'primeng/tag';
import { InplaceModule } from 'primeng/inplace';
import { DataViewModule } from 'primeng/dataview';
import {ChipsModule} from 'primeng/chips';
import { MessagesModule } from 'primeng/messages';
import {} from '@angular/material/form-field';
import {} from '@angular/material/input';


import { HttpClientModule } from '@angular/common/http';
import { ProductService } from './shared/services/product.service';
//import { PalletBwsListComponent } from './components/pallets-bws/pallet-bws-list/pallet-bws-list.component';
import { ClientListComponent } from './components/clients/client-list/client-list.component';
import { LocationListComponent } from './components/locations/location-list/location-list.component';
import { RowListComponent } from './components/rows/row-list/row-list.component';
import { ColumnListComponent } from './components/columns/column-list/column-list.component';
import { LevelListComponent } from './components/levels/level-list/level-list.component';
import { ContentTypeComponent } from './components/types/content-type/content-type.component';
import { PackagingTypesComponent } from './components/types/packaging-types/packaging-types.component';
import { ProductTypesComponent } from './components/types/product-types/product-types.component';
import { ContainerTypesComponent } from './components/types/container-types/container-types.component';
import { UnitComponent } from './components/types/unit/unit.component';
import { StockComponent } from './components/stock/stock.component';
import { MoveSelectionComponent } from './components/move/move-selection/move-selection.component';
import { TransformSelectionComponent } from './components/transform/transform-selection/transform-selection.component';
import { TransformConfirmationComponent } from './components/transform/transform-confirmation/transform-confirmation.component';
import { MoveConfirmationComponent } from './components/move/move-confirmation/move-confirmation.component';
import { MoveLocationComponent } from './components/move/move-location/move-location.component';
import { TransformToProductsComponent } from './components/transform/transform-to-products/transform-to-products.component';
import { TransformStepsComponent } from './components/transform/transform-steps/transform-steps.component';
import { MoveStepsComponent } from './components/move/move-steps/move-steps.component';
import { TransformTableComponent } from './components/transform/transform-table/transform-table.component';
import { MovementTableComponent } from './components/move/movement-table/movement-table.component';
import { ProductComponent } from './components/product/product.component';
import { ProductTypeComponent } from './components/product-type/product-type.component';
import { ReceptionsComponent } from './components/receptions/receptions.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { RecipesComponent } from './components/transform/recipes/recipes.component';
import { ProvidersComponent } from './components/providers/providers.component';
import { DeliveryMenComponent } from './components/delivery-men/delivery-men.component';

import { QRCodeModule } from 'angularx-qrcode';
import { ProductsPerLocationComponent } from './components/products-per-location/products-per-location.component';
import { ProductsPerTypeComponent } from './components/products-per-type/products-per-type.component';
import { CompaniesComponent } from './components/clients/companies/companies.component';

import { NgxBarcode6Module } from 'ngx-barcode6';
import { RecipeItemsComponent } from './components/transform/recipe-items/recipe-items.component';
import { OutputsComponent } from './components/outputs/outputs.component';
import { TransformsComponent } from './transformation/transforms/transforms.component';
import { MovesComponent } from './movement/moves/moves.component';
import { WithdrawsComponent } from './withdrawal/withdraws/withdraws.component';
import { ReceivesComponent } from './reception/receives/receives.component';

import { ReceivesComponent as WoReceivesComponent } from './wo/receives/receives.component';
import { DriesComponent } from './wo/dries/dries.component';
import { MovesComponent as WoMovesComponent } from './wo/moves/moves.component';
import { TransformsComponent as WoTransformsComponent } from './wo/transforms/transforms.component';
import { WoProductTypeComponent } from './wo/wo-product-type/wo-product-type.component';
import { WoRecipesComponent } from './wo/wo-recipes/wo-recipes.component';
import { WoProductComponent } from './wo/wo-product/wo-product.component';
import { WoReceptionsComponent } from './wo/wo-receptions/wo-receptions.component';
import { PurchaseOrdersComponent } from './purchases/purchase-orders/purchase-orders.component';
import { PurchaseOrderComponent } from './purchases/purchase-order/purchase-order.component';
import { DryTableComponent } from './transformation/dry-table/dry-table.component';
import { TaProductsComponent } from './ta/ta-products/ta-products.component';
import { TypesComponent } from './barrels/types/types.component';
import { CoopersComponent } from './barrels/coopers/coopers.component';
import { VolumesComponent } from './barrels/volumes/volumes.component';
import { WoodsComponent } from './barrels/woods/woods.component';
import { BarrelArchiveComponent } from './barrels/barrel-archive/barrel-archive.component';
import { BarrelCurrentComponent } from './barrels/barrel-current/barrel-current.component';
import { PalletCurrentComponent } from './pallets/pallet-current/pallet-current.component';
import { PalletArchiveComponent } from './pallets/pallet-archive/pallet-archive.component';
import { ColoursComponent } from './wines/colours/colours.component';
import { GrapeVarietiesComponent } from './wines/grape-varieties/grape-varieties.component';
import { WineCurrentComponent } from './wines/wine-current/wine-current.component';
import { WineArchiveComponent } from './wines/wine-archive/wine-archive.component';
import { RacksComponent } from './barrels/racks/racks.component';
import { BatchesComponent } from './wines/batches/batches.component';
import { RegionsComponent } from './wines/regions/regions.component';
import { GrowersComponent } from './wines/growers/growers.component';
import { TanksComponent } from './wines/tanks/tanks.component';
import { WithdrawalTableComponent } from './withdrawal/withdrawal-table/withdrawal-table.component';
import { ParcelsComponent } from './parcels/parcels.component';
import { BlocksComponent } from './blocks/blocks.component';
import { SpraysComponent } from './sprays/sprays.component';
import { SpreadingsComponent } from './spreadings/spreadings.component';
import { TargetsComponent } from './targets/targets.component';
import { GrowthStageComponent } from './growth-stage/growth-stage.component';
import { BinsComponent } from './bins/bins.component';
import { GrowthStagesComponent } from './growth-stages/growth-stages.component';
import { RackLocationsComponent } from './rack-locations/rack-locations.component';
import { BarrelLocationsComponent } from './barrel-locations/barrel-locations.component';
import { PalletLocationsComponent } from './pallet-locations/pallet-locations.component';
import { BoxLocationsComponent } from './box-locations/box-locations.component';
import { BinLocationsComponent } from './bin-locations/bin-locations.component';
import { BoxesComponent } from './boxes/boxes.component';
import { OwnersComponent } from './barrels/owners/owners.component';
import { TunaComponent } from './tuna/tuna.component';
import { TunaPiecesComponent } from './tuna-pieces/tuna-pieces.component';
import { FridgesComponent } from './fridges/fridges.component';
import { ScalesComponent } from './scales/scales.component';
import { TunaBatchesComponent } from './tuna-batches/tuna-batches.component';
import { HarvestsComponent } from './harvests/harvests.component';
import { CagesComponent } from './cages/cages.component';
import { BoatsComponent } from './boats/boats.component';
import { CaptainsComponent } from './captains/captains.component';
import { CrewsComponent } from './crews/crews.component';
import { DispatchesComponent } from './dispatches/dispatches.component';
import { PackingsComponent } from './packings/packings.component';
import { SalesOrdersComponent } from './sales-orders/sales-orders.component';
import { WorkOrdersComponent } from './work-orders/work-orders.component';
import { TastedWinesComponent } from './wineclub/tasted-wines/tasted-wines.component';
import { MyRankedWinesComponent } from './wineclub/my-ranked-wines/my-ranked-wines.component';
import { RankedWinesComponent } from './wineclub/ranked-wines/ranked-wines.component';
import { Dispatches1Component } from './dispatches1/dispatches1.component';
import { CageToFreezerComponent } from './cage-to-freezer/cage-to-freezer.component';
import { TunaProcessComponent } from './tuna-process/tuna-process.component';
import { TunaPieceProcessComponent } from './tuna-piece-process/tuna-piece-process.component';
import { FillingTankComponent } from './filling/filling-tank/filling-tank.component';
import { EmployeesComponent } from './components/clients/employees/employees.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CompanyConnectionComponent } from './components/clients/company-connection/company-connection.component';
import { InvoiceDownloadComponent } from './invoice-download/invoice-download.component';
import { HomeComponent } from './components/chat/home/home.component';
import { DateDisplayPipe } from './shared/pipe/date-display.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { HotToastModule } from '@ngneat/hot-toast';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

import { MyOrganisationsComponent } from './my-organisations/my-organisations.component';
import { OrganisationsComponent } from './organisations/organisations.component';
import { MyJoinedOrganisationsComponent } from './my-joined-organisations/my-joined-organisations.component';
import { MyJoinedRequestsComponent } from './my-joined-requests/my-joined-requests.component';
import { JoinedRequestsComponent } from './joined-requests/joined-requests.component';
import { BinsArchiveComponent } from './bins-archive/bins-archive.component';

import { PaymentplansComponent } from './paymentplans/paymentplans.component';
import  { PaymentOffersComponent } from './payment-offers/payment-offers.component';
import { BinBrandsComponent } from './bin-brands/bin-brands.component';
import { OffersComponent } from './offers/offers.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { EblocksComponent } from './eblocks/eblocks.component';
import { EblocksHistoryComponent } from './eblocks-history/eblocks-history.component';
import { BinEventsComponent } from './bin-events/bin-events.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    SettingsComponent,
    NavigationBarComponent,
    FooterComponent,
    ZLongComponent,
    ZShortComponent,
    ItemListComponent,
    ClientListComponent,
    LocationListComponent,
    RowListComponent,
    ColumnListComponent,
    LevelListComponent,
    EmployeesComponent,
    ContentTypeComponent,
    PackagingTypesComponent,
    ProductTypesComponent,
    ContainerTypesComponent,
    UnitComponent,
    StockComponent,
    MoveSelectionComponent,
    TransformSelectionComponent,
    TransformConfirmationComponent,
    MoveConfirmationComponent,
    MoveLocationComponent,
    TransformToProductsComponent,
    TransformStepsComponent,
    MoveStepsComponent,
    TransformTableComponent,
    MovementTableComponent,
    ProductComponent,
    ProductTypeComponent,
    ReceptionsComponent,
    MyProfileComponent,
    RecipesComponent,
    ProvidersComponent,
    DeliveryMenComponent,
    ProductsPerLocationComponent,
    ProductsPerTypeComponent,
    CompaniesComponent,
    RecipeItemsComponent,
    OutputsComponent,
    TransformsComponent,
    MovesComponent,
    WithdrawsComponent,
    ReceivesComponent,
    EblocksHistoryComponent,
    WoReceivesComponent,
    WoMovesComponent,
    WoTransformsComponent,
    DriesComponent,
    WoProductTypeComponent,
    WoRecipesComponent,
    WoProductComponent,
    WoReceptionsComponent,
    PurchaseOrdersComponent,
    PurchaseOrderComponent,
    DryTableComponent,
    TaProductsComponent,
    TypesComponent,
    CoopersComponent,
    VolumesComponent,
    WoodsComponent,
    BarrelArchiveComponent,
    BarrelCurrentComponent,
    PalletCurrentComponent,
    PalletArchiveComponent,
    ColoursComponent,
    GrapeVarietiesComponent,
    WineCurrentComponent,
    WineArchiveComponent,
    RacksComponent,
    BatchesComponent,
    RegionsComponent,
    GrowersComponent,
    TanksComponent,
    WithdrawalTableComponent,
    ParcelsComponent,
    BlocksComponent,
    SpraysComponent,
    SpreadingsComponent,
    TargetsComponent,
    GrowthStageComponent,
    BinsComponent,
    GrowthStagesComponent,
    RackLocationsComponent,
    BarrelLocationsComponent,
    PalletLocationsComponent,
    BoxLocationsComponent,
    BinLocationsComponent,
    BoxesComponent,
    OwnersComponent,
    TunaComponent,
    TunaPiecesComponent,
    FridgesComponent,
    ScalesComponent,
    TunaBatchesComponent,
    HarvestsComponent,
    CagesComponent,
    BoatsComponent,
    CaptainsComponent,
    CrewsComponent,
    DispatchesComponent,
    PackingsComponent,
    SalesOrdersComponent,
    WorkOrdersComponent,
    TastedWinesComponent,
    MyRankedWinesComponent,
    RankedWinesComponent,
    Dispatches1Component,
    CageToFreezerComponent,
    TunaProcessComponent,
    TunaPieceProcessComponent,
    FillingTankComponent,
    CompanyProfileComponent,
    CompanyConnectionComponent,
    InvoiceDownloadComponent,
    HomeComponent,
    DateDisplayPipe,

    MyOrganisationsComponent,
    OrganisationsComponent,
    MyJoinedOrganisationsComponent,
    MyJoinedRequestsComponent,
    JoinedRequestsComponent,
    BinsArchiveComponent,

    PaymentOffersComponent,
     BinBrandsComponent,
     OffersComponent,
     EquipmentComponent,
     EblocksComponent,
     EblocksHistoryComponent,
     BinEventsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    GuidedTourModule,
    FormsModule, 
    ReactiveFormsModule,
    // MatToolbarModule,
    // MatIconModule,
    // MatMenuModule,
    // MatSidenavModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    //AngularFirestoreModule,
   // ToastrModule.forRoot(),

    GoogleChartsModule,
    GoogleMapsModule,

    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
    ListboxModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    BreadcrumbModule,
    InputSwitchModule,
    StepsModule,
    PickListModule,
    TreeModule,
    ConfirmPopupModule,
    CardModule,
    TimelineModule,
    TagModule,
    InplaceModule,
    DataViewModule,
    ChipsModule,
    MessagesModule,
    HttpClientModule,
    QRCodeModule,
    NgxBarcode6Module,
    MatAutocompleteModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideFirestore(() => getFirestore()),
    HotToastModule.forRoot(),
    MatMenuModule,
    MatListModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDividerModule,
  ],
  providers: [AuthService, MessageService, ConfirmationService, ProductService, FilterService, DatePipe, GuidedTourService,],
  bootstrap: [AppComponent]
})
export class AppModule { }